import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { useActivity } from '@stackflow/react'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useLogEvent } from '@src/components/Analytics'
import { CurrentPositionDot } from '@src/components/Map'
import { KakaoCustomOverlay, KakaoMap, useKakaoMap } from '@src/components/Map/KakaoMap'
import { useCurrentPosition } from '@src/hooks'
import { checkInRegionInfoAtom } from '@src/store'

import Marker from './Marker'
import { useFilteredPois, usePoiVariables, useSelectedPoi } from '../hooks'
import { isMapBoundChangedAtom } from '../state/map'

interface Props {
  initialZoomLevel: number
}
const Map: React.FC<Props> = ({ initialZoomLevel }) => {
  const logEvent = useLogEvent()
  const { map, getLevel } = useKakaoMap()

  const { spotlightPoiOnMap } = useSelectedPoi()
  const filteredPois = useFilteredPois()
  const { isTop } = useActivity()
  const { access, position } = useCurrentPosition()

  const setIsMapBoundChanged = useSetRecoilState(isMapBoundChangedAtom)

  const checkInRegion = useRecoilValue(checkInRegionInfoAtom)
  const { coordinates: centerPositionString } = usePoiVariables()

  const mapCenter: React.ComponentPropsWithRef<typeof KakaoMap>['center'] = useMemo(() => {
    if (centerPositionString) {
      const _coordinates = centerPositionString.split(',').map((str) => parseFloat(str))

      return {
        latitude: _coordinates[0],
        longitude: _coordinates[1],
      }
    } else {
      return checkInRegion.centerCoordinates
    }
  }, [centerPositionString, checkInRegion.centerCoordinates])

  const zoomLevelRef = useRef<number>(initialZoomLevel)

  const handleClickMarker = useCallback(
    (poi: Poi) => {
      spotlightPoiOnMap(poi.id!)

      const bizAccountId = poi.bizAccountId

      logEvent({
        name: 'click_map_pin',
        params: {
          poi_type: bizAccountId ? 'biz_account' : 'poi',
          poi_id: bizAccountId ?? poi.id,
        },
      })
    },
    [logEvent, spotlightPoiOnMap]
  )

  const handleDragEnd = useCallback(() => {
    logEvent({ name: 'click_dragend_map', params: {} })
    setIsMapBoundChanged(true)
  }, [logEvent, setIsMapBoundChanged])

  const handleZoomChanged = useCallback(() => {
    const previousLevel = zoomLevelRef.current
    const currentLevel = getLevel()

    if (previousLevel === currentLevel) return

    logEvent({
      name: previousLevel < currentLevel ? 'click_zoom_out_map' : 'click_zoom_in_map',
      params: {
        previous_level: previousLevel,
        current_level: currentLevel,
      },
    })

    setIsMapBoundChanged(true)
    zoomLevelRef.current = getLevel()
  }, [getLevel, logEvent, setIsMapBoundChanged])

  useEffect(() => {
    if (!map) return

    if (isTop) {
      map.relayout()
    }
  }, [isTop, map])

  return (
    <StyledKakaoMap
      center={mapCenter}
      zoomable={true}
      draggable={true}
      level={initialZoomLevel}
      watermarkPosition={['top', 'right']}
      onZoomChanged={handleZoomChanged}
      onDragEnd={handleDragEnd}>
      {filteredPois.map((poi) => {
        return <Marker poi={poi.poi} key={poi.poi.id} onClick={handleClickMarker} />
      })}
      {access === 'granted' && (
        <KakaoCustomOverlay position={position} clickable={false} zIndex={1}>
          <CurrentPositionDot />
        </KakaoCustomOverlay>
      )}
    </StyledKakaoMap>
  )
}

const StyledKakaoMap = styled(KakaoMap)`
  position: initial;
  width: 100%;
  height: 100%;
`

export default Map

import styled from '@emotion/styled'
import IconThumbUpFill from '@karrotmarket/karrot-ui-icon/lib/react/IconThumbUpFill'
import { vars } from '@seed-design/design-token'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { bizReviewApi } from '@src/apis'
import { POIReactionsApi } from '@src/apis/generated/poi/api'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { useAnalytics } from './hooks'
import { Reaction, reactionItemSelector } from './state/reaction'

interface Props {
  resourceType: Reaction['resourceType']
  resourceId: Reaction['resourceId']
  type: Reaction['type']
  bizId?: string | null
}

const UpvoteButton: React.FC<Props> = ({ resourceType, resourceId, type, bizId }) => {
  const logEvent = useAnalytics()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const [reaction, setReaction] = useRecoilState(
    reactionItemSelector({ resourceType, resourceId, type, userId: userInfo?.id.toString() ?? '' })
  )

  const isUpvotedByMe = reaction?.isReactedByMe ?? false
  const upvoteCount = reaction?.reactionCount ?? 0

  const isUpvotedRef = useRef<boolean>(isUpvotedByMe)

  const [upvoteStatus, setUpvoteStatus] = useState<{
    count: number
    isUpvoted: boolean
  }>({
    count: upvoteCount <= 0 ? 0 : upvoteCount,
    isUpvoted: isUpvotedByMe,
  })

  const convertResourceTypeToSourceType = (resourceType: Props['resourceType']) =>
    resourceType === 'STORY_COMMENT'
      ? 'community_comment'
      : resourceType === 'STORY_ARTICLE'
      ? 'community_post'
      : resourceType === 'BIZ_REVIEW'
      ? 'biz_review'
      : 'poi_review'

  const handleUpvoteFetching = useCallback(
    async (isUpvoted: boolean) => {
      if (isUpvoted !== isUpvotedRef.current) return

      const upvote = async () => {
        isUpvotedRef.current = true

        if (resourceType === 'BIZ_REVIEW') {
          if (bizId) await bizReviewApi.like(bizId, resourceId)
        } else {
          await POIReactionsApi.createReactionV2(userInfo!.authToken, {
            resourceId,
            resourceType,
            type,
          })
        }

        setReaction((prev) => ({
          ...prev,
          resourceId,
          type,
          resourceType,
          isReactedByMe: !prev?.isReactedByMe ?? true,
          reactionCount: prev?.reactionCount ? prev?.reactionCount + 1 : 1,
        }))

        logEvent('click_upvote_button', {
          source_type: convertResourceTypeToSourceType(resourceType),
          source_id: resourceId,
        })
      }

      const cancelUpvote = async () => {
        isUpvotedRef.current = false

        if (resourceType === 'BIZ_REVIEW') {
          if (bizId) await bizReviewApi.unlike(bizId, resourceId)
        } else {
          await POIReactionsApi.deleteReactionV2(userInfo!.authToken, {
            resourceId,
            resourceType,
          })
        }

        setReaction((prev) => ({
          ...prev,
          resourceId,
          type,
          resourceType,
          isReactedByMe: !prev?.isReactedByMe ?? false,
          reactionCount: prev?.reactionCount ? prev?.reactionCount - 1 : 0,
        }))

        logEvent('click_cancel_upvote_button', {
          source_type: convertResourceTypeToSourceType(resourceType),
          source_id: resourceId,
        })
      }

      isUpvoted ? cancelUpvote() : upvote()
    },
    [bizId, logEvent, resourceId, resourceType, setReaction, type, userInfo]
  )

  const debouncedHandleUpvoteFetching = useMemo(() => debounce(handleUpvoteFetching, 300), [handleUpvoteFetching])

  const handleUpvote = useCallback(
    async (isUpvoted: boolean) => {
      const upvote = () => {
        setUpvoteStatus((prevState) => ({
          count: prevState.count + 1,
          isUpvoted: true,
        }))
      }

      const cancelUpvote = () => {
        setUpvoteStatus((prevState) => ({
          count: prevState.count - 1,
          isUpvoted: false,
        }))
      }

      isUpvoted ? cancelUpvote() : upvote()

      debouncedHandleUpvoteFetching(isUpvoted)
    },
    [debouncedHandleUpvoteFetching]
  )

  useEffect(() => {
    isUpvotedRef.current = isUpvotedByMe

    setUpvoteStatus({
      count: upvoteCount <= 0 ? 0 : upvoteCount,
      isUpvoted: isUpvotedByMe,
    })
  }, [isUpvotedByMe, upvoteCount])

  return (
    <Button onClick={() => handleUpvote(upvoteStatus.isUpvoted)} isUpvoted={upvoteStatus.isUpvoted}>
      <IconThumbUpFill className="icon" />
      도움돼요{upvoteStatus.count > 0 && ` ${upvoteStatus.count}`}
    </Button>
  )
}

const Button = styled.button<{ isUpvoted: boolean }>`
  width: fit-content;
  padding: 0.375rem 0.75rem;
  background-color: ${({ isUpvoted }) => (isUpvoted ? vars.$scale.color.carrotAlpha50 : 'transparent')};
  border: 1px solid ${({ isUpvoted }) => (isUpvoted ? vars.$semantic.color.primary : vars.$scale.color.gray300)};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 154%;
  color: ${({ isUpvoted }) => (isUpvoted ? vars.$semantic.color.primary : vars.$scale.color.gray700)};

  .icon {
    width: 1rem;
    height: 1rem;
    color: ${({ isUpvoted }) => (isUpvoted ? vars.$semantic.color.primary : vars.$scale.color.gray600)};
  }
`

export default UpvoteButton

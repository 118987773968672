/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { CurationCategoryResponse } from '../models';
/**
 * CurationCategoriesApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @param {'daangn-map' | 'parenting-map'} serviceName 호출하는 서비스의 이름. 서비스마다 제공되는 큐레이션 카테고리가 달라요. default: daangn-map(당근지도)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getCurationCategoriesV2AxiosParamCreator = async (serviceName: 'daangn-map' | 'parenting-map', options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/curation-category`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (serviceName !== undefined) {
          localVarQueryParameter['serviceName'] = serviceName;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * CurationCategoriesApi - functional programming interface
 * @export
 */
    /**
     * 
     * @param {'daangn-map' | 'parenting-map'} serviceName 호출하는 서비스의 이름. 서비스마다 제공되는 큐레이션 카테고리가 달라요. default: daangn-map(당근지도)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getCurationCategoriesV2Fp = async (serviceName: 'daangn-map' | 'parenting-map', options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurationCategoryResponse>> => {
        const localVarAxiosArgs = await getCurationCategoriesV2AxiosParamCreator(serviceName, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * CurationCategoriesApi - factory interface
 * @export
 */
    /**
     * 
     * @param {'daangn-map' | 'parenting-map'} serviceName 호출하는 서비스의 이름. 서비스마다 제공되는 큐레이션 카테고리가 달라요. default: daangn-map(당근지도)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getCurationCategoriesV2 = (serviceName: 'daangn-map' | 'parenting-map', options?: any): AxiosPromise<CurationCategoryResponse> => {
        return getCurationCategoriesV2Fp(serviceName, options).then((request) => request());
    }



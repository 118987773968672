import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { useImageSliderState } from '@src/hooks/useImageSliderState'

import ImageCenterCover from './ImageCenterCover'
import ImageDetailSlider from './ImageDetailSlider'

interface Props {
  images: string[]
}

const ImageBoard: React.FC<Props> = ({ images }) => {
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()

  const handleClickMorePhotos = (index: number) => {
    handleSelectDetailImage(index)
  }

  return images.length === 0 ? null : (
    <>
      <StyledImageBoard imageLength={images.length}>
        <div className="image-container" onClick={handleClickMorePhotos.bind(null, 0)}>
          <ImageCenterCover src={images[0]} />
        </div>
        {images.length >= 2 && (
          <div className="rightside-images">
            {images.slice(1, 3).map((image, idx) => {
              const showMorePhotoOverlay = idx === 1 && images.length > 3
              return (
                <div key={idx} className="image-container" onClick={handleClickMorePhotos.bind(null, idx + 1)}>
                  <ImageCenterCover src={image} />
                  {showMorePhotoOverlay && <div className="more-photo-overlay">+{images.length - 3}</div>}
                </div>
              )
            })}
          </div>
        )}
      </StyledImageBoard>
      <ImageDetailSlider onClose={handleCloseDetailImage} initialIndex={selectedImageIdx} images={images} />
    </>
  )
}

const StyledImageBoard = styled.div<{ imageLength: number }>`
  display: flex;
  position: relative;
  overflow: hidden;
  border-radius: 6px;
  width: 100%;
  .image-container {
    position: relative;
    display: block;
    width: ${({ imageLength }) => (imageLength > 1 ? '50%' : '100%')};
    height: 11.25rem;
  }
  .rightside-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 2px;
    height: 180px;
    width: ${({ imageLength }) => (imageLength > 1 ? '50%' : '100%')};
    .image-container {
      height: ${({ imageLength }) => (imageLength > 2 ? '50%' : '100%')};
      width: 100%;
    }
    .image-container:first-of-type {
      margin: 0 0 2px;
    }
    .more-photo-overlay {
      ${vars.$semantic.typography.label1Regular}
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba(0, 0, 0, 0.4);
      color: ${vars.$static.color.staticWhite};
    }
  }
`

export default ImageBoard

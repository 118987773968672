import styled from '@emotion/styled'
import { ActivityComponentType } from '@stackflow/react'
import React, { useEffect } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { withKakaoMap } from '@src/components/Map/KakaoMap'
import { watchedByUserMapperSelector } from '@src/shared/watch/store'
import { Screen } from '@src/stackflow'

import RightBottomButtons from './FloatingLayer/RightBottomButtons'
import ViewTypeToggleButton from './FloatingLayer/ViewToggleButton'
import { useClearNudge, useOnFilteredPoisChangeEffect, useReviewFeedback, useSyncPoisEffect } from './hooks'
import useFetchInitialPois from './hooks/useFetchInitialPois'
import IntroductionDrawer from './IntroductionDrawer'
import { ListView } from './ListView/ListView'
import { MapView } from './MapView/MapView'
import MapWithoutMap from './MapWithoutMap'
import ButtonShare from './Navbar/ButtonShare'
import ButtonWatchList from './Navbar/ButtonWatchList'
import CurationCategoryButtons from './Navbar/CurationCategoryButtons'
import FilterButtonsWithAsyncBoundary from './Navbar/FilterButtons'
import { submittedReviewAtom } from './state'
import type { ViewType } from './types'
import NudgeItems from '../../components/Nudge/NudgeItems'

export type DaangnMapParams = {
  p?: string
  theme?: string
  poi_id?: string
  viewType?: ViewType
}
const MapMain: ActivityComponentType<DaangnMapParams> = ({ params: { viewType = 'MAP' } }) => {
  const { handleReviewFeedback } = useReviewFeedback()

  const submittedReview = useRecoilValue(submittedReviewAtom)

  useEffect(() => {
    if (!submittedReview) return

    const { poiId, bizAccountId, reviewId } = submittedReview

    handleReviewFeedback({ poiId, bizAccountId, reviewId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedReview])

  useRecoilValueLoadable(watchedByUserMapperSelector)
  useClearNudge()
  useSyncPoisEffect()
  useFetchInitialPois()
  useOnFilteredPoisChangeEffect()

  return (
    <>
      <Screen
        appBar={{
          title: '당근지도',
          border: false,
          appendRight: () => {
            return (
              <ButtonWrapper>
                <ButtonWatchList />
                <ButtonShare />
              </ButtonWrapper>
            )
          },
        }}>
        <FlexContainer>
          <CurationCategoryButtons />
          <FilterButtonsWithAsyncBoundary />

          <Contents>
            <MapView viewType={viewType} />
            {viewType === 'LIST' && <ListView viewType={viewType} />}
          </Contents>
        </FlexContainer>

        <RightBottomButtons viewType={viewType} />
        <ViewTypeToggleButton viewType={viewType} />
      </Screen>

      <IntroductionDrawer />
      <NudgeItems />
    </>
  )
}

export const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`
export const Contents = styled.div`
  flex: 1;
  position: relative;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export default withKakaoMap(MapMain, MapWithoutMap)

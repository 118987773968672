import { css } from '@emotion/css'
import styled from '@emotion/styled'
import IconNewtopicFill from '@karrotmarket/karrot-ui-icon/lib/react/IconNewtopicFill'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { MouseEventHandler } from 'react'
import { useRecoilValue } from 'recoil'

import { CurationMapItem, Poi as PoiType } from '@src/apis/generated/poi/models'
import defaultPoiImage from '@src/assets/images/poi_img_map.png'
import { bridge } from '@src/bridge'
import { EllipsisText } from '@src/components/Map/EllipsisText'
import { generateGlobalNudgeKey } from '@src/components/Nudge/generateNudgeKey'
import { uniqueKeysForNudge } from '@src/components/Nudge/uniqueKeysForNudge'
import { useNudge } from '@src/components/Nudge/useNudge'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useWatch } from '@src/shared/watch/hooks'
import { poiToPlaceWatchId } from '@src/shared/watch/utils'
import WatchButton from '@src/shared/watch/WatchButton'
import { useActivity, useNavigator } from '@src/stackflow'
import { APP_BAR_HEIGHT } from '@src/stackflow/styles'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { getSafeAreaValue } from '@src/styles/utils'
import { calculateDistance } from '@src/utils/coordinate'
import { WEEK_IN_MS } from '@src/utils/date'
import { createQueryString } from '@src/utils/url'

import { useAnalytics } from '../hooks'

interface Props {
  poi: PoiType
  distance: CurationMapItem['distance']
}

const Poi: React.FC<Props> = ({ poi, distance }) => {
  const { referrer } = useActivityParams<{ referrer: string }>()
  const { id, name, category, createdAt, thumbnail, bizAccountId } = poi
  const logEvent = useAnalytics()
  const { showNudge } = useNudge()
  const { resetWatchedByUser } = useWatch()
  const { push } = useNavigator()
  const { name: activityName } = useActivity()
  const placeWatchId = poiToPlaceWatchId(poi)

  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const showNewBadge = createdAt && new Date().valueOf() - new Date(createdAt).valueOf() <= WEEK_IN_MS

  const handleRouteToDetailPage: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()

    logEvent('click_view_poi_details_page_card', {
      poi_type: bizAccountId ? 'biz_account' : 'poi',
      poi_id: bizAccountId ?? id,
      view_type: 'map',
    })

    if (bizAccountId) {
      const queryString = createQueryString({
        referrer: referrer ?? `place.${activityName}`,
        entry: `place.${activityName}`,
      })

      bridge.router.push({
        app: `${WEBVIEW_BASE_URL}business-platform/home`,
        path: `/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
        navbar: false,
        scrollable: false,
      })
    } else {
      push('poi_detail', { poiId: id })

      resetWatchedByUser()
    }
  }

  const handleLogClickWatch = (setToWatch: boolean) => () => {
    logEvent(setToWatch ? 'click_add_watch_button' : 'click_remove_watch_button', {
      poi_type: bizAccountId ? 'biz_account' : 'poi',
      poi_id: bizAccountId ?? id,
    })
  }

  const handleAddWatch = () => {
    if (!userInfo?.id) return

    handleLogClickWatch(true)()

    const safeAreaTop = parseInt(getSafeAreaValue('top').slice(0, getSafeAreaValue('bottom').length - 2))

    showNudge(generateGlobalNudgeKey(uniqueKeysForNudge.watchPage, userInfo.id), {
      text: '관심 가게는 여기서 볼 수 있어요.',
      arrowDirection: 'topRight',
      isShowCloseButton: false,
      duration: 5000,
      top: safeAreaTop + APP_BAR_HEIGHT,
      right: 48,
    })
  }

  return (
    <Card onClick={handleRouteToDetailPage}>
      <Image>
        <img className="thumbnail-image" alt="thumbnail-image" src={thumbnail?.url || defaultPoiImage} />
      </Image>
      <Content>
        <Text>
          <Title className="title">
            {name}
            {showNewBadge && (
              <IconNewtopicFill
                className="newBadge"
                width="16px"
                height="16px"
                color={vars.$semantic.color.primary}
                fill={vars.$semantic.color.primary}
              />
            )}
          </Title>
          <Information>
            {category?.name}
            {category && !!distance && <span className="bull">&bull;</span>}
            {!!distance && calculateDistance(distance)}
          </Information>
        </Text>
        <StyledWatchButton
          placeWatchId={placeWatchId}
          onAddWatch={handleAddWatch}
          onRemoveWatch={handleLogClickWatch(false)}
          customDefaultIconCSS={css`
            path:last-of-type {
              fill: ${vars.$scale.color.gray600};
            }
          `}
        />
      </Content>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  display: flex;
  align-items: left;
  overflow: hidden;
  border-radius: 6px;
  border: 1px solid ${vars.$semantic.color.divider2};
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.04);
`

const Image = styled.div`
  height: 62px;
  flex-basis: 62px;

  .thumbnail-image {
    object-fit: cover;
    width: 62px;
    height: 62px;
    min-width: 62px;
    min-height: 62px;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 62px);
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: calc(100% - 54px);
  padding: 10px 0 10px 14px;

  .newBadge {
    margin: 0 0 0 2px;
  }
`

const Title = styled(EllipsisText)`
  ${vars.$semantic.typography.bodyM2Regular}
  margin: 0 4px 2px 0;
  width: 100%;
  height: 20px;
  svg {
    transform: translateY(4px);
  }
`

const Information = styled(EllipsisText)`
  ${vars.$semantic.typography.caption1Regular}
  min-width: 30px;
  height: 20px;
  color: ${vars.$scale.color.gray600};

  .bull {
    margin: 0 2px 2px;
    color: ${vars.$scale.color.gray600};
    font-weight: 100;
  }
`

const StyledWatchButton = styled(WatchButton)`
  padding: 0 14px 0 0;
  color: ${vars.$scale.color.gray600};
  width: 54px;
  font-size: 0.75rem;
`

export default Poi

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { SearchCategoriesV2200Response } from '../models';
/**
 * CategoriesApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @summary SMB 카테고리를 위한 API. 통합 카테고리와 관련 없음
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getCategoriesV2AxiosParamCreator = async (options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/categories`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary ID로 통합 카테고리 검색
   * @param {number} id 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getCategoryByIdV2AxiosParamCreator = async (id: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/categories/{id}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 통합 카테고리 검색
   * @param {string} keyword 
   * @param {number} page 조회할 페이지
   * @param {number} perPage 조회할 아이템 갯수
   * @param {string} [servicedFrom] 검색 요청을 한 서비스의 이름. 지표 트래킹을 위해 꼭  넣어주세요!
   * @param {string} [xAuthToken] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const searchCategoriesV2AxiosParamCreator = async (keyword: string, page: number, perPage: number, servicedFrom?: string, xAuthToken?: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/categories/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (keyword !== undefined) {
          localVarQueryParameter['keyword'] = keyword;
      }

      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }

      if (servicedFrom !== undefined) {
          localVarQueryParameter['servicedFrom'] = servicedFrom;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * CategoriesApi - functional programming interface
 * @export
 */
    /**
     * 
     * @summary SMB 카테고리를 위한 API. 통합 카테고리와 관련 없음
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getCategoriesV2Fp = async (options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await getCategoriesV2AxiosParamCreator(options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary ID로 통합 카테고리 검색
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getCategoryByIdV2Fp = async (id: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await getCategoryByIdV2AxiosParamCreator(id, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 통합 카테고리 검색
     * @param {string} keyword 
     * @param {number} page 조회할 페이지
     * @param {number} perPage 조회할 아이템 갯수
     * @param {string} [servicedFrom] 검색 요청을 한 서비스의 이름. 지표 트래킹을 위해 꼭  넣어주세요!
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const searchCategoriesV2Fp = async (keyword: string, page: number, perPage: number, servicedFrom?: string, xAuthToken?: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchCategoriesV2200Response>> => {
        const localVarAxiosArgs = await searchCategoriesV2AxiosParamCreator(keyword, page, perPage, servicedFrom, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * CategoriesApi - factory interface
 * @export
 */
    /**
     * 
     * @summary SMB 카테고리를 위한 API. 통합 카테고리와 관련 없음
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getCategoriesV2 = (options?: any): AxiosPromise<void> => {
        return getCategoriesV2Fp(options).then((request) => request());
    }
    /**
     * 
     * @summary ID로 통합 카테고리 검색
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getCategoryByIdV2 = (id: number, options?: any): AxiosPromise<void> => {
        return getCategoryByIdV2Fp(id, options).then((request) => request());
    }
    /**
     * 
     * @summary 통합 카테고리 검색
     * @param {string} keyword 
     * @param {number} page 조회할 페이지
     * @param {number} perPage 조회할 아이템 갯수
     * @param {string} [servicedFrom] 검색 요청을 한 서비스의 이름. 지표 트래킹을 위해 꼭  넣어주세요!
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const searchCategoriesV2 = (keyword: string, page: number, perPage: number, servicedFrom?: string, xAuthToken?: string, options?: any): AxiosPromise<SearchCategoriesV2200Response> => {
        return searchCategoriesV2Fp(keyword, page, perPage, servicedFrom, xAuthToken, options).then((request) => request());
    }



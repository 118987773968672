import { CurationMapItem } from '@src/apis/generated/poi/models'
import { GPS } from '@src/types/global'
import { convertCoordinatesArrayToObject, haversineDistance } from '@src/utils/coordinate'

export const sortPoisByDistance = (pois: CurationMapItem[], centerPosition: GPS) => {
  const centerPositionCoordinates = convertCoordinatesArrayToObject(centerPosition)

  return pois?.slice().sort(({ poi: a }, { poi: b }) => {
    return a.coordinates && b.coordinates
      ? haversineDistance(a.coordinates, centerPositionCoordinates) -
          haversineDistance(b.coordinates, centerPositionCoordinates)
      : -1
  })
}

import React from 'react'

import { bridge } from '@src/bridge'
import { SearchAddressListItem } from '@src/utils/searchAddress'

import { useAnalytics } from './hooks'
import SearchAddressView from './SearchAddressView'

const SearchAddressExternal = () => {
  const logEvent = useAnalytics()

  const handleClickAddress = (address: SearchAddressListItem) => {
    bridge.analytics.log({
      name: 'click_poi_address_select',
      params: { from: 'commerce' },
    })
    logEvent('click_select_address')

    bridge.stream.emit('poi.select_address', address)
    bridge.router.close()
  }

  return <SearchAddressView onClickAddress={handleClickAddress} />
}

export default SearchAddressExternal

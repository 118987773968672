import { karrotBridge } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'

import Request from './Request'
import { PoiTaggedCommentsResponse } from './types/communityApi'

export default class CommunityApi extends Request {
  getPoiTaggedComments = async ({
    storyArticleId,
    cursor,
    limit,
  }: {
    storyArticleId: number
    cursor?: string
    limit?: number
  }) => {
    const userAgent = IS_LOCAL
      ? 'TowneersApp/22.50.0/225000 iOS/16.0.0/1946 iPhone13,1'
      : (await karrotBridge.getAppInfo({})).info.app.userAgent

    return this.req.get<PoiTaggedCommentsResponse>(`/api/v1/articles/${storyArticleId}/comments/poi-tagged`, {
      headers: {
        ...this.getUserAuthHeader(),
        'X-User-Agent': userAgent,
      },
      params: {
        cursor,
        limit,
      },
    })
  }
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconBackwardRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconBackwardRegular'
import { vars } from '@seed-design/design-token'
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'

import { useNavigator } from '@src/stackflow'
import { getSafeAreaStyle } from '@src/styles/iosSafeArea'
import type { Theme } from '@src/types/theme'

export interface NavbarProps {
  title?: React.ReactNode
  appendRight?: React.ReactNode
  appendBottom?: React.ReactNode
  hideBoxShadow?: boolean
  iconColor: string
  theme: Theme
  showNavigationBg?: boolean
  showLinearGradientTmp?: boolean // MEMO: 다크모드 적용 후 없앨 예정인 값
  onClickBack?: () => void
}

const Navbar = forwardRef<HTMLDivElement, NavbarProps>(
  (
    {
      title,
      appendRight,
      appendBottom,
      hideBoxShadow = false,
      iconColor,
      theme,
      showNavigationBg = true,
      showLinearGradientTmp = true,
      onClickBack,
    },
    ref
  ) => {
    const [centerTextMaxWidth, setCenterTextMaxWidth] = useState<undefined | number>(undefined)

    const { pop } = useNavigator()

    const centerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      if (theme === 'Cupertino') {
        let currentClientWidth = 0
        let animationFrameId: number

        const detectMaxWidth = () => {
          animationFrameId = requestAnimationFrame(() => {
            const clientWidth = centerRef.current?.clientWidth
            if (clientWidth && clientWidth !== currentClientWidth) {
              currentClientWidth = clientWidth
              setCenterTextMaxWidth(clientWidth - 32)
            }
            detectMaxWidth()
          })
        }
        detectMaxWidth()
        return () => {
          cancelAnimationFrame(animationFrameId)
        }
      }
    }, [theme])

    const leftIconInstance = useMemo(
      () => (
        <Back
          iconColor={iconColor}
          onClick={() => {
            if (onClickBack) {
              onClickBack()
            } else {
              pop()
            }
          }}>
          <IconBackwardRegular className="icon" />
        </Back>
      ),
      [iconColor, pop, onClickBack]
    )

    return (
      <NavbarBase>
        <Container
          navigatorTheme={theme}
          ref={ref}
          showNavigationBg={showNavigationBg}
          showLinearGradientTmp={showLinearGradientTmp}
          hideBoxShadow={hideBoxShadow}>
          <Flex>
            <Left>{leftIconInstance}</Left>
            <Center ref={centerRef} showNavigationBg={showNavigationBg} navigatorTheme={theme}>
              <h1>
                <TitleSpan style={{ maxWidth: centerTextMaxWidth }}>{title}</TitleSpan>
              </h1>
            </Center>
            <Right>{appendRight}</Right>
          </Flex>
        </Container>

        {appendBottom && <Bottom navigatorTheme={theme}>{appendBottom}</Bottom>}
      </NavbarBase>
    )
  }
)

const NavbarBase = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`

interface ContainerProps {
  navigatorTheme: Theme
  showNavigationBg: boolean
  hideBoxShadow: boolean
  showLinearGradientTmp: boolean
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  width: 100%;
  top: 0;

  ${(props) => {
    switch (props.navigatorTheme) {
      case 'Cupertino':
        return css`
          ${getSafeAreaStyle({
            direction: 'top',
            property: 'height',
            converter: (safeAreaValue) => `2.75rem + ${safeAreaValue}`,
          })}
          ${props.hideBoxShadow ? '' : 'box-shadow: inset 0px -0.5px 0 rgba(0, 0, 0, 0.12);'}
        `
      default:
        return css`
          height: 3.5rem;
          ${props.hideBoxShadow ? '' : 'box-shadow: inset 0px -0.5px 0 rgba(0, 0, 0, 0.12);'}
        `
    }
  }}
  background: ${({ showNavigationBg, showLinearGradientTmp }) =>
    showNavigationBg
      ? vars.$semantic.color.paperDefault
      : showLinearGradientTmp
      ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%)'
      : 'transparent'};
  ${({ showNavigationBg }) =>
    !showNavigationBg &&
    css`
      box-shadow: none;
    `}

  z-index: 100;
  transition: background 200ms;
`

const Flex = styled.div`
  display: flex;
  position: absolute;
  gap: 16px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  ${getSafeAreaStyle({
    direction: 'top',
    property: 'padding-top',
    converter: (safeAreaValue) => safeAreaValue,
  })}
`

const Left = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;

  &:empty {
    display: none;
  }
  svg span {
    transition: fill 450ms;
  }
`

const Back = styled.button<{ iconColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 1;
  transition: opacity 300ms;

  &:active {
    opacity: 0.2;
    transition: opacity 0s;
  }
  .icon {
    width: 24px;
    height: 24px;
    color: ${({ iconColor }) => iconColor};
  }
`

interface CenterProps {
  navigatorTheme: Theme
  showNavigationBg: boolean
}
const Center = styled.div<CenterProps>`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    width: 100%;
    opacity: ${({ showNavigationBg }) => (showNavigationBg ? 1 : 0)};

    ${(props) => {
      switch (props.navigatorTheme) {
        case 'Cupertino':
          return css`
            font-family: -apple-system, BlinkMacSystemFont;
            text-align: center;
            font-weight: 600;
            font-size: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            padding: 12px 0 0;
            padding: calc(env(safe-area-inset-top) + 12px) 0 0;
            padding: calc(constant(safe-area-inset-top) + 12px) 0 0;
          `
        default:
          return css`
            font-family: 'Noto Sans KR', sans-serif;
            text-align: left;
            font-weight: 700;
            font-size: 1.1875rem;
          `
      }
    }}
  }
`

const Right = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: auto;
  z-index: 1;

  &:empty {
    display: none;
  }
`

const TitleSpan = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
  font-weight: inherit;
`

const Bottom = styled.div<{ navigatorTheme: Theme }>`
  position: absolute;
  width: 100%;
  z-index: 20;
  ${(props) => {
    switch (props.navigatorTheme) {
      case 'Cupertino':
        return css`
          ${getSafeAreaStyle({
            direction: 'top',
            property: 'top',
            converter: (safeAreaValue) => `2.75rem + ${safeAreaValue}`,
          })}
        `
      default:
        return css`
          top: 3.5rem;
        `
    }
  }}
`

export default Navbar

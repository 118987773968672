import {
  CouponBoxDtoEventType as CouponEventType,
  CouponBoxDtoType as CouponType,
} from '@daangn/local-business-network/lib/coupon'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useMemo } from 'react'

import CouponCardActions from './Actions'
import BarcodeCouponCardInfo from './BarcodeCouponCard/BarcodeCouponCardInfo'
import OfflineCouponCardInfo from './OfflineCouponCard/OfflineCouponCardInfo'
import OnlineCouponCardInfo from './OnlineCouponCard/OnlineCouponCardInfo'
import { BizAccountType, CouponStateReturnValue } from '../types'
import { isCouponManagerViewUiDisabled, isCouponUserViewUiDisabled } from '../utils/coupon-util'

export interface CouponCardBaseProps {
  isManager: boolean
  couponStatus: CouponStateReturnValue
  couponId: number
  name: string
  target: CouponEventType
  issuedCount: number | null
  claimedCount: number
  couponType: CouponType
  bizAccountType?: BizAccountType
  minConditionPrice?: number
  expiresAt?: string
  bizId?: string
  disableClickAction?: boolean
  brandLogoImg?: string
  onClickCouponContent?: () => void
}

const CouponCardBase: React.FC<CouponCardBaseProps> = ({
  issuedCount,
  claimedCount,
  couponStatus,
  isManager,
  name,
  expiresAt,
  couponId,
  minConditionPrice,
  bizId,
  target,
  disableClickAction = false,
  couponType,
  bizAccountType,
  brandLogoImg,
  onClickCouponContent,
}) => {
  const uiDisabled = useMemo(
    () => (isManager ? isCouponManagerViewUiDisabled(couponStatus) : isCouponUserViewUiDisabled(couponStatus)),
    [couponStatus, isManager]
  )
  const renderCardInfoByType = () => {
    switch (couponType) {
      case 'ONLINE':
        return (
          <OnlineCouponCardInfo
            isManager={isManager}
            couponStatus={couponStatus}
            name={name}
            target={target}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            couponType={couponType}
            minConditionPrice={minConditionPrice}
            couponBoxId={couponId}
            expiresAt={expiresAt}
            bizAccountType={bizAccountType}
          />
        )
      case 'THIRD_BARCODE':
        return (
          <BarcodeCouponCardInfo
            isManager={isManager}
            couponStatus={couponStatus}
            name={name}
            target={target}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            couponType={couponType}
            brandLogoImg={brandLogoImg}
            expiresAt={expiresAt}
            bizId={bizId ?? ''}
            couponBoxId={couponId}
            bizAccountType={bizAccountType}
            onClickCouponContent={onClickCouponContent}
          />
        )
      default:
        return (
          <OfflineCouponCardInfo
            isManager={isManager}
            couponStatus={couponStatus}
            name={name}
            target={target}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            couponType={couponType}
            couponBoxId={couponId}
            expiresAt={expiresAt}
            bizAccountType={bizAccountType}
            onClickCouponContent={onClickCouponContent}
          />
        )
    }
  }

  return (
    <S_Base uiDisabled={uiDisabled}>
      <S_CouponCardInfoWrapper>{renderCardInfoByType()}</S_CouponCardInfoWrapper>

      <CouponCardActions
        isManager={isManager}
        status={couponStatus}
        couponId={couponId}
        couponType={couponType}
        disabled={disableClickAction}
        uiDisabled={uiDisabled}
        bizId={bizId}
        target={target}
      />
    </S_Base>
  )
}

export interface UiDisableProps {
  uiDisabled: boolean
}

const S_Base = styled.div<UiDisableProps>`
  background: ${vars.$semantic.color.paperDefault};
  border: 1px solid ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray400 : vars.$scale.color.carrot400)};
  border-radius: 0.375rem;
  display: flex;
  overflow: hidden;
  min-height: 5.5rem;
`
const S_CouponCardInfoWrapper = styled.div`
  flex: 1;
`

export default CouponCardBase

import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import CreatePoiReviewWithFixedPoiPage from './CreatePoiReviewWithFixedPoiPage'
import UpdatePoiReviewPage from './UpdatePoiReviewPage'
import { WritePoiReviewPage as DefaultWritePoiReviewPage } from './WritePoiReviewPage'

export type ActivityParams = {
  action: 'write' | 'create' | 'update'
  poiId?: string
  poiName?: string
  bizAccountId?: string
  reviewId?: string
}
const WritePoiReviewPage: ActivityComponentType<ActivityParams> = ({ params: { action, poiId, reviewId } }) => {
  switch (action) {
    case 'create':
      if (!poiId) return <DefaultWritePoiReviewPage />

      return <CreatePoiReviewWithFixedPoiPage />
    case 'update':
      if (!reviewId || !poiId) return <DefaultWritePoiReviewPage />

      return <UpdatePoiReviewPage />
    case 'write':
      return <DefaultWritePoiReviewPage />
    default:
      return <DefaultWritePoiReviewPage />
  }
}

export default WritePoiReviewPage

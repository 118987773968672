import styled from '@emotion/styled'
import React, { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { BoundarySearchButton as BoundarySearchButtonComponent } from '@src/components/Map'
import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { VIEW_BUTTON_HEIGHT } from '@src/components/Map/ViewTypeToggleButton'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeightAtom, SAFE_AREA } from '../constants/style'
import { useAnalytics, useIsCardShown, useFetchPois, usePoisStatus } from '../hooks'
import { isMapBoundChangedAtomFamily, mapThemeAtomFamily, selectedPoiIdAtomFamily } from '../state'

const BoundarySearchButton: React.FC = () => {
  const screenInstance = useScreenInstance()

  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const isCardShown = useIsCardShown()
  const poisStatus = usePoisStatus()
  const setMapBoundChanged = useSetRecoilState(isMapBoundChangedAtomFamily(screenInstance))
  const mapPoiCardHeight = useRecoilValue(mapPoiCardHeightAtom)
  const setSelectedPoiId = useSetRecoilState(selectedPoiIdAtomFamily(screenInstance))

  const logEvent = useAnalytics()
  const { getBounds } = useKakaoMap()
  const { fetchPois } = useFetchPois()

  const handleSearchPoisInBound = useCallback(() => {
    const currentBounds = getBounds({
      margin: {
        bottom: isCardShown
          ? VIEW_BUTTON_HEIGHT +
            DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS +
            mapPoiCardHeight +
            CARD_MARGIN +
            SAFE_AREA.getBottom()
          : 0,
      },
    })

    logEvent('click_search_current_area_button', {
      bound: currentBounds,
    })

    fetchPois({
      bounds: currentBounds,
      mapTheme: mapTheme,
      centerPosition: null,
      isCurrentAreaSearch: true,
    })

    setMapBoundChanged(false)
    setSelectedPoiId(null)
  }, [mapPoiCardHeight, fetchPois, getBounds, isCardShown, logEvent, mapTheme, setMapBoundChanged, setSelectedPoiId])

  return <Button onClick={handleSearchPoisInBound} isLoading={poisStatus === 'loading'} />
}

const Button = styled(BoundarySearchButtonComponent)`
  white-space: nowrap;
`

export default BoundarySearchButton

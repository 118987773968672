import { getRecommendationsV2 } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { selector } from 'recoil'

import { checkInRegionInfoAtom, userInfoAtom } from '@src/store'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

export const generateKey = recoilKeyGenerator(['searchPoi', 'recommendations'])

export const recommendationsSelector = selector({
  key: generateKey('recommendationsSelector'),
  get: async ({ get }) => {
    const { authToken: xAuthToken } = get(userInfoAtom)
    const { id: regionId } = get(checkInRegionInfoAtom)

    try {
      const {
        data: { data },
      } = await getRecommendationsV2({ params: { xAuthToken, regionId } })

      return data
    } catch (err) {
      captureException(err)

      return 'error'
    }
  },
})

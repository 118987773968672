import axios from 'axios'
import { selectorFamily } from 'recoil'

import { bizReviewApi } from '@src/apis'
import type { Review as BizReviewType } from '@src/apis/types/BizReview'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const reviewPagePrefix = 'review-page'
const generateKey = recoilKeyGenerator(reviewPagePrefix)

type ReviewableStatus =
  | 'NEW_REVIEW_AVAILABLE' /** 후기 작성 가능 */
  | 'REVIEW_ALREADY_EXIST' /** 이미 작성한 후기가 존재 */
  | 'DELETED_BLOCKED_REVIEW_EXIST' /** 제재된 후기가 삭제된 이력이 존재 */
  | 'SERVER_ERROR'
  | 'UNKNOWN'
export const reviewableBizStatusSelectorFamily = selectorFamily<
  { status: ReviewableStatus; reviewId: string | null },
  string
>({
  key: generateKey('bizReviewAvailabilitySelectorFamily'),
  get: (bizAccountId: string) => async () => {
    if (!bizAccountId) return { status: 'UNKNOWN', reviewId: null }

    try {
      const {
        data: { data: response },
      } = await bizReviewApi.reviewableStatus(bizAccountId)

      const { reviewable, cannot_write_reason: reason, data } = response
      const reviewId = data?.review_id || null

      if (reviewable) {
        /** 후기 작성 가능 상태 */
        return { status: 'NEW_REVIEW_AVAILABLE', reviewId }
      } else {
        /** 이미 작성한 후기가 있는 상태 */
        if (reason === 'REVIEW_ALREADY_EXIST') return { status: 'REVIEW_ALREADY_EXIST', reviewId }
        /** 제재(blocked)된 후 삭제된 후기가 있는 상태 */
        if (reason === 'BLOCKED_REVIEW_EXIST') return { status: 'DELETED_BLOCKED_REVIEW_EXIST', reviewId }
        /** 서버 에러 */
        if (reason === 'UNKNOWN') return { status: 'SERVER_ERROR', reviewId: null }

        return { status: reason, reviewId }
      }
    } catch (err) {
      const SERVER_ERROR = 500

      if (axios.isAxiosError(err)) {
        const { response } = err

        if (response?.status === SERVER_ERROR) {
          return { status: 'SERVER_ERROR', reviewId: null }
        }
      }
      return { status: 'UNKNOWN', reviewId: null }
    }
  },
})

export type BizReviewStatus =
  | 'NORMAL' /** 정상 */
  | 'BLOCKED' /** 제재됨 */
  | 'BLOCKED_DELETED' /** 제재된 후 삭제됨 */
  | 'POST_STOPPED' /** 30일 임시 게시중단된 상태 */
  | 'DELETED' /** 게시중단(삭제) */
  | 'ERROR'
export const myBizReviewSelectorFamily = selectorFamily<
  { status: BizReviewStatus; review: BizReviewType | null },
  string
>({
  key: generateKey('myBizReviewSelectorFamily'),
  get:
    (bizAccountId: string) =>
    async ({ get }) => {
      if (!bizAccountId) return { status: 'ERROR', review: null }

      const { status: reviewableStatus, reviewId } = get(reviewableBizStatusSelectorFamily(bizAccountId))

      if (reviewableStatus === 'SERVER_ERROR' || reviewableStatus === 'UNKNOWN')
        return { status: 'ERROR', review: null }

      /** 제재된 후 삭제된 상태 */
      if (reviewableStatus === 'DELETED_BLOCKED_REVIEW_EXIST') return { status: 'BLOCKED_DELETED', review: null }

      if (!reviewId) return { status: 'NORMAL', review: null }

      try {
        const {
          data: { data: review },
        } = await bizReviewApi.getItem(reviewId)

        const { blocked, post_stopped, deleted } = review
        /** 제재된 상태 */
        if (blocked) return { status: 'BLOCKED', review }
        /** 30일 게시중단된 상태 */
        if (post_stopped) return { status: 'POST_STOPPED', review }
        /** 게시중단 상태 */
        if (deleted) return { status: 'DELETED', review }

        return { status: 'NORMAL', review }
      } catch (err) {
        const DELETED_REVIEW = 400
        if (axios.isAxiosError(err)) {
          const { response } = err

          if (response?.status === DELETED_REVIEW) return { status: 'DELETED', review: null }
        }

        return { status: 'ERROR', review: null }
      }
    },
})

import { ToggleTab } from '@daangn/local-business-kit'
import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import { css } from '@emotion/react'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useCallback, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { reviewsByPoiIdAndUserIdLoadableFamily } from '@src/shared/review'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics } from '../hooks'
import { ReviewTab } from '../ReviewTab'
import { postsTabAtomFamily, communityPostsStaticMetaAtomFamily, communityPostsSortingOptionAtomFamily } from '../state'
import { StoryTab } from '../StoryTab'

const PostsTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const logEvent = useAnalytics()
  const screenInstance = useScreenInstance()

  const [currentTabKey, setCurrentTabKey] = useRecoilState(postsTabAtomFamily(screenInstance))
  const communityPostsSortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const communityPostsStaticMeta = useRecoilValue(
    communityPostsStaticMetaAtomFamily({ poiId, sortingOption: communityPostsSortingOption, screenInstance })
  )
  const { value: reviewsStore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId, sort: 'latestCreation', userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  const total = reviewsStore?.total ?? 0

  const onClickReviewTab = useCallback(() => {
    logEvent('click_review_tab_button', {
      count: total,
    })
    setCurrentTabKey('review')
  }, [logEvent, total, setCurrentTabKey])

  const onClickCommunityTab = useCallback(() => {
    logEvent('click_community_tab_button', {
      count: communityPostsStaticMeta.total,
    })
    setCurrentTabKey('story')
  }, [communityPostsStaticMeta.total, logEvent, setCurrentTabKey])

  const tabs = useMemo(
    () =>
      [
        {
          key: `review`,
          label: `후기${Math.max(total ?? 0, 0) ? ` ${Math.max(total ?? 0, 0)}` : ''}`,
          onClick: onClickReviewTab,
        },
        {
          key: `story`,
          label: `동네생활${
            Math.max(communityPostsStaticMeta.total, 0) ? ` ${Math.max(communityPostsStaticMeta.total, 0)}` : ''
          }`,
          onClick: onClickCommunityTab,
        },
      ] as const,
    [onClickCommunityTab, onClickReviewTab, total, communityPostsStaticMeta.total]
  )

  const renderTab = () => {
    switch (currentTabKey) {
      case 'review':
      default:
        return <ReviewTab />
      case 'story':
        return <StoryTab />
    }
  }
  return (
    <div css={container}>
      <div css={toggleTabWrapper}>
        <ToggleTab tabs={tabs} externalTabKey={currentTabKey} />
      </div>
      {renderTab()}
    </div>
  )
}

const container = css`
  margin: 0.5rem 0 0;
`
const toggleTabWrapper = css`
  background: ${vars.$semantic.color.paperDefault};
  padding: 1.25rem 1rem 0.75rem;
`

export default PostsTab

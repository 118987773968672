import { SuggestUpdateBody, suggestUpdateV2 } from '@daangn/local-business-network/lib/poi'
import * as Sentry from '@sentry/react'
import { ActivityComponentType } from '@stackflow/react'
import { FormikHelpers } from 'formik'
import flatten from 'lodash/flatten'
import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { bridge } from '@src/bridge'
import { AlertDialog } from '@src/components/AlertDialog'
import { addressPickerAtom } from '@src/components/Input/AddressInput/store/addressPicker'
import { useToast } from '@src/components/Toast'
import { eventOptions, winterSnackEventId } from '@src/constants/poiEvent'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import { useNavigator, useStepNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { selectedCategoryAtom } from '@src/widgets/CategoryInput/state'
import { FormPage } from '@src/widgets/form/FormPage'
import type { Field, UpdateSuggestionRequestBody } from '@src/widgets/form/types'

import { useAnalytics } from './hooks'
import RemoveSuggestion from './RemoveSuggestion'
import { errorMessage } from '../../components/Error/constants'

type DialogType = 'completeSubmit'

type ActivityParams = {
  poiId: string
  alert?: DialogType
}
const UpdateSuggestion: ActivityComponentType<ActivityParams> = ({ params: { poiId, alert } }) => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()
  const { stepPush } = useStepNavigator('update_poi_suggestion')
  const { showToast } = useToast()

  const setAddressPicker = useSetRecoilState(addressPickerAtom)
  const setSelectedCategory = useSetRecoilState(selectedCategoryAtom)
  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const [isInitialValuesSet, setIsInitialValuesSet] = useState(false)

  const filteredEventsByEventId =
    poi?.events &&
    poi.events.filter(({ id, name }) => {
      return id === winterSnackEventId && eventOptions[name]
    })
  const selectedAttributeIds = useMemo(() => {
    const selectedAttributeIds = poi?.attributes ?? []
    return flatten(
      filteredEventsByEventId?.map(({ attributes: eventAttributes }) => {
        return selectedAttributeIds
          .map(({ id }) => id)
          .filter((selectedAttributeId) =>
            // https://karrot.atlassian.net/browse/PLC-3447?atlOrigin=eyJpIjoiNGFkNDJmOTI4N2JjNGUzYmI5YmY3NGE2MjhiZTE0ZjgiLCJwIjoiaiJ9
            eventAttributes?.some((eventAttribute: any) => eventAttribute?.id === selectedAttributeId)
          )
      })
    )
  }, [poi, filteredEventsByEventId])

  const handleSubmit = async (requestBody: Partial<UpdateSuggestionRequestBody>, { setErrors }: FormikHelpers<any>) => {
    const isWinterSnack = !!winterSnackEvent
    const attributeIds = requestBody?.attributeIds || selectedAttributeIds
    const hasAttributeIds = attributeIds && attributeIds.length > 0
    if (isWinterSnack && !hasAttributeIds) {
      setErrors({
        attributeIds: '판매하는 메뉴를 선택해주세요.',
      })
      return
    }

    const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

    try {
      if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

      await suggestUpdateV2({
        params: {
          xAuthToken: userInfo.authToken,
          suggestUpdateBody: {
            ...requestBody,
            poiId,
            openingHours: requestBody.openingHours as SuggestUpdateBody['openingHours'],
          },
        },
      })

      logEvent('click_submit_form_button')

      stepPush({ poiId, alert: 'completeSubmit' })
    } catch (err) {
      Sentry.captureException(err)

      if (err instanceof Error) {
        if (err.message === AUTH_TOKEN_ERROR) {
          return showToast({
            text: errorMessage.AUTHENTICATION_MESSAGE,
            duration: 'long',
          })
        }
      }

      bridge.toast.open({ body: errorMessage.PLEASE_RETRY_MESSAGE })
    }
  }

  const winterSnackEvent = useMemo(() => poi?.events.find(({ id }) => id === winterSnackEventId), [poi])

  const fields = useMemo(() => {
    const baseFields: Field<
      'name' | 'address' | 'categoryId' | 'phone' | 'operatingInformation' | 'url' | 'pricing' | 'photos'
    >[] = [
      {
        id: 'name',
        isRequired: true,
      },
      {
        id: 'address',
        isRequired: true,
      },
      {
        id: 'categoryId',
      },
      {
        id: 'phone',
      },
      {
        id: 'operatingInformation',
      },
      {
        id: 'url',
      },
      {
        id: 'pricing',
      },
      {
        id: 'photos',
      },
    ]

    if (winterSnackEvent) {
      const winterSnackFields: Field<
        | 'name'
        | 'winterSnackMenu'
        | 'address'
        | 'categoryId'
        | 'phone'
        | 'operatingInformation'
        | 'url'
        | 'pricing'
        | 'photos'
      >[] = [
        {
          id: 'name',
          isRequired: true,
        },
        {
          id: 'winterSnackMenu',
          isRequired: true,
        },
        {
          id: 'address',
          isRequired: true,
        },
        {
          id: 'categoryId',
        },
        {
          id: 'phone',
        },
        {
          id: 'operatingInformation',
        },
        {
          id: 'url',
        },
        {
          id: 'pricing',
        },
        {
          id: 'photos',
        },
      ]

      return winterSnackFields
    }

    return baseFields
  }, [winterSnackEvent])

  // Set picker values to poi, if is update suggestion
  useEffect(() => {
    if (!poi) return

    if (poi.address || poi.jibunAddress || poi.coordinates) {
      setAddressPicker({
        roadAddress: poi.address ?? undefined,
        jibunAddress: poi.jibunAddress ?? undefined,
        coordinates: poi.coordinates ?? undefined,
      })
    }

    if (poi.category) setSelectedCategory(poi.category)

    setIsInitialValuesSet(true)
  }, [poi, setAddressPicker, setSelectedCategory, winterSnackEvent])

  return poi && isInitialValuesSet ? (
    <>
      <FormPage
        fields={fields}
        title="정보 수정 제안"
        poi={poi}
        onClickAddressSearch={() => {
          bridge.analytics.log({
            name: 'click_tagging_address_register',
            params: { from: 'search' },
          })
          logEvent('click_search_address_input')
        }}
        onClickPinAddress={
          window.kakao
            ? (e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                bridge.analytics.log({
                  name: 'click_tagging_address_register',
                  params: { from: 'map' },
                })
                logEvent('click_pin_address_button')
              }
            : undefined
        }
        appendBottom={() => {
          return <RemoveSuggestion poiId={poiId} />
        }}
        onSubmit={handleSubmit}
      />
      {alert === 'completeSubmit' && (
        <AlertDialog
          header="정보 수정 제안 완료"
          contents="반영까지는 2~3일이 소요될 수 있으며, 반영 후에 알림으로 알려드릴게요."
          CTAButton={{
            label: '확인',
            onClick: pop,
          }}
        />
      )}
    </>
  ) : null
}

export default UpdateSuggestion

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/components/Analytics'
import { Skeleton } from '@src/components/Loading'
import { FilterButton } from '@src/components/Map/FilterButton'
import { withSuspense } from '@src/components/Suspense'
import useScrollElementIntoView from '@src/hooks/useScrollElementIntoView'
import { useStepNavigator } from '@src/stackflow'
import { Stack } from '@src/styles/Stack'

import { defaultFilter, useFilter } from '../hooks'
import { winterSnackFiltersSelector } from '../state'
import { WinterSnackParams } from '../WinterSnackPage'

const FilterButtons = () => {
  const logEvent = useLogEvent()
  const { selectedFilterId, toggleFilter } = useFilter()
  const activityParams = useActivityParams<WinterSnackParams>()
  const { filterId } = activityParams
  const { stepReplace } = useStepNavigator('winter_snack')

  const filters = useRecoilValue(winterSnackFiltersSelector)

  const scrollRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<{ [id: string]: HTMLElement }>({})

  const scrollIntoView = useScrollElementIntoView()

  const handleClickFilter = (filterId: number) => {
    const isFilterToggledOn = toggleFilter(filterId)

    if (isFilterToggledOn) {
      logEvent({
        name: 'click_select_filter_button',
        params: {
          filter_id: filterId,
        },
      })
    } else {
      logEvent({
        name: 'click_unselect_filter_button',
        params: {
          filter_id: filterId,
        },
      })
    }
  }

  useEffect(() => {
    if (scrollRef.current && targetRef.current[selectedFilterId ?? '']) {
      scrollIntoView(scrollRef.current, targetRef.current[selectedFilterId ?? ''], 'horizontal', {
        paddingMarginStart: 40,
        paddingMarginEnd: 40,
      })
    }
  }, [scrollIntoView, selectedFilterId])

  useEffect(() => {
    if (!filters || filters.length === 0 || activityParams.filterId) return

    stepReplace({
      filterId: defaultFilter.id,
    })
  }, [activityParams.filterId, filters, stepReplace])

  return (
    <StyledStack gutter={6} alignItems="center" ref={scrollRef}>
      {[
        {
          ...defaultFilter,
          id: -1,
        },
      ]
        .concat(filters ?? [])
        .map(({ id, name }) => {
          return (
            <FilterButton
              ref={(el) => !!el && !!targetRef.current && (targetRef.current[id] = el)}
              key={id}
              isSelected={(id === -1 && filterId === 'all') || id.toString() === filterId}
              onClick={() => handleClickFilter(id)}>
              {name}
            </FilterButton>
          )
        })}
      <div className="endPadding" />
    </StyledStack>
  )
}

function Loading() {
  return (
    <StyledStack gutter={6} alignItems="center">
      <SkeletonButton width={70} height={30} />
      <SkeletonButton width={70} height={30} />
      <SkeletonButton width={70} height={30} />
    </StyledStack>
  )
}

export default withSuspense(FilterButtons, Loading)

const StyledStack = styled(Stack)`
  position: relative;
  display: flex;
  padding: 0.1rem 1.5rem 0.75rem 0.75rem;
  align-items: center;
  overflow-x: scroll;
  width: 100%;
  background-color: ${vars.$semantic.color.paperDefault};
  z-index: 2;

  .endPadding {
    height: 100%;
    min-width: 12px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const SkeletonButton = styled(Skeleton)`
  border-radius: 18px;
`

import { atomFamily, selectorFamily } from 'recoil'

import { POIPostsApi } from '@src/apis/generated/poi/api'
import { GetPostsByUserIdV2200Response } from '@src/apis/generated/poi/models'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const subprofileKey = recoilKeyGenerator(['subprofile', 'userPosts'])

export const userPostsPaginationAtom = atomFamily({
  key: subprofileKey('pagination'),
  default: {
    page: 1,
    perPage: 10,
  },
})

type PostsSortBy = 'createdAt' | 'upvoteCount'
export const userPostsSortByAtom = atomFamily<PostsSortBy, string>({
  key: subprofileKey('sortBy'),
  default: 'createdAt',
})

export const userPostsQuerySelectorFamily = selectorFamily({
  key: subprofileKey('query'),
  get:
    (userId: string) =>
    async ({ get }) => {
      const userInfo = get(bridgeInfoUserAtom)
      if (!userId || !userInfo?.authToken) return null

      const sortBy = get(userPostsSortByAtom(userId))
      const { page, perPage } = get(userPostsPaginationAtom({ sortBy, userId }))
      const { data: profile } = await POIPostsApi.getPostsByUserIdV2(userInfo.authToken, userId, page, perPage, sortBy)

      return profile
    },
})

export const userPostsAtom = atomFamily({
  key: subprofileKey('userPostsStore'),
  default: [] as GetPostsByUserIdV2200Response['data'],
})

export const userPostsMetaAtom = atomFamily({
  key: subprofileKey('meta'),
  default: {} as GetPostsByUserIdV2200Response['meta'],
})

export const syncedUserPostPageAtom = atomFamily({
  key: subprofileKey('syncedPage'),
  default: 0,
})

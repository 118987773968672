import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useLayoutEffect, useRef } from 'react'

import { Button } from '@src/components/Button'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'

const FALLBACK_OFFSET_HEIGHT = 108

export const EmptyState = () => {
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const ref = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = React.useState(FALLBACK_OFFSET_HEIGHT)

  const handleClickAddReview = async () => {
    logEvent('click_write_review_button', {
      area: 'poi_card',
      view_type: 'list',
    })

    push('poi_review', { action: 'write' })
  }

  useLayoutEffect(() => {
    setOffsetHeight(ref.current?.offsetHeight ?? FALLBACK_OFFSET_HEIGHT)
  }, [])

  return (
    <Container ref={ref} offsetHeight={offsetHeight}>
      <Text>{`필터에 해당하는 장소가 없어요\n새로운 장소를 이웃에게 추천해보세요!`}</Text>
      <Button className="button" textSize="16" styleTheme="secondary-outlined" onClick={handleClickAddReview}>
        장소 추천하기
      </Button>
    </Container>
  )
}

const Container = styled.div<{ offsetHeight: number }>`
  position: relative;
  top: ${({ offsetHeight }) => `calc(40% - ${offsetHeight}px)`};
  margin: auto 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Text = styled.p`
  margin: 0 0 1rem 0;
  white-space: pre-line;
  text-align: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.bodyL1Regular};
`

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { MouseEventHandler } from 'react'

import defaultProfileImage from '@src/assets/images/profile_default.png'
import { getDateDiffFromNow } from '@src/utils/date'
import { useSubprofileHalfview } from '@src/widgets/SubprofileHalfview/hooks'

interface Props {
  name: string
  id: number
  profileImage?: string
  regionName?: string
  regionCheckInCount?: number
  createdAt?: string
  className?: string
}

const UserInfo: React.FC<Props> = (props) => {
  const regionInfo =
    props.regionName && props.regionCheckInCount
      ? `${props.regionName} 인증 ${props.regionCheckInCount}회`
      : `${props.regionName}`

  const timeSinceCreated = props.createdAt && `${getDateDiffFromNow(new Date(props.createdAt)).text} 전`

  const { open } = useSubprofileHalfview()

  const handleClickUserProfile: MouseEventHandler = (e) => {
    e.stopPropagation()

    open(props.id.toString())
  }

  return (
    <Container onClick={handleClickUserProfile} className={props.className}>
      <ProfileImage src={props.profileImage || defaultProfileImage} />
      <div>
        <Name>{props.name}</Name>
        <Region>{[regionInfo, timeSinceCreated].filter((element) => Boolean(element)).join(' \u2022 ')}</Region>
      </div>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`

const ProfileImage = styled.img`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  align-self: center;
`

const Name = styled.p`
  font-weight: 700;
  font-size: 0.875rem;
  color: ${vars.$scale.color.gray900};
  line-height: 1.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`

const Region = styled.p`
  font-weight: 400;
  font-size: 0.813rem;
  color: ${vars.$scale.color.gray600};
  line-height: 1.25rem;
`
export default UserInfo

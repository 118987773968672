import styled from '@emotion/styled'
import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { FloatingButton } from '@src/components/Button'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import type { ViewType } from '../types'

interface Props {
  viewType: ViewType
  bottomSpacing: number
}

const WriteReviewButton = ({ viewType }: Props) => {
  const logEvent = useAnalytics()
  const { push } = useNavigator()

  const goToWriteReviewPage = async () => {
    logEvent('click_write_review_button', {
      area: 'floating_action_button',
      view_type: viewType === 'MAP' ? 'map' : 'list',
    })

    push('poi_review', { action: 'write' })
  }

  return (
    <Button
      viewType={viewType}
      color="default"
      size="size48"
      onClick={goToWriteReviewPage}
      icon={<IconWriteRegular />}
    />
  )
}

const Button = styled(FloatingButton)<Pick<Props, 'viewType'>>`
  background-color: ${({ viewType }) =>
    viewType === 'LIST' ? vars.$scale.color.gray700 : vars.$semantic.color.paperDefault};

  .icon {
    color: ${({ viewType }) => (viewType === 'LIST' ? vars.$scale.color.gray00 : vars.$scale.color.gray900)};
  }
`

export default WriteReviewButton

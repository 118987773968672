import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import { useActivityParams } from '@stackflow/react'
import { uniq } from 'lodash'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useCurrentPosition } from '@src/hooks'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { PurposefulCategoryActivityParams } from '../PurposefulCategoryPage'
import { bizProfileAndPoiByThemeLoadableFamily } from '../store'

const useBizProfileAndPoiByThemeLoadableFamily = () => {
  const { themeId, categoryFilterId, sortBy, toggleFilterIds } = useActivityParams<PurposefulCategoryActivityParams>()
  const { position } = useCurrentPosition()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const filterIds = useMemo(() => {
    const toggleFiltersOn = (toggleFilterIds?.split(',') ?? []).filter((id) => id !== '')

    // only unique id
    if (!categoryFilterId || categoryFilterId === 'all') return uniq([...toggleFiltersOn])

    return uniq([categoryFilterId, ...toggleFiltersOn])
  }, [categoryFilterId, toggleFilterIds])

  return useLoadableFamilyPaginate({
    loadable: bizProfileAndPoiByThemeLoadableFamily,
    variables: {
      themeId,
      sort: sortBy ?? '',
      filterIds,
      regionId: userInfo?.region?.id ?? -1,
      userPositionLat: position?.latitude,
      userPositionLng: position?.longitude,
    },
    fetchPolicy: 'store-or-network',
  })
}

export default useBizProfileAndPoiByThemeLoadableFamily

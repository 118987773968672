import { ReactionDto } from '@src/apis/generated/poi/models'

export const convertResourceTypeToSourceType = (resourceType: ReactionDto['resourceType'] | 'BIZ_REVIEW') =>
  resourceType === 'STORY_COMMENT'
    ? 'community_comment'
    : resourceType === 'STORY_ARTICLE'
    ? 'community_post'
    : resourceType === 'BIZ_REVIEW'
    ? 'biz_review'
    : 'poi_review'

import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { myPoiReviewSelectorFamily } from '@src/shared/review/store/poiReview'
import { useNavigator } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'

import QuickMenuButton from './QuickMenuButton'
import { useAnalytics } from '../../hooks'
import { postsTabAtomFamily, tabAtomFamily } from '../../state'

interface Props {
  poiId: string
}

const WriteReviewButton: React.FC<Props> = ({ poiId }) => {
  const logEvent = useAnalytics()
  const { push } = useNavigator()
  const screenInstance = useScreenInstance()

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const setTab = useSetRecoilState(tabAtomFamily(screenInstance))
  const setPostTab = useSetRecoilState(postsTabAtomFamily(screenInstance))

  const handleClickWriteReview = async () => {
    /** @deprecated */
    logEvent('click_write_review_button', {
      area: 'quick_menu',
    })
    logEvent('click_write_profile_review_button', {
      area: 'quick_menu',
    })

    push('poi_review', { action: 'create', poiId })
    setTab('posts')
    setPostTab('review')
  }

  const handleClickEditReview = async () => {
    logEvent('click_edit_review_button', {
      area: 'quick_menu',
      review_id: myReview?.id,
    })

    push('poi_review', { action: 'update', poiId, reviewId: myReview?.id })
    setTab('posts')
    setPostTab('review')
  }

  return (
    <QuickMenuButton
      onClick={myReview?.id ? handleClickEditReview : handleClickWriteReview}
      Icon={IconWriteRegular}
      label={`후기 ${myReview?.id ? '수정' : '작성'}`}
    />
  )
}

export default WriteReviewButton

import IconCallRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCallRegular'
import React, { useRef } from 'react'

import QuickMenuButton from './QuickMenuButton'
import { useAnalytics } from '../../hooks'

interface Props {
  phoneNumber: string
}

const CallButton: React.FC<Props> = ({ phoneNumber }) => {
  const logEvent = useAnalytics()

  const telLink = useRef<HTMLAnchorElement>(null)

  const handleCall = () => {
    logEvent('click_call_button', {
      area: 'quick_menu',
    })

    telLink.current?.click()
  }

  return (
    <QuickMenuButton onClick={handleCall} Icon={IconCallRegular} label={'전화'}>
      <a
        ref={telLink}
        href={`tel:${phoneNumber}`}
        onClick={(e) => {
          e.stopPropagation()
        }}
        style={{ display: 'none' }}
      />
    </QuickMenuButton>
  )
}

export default CallButton

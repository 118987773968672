import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { captureException } from '@sentry/react'
import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { POISuggestionsApi } from '@src/apis/generated/poi/api'
import RadioSelected from '@src/assets/icons/RadioSelected'
import RadioUnSelected from '@src/assets/icons/RadioUnSelected'
import { bridge } from '@src/bridge'
import { AlertDialog } from '@src/components/AlertDialog'
import { Button } from '@src/components/Button'
import { ImageUploader, ImageUploadPicture } from '@src/components/Image'
import { TextInput } from '@src/components/Input'
import { useToast } from '@src/components/Toast'
import { deleteSuggestionReasons } from '@src/constants/deleteSuggestionReasons'
import { Screen, useNavigator, useStepNavigator } from '@src/stackflow'
import { ActivitiesType } from '@src/stackflow/activities'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { FormItemWrapper } from '@src/widgets/form/FormItemWrapper'

import { useAnalytics } from './hooks'
import { poiIdAtom } from './state/poi'
import { DialogType } from './types'
import { errorMessage } from '../../components/Error/constants'

// Component
interface FormType {
  suggestionReason: string
  content: string
  imageIds?: string[]
}

type ActivityParams = {
  poiId: string
  alert?: DialogType
}
const DeleteSuggestion: ActivityComponentType<ActivityParams> = ({ params }) => {
  const { entry } = useActivityParams<{ entry?: keyof ActivitiesType }>()

  const logEvent = useAnalytics()
  const { pop } = useNavigator()
  const { stepPush } = useStepNavigator('delete_poi_suggestion')
  const { showToast } = useToast()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const setPoiId = useSetRecoilState(poiIdAtom)

  const { values, errors, handleChange, handleSubmit, setErrors, setFieldValue } = useFormik<FormType>({
    initialValues: {
      suggestionReason: '폐업',
      content: '',
      imageIds: [] as string[],
    },
    validate: (values: FormType) => {
      const errors = {} as FormType

      if (values.suggestionReason === '기타' && values.content === '') {
        errors.content = '기타 선택시, 사유가 필수에요.'
      }

      return errors
    },
    validateOnChange: true,
    onSubmit: async (values: FormType) => {
      const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

        const poiId = params.poiId

        if (!poiId) {
          throw new Error('Empty poiId!')
        }

        const { content, suggestionReason, imageIds } = values

        await POISuggestionsApi.suggestRemovalV2(userInfo.authToken, {
          poiId,
          userNote: content,
          reason: suggestionReason,
          imageIds,
        })

        logEvent('click_submit_form_button')

        stepPush({ poiId, alert: 'completeSubmit' })
      } catch (err) {
        captureException(err)

        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        bridge.toast.open({ body: errorMessage.PLEASE_RETRY_MESSAGE })

        pop()
      }
    },
  })

  const handleImageUploaded = (pictures: ImageUploadPicture[]) => {
    const pictureIds = pictures.map(({ id }) => id)
    setFieldValue('imageIds', pictureIds)
  }

  useEffect(() => {
    if (!params.poiId) return

    setPoiId(params.poiId)
  }, [params.poiId, setPoiId])

  return (
    <>
      <Screen
        appBar={{
          title: '장소 삭제',
          appendRight: () => (
            <StyledSubmitButton
              type="submit"
              styleTheme="text"
              disabled={!!errors.content}
              form="delete-suggestion"
              onClick={() => handleSubmit()}>
              완료
            </StyledSubmitButton>
          ),
        }}>
        <StyledDeleteSuggestion>
          <FormItemWrapper id="suggestionReason" label="장소 삭제 이유를 선택해 주세요." errorMessage={errors.content}>
            {deleteSuggestionReasons.map(({ id, text }) => {
              return (
                <StyledRadio
                  key={id}
                  onClick={() => {
                    setFieldValue('suggestionReason', text)
                    if (text !== '기타') {
                      setErrors({ content: '' })
                      setFieldValue('content', '')
                    }
                  }}>
                  {text === values.suggestionReason ? <RadioSelected /> : <RadioUnSelected />}
                  <label>{text}</label>
                  <input type="radio" name="suggestionReason" value={id} />
                </StyledRadio>
              )
            })}
            {values.suggestionReason === '기타' && (
              <TextInput
                id="content"
                width="100%"
                invalid={!!errors.content}
                placeholder="이유를 입력해 주세요."
                value={values.content}
                onChange={(e) => {
                  setErrors({ content: '' })
                  handleChange(e)
                }}
              />
            )}
          </FormItemWrapper>
          <FormItemWrapper id="imageIds" label="이유를 확인할 수 있는 사진을 올려주세요.">
            <ImageUploader maxCount={5} onUpdateImage={handleImageUploaded} />
          </FormItemWrapper>
        </StyledDeleteSuggestion>
      </Screen>

      {params.alert === 'completeSubmit' && (
        <AlertDialog
          header="정보 수정 제안 완료"
          contents="반영까지는 2~3일이 소요될 수 있으며, 반영 후에 알림으로 알려드릴게요."
          CTAButton={{
            label: '확인',
            onClick: () => {
              if (entry === 'update_poi_suggestion') {
                return pop(2)
              }

              pop()
            },
          }}
        />
      )}
    </>
  )
}

const StyledDeleteSuggestion = styled.div`
  padding: 24px 16px;
`

const StyledSubmitButton = styled(Button)<{ disabled: boolean }>`
  padding: 0.5rem;
  color: ${({ disabled }) => (disabled ? vars.$scale.color.gray400 : vars.$semantic.color.primary)};
  ${vars.$semantic.typography.bodyM1Regular};
`

const StyledRadio = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 16px;
  label {
    ${vars.$semantic.typography.label3Regular}
    margin: 0 0 0 10px;
  }
`

export default DeleteSuggestion

import { css } from '@emotion/react'
import { vars } from '@seed-design/design-token'

const globalCss = css`
  * {
    box-sizing: border-box;
  }
  html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 16px;
    user-select: none;
    -webkit-user-select: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
  }
  body,
  html,
  #root {
    margin: 0;
    padding: 0;
    --safe-area-inset-top-env: env(safe-area-inset-top);
    --safe-area-inset-right-env: env(safe-area-inset-right);
    --safe-area-inset-bottom-env: env(safe-area-inset-bottom);
    --safe-area-inset-left-env: env(safe-area-inset-left);
    --safe-area-inset-top-constant: constant(safe-area-inset-top);
    --safe-area-inset-right-constant: constant(safe-area-inset-right);
    --safe-area-inset-bottom-constant: constant(safe-area-inset-bottom);
    --safe-area-inset-left-constant: constant(safe-area-inset-left);
  }
  #root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  button {
    outline: none;
    background: none;
    margin: 0;
    border: none;
    padding: 0;
    color: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  textarea,
  input {
    outline: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  a {
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  p,
  div,
  span {
    color: ${vars.$scale.color.gray900};
  }
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  input {
    border: none;
    background: transparent;
  }
  textarea {
    resize: none;
    border: none;
    outline: none;
  }
`

export default globalCss

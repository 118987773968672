import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconCheckRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCheckRegular'
import IconWarningRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWarningRegular'
import { vars } from '@seed-design/design-token'
import React, { useContext } from 'react'

import type { IToast } from './context'
import ToastContext from './context'

interface Props extends IToast {
  id: string
}
function Toast({ id, text, button, duration, icon, bottomMargin }: Props) {
  const durationInMs = typeof duration === 'number' ? duration : duration === 'long' ? 5000 : 3000

  const { removeToast } = useContext(ToastContext)

  const handleClickButton = () => {
    button?.onClickButton?.()
    removeToast(id)
  }

  return (
    <AnimationContainer showToast={true} duration={durationInMs} bottomMargin={bottomMargin ?? 0}>
      <Container>
        {icon === 'warning' && <IconWarningRegular css={warningCss} />}
        {icon === 'check' && <IconCheckRegular css={checkCss} />}
        <Text>
          {text.split('\n').map((text, idx) => (
            <React.Fragment key={idx}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </Text>
        {!!button && <Button onClick={handleClickButton}>{button.text}</Button>}
      </Container>
    </AnimationContainer>
  )
}

const AnimationContainer = styled.div<Pick<Props, 'bottomMargin'> & { duration: number; showToast: boolean }>`
  visibility: ${({ showToast }) => (showToast ? 'visible' : 'hidden')};
  position: absolute;
  bottom: ${({ bottomMargin }) => bottomMargin}px;
  width: 100%;
  animation: fadeIn 200ms, fadeOut 200ms ${({ duration }) => duration - 150}ms;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate3d(0, ${({ bottomMargin }) => bottomMargin}px, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeOut {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    100% {
      transform: translate3d(0, ${({ bottomMargin }) => bottomMargin}px, 0);
      opacity: 0;
    }
  }
`

const Container = styled.div`
  min-height: 2.75rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.625rem 0.75rem 0.625rem 1rem;
  background-color: ${vars.$scale.color.gray900};
  opacity: 0.95;
  border-radius: 0.375rem;
`
const Text = styled.p`
  flex: 1;
  margin: 0 auto 0 0;
  display: flex;
  align-items: center;
  color: ${vars.$scale.color.gray00};
  ${vars.$semantic.typography.bodyM2Regular};
  line-height: 150%;
  word-break: keep-all;

  br:last-of-type {
    display: none;
  }
`
const Button = styled.button`
  outline: none;
  background: none;
  border: none;
  margin: 0 0 0 0.5rem;
  padding: 0.125rem 0.375rem;
  display: flex;
  width: fit-content;
  align-items: center;
  text-align: center;
  color: ${vars.$semantic.color.primary};
  ${vars.$semantic.typography.label3Bold};
`
const iconCss = css`
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.5rem;
`
const checkCss = css`
  ${iconCss};
  color: ${vars.$semantic.color.success};
`
const warningCss = css`
  ${iconCss};
  color: ${vars.$semantic.color.danger};
`

export default Toast

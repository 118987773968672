import { ActivityComponentType } from '@stackflow/react'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { PageLoading } from '@src/components/Loading'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'

import ClaimOwnershipDialog from './Modal/ClaimOwnershipDialog'
import ConfirmWriteStoryDialog from './Modal/ConfirmWriteStoryDialog'
import { PoiOptionsActionSheet } from './Modal/PoiOptionsActionSheet'
import { SingleReviewActionSheet } from './Modal/SingleReviewActionSheet'
import { SingleStoryActionSheet } from './Modal/SingleStoryActionSheet'
import { DialogType, BottomSheetType } from './types'

export type ModalActivityParams = {
  poiId: string
  modalId: DialogType | BottomSheetType
}

const PoiDetailModal: ActivityComponentType<ModalActivityParams> = ({ params: { poiId, modalId } }) => {
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  if (!poi || isEmpty(poi)) return <PageLoading />

  if (poi.bizAccountId) return null

  return (
    <>
      {modalId === 'confirmWriteStoryDialog' && <ConfirmWriteStoryDialog poiName={poi.name ?? ''} />}
      {modalId === 'claimOwnershipDialog' && <ClaimOwnershipDialog poiName={poi.name ?? ''} />}
      {modalId === 'poiOptionsBottomSheet' && <PoiOptionsActionSheet />}
      {modalId === 'singleReviewOptionsBottomSheet' && <SingleReviewActionSheet />}
      {modalId === 'singleStoryOptionsBottomSheet' && <SingleStoryActionSheet />}
    </>
  )
}

export default PoiDetailModal

import React, { useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Slide from '@src/components/Slides/Slide'
import Slides from '@src/components/Slides/Slides'
import { getSafeAreaValue } from '@src/styles/utils'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { mapPoiCardHeight, SLIDE_GAP, SLIDE_MARGIN } from '../constants/style'
import { useSelectedWatchedPlaceId } from '../hooks'
import WatchCard from '../PoiCard/mapView/WatchedPlaceCard'
import { currentPositionClickedAtomFamily, enableFocusingWatchedPlaceAtomFamily } from '../state'
import {
  watchedByUserMapperSelectorFamily,
  filteredWatchedPlacesByMapThemeSelectorFamily,
} from '../state/selectedWatchedPoi'

export function PlaceCardsSection() {
  const { selectedWatchedPlaceId, setSelectedWatchedPlaceId } = useSelectedWatchedPlaceId()
  const screenInstance = useScreenInstance()

  const filteredWatchedPlaces = useRecoilValue(filteredWatchedPlacesByMapThemeSelectorFamily(screenInstance))
  const watchedPlaceMapper = useRecoilValue(watchedByUserMapperSelectorFamily(screenInstance))
  const setEnableFocusingWatchedPlace = useSetRecoilState(enableFocusingWatchedPlaceAtomFamily(screenInstance))
  const setCurrentPositionClicked = useSetRecoilState(currentPositionClickedAtomFamily(screenInstance))

  const poiIdToIndexMapper = useMemo(() => {
    if (!filteredWatchedPlaces) return {}

    return filteredWatchedPlaces.reduce(
      (mapper, place, index) =>
        !place.targetId
          ? mapper
          : {
              ...mapper,
              [place.targetId]: index,
            },
      {} as { [id: string]: number }
    )
  }, [filteredWatchedPlaces])

  const focusedCardIndex = useMemo(() => {
    return selectedWatchedPlaceId ? poiIdToIndexMapper[selectedWatchedPlaceId] : 0
  }, [selectedWatchedPlaceId, poiIdToIndexMapper])

  const slicedPois = useMemo(() => {
    const renderCardsLength = 5

    const beginIndex = Math.max(0, focusedCardIndex - 2)
    const endIndex = beginIndex + renderCardsLength

    return filteredWatchedPlaces ? filteredWatchedPlaces.slice(beginIndex, endIndex) : []
  }, [filteredWatchedPlaces, focusedCardIndex])

  return (
    <Slides
      maxLength={filteredWatchedPlaces ? filteredWatchedPlaces.length - 1 : 0}
      focusedCardIndex={focusedCardIndex}
      slideMargin={SLIDE_MARGIN}
      slideGap={SLIDE_GAP}
      height={mapPoiCardHeight + parseInt(getSafeAreaValue('bottom')) + 6}
      onSwipeCardEnd={(cursor) => {
        const selectedWatchedPoi = filteredWatchedPlaces && filteredWatchedPlaces[cursor]
        if (selectedWatchedPoi && selectedWatchedPoi.targetId) {
          const isWatched = watchedPlaceMapper[selectedWatchedPoi.targetId]
          if (isWatched) {
            setEnableFocusingWatchedPlace(true)
          } else {
            setEnableFocusingWatchedPlace(false)
          }

          setSelectedWatchedPlaceId(filteredWatchedPlaces[cursor].targetId ?? null)
          setCurrentPositionClicked(false)
        }
      }}>
      {slicedPois.map((place, index) => {
        if (!place.targetId) return null

        return (
          <Slide key={place.targetId + index} index={poiIdToIndexMapper[place.targetId]}>
            <WatchCard {...place} />
          </Slide>
        )
      })}
    </Slides>
  )
}

import React, { ComponentType } from 'react'

import Suspense from './Suspense'

function withSuspense<P extends object>(Component: ComponentType<P>, PendingFallbackComponent?: ComponentType) {
  const WithSuspense: React.FC<P> = (props: P) => {
    return (
      <Suspense fallback={PendingFallbackComponent ? <PendingFallbackComponent /> : null}>
        <Component {...props} />
      </Suspense>
    )
  }

  return WithSuspense
}

export default withSuspense

import { CommunityPostCard, XCommunityPostCard } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useCallback, useRef, useState } from 'react'

import { ImageDetailSlider } from '@src/components/Image'
import { APP_SCHEME } from '@src/constants/environmentConstants'
import { useImageSliderState } from '@src/hooks/useImageSliderState'
import useSpotlightListItem from '@src/hooks/useSpotlightListItem'
import { useStepNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { ActivityParams } from '../PoiDetailPage'
import UpvoteButton from '../UpvoteButton'

interface Props {
  data: XCommunityPostCard['data'] & { articleId?: number }
  index: number
  onClick3Dot: () => void
  onClickUserProfile: () => void
}
const StoryCard: React.FC<Props> = ({ data, index, onClick3Dot, onClickUserProfile }) => {
  const { id, type } = data

  const logEvent = useAnalytics()

  const cardItemRef = useRef<HTMLDivElement>(null)
  const [isHighlighted, setIsHighlighted] = useState(false)

  const { spotlightPostId, ...params } = useActivityParams<ActivityParams>()
  const { stepReplace } = useStepNavigator('poi_detail')

  const handleBeforeSpotlight = useCallback(() => {
    logEvent('show_highlight_story', {
      source_type: type,
      source_id: id,
    })

    stepReplace({
      ...params,
      spotlightPostId: undefined,
    })

    setIsHighlighted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAfterSpotlight = useCallback(() => {
    setIsHighlighted(false)
  }, [])

  useSpotlightListItem({
    itemElement: cardItemRef,
    shouldSpotlight: id === spotlightPostId,
    onBeforeSpotlight: handleBeforeSpotlight,
    onAfterSpotlight: handleAfterSpotlight,
  })

  const handleClickContent = () => {
    logEvent('click_community_card', {
      area: 'community_tab',
      position: index + 1,
      source_type: type === 'article' ? 'post' : type === 'comment' ? 'comment' : undefined,
      source_id: id,
    })

    const postId = type === 'comment' ? data.articleId : id

    window.location.href = `${APP_SCHEME}story_articles/${postId}`
  }

  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()
  const handleClickImage = (idx: number) => {
    handleSelectDetailImage(idx)
  }

  const resourceMap = {
    ['article']: 'STORY_ARTICLE',
    ['comment']: 'STORY_COMMENT',
  } as const

  return (
    <>
      <StoryArticleCard ref={cardItemRef} isHighlighted={isHighlighted}>
        <CommunityPostCard
          data={data}
          onClickContent={handleClickContent}
          onClickMoreOptions={onClick3Dot}
          onClickImage={(idx) => handleClickImage(idx)}
          onClickUserProfile={onClickUserProfile}
        />
        <UpvoteButton resourceType={resourceMap[type]} resourceId={id} type={'UPVOTE'} />
      </StoryArticleCard>

      <ImageDetailSlider
        onClose={handleCloseDetailImage}
        initialIndex={selectedImageIdx}
        images={data.images?.map((image) => image.url) ?? []}
      />
    </>
  )
}

const StoryArticleCard = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: ${({ isHighlighted }) =>
    isHighlighted ? vars.$semantic.color.paperAccent : vars.$semantic.color.paperDefault};
  transition: 300ms background;
`

export default StoryCard

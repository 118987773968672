import { StackflowReactPlugin } from '@stackflow/react'
import React from 'react'

import { DevTools } from '@src/components/DevTools'

export function devToolsPlugin(): StackflowReactPlugin {
  return () => ({
    key: 'nearbyDevtools',
    wrapStack({ stack }) {
      return (
        <>
          {stack.render()}
          <DevTools />
        </>
      )
    },
  })
}

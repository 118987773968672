import styled from '@emotion/styled'
import React, { forwardRef, ReactNode } from 'react'
import { useSwipeable } from 'react-swipeable'
import { ConfigurationOptions, SwipeableCallbacks } from 'react-swipeable/dist/types'

interface Props {
  swipeableOptions?: Partial<SwipeableCallbacks & ConfigurationOptions>
  children?: ReactNode
  className?: string
}

const ListView = forwardRef<HTMLDivElement, Props>(({ swipeableOptions = {}, children, className }, ref) => {
  const { ref: swipeableRef, onMouseDown } = useSwipeable(swipeableOptions)

  return (
    <Container
      className={className}
      ref={(element) => {
        if (typeof ref === 'function') {
          ref(element)
        } else {
          if (!ref) return
          ref.current = element
        }
        swipeableRef(element)
      }}
      onMouseDown={onMouseDown}>
      {children}
    </Container>
  )
})

const Container = styled.div`
  height: 100%;
  overflow-y: scroll;
`

export default ListView

import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { bridge } from '@src/bridge'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useActivity, useNavigator } from '@src/stackflow'
import { createQueryString } from '@src/utils/url'

import useAnalytics from './useAnalytics'

interface PushArgs {
  poiId: string
  bizAccountId?: string
  reviewId: string
  options?: {
    operation?: 'push' | 'replace'
    area?: 'dialog' | 'snackbar'
  }
}
const usePushToDetailPage = () => {
  const navigator = useNavigator()
  const logEvent = useAnalytics()
  const { referrer } = useActivityParams<{ referrer: string }>()
  const { name } = useActivity()

  const handlePush = useCallback(
    ({ poiId, bizAccountId, reviewId, options }: PushArgs) => {
      const { operation = 'push', area } = options ?? {}

      logEvent('click_view_review_button', {
        poi_type: bizAccountId ? 'biz_account' : 'poi',
        poi_id: poiId,
        biz_account_id: bizAccountId,
        review_id: reviewId,
        area,
      })

      if (bizAccountId) {
        const queryString = createQueryString({
          referrer: referrer ?? `place.${name}`,
          entry: `place.${name}`,
          tab: 'REVIEW',
          highlightReviewId: reviewId,
        })

        type PushParams = Parameters<(typeof bridge)['router']['push']>['0']
        const bridgeRouterParams: PushParams = {
          app: `${WEBVIEW_BASE_URL}business-platform/home`,
          path: `/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
          navbar: false,
          scrollable: false,
        }

        if (operation === 'replace') navigator.pop()

        return bridge.router.push(bridgeRouterParams)
      } else {
        return navigator[operation]('poi_detail', { poiId, tab: 'review', spotlightPostId: reviewId })
      }
    },
    [logEvent, navigator, referrer, name]
  )

  return { handlePush }
}

export default usePushToDetailPage

import { useLoadableFamily } from '@daangn/recoil-loadable'
import { useActivityParams } from '@stackflow/react'
import { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { karrotBridgeCache } from '@src/bridge'
import { checkInRegionInfoAtom, userInfoAtom } from '@src/store'
import { haversineDistance } from '@src/utils/coordinate'

import { poiByIdSelectorFamily, winterSnackPoisLoadable } from '../state'
import { WinterSnackParams } from '../WinterSnackPage'

export const usePoiVariables = () => {
  const activityParams = useActivityParams<WinterSnackParams>()

  const user = useRecoilValue(userInfoAtom)
  const checkInRegion = useRecoilValue(checkInRegionInfoAtom)

  /**
   * comma separated coordinates: `${latitude}`,`${longitude}`
   * set 'undefined' for not using coordinates
   * not providing coordinates will use region center coordinates computed by backend
   */
  const coordinates = useMemo(() => {
    if (activityParams.coordinates) return activityParams.coordinates

    const currentPosition = karrotBridgeCache.getCurrentPosition()

    if (!currentPosition?.geolocation.currentPosition?.position || checkInRegion.centerCoordinates) {
      return undefined
    } else {
      const position = currentPosition.geolocation.currentPosition.position

      const distanceBetweenUserAndRegionCenter = haversineDistance(position, checkInRegion.centerCoordinates)

      if (distanceBetweenUserAndRegionCenter < 1000) {
        return `${position.latitude},${position.longitude}`
      } else {
        return undefined
      }
    }
  }, [activityParams.coordinates, checkInRegion.centerCoordinates])

  return useMemo(
    () => ({
      regionId: checkInRegion.id,
      coordinates,
      authToken: user.authToken,
    }),
    [checkInRegion.id, coordinates, user.authToken]
  )
}

export const usePois = () => {
  const variables = usePoiVariables()

  return useLoadableFamily({
    loadable: winterSnackPoisLoadable,
    variables,
  })
}

export const usePoisStore = () => {
  const variables = usePoiVariables()

  return useRecoilState(winterSnackPoisLoadable.store(variables))
}

export const usePoi = (id: string) => {
  const variables = usePoiVariables()

  return useRecoilState(
    poiByIdSelectorFamily({
      poiId: id,
      ...variables,
    })
  )
}

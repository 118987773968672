import React from 'react'

import { AlertDialog } from '@src/components/AlertDialog'

interface Props {
  onConfirm: () => void
}

const CategorySuggestionModal: React.FC<Props> = ({ onConfirm }) => {
  return (
    <AlertDialog
      contents="감사해요. 요청하신 내용 참고하여 카테고리 개선에 반영할게요."
      CTAButton={{
        label: '확인',
        onClick: onConfirm,
      }}
    />
  )
}

export default CategorySuggestionModal

import { createLoadableFamily } from '@daangn/recoil-loadable'
import axios from 'axios'

import { IS_PROD } from '@src/constants/environmentConstants'

import { pageKeyPrefix } from './key'
import { MiniApp } from './type'

export const miniAppLoadableFamily = createLoadableFamily<
  MiniApp | null,
  {
    regionId: number
    themeId: string
  },
  {
    miniapps_for_launchpad: MiniApp[] | null
  }
>({
  getStore({ atomFamily }) {
    return atomFamily({
      key: pageKeyPrefix('miniApp'),
    })
  },
  async fetch({ variables: { regionId, themeId } }) {
    if (regionId === -1 || themeId === '') return null

    const baseUrl = `https://mini${IS_PROD ? '' : '.alpha'}.kr.karrotmarket.com`

    try {
      return await axios
        .get(
          `${baseUrl}/api/v1/miniapps-for-launchpad/by-region-id-and-theme-id?region_id=${regionId}&theme_id=${themeId}`
        )
        .then((res) => {
          return res.data
        })
    } catch {
      return null
    }
  },
  onSuccess({ response }) {
    if (!response?.miniapps_for_launchpad) return null

    const randomIndex = Math.floor(Math.random() * response.miniapps_for_launchpad.length)
    return response.miniapps_for_launchpad[randomIndex]
  },
})

import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/components/Analytics'
import { myPoiReviewSelectorFamily } from '@src/shared/review/store/poiReview'
import { usePoiInputSelectionState } from '@src/widgets/PoiInput/state'

const useAnalytics = () => {
  const logEvent = useLogEvent()
  const { poiId, reviewId } = useActivityParams<{ poiId: string; reviewId: string }>()

  const [selectedPoiInfo] = usePoiInputSelectionState()
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId: selectedPoiInfo.id }))

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = {
        is_new: !(!!reviewId || !!myReview?.id),
        poi_id: poiId,
        review_id: (reviewId || myReview?.id) ?? null,
        ...params,
      }

      logEvent({ name, params: _params })
    },
    [logEvent, poiId, reviewId, myReview]
  )
}

export default useAnalytics

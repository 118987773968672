import { Coordinates } from '@src/types/global'

export function getBoundingBoxOfCircle(centerCoordinates: Coordinates, radius: number) {
  const radiusOfEarth = 6371 // in km

  const x = centerCoordinates.latitude
  const y = centerCoordinates.longitude
  const dy = (360 * radius) / radiusOfEarth
  const dx = dy * Math.cos((y * Math.PI) / 180)

  const swLat = x - dx
  const swLng = y - dy
  const neLat = x + dx
  const neLng = y + dy

  const sw = [swLat, swLng]
  const ne = [neLat, neLng]

  return {
    sw,
    ne,
  }
}

import { useRecoilState, useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import {
  userPostsQuerySelectorFamily,
  userPostsAtom,
  userPostsMetaAtom,
  userPostsPaginationAtom,
  userPostsSortByAtom,
  subprofileUserIdAtomFamily,
} from '../state'

export const useUserPosts = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))

  const userPostsLoadable = useRecoilValueLoadable(userPostsQuerySelectorFamily(userId))
  const [sortBy, setSortBy] = useRecoilState(userPostsSortByAtom(userId))
  const posts = useRecoilValue(userPostsAtom({ userId, sortBy }))
  const meta = useRecoilValue(userPostsMetaAtom({ userId, sortBy }))
  const setPagination = useSetRecoilState(userPostsPaginationAtom({ sortBy, userId }))

  const fetchMore = () => {
    if (meta?.hasNextPage && userPostsLoadable.state === 'hasValue') {
      setPagination((prev) => ({
        ...prev,
        page: prev.page + 1,
      }))
    }
  }

  return {
    posts,
    meta,
    fetchMore,
    setSortBy,
  }
}

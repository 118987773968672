import { CircularProgress } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import IconRetryRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconRetryRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { ActionChip } from '@src/components/Chip'

interface Props {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  className?: string
}
export const BoundarySearchButton = ({ onClick, isLoading, ...props }: Props) => {
  return (
    <ActionChip
      className={props.className}
      onClick={onClick}
      text="이 지역 재검색"
      type="normal"
      appendLeft={
        <Loading>
          {isLoading ? (
            <CircularProgress color={vars.$scale.color.grayAlpha200} size={14} />
          ) : (
            <IconRetryRegular width="100%" height="100%" color={vars.$scale.color.gray900} />
          )}
        </Loading>
      }
      css={{
        boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
      }}
    />
  )
}

const Loading = styled.span`
  min-height: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  width: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

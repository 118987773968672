import { createOperatingStatusContributionV2, WinterSnackMapItem } from '@daangn/local-business-network/lib/poi'
import { vars } from '@seed-design/design-token'
import { captureException } from '@sentry/react'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { useLogEvent } from '@src/components/Analytics'
import { Button } from '@src/components/Button'
import { useToast } from '@src/components/Toast'
import { useCurrentPosition } from '@src/hooks'
import { useNavigator } from '@src/stackflow'
import { userInfoAtom } from '@src/store'
import { haversineDistance } from '@src/utils/coordinate'

import { DEFAULT_MAP_POI_CARD_HEIGHT, mapPoiCardHeightAtom } from '../constants/style'
import { usePoi } from '../hooks'

interface Props {
  item: WinterSnackMapItem
  distance?: number
}
export const VisitButton = ({ item, distance }: Props) => {
  const { poi } = item

  const logEvent = useLogEvent()
  const { push } = useNavigator()

  const [, setPoi] = usePoi(poi.id)

  const { showToast } = useToast()
  const { access, position, handleOnAccessDenied } = useCurrentPosition()

  const user = useRecoilValue(userInfoAtom)
  const setPoiCardHeight = useSetRecoilState(mapPoiCardHeightAtom)

  const isOperatingStatusUpdated = !!poi.operatingStatus

  const isCurrentPositionCloseToPoi = () => {
    if (access === 'denied') {
      handleOnAccessDenied()

      return false
    } else if (access === 'loading') {
      return false
    } else {
      const distanceBetweenCurrentPositionAndPoi = haversineDistance(position, item.poi.coordinates!)

      if (distanceBetweenCurrentPositionAndPoi > 1000) {
        showToast({
          text: '가까운 가게만 방문할 수 있어요.',
          duration: 'short',
        })

        return false
      }

      return true
    }
  }

  const pushToWriteReviewPage = () => {
    logEvent({ name: 'click_write_review', params: {} })
    push('poi_review', {
      poiId: poi.id,
      action: 'write',
    })
  }

  const handleClickVisit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()

    try {
      if (!user.authToken) return
      if (!isCurrentPositionCloseToPoi()) return

      await createOperatingStatusContributionV2({
        params: {
          xAuthToken: user.authToken,
          createV2Body: {
            poiId: Number(poi.id),
            operatingStatus: 'open',
            openReason: 'visited',
            // eslint-disable-next-line no-warning-comments
            // @todo remove 'closedReason' field after backend is updated
            // closedReason: 'closed',
          } as any,
        },
      })

      karrotBridge.setHapticSuccess({})

      if (!isOperatingStatusUpdated) {
        setPoiCardHeight(DEFAULT_MAP_POI_CARD_HEIGHT)
      }

      setPoi({
        ...item,
        poi: {
          ...poi,
          operatingStatus: {
            status: 'open',
            contributedAt: new Date().toISOString(),
            user: {
              // eslint-disable-next-line no-warning-comments
              // @todo type
              id: user.id,
              nickname: user.nickname,
              profileImageUrl: user.profileImage,
              phone: user.phone,
            } as any,
          },
        },
      })

      showToast({
        text: `감사해요! ${user.nickname}님 덕분에 동네 정보가 풍부해졌어요.`,
        button: {
          text: '후기 작성',
          onClickButton: pushToWriteReviewPage,
        },
        duration: 'long',
      })
    } catch (error) {
      captureException(error)
      showToast({
        text: '방문 여부를 알려주는데 실패했어요. 잠시후 다시 시도해주세요.',
        duration: 'short',
      })
    } finally {
      logEvent({ name: 'click_success_visit', params: { distance: distance ? Math.round(distance) : null } })
    }
  }

  return (
    <Button
      styleTheme="primary-low"
      textSize="14"
      fullwidth
      onClick={(e) => handleClickVisit(e)}
      css={[
        {
          padding: '0.656rem 0',
          minHeight: '2.5rem',
        },
        vars.$semantic.typography.label3Bold,
      ]}>
      방문에 성공했어요
    </Button>
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { CreateV2Body } from '../models';
// @ts-ignore
import { CreateV2Dto } from '../models';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @param {string} xAuthToken 
   * @param {CreateV2Body} createV2Body 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const createOperatingStatusContributionV2AxiosParamCreator = async (xAuthToken: string, createV2Body: CreateV2Body, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/operating-status-contributions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = createV2Body || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 신고 생성
   * @param {string} xAuthToken 
   * @param {CreateV2Dto} createV2Dto 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const createReportV2AxiosParamCreator = async (xAuthToken: string, createV2Dto: CreateV2Dto, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = createV2Dto || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * DefaultApi - functional programming interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {CreateV2Body} createV2Body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const createOperatingStatusContributionV2Fp = async (xAuthToken: string, createV2Body: CreateV2Body, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await createOperatingStatusContributionV2AxiosParamCreator(xAuthToken, createV2Body, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 신고 생성
     * @param {string} xAuthToken 
     * @param {CreateV2Dto} createV2Dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const createReportV2Fp = async (xAuthToken: string, createV2Dto: CreateV2Dto, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await createReportV2AxiosParamCreator(xAuthToken, createV2Dto, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * DefaultApi - factory interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {CreateV2Body} createV2Body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const createOperatingStatusContributionV2 = (xAuthToken: string, createV2Body: CreateV2Body, options?: any): AxiosPromise<void> => {
        return createOperatingStatusContributionV2Fp(xAuthToken, createV2Body, options).then((request) => request());
    }
    /**
     * 
     * @summary 신고 생성
     * @param {string} xAuthToken 
     * @param {CreateV2Dto} createV2Dto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const createReportV2 = (xAuthToken: string, createV2Dto: CreateV2Dto, options?: any): AxiosPromise<void> => {
        return createReportV2Fp(xAuthToken, createV2Dto, options).then((request) => request());
    }



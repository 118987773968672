import React, { Suspense as ReactSuspense, ComponentProps, ReactNode } from 'react'

import { Loading } from '../Loading'

export interface SuspenseProps {
  fallback?: ReactNode
  children?: ComponentProps<typeof ReactSuspense>['children']
}

const Suspense = ({ fallback, children }: SuspenseProps) => {
  return <ReactSuspense fallback={fallback ?? <Loading />}>{children}</ReactSuspense>
}

export default Suspense

import React from 'react'

import { Poi } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'

import CreateSuggestion from './CreateSuggestion'

const CreateSuggestionForCommunityTagPage = () => {
  const handleSubmitComplete = (poi: Poi | null) => {
    if (poi) {
      bridge.stream.emit('SELECT_POI_TAG_FROM_WEBVIEW', {
        poi_id: poi.id,
        poi_name: poi.name,
      })
    } else {
      bridge.toast.open({ body: '생성하신 장소를 태깅하는데 실패했습니다.' })
      bridge.router.close()
    }
  }

  return <CreateSuggestion onSubmit={handleSubmitComplete} />
}

export default CreateSuggestionForCommunityTagPage

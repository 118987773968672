import { BookmarkPoiIdDto } from '@daangn/local-business-network/lib/poi'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { useToast } from '@src/components/Toast'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import BaseWatchButton from '@src/shared/watch/WatchButton'
import { useNavigator } from '@src/stackflow'
import { add을를 } from '@src/utils/checkIsWithBatchim'

import { useAnalytics } from '../../hooks'

interface Props {
  placeWatchId: BookmarkPoiIdDto
}
const WatchButton: React.FC<Props> = ({ placeWatchId }) => {
  const logEvent = useAnalytics()
  const { showToast } = useToast()
  const { push } = useNavigator()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId: placeWatchId.targetId }))

  const handleAddWatch = () => {
    logEvent('click_add_watch_button')

    if (poi) {
      showToast({
        text: `${add을를(poi.name)} 관심 가게에 추가했어요.`,
        duration: 'short',
        button: {
          text: '관심 가게 보기',
          onClickButton: () => {
            push('place_watch', {})
          },
        },
      })
    }
  }

  const handleRemoveWatch = () => {
    logEvent('click_remove_watch_button')
  }

  return (
    <StyledWatchButton
      placeWatchId={placeWatchId}
      labels={{
        default: '관심',
        watched: '관심',
      }}
      onAddWatch={handleAddWatch}
      onRemoveWatch={handleRemoveWatch}
      customDefaultIconCSS={defaultIconCSS}
      customWatchedIconCSS={watchedIconCSS}
    />
  )
}

const StyledWatchButton = styled(BaseWatchButton)`
  background: none;
  border: none;
  flex: 1 100%;
  height: 3rem;
  margin: 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.caption1Regular};

  &:not(:last-child) {
    border-right: 0.0625rem solid ${vars.$semantic.color.divider1};
  }
`
const defaultIconCSS = css`
  margin: 0 0 0.25rem;
  color: ${vars.$scale.color.gray900};
`
const watchedIconCSS = css`
  margin: 0 0 0.25rem;
`

export default WatchButton

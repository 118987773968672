import axios from 'axios'
import sha256 from 'sha256'

import Request from './Request'
import { BigPictureUploadRespone } from './types/bigPicture'

interface FormOptions {
  region?: string
  type?: string
  service?: string
}

export default class BigPictureAPI extends Request {
  imageUpload = async ({
    userId,
    authToken,
    blob,
    file,
    image,
    formOptions = {},
  }: {
    userId: number
    authToken: string
    blob: Blob
    file: File
    image: HTMLCanvasElement
    formOptions?: FormOptions
  }) => {
    const region = formOptions?.region || 'kr'
    const service = formOptions?.service || 'poi'
    const type = formOptions?.type || 'suggestion'
    const dateString = new Date().toString()

    const form = new FormData()
    form.append('body', blob)
    form.append('region', region)
    form.append('service', service)
    form.append('type', type)
    form.append('width', `${image.width}`)
    form.append('height', `${image.height}`)
    form.append('content_type', file.type)
    form.append('user_id', `${userId}`)
    form.append(
      'filepath',
      `${service}/${type}/${userId}/${sha256(file.name + dateString)}.${file.type.replace('image/', '')}`
    )

    const { data } = await this.req.post<{
      data: {
        image: BigPictureUploadRespone
      }
    }>('/api/v2/request_upload_url', form, this.generateApiHeaders(authToken))

    await axios.put(data.data.image.upload_url, blob, {
      headers: {
        'x-amz-meta-id': data.data.image.id,
        'Content-Type': file.type,
      },
    })

    return data
  }
}

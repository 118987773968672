import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useMemo } from 'react'

import { SectionHeader } from '@src/components'
import { Button } from '@src/components/Button'
import { Section } from '@src/components/Layout'
import { PostPreviewSimplified } from '@src/components/Preview'
import { reviewsByPoiIdAndUserIdLoadableFamily } from '@src/shared/review'

import EmptyReviewSection from './EmptyReviewSection'
import { useAnalytics, useTab } from '../../hooks'

const ReviewSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const { setTab } = useTab({ poiId })
  const logEvent = useAnalytics()

  const { value: reviewsStore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId, sort: 'latestCreation', userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  const filteredReviewList = useMemo(
    () => (reviewsStore?.items ?? []).filter((review) => review.content),
    [reviewsStore]
  )

  const reviewPreviewData = useMemo(
    () =>
      filteredReviewList.slice(0, 3).map((review) => ({
        type: 'review' as const,
        id: String(review.id!),
        author: {
          id: review.user!.id,
          name: review.user!.nickname,
          profileImage: review.user?.profileImageUrl,
          regionName: review.user?.region?.name,
          regionCheckInCount: review.user?.displayRegionCheckinsCount,
        },
        content: review.content!,
        tags: review.tagNames,
        images: {
          url: review.images?.map((image) => image.url) ?? [],
        },
        createdAt: review.createdAt!,
      })),
    [filteredReviewList]
  )

  const handleClickMoreReviews = () => {
    logEvent('click_view_more_review_button')
    setTab('posts', 'review')
  }

  if (!reviewsStore) return null

  const isEmpty = (reviewsStore.total ?? 0) <= 0 || reviewsStore.items.length <= 0
  if (isEmpty) {
    return <EmptyReviewSection />
  }
  return filteredReviewList.length > 0 ? (
    <Section>
      <Container>
        <SectionHeader type="basic" text="후기" />
        <div className="content">
          {reviewPreviewData.map((previewData) => (
            <ReviewPreview key={previewData.id} data={previewData} />
          ))}
        </div>
        {reviewsStore?.total && reviewsStore.total > 3 && filteredReviewList.length >= 3 && (
          <Button styleTheme="secondary-outlined" textSize="14" fullwidth onClick={handleClickMoreReviews}>
            후기 더보기
          </Button>
        )}
      </Container>
    </Section>
  ) : null
}

const Container = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    h3 {
      padding: 16px 0 16px 16px;
    }
    .count {
      color: ${vars.$scale.color.carrot500};
    }
  }
  .button-container {
    padding: 0.625rem 0 1.5rem;
  }
`

const ReviewPreview = styled(PostPreviewSimplified)`
  padding: 1rem 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${vars.$semantic.color.divider1};
  }
`

export default ReviewSection

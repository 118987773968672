/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { CurationMapResponse } from '../models';
// @ts-ignore
import { GetPoiImagesV2200Response } from '../models';
// @ts-ignore
import { GetPoisV2200Response } from '../models';
// @ts-ignore
import { ListResponseOfFilterDto } from '../models';
// @ts-ignore
import { PaginateHospitalResponseDto } from '../models';
// @ts-ignore
import { Poi } from '../models';
// @ts-ignore
import { PoisResponse } from '../models';
// @ts-ignore
import { WinterSnackMapResponse } from '../models';
/**
 * PoisApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @summary Business account id 리스트로 POI 리스트 조회
   * @param {Array<number>} baIds 비즈프로필 아이디 리스트
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getByBaIdsV2AxiosParamCreator = async (baIds: Array<number>, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/by-ba-ids`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (baIds) {
          localVarQueryParameter['baIds'] = baIds;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 브랜드프로필 ids로 조회
   * @param {string} brandBaId 본사 브랜드프로필 id
   * @param {string} coords 반경검색시 사용되는 검색 기준점. 해당 좌표를 중심으로 가까운 poi검색
   * @param {'distance'} orderBy 정렬 방식
   * @param {number} [take] 검색 개수
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getByBrandBaIdV2AxiosParamCreator = async (brandBaId: string, coords: string, orderBy: 'distance', take?: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/by-brand-ba-id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (brandBaId !== undefined) {
          localVarQueryParameter['brandBaId'] = brandBaId;
      }

      if (coords !== undefined) {
          localVarQueryParameter['coords'] = coords;
      }

      if (orderBy !== undefined) {
          localVarQueryParameter['orderBy'] = orderBy;
      }

      if (take !== undefined) {
          localVarQueryParameter['take'] = take;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {number} curationCategoryId 큐레이션 카테고리 id
   * @param {number} regionId 유저가 체크인한 리전 아이디
   * @param {string} xAuthToken 
   * @param {string} [coordinates] 반경검색시 사용되는 검색 기준점
   * @param {string} [userLocation] 유저의 현위치. 위치동의한 유저라면 어떤 검색을 할 때든 항상 같이 넣어요. poi와의 거리를 계산할 때만 사용해요.
   * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열. bound우선으로 검색되어요.
   * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
   * @param {string} [visitSessionId] 통합지도 검색로그를 위한 visitSessionId
   * @param {string} [querySessionId] 통합지도 검색로그를 위한 querySessionId
   * @param {boolean} [isCurrentAreaSearch] 통합지도 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getCurationPoisV2AxiosParamCreator = async (curationCategoryId: number, regionId: number, xAuthToken: string, coordinates?: string, userLocation?: string, boundSw?: string, boundNe?: string, visitSessionId?: string, querySessionId?: string, isCurrentAreaSearch?: boolean, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/curation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (curationCategoryId !== undefined) {
          localVarQueryParameter['curationCategoryId'] = curationCategoryId;
      }

      if (regionId !== undefined) {
          localVarQueryParameter['regionId'] = regionId;
      }

      if (coordinates !== undefined) {
          localVarQueryParameter['coordinates'] = coordinates;
      }

      if (userLocation !== undefined) {
          localVarQueryParameter['userLocation'] = userLocation;
      }

      if (boundSw !== undefined) {
          localVarQueryParameter['boundSw'] = boundSw;
      }

      if (boundNe !== undefined) {
          localVarQueryParameter['boundNe'] = boundNe;
      }

      if (visitSessionId !== undefined) {
          localVarQueryParameter['visitSessionId'] = visitSessionId;
      }

      if (querySessionId !== undefined) {
          localVarQueryParameter['querySessionId'] = querySessionId;
      }

      if (isCurrentAreaSearch !== undefined) {
          localVarQueryParameter['isCurrentAreaSearch'] = isCurrentAreaSearch;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getHospitalFiltersV2AxiosParamCreator = async (options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/hospitals/filters`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 하나의 POI를 아이디로 조회
   * @param {string} id POI ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getPoiByIdV2AxiosParamCreator = async (id: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/{id}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary POI와 관련된 모든 이미지 조회(태깅, 가격표, 수정제안, 리뷰)
   * @param {string} id POI ID
   * @param {number} [page] 조회할 페이지
   * @param {number} [perPage] 조회할 아이템 갯수
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getPoiImagesV2AxiosParamCreator = async (id: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/{id}/images`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary POI public ids로 조회
   * @param {Array<string>} publicIds POI public id 리스트
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getPoisByPublicIdsV2AxiosParamCreator = async (publicIds: Array<string>, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/by-public-ids`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (publicIds) {
          localVarQueryParameter['publicIds'] = publicIds;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getPoisV2AxiosParamCreator = async (options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {number} regionId 유저가 체크인한 리전 아이디. coordinates와 boundSw, boundNe 필드가 모두 전달되지 않는 경우 지역 중심 좌표 기준 3km를 조회해요
   * @param {string} xAuthToken 
   * @param {string} [coordinates] 반경검색시 사용되는 검색 기준점. 이 좌표 기준 1.5km 조회해요
   * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열. bound우선으로 검색되어요.
   * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
   * @param {string} [visitSessionId] 통합지도 검색로그를 위한 visitSessionId
   * @param {string} [querySessionId] 통합지도 검색로그를 위한 querySessionId
   * @param {boolean} [isCurrentAreaSearch] 통합지도 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getWinterSnackPoisV2AxiosParamCreator = async (regionId: number, xAuthToken: string, coordinates?: string, boundSw?: string, boundNe?: string, visitSessionId?: string, querySessionId?: string, isCurrentAreaSearch?: boolean, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/winter-snack`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (regionId !== undefined) {
          localVarQueryParameter['regionId'] = regionId;
      }

      if (coordinates !== undefined) {
          localVarQueryParameter['coordinates'] = coordinates;
      }

      if (boundSw !== undefined) {
          localVarQueryParameter['boundSw'] = boundSw;
      }

      if (boundNe !== undefined) {
          localVarQueryParameter['boundNe'] = boundNe;
      }

      if (visitSessionId !== undefined) {
          localVarQueryParameter['visitSessionId'] = visitSessionId;
      }

      if (querySessionId !== undefined) {
          localVarQueryParameter['querySessionId'] = querySessionId;
      }

      if (isCurrentAreaSearch !== undefined) {
          localVarQueryParameter['isCurrentAreaSearch'] = isCurrentAreaSearch;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {number} regionId 지역 아이디. 지역의 중심 좌표를 검색 반경의 중심좌표로 검색해요
   * @param {string} [center] 검색 반경의 중심좌표
   * @param {number} [radiusM] 검색 반경 (단위: m)
   * @param {number} [filterId] 필터 아이디
   * @param {number} [page] 페이지 수
   * @param {number} [perPage] 페이지 당 아이템 수
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const paginateHospitalsV2AxiosParamCreator = async (regionId: number, center?: string, radiusM?: number, filterId?: number, page?: number, perPage?: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/hospitals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (center !== undefined) {
          localVarQueryParameter['center'] = center;
      }

      if (regionId !== undefined) {
          localVarQueryParameter['regionId'] = regionId;
      }

      if (radiusM !== undefined) {
          localVarQueryParameter['radiusM'] = radiusM;
      }

      if (filterId !== undefined) {
          localVarQueryParameter['filterId'] = filterId;
      }

      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {string} query 검색어
   * @param {Array<number>} [types] Poi 타입. 1: 비즈니스, 2: 공원, 3: 공공개방장소, 5: 길거리음식, 6:SPOT, 7:UNDEFINED
   * @param {number} [regionId] 지역 아이디
   * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
   * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
   * @param {number} [limit] 조회할 아이템 수
   * @param {number} [page] 페이지 수
   * @param {string} [servicedFrom] 검색 요청을 보낸 서비스 (webapp:호이안, poi_web)
   * @param {string} [visitSessionId] 검색로그를 위한 필드.uuidv4
   * @param {string} [querySessionId] 검색로그를 위한 필드.uuidv4
   * @param {boolean} [isPredictedKeyword] 검색로그를 위한 필드.추천 키워드로 검색 여부
   * @param {boolean} [isCurrentAreaSearch] 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
   * @param {string} [xAuthToken] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const searchPoisV2AxiosParamCreator = async (query: string, types?: Array<number>, regionId?: number, boundSw?: string, boundNe?: string, limit?: number, page?: number, servicedFrom?: string, visitSessionId?: string, querySessionId?: string, isPredictedKeyword?: boolean, isCurrentAreaSearch?: boolean, xAuthToken?: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/pois/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (query !== undefined) {
          localVarQueryParameter['query'] = query;
      }

      if (types) {
          localVarQueryParameter['types'] = types;
      }

      if (regionId !== undefined) {
          localVarQueryParameter['regionId'] = regionId;
      }

      if (boundSw !== undefined) {
          localVarQueryParameter['boundSw'] = boundSw;
      }

      if (boundNe !== undefined) {
          localVarQueryParameter['boundNe'] = boundNe;
      }

      if (limit !== undefined) {
          localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (servicedFrom !== undefined) {
          localVarQueryParameter['servicedFrom'] = servicedFrom;
      }

      if (visitSessionId !== undefined) {
          localVarQueryParameter['visitSessionId'] = visitSessionId;
      }

      if (querySessionId !== undefined) {
          localVarQueryParameter['querySessionId'] = querySessionId;
      }

      if (isPredictedKeyword !== undefined) {
          localVarQueryParameter['isPredictedKeyword'] = isPredictedKeyword;
      }

      if (isCurrentAreaSearch !== undefined) {
          localVarQueryParameter['isCurrentAreaSearch'] = isCurrentAreaSearch;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * PoisApi - functional programming interface
 * @export
 */
    /**
     * 
     * @summary Business account id 리스트로 POI 리스트 조회
     * @param {Array<number>} baIds 비즈프로필 아이디 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getByBaIdsV2Fp = async (baIds: Array<number>, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Poi>>> => {
        const localVarAxiosArgs = await getByBaIdsV2AxiosParamCreator(baIds, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 브랜드프로필 ids로 조회
     * @param {string} brandBaId 본사 브랜드프로필 id
     * @param {string} coords 반경검색시 사용되는 검색 기준점. 해당 좌표를 중심으로 가까운 poi검색
     * @param {'distance'} orderBy 정렬 방식
     * @param {number} [take] 검색 개수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getByBrandBaIdV2Fp = async (brandBaId: string, coords: string, orderBy: 'distance', take?: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoisResponse>> => {
        const localVarAxiosArgs = await getByBrandBaIdV2AxiosParamCreator(brandBaId, coords, orderBy, take, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {number} curationCategoryId 큐레이션 카테고리 id
     * @param {number} regionId 유저가 체크인한 리전 아이디
     * @param {string} xAuthToken 
     * @param {string} [coordinates] 반경검색시 사용되는 검색 기준점
     * @param {string} [userLocation] 유저의 현위치. 위치동의한 유저라면 어떤 검색을 할 때든 항상 같이 넣어요. poi와의 거리를 계산할 때만 사용해요.
     * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열. bound우선으로 검색되어요.
     * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {string} [visitSessionId] 통합지도 검색로그를 위한 visitSessionId
     * @param {string} [querySessionId] 통합지도 검색로그를 위한 querySessionId
     * @param {boolean} [isCurrentAreaSearch] 통합지도 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getCurationPoisV2Fp = async (curationCategoryId: number, regionId: number, xAuthToken: string, coordinates?: string, userLocation?: string, boundSw?: string, boundNe?: string, visitSessionId?: string, querySessionId?: string, isCurrentAreaSearch?: boolean, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurationMapResponse>> => {
        const localVarAxiosArgs = await getCurationPoisV2AxiosParamCreator(curationCategoryId, regionId, xAuthToken, coordinates, userLocation, boundSw, boundNe, visitSessionId, querySessionId, isCurrentAreaSearch, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getHospitalFiltersV2Fp = async (options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListResponseOfFilterDto>> => {
        const localVarAxiosArgs = await getHospitalFiltersV2AxiosParamCreator(options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 하나의 POI를 아이디로 조회
     * @param {string} id POI ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getPoiByIdV2Fp = async (id: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Poi>> => {
        const localVarAxiosArgs = await getPoiByIdV2AxiosParamCreator(id, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary POI와 관련된 모든 이미지 조회(태깅, 가격표, 수정제안, 리뷰)
     * @param {string} id POI ID
     * @param {number} [page] 조회할 페이지
     * @param {number} [perPage] 조회할 아이템 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getPoiImagesV2Fp = async (id: string, page?: number, perPage?: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoiImagesV2200Response>> => {
        const localVarAxiosArgs = await getPoiImagesV2AxiosParamCreator(id, page, perPage, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary POI public ids로 조회
     * @param {Array<string>} publicIds POI public id 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getPoisByPublicIdsV2Fp = async (publicIds: Array<string>, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Poi>>> => {
        const localVarAxiosArgs = await getPoisByPublicIdsV2AxiosParamCreator(publicIds, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getPoisV2Fp = async (options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoisV2200Response>> => {
        const localVarAxiosArgs = await getPoisV2AxiosParamCreator(options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {number} regionId 유저가 체크인한 리전 아이디. coordinates와 boundSw, boundNe 필드가 모두 전달되지 않는 경우 지역 중심 좌표 기준 3km를 조회해요
     * @param {string} xAuthToken 
     * @param {string} [coordinates] 반경검색시 사용되는 검색 기준점. 이 좌표 기준 1.5km 조회해요
     * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열. bound우선으로 검색되어요.
     * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {string} [visitSessionId] 통합지도 검색로그를 위한 visitSessionId
     * @param {string} [querySessionId] 통합지도 검색로그를 위한 querySessionId
     * @param {boolean} [isCurrentAreaSearch] 통합지도 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getWinterSnackPoisV2Fp = async (regionId: number, xAuthToken: string, coordinates?: string, boundSw?: string, boundNe?: string, visitSessionId?: string, querySessionId?: string, isCurrentAreaSearch?: boolean, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WinterSnackMapResponse>> => {
        const localVarAxiosArgs = await getWinterSnackPoisV2AxiosParamCreator(regionId, xAuthToken, coordinates, boundSw, boundNe, visitSessionId, querySessionId, isCurrentAreaSearch, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {number} regionId 지역 아이디. 지역의 중심 좌표를 검색 반경의 중심좌표로 검색해요
     * @param {string} [center] 검색 반경의 중심좌표
     * @param {number} [radiusM] 검색 반경 (단위: m)
     * @param {number} [filterId] 필터 아이디
     * @param {number} [page] 페이지 수
     * @param {number} [perPage] 페이지 당 아이템 수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const paginateHospitalsV2Fp = async (regionId: number, center?: string, radiusM?: number, filterId?: number, page?: number, perPage?: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginateHospitalResponseDto>> => {
        const localVarAxiosArgs = await paginateHospitalsV2AxiosParamCreator(regionId, center, radiusM, filterId, page, perPage, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {string} query 검색어
     * @param {Array<number>} [types] Poi 타입. 1: 비즈니스, 2: 공원, 3: 공공개방장소, 5: 길거리음식, 6:SPOT, 7:UNDEFINED
     * @param {number} [regionId] 지역 아이디
     * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {number} [limit] 조회할 아이템 수
     * @param {number} [page] 페이지 수
     * @param {string} [servicedFrom] 검색 요청을 보낸 서비스 (webapp:호이안, poi_web)
     * @param {string} [visitSessionId] 검색로그를 위한 필드.uuidv4
     * @param {string} [querySessionId] 검색로그를 위한 필드.uuidv4
     * @param {boolean} [isPredictedKeyword] 검색로그를 위한 필드.추천 키워드로 검색 여부
     * @param {boolean} [isCurrentAreaSearch] 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const searchPoisV2Fp = async (query: string, types?: Array<number>, regionId?: number, boundSw?: string, boundNe?: string, limit?: number, page?: number, servicedFrom?: string, visitSessionId?: string, querySessionId?: string, isPredictedKeyword?: boolean, isCurrentAreaSearch?: boolean, xAuthToken?: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPoisV2200Response>> => {
        const localVarAxiosArgs = await searchPoisV2AxiosParamCreator(query, types, regionId, boundSw, boundNe, limit, page, servicedFrom, visitSessionId, querySessionId, isPredictedKeyword, isCurrentAreaSearch, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * PoisApi - factory interface
 * @export
 */
    /**
     * 
     * @summary Business account id 리스트로 POI 리스트 조회
     * @param {Array<number>} baIds 비즈프로필 아이디 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getByBaIdsV2 = (baIds: Array<number>, options?: any): AxiosPromise<Array<Poi>> => {
        return getByBaIdsV2Fp(baIds, options).then((request) => request());
    }
    /**
     * 
     * @summary 브랜드프로필 ids로 조회
     * @param {string} brandBaId 본사 브랜드프로필 id
     * @param {string} coords 반경검색시 사용되는 검색 기준점. 해당 좌표를 중심으로 가까운 poi검색
     * @param {'distance'} orderBy 정렬 방식
     * @param {number} [take] 검색 개수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getByBrandBaIdV2 = (brandBaId: string, coords: string, orderBy: 'distance', take?: number, options?: any): AxiosPromise<PoisResponse> => {
        return getByBrandBaIdV2Fp(brandBaId, coords, orderBy, take, options).then((request) => request());
    }
    /**
     * 
     * @param {number} curationCategoryId 큐레이션 카테고리 id
     * @param {number} regionId 유저가 체크인한 리전 아이디
     * @param {string} xAuthToken 
     * @param {string} [coordinates] 반경검색시 사용되는 검색 기준점
     * @param {string} [userLocation] 유저의 현위치. 위치동의한 유저라면 어떤 검색을 할 때든 항상 같이 넣어요. poi와의 거리를 계산할 때만 사용해요.
     * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열. bound우선으로 검색되어요.
     * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {string} [visitSessionId] 통합지도 검색로그를 위한 visitSessionId
     * @param {string} [querySessionId] 통합지도 검색로그를 위한 querySessionId
     * @param {boolean} [isCurrentAreaSearch] 통합지도 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getCurationPoisV2 = (curationCategoryId: number, regionId: number, xAuthToken: string, coordinates?: string, userLocation?: string, boundSw?: string, boundNe?: string, visitSessionId?: string, querySessionId?: string, isCurrentAreaSearch?: boolean, options?: any): AxiosPromise<CurationMapResponse> => {
        return getCurationPoisV2Fp(curationCategoryId, regionId, xAuthToken, coordinates, userLocation, boundSw, boundNe, visitSessionId, querySessionId, isCurrentAreaSearch, options).then((request) => request());
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getHospitalFiltersV2 = (options?: any): AxiosPromise<ListResponseOfFilterDto> => {
        return getHospitalFiltersV2Fp(options).then((request) => request());
    }
    /**
     * 
     * @summary 하나의 POI를 아이디로 조회
     * @param {string} id POI ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getPoiByIdV2 = (id: string, options?: any): AxiosPromise<Poi> => {
        return getPoiByIdV2Fp(id, options).then((request) => request());
    }
    /**
     * 
     * @summary POI와 관련된 모든 이미지 조회(태깅, 가격표, 수정제안, 리뷰)
     * @param {string} id POI ID
     * @param {number} [page] 조회할 페이지
     * @param {number} [perPage] 조회할 아이템 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getPoiImagesV2 = (id: string, page?: number, perPage?: number, options?: any): AxiosPromise<GetPoiImagesV2200Response> => {
        return getPoiImagesV2Fp(id, page, perPage, options).then((request) => request());
    }
    /**
     * 
     * @summary POI public ids로 조회
     * @param {Array<string>} publicIds POI public id 리스트
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getPoisByPublicIdsV2 = (publicIds: Array<string>, options?: any): AxiosPromise<Array<Poi>> => {
        return getPoisByPublicIdsV2Fp(publicIds, options).then((request) => request());
    }
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getPoisV2 = (options?: any): AxiosPromise<GetPoisV2200Response> => {
        return getPoisV2Fp(options).then((request) => request());
    }
    /**
     * 
     * @param {number} regionId 유저가 체크인한 리전 아이디. coordinates와 boundSw, boundNe 필드가 모두 전달되지 않는 경우 지역 중심 좌표 기준 3km를 조회해요
     * @param {string} xAuthToken 
     * @param {string} [coordinates] 반경검색시 사용되는 검색 기준점. 이 좌표 기준 1.5km 조회해요
     * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열. bound우선으로 검색되어요.
     * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {string} [visitSessionId] 통합지도 검색로그를 위한 visitSessionId
     * @param {string} [querySessionId] 통합지도 검색로그를 위한 querySessionId
     * @param {boolean} [isCurrentAreaSearch] 통합지도 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getWinterSnackPoisV2 = (regionId: number, xAuthToken: string, coordinates?: string, boundSw?: string, boundNe?: string, visitSessionId?: string, querySessionId?: string, isCurrentAreaSearch?: boolean, options?: any): AxiosPromise<WinterSnackMapResponse> => {
        return getWinterSnackPoisV2Fp(regionId, xAuthToken, coordinates, boundSw, boundNe, visitSessionId, querySessionId, isCurrentAreaSearch, options).then((request) => request());
    }
    /**
     * 
     * @param {number} regionId 지역 아이디. 지역의 중심 좌표를 검색 반경의 중심좌표로 검색해요
     * @param {string} [center] 검색 반경의 중심좌표
     * @param {number} [radiusM] 검색 반경 (단위: m)
     * @param {number} [filterId] 필터 아이디
     * @param {number} [page] 페이지 수
     * @param {number} [perPage] 페이지 당 아이템 수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const paginateHospitalsV2 = (regionId: number, center?: string, radiusM?: number, filterId?: number, page?: number, perPage?: number, options?: any): AxiosPromise<PaginateHospitalResponseDto> => {
        return paginateHospitalsV2Fp(regionId, center, radiusM, filterId, page, perPage, options).then((request) => request());
    }
    /**
     * 
     * @param {string} query 검색어
     * @param {Array<number>} [types] Poi 타입. 1: 비즈니스, 2: 공원, 3: 공공개방장소, 5: 길거리음식, 6:SPOT, 7:UNDEFINED
     * @param {number} [regionId] 지역 아이디
     * @param {string} [boundSw] 검색할 바운더리의 남서쪽(sw) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {string} [boundNe] 검색할 바운더리의 북동쪽(ne) 꼭지점의 위경도를 콤마로 이어 붙인 문자열
     * @param {number} [limit] 조회할 아이템 수
     * @param {number} [page] 페이지 수
     * @param {string} [servicedFrom] 검색 요청을 보낸 서비스 (webapp:호이안, poi_web)
     * @param {string} [visitSessionId] 검색로그를 위한 필드.uuidv4
     * @param {string} [querySessionId] 검색로그를 위한 필드.uuidv4
     * @param {boolean} [isPredictedKeyword] 검색로그를 위한 필드.추천 키워드로 검색 여부
     * @param {boolean} [isCurrentAreaSearch] 검색로그를 위한 필드. 유저가 \&quot;현 지역 검색\&quot;으로 검색 했는지 여부
     * @param {string} [xAuthToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const searchPoisV2 = (query: string, types?: Array<number>, regionId?: number, boundSw?: string, boundNe?: string, limit?: number, page?: number, servicedFrom?: string, visitSessionId?: string, querySessionId?: string, isPredictedKeyword?: boolean, isCurrentAreaSearch?: boolean, xAuthToken?: string, options?: any): AxiosPromise<GetPoisV2200Response> => {
        return searchPoisV2Fp(query, types, regionId, boundSw, boundNe, limit, page, servicedFrom, visitSessionId, querySessionId, isPredictedKeyword, isCurrentAreaSearch, xAuthToken, options).then((request) => request());
    }



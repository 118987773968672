import styled from '@emotion/styled'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { withKakaoMapErrorFallback } from '@src/components/Map/KakaoMap'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { EmptyState } from './EmptyState'
import { ListView } from './ListView'
import { MapView } from './MapView'
import { selectedViewTypeAtomFamily } from './state'
import { isWatchedPlacesEmptySelectorFamily } from './state/selectedWatchedPoi'

const Contents = () => {
  const screenInstance = useScreenInstance()

  const isNoWatchedPlaces = useRecoilValue(isWatchedPlacesEmptySelectorFamily(screenInstance))
  const selectedViewType = useRecoilValue(selectedViewTypeAtomFamily(screenInstance))

  if (isNoWatchedPlaces) {
    return <EmptyState />
  }
  return (
    <Container>
      {selectedViewType === 'LIST' && (
        <ListWrapper>
          <ListView />
        </ListWrapper>
      )}
      <MapWrapper>
        <MapView />
      </MapWrapper>
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  position: relative;
`
const ListWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`
const MapWrapper = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`

export default withKakaoMapErrorFallback(Contents, () => <ListView />)

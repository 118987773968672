import { vars } from '@seed-design/design-token'
import { useActivity } from '@stackflow/react'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { useRecoilValue } from 'recoil'

import IconWatchListRegular from '@src/assets/icons/IconWatchListRegular'
import { Tooltip } from '@src/components/Nudge'
import { Link } from '@src/stackflow'
import { LOCAL_STORAGE_KEYS } from '@src/utils/localStorage'

import { watchTooltipVisibilityAtom } from '../state/tooltip'

export const WatchActivityLink = () => {
  const { isTop } = useActivity()

  const tooltipVisibility = useRecoilValue(watchTooltipVisibilityAtom)

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const { styles } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [8, 12] } }],
  })

  return (
    <>
      <div ref={setReferenceElement} style={{ marginRight: '0.5rem' }}>
        <Link
          activityName="place_watch"
          activityParams={{}}
          style={{
            width: '1.5rem',
            height: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <IconWatchListRegular width="100%" height="100%" color={vars.$scale.color.gray900} />
        </Link>
      </div>
      {tooltipVisibility === 'visible' &&
        isTop &&
        createPortal(
          <Tooltip
            ref={setPopperElement}
            text="저장한 장소는 여기서 볼 수 있어요"
            arrowDirection="topRight"
            uniqueKey={LOCAL_STORAGE_KEYS.WATCH_PAGE_TOOLTIP_story_article_poi_collection}
            duration={3000}
            isShowCloseButton={false}
            style={styles.popper}
          />,
          document.body
        )}
    </>
  )
}

import { atom } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateKey = recoilKeyGenerator(['searchPoi', 'view'])

export type ViewType = 'list' | 'map'
export const viewTypeAtom = atom<ViewType>({
  key: generateKey('viewType'),
  default: 'list',
})

import { atom } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

import { deleteSuggestionPagePrefix } from './index'

const prefix = `${deleteSuggestionPagePrefix}/poi`
const generateKey = recoilKeyGenerator(prefix)

export const poiIdAtom = atom({
  key: generateKey('poiIdAtom'),
  default: '',
})

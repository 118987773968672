import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { ChangeEvent, ComponentType, ReactEventHandler, useCallback, useMemo, useRef } from 'react'

import { clickAfterDimm } from '@src/styles/others'

import { ImageUploadBox } from './ImageUploadBox'
import { ImageUploadPicture } from './useImageUpload'

interface Props {
  pictures: ImageUploadPicture[]
  maxCount?: number
  disabled?: boolean
  iconComponent: ComponentType<{ isMax: boolean }>
  onFileChange: (e: ChangeEvent<HTMLInputElement>) => void
  onClickDeletePictureItem: (id: string) => void
  onClickPictureItem?: (item: ImageUploadPicture, idx: number) => void
}

export const ImageScrollView: React.FC<Props> = ({
  pictures,
  disabled: propsDisabled,
  maxCount = 10,
  iconComponent: IconComp,
  onFileChange,
  onClickPictureItem,
  onClickDeletePictureItem,
}) => {
  const fileInputEl = useRef<HTMLInputElement>(null)
  const disabled = useMemo(() => pictures.length >= maxCount, [pictures, maxCount])

  const handleClickFileInput: ReactEventHandler = useCallback((e) => {
    e.preventDefault()
    fileInputEl.current?.click()
  }, [])

  return (
    <Base>
      <ImageSelectionWrapper>
        <FileInput
          disabled={disabled || propsDisabled}
          onChange={onFileChange}
          ref={fileInputEl}
          type="file"
          accept="image/*"
          multiple
          hidden
        />
        <ImageSelectButton onClick={handleClickFileInput} disabled={disabled}>
          <IconComp isMax={disabled} />
          <ImageCount disabled={disabled}>
            <ImageUploadedCount active={!!pictures.length && !disabled}>{pictures.length}</ImageUploadedCount>/
            {maxCount}
          </ImageCount>
        </ImageSelectButton>
        {pictures.map((picture, idx) => {
          const { id, url, loading } = picture
          return (
            <UploadImageWrapper key={id}>
              <ImageUploadBox
                id={id}
                url={url}
                loading={loading}
                onClickDelete={onClickDeletePictureItem}
                onClick={() => onClickPictureItem?.(picture, idx)}
              />
            </UploadImageWrapper>
          )
        })}
      </ImageSelectionWrapper>
    </Base>
  )
}

const Base = styled.div`
  overflow-x: scroll;
  transform: translateZ(0);
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
`
const ImageSelectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: nowrap;
  padding: 6px 16px 0;
`
const ImageSelectButton = styled.button`
  text-align: center;
  background-color: ${vars.$scale.color.gray00};
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${vars.$scale.color.gray400};
  height: 64px;
  width: 64px;
  min-width: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  transition: background-color 200ms;
  ${clickAfterDimm}

  :disabled {
    background-color: ${vars.$scale.color.gray100};
  }

  svg path {
    transition: fill 200ms;
  }
`
const ImageCount = styled.span<{ disabled: boolean }>`
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${({ disabled }) => (disabled ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};

  transition: color 200ms;
`
const ImageUploadedCount = styled.span<{ active: boolean }>`
  color: ${({ active }) => (active ? vars.$scale.color.gray600 : 'inherit')};
`
const UploadImageWrapper = styled.div`
  margin: 0 4px;
`
const FileInput = styled.input`
  display: none;
`

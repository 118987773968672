import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useRecoilValue } from 'recoil'

import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { generateNudgeKey, generateGlobalNudgeKey } from './generateNudgeKey'
import type { ArrowDirection, NudgeType } from './state'
import { useNudge } from './useNudge'

interface Props extends NudgeType {
  uniqueKey: string
}

function Nudge({
  uniqueKey,
  text,
  arrowDirection,
  isShowCloseButton = true,
  className,
  duration,
  top,
  bottom,
  left,
  right,
  ...props
}: Props) {
  const { hideNudge, isNudgeAlreadySeen } = useNudge()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const [showNudge, setShowNudge] = useState(false)

  useEffect(() => {
    if (!userInfo?.id) return
    ;(async () => {
      const seenNudge = await isNudgeAlreadySeen(
        generateNudgeKey(uniqueKey, userInfo.id) || generateGlobalNudgeKey(uniqueKey, userInfo.id)
      )

      if (seenNudge) {
        hideNudge(uniqueKey)
        setShowNudge(false)
      } else {
        setShowNudge(true)

        duration &&
          setTimeout(() => {
            hideNudge(uniqueKey)
          }, duration)
      }
    })()
  }, [duration, hideNudge, isNudgeAlreadySeen, uniqueKey, userInfo?.id])

  return showNudge
    ? createPortal(
        <Container
          className={className}
          arrowDirection={arrowDirection}
          duration={duration}
          style={{
            top,
            bottom,
            left,
            right,
          }}
          {...props}>
          {text}
          {isShowCloseButton && <IconCloseRegular onClick={() => hideNudge(uniqueKey)} className="icon" />}
        </Container>,
        document.body
      )
    : null
}

interface StyleProps {
  arrowDirection: ArrowDirection
  duration?: number
  zIndex?: number
}

export const Container = styled.div<StyleProps>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex ?? 98};

  padding: 0.5rem 0.75rem;
  width: fit-content;
  height: 2.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.375rem;
  background-color: ${vars.$scale.color.gray900};
  color: ${vars.$scale.color.gray00};

  ${vars.$semantic.typography.caption1Bold};

  .icon {
    width: 23px;
    height: 23px;
    color: ${vars.$scale.color.gray00};
    padding-left: 7px;
  }

  ::before {
    content: '';
    display: block;
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;

    ${({ arrowDirection }) => setArrowDirection(arrowDirection)}
  }

  animation: fadeout 0.3s;
  animation-delay: ${({ duration }) => duration! / 1000 - 0.3}s;
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

const setArrowDirection = (arrowDirection: ArrowDirection) => {
  switch (arrowDirection) {
    case 'topRight':
      return arrowTopRight
    case 'topLeft':
      return arrowTopLeft
    case 'bottomRight':
      return arrowBottomRight
    case 'bottomLeft':
      return arrowBottomLeft
  }
}

const arrowTopRight = css`
  top: -12px;
  right: 13px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid ${vars.$scale.color.gray900};
`

const arrowTopLeft = css`
  top: -12px;
  left: 13px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid ${vars.$scale.color.gray900};
`

const arrowBottomRight = css`
  bottom: -12px;
  right: 13px;
  border-top: 6px solid ${vars.$scale.color.gray900};
  border-bottom: 6px solid transparent;
`

const arrowBottomLeft = css`
  bottom: -12px;
  left: 13px;
  border-top: 6px solid ${vars.$scale.color.gray900};
  border-bottom: 6px solid transparent;
`

export default React.memo(Nudge)

import { useRecoilCallback } from 'recoil'

import { CurationMapItem } from '@src/apis/generated/poi/models'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { poiIdsAtomFamily, poiSelectorFamily } from '../state/pois'

const usePois = () => {
  const screenInstance = useScreenInstance()

  const getPoi = useRecoilCallback(
    ({ snapshot }) =>
      (id: string) => {
        const poi: CurationMapItem = snapshot.getLoadable(poiSelectorFamily(id)).contents

        return poi
      },
    []
  )

  function isNotNullOrUndefined<T>(input: null | undefined | T): input is T {
    return input != null
  }
  const getPois = useRecoilCallback(
    ({ snapshot }) =>
      (ids: string[]) => {
        const pois: CurationMapItem[] = ids.map((id) => snapshot.getLoadable(poiSelectorFamily(id)).contents)
        const nonNullablePois = pois.filter(isNotNullOrUndefined)

        return nonNullablePois
      },
    []
  )

  const setPoi = useRecoilCallback(
    ({ set }) =>
      (poi: CurationMapItem) => {
        set(poiSelectorFamily(poi.poi.id!), poi)
      },
    []
  )

  const setPois = useRecoilCallback(
    ({ set }) =>
      (pois: CurationMapItem[]) => {
        for (const poi of pois) {
          set(poiSelectorFamily(poi.poi.id!), poi)
        }

        set(
          poiIdsAtomFamily(screenInstance),
          pois.map((poi) => poi.poi.id!)
        )
      },
    [screenInstance]
  )

  return {
    getPoi,
    getPois,
    setPoi,
    setPois,
  }
}

export default usePois

import { useEffect } from 'react'
import { atom, atomFamily, selector, useRecoilState, useRecoilValue } from 'recoil'

import { POICategoriesApi } from '@src/apis/generated/poi/api'
import { SearchCategoriesV2200Response } from '@src/apis/generated/poi/models'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateSearchCategoryKey = recoilKeyGenerator('searchCategory')

export const searchTextAtom = atom({
  key: generateSearchCategoryKey('searchText'),
  default: '',
})

export const paginationAtomFamily = atomFamily({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'pagination', 'atomFamily']),
  default: {
    page: 0,
    perPage: 10,
  },
})
export const paginationSelector = selector<{
  page: number
  perPage: number
}>({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'pagination', 'selector']),
  get: ({ get }) => get(paginationAtomFamily(get(searchTextAtom))),
  set: ({ get, set }, newValue) => set(paginationAtomFamily(get(searchTextAtom)), newValue),
})

const DEFAULT_RESPONSE = {
  items: [] as NonNullable<SearchCategoriesV2200Response['items']>,
  hasNextPage: false,
  total: 0,
}

export const categoriesBySearchTextAtomFamily = atomFamily({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'atomFamily']),
  default: {
    ...DEFAULT_RESPONSE,
    page: 0,
  },
})
export const categoriesBySearchTextSelector = selector({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'atomFamily']),
  get: ({ get }) => get(categoriesBySearchTextAtomFamily(get(searchTextAtom))),
})

export const categoriesBySearchTextResponseSelector = selector({
  key: generateSearchCategoryKey(['categoriesBySearchText', 'response', 'selector']),
  get: async ({ get }) => {
    const searchText = get(searchTextAtom)
    const { page, perPage } = get(paginationSelector)

    if (!searchText || !page) {
      return DEFAULT_RESPONSE
    }

    const userInfo = get(bridgeInfoUserAtom)

    // REMINDER: why need current form type?
    const { data } = await POICategoriesApi.searchCategoriesV2(searchText, page, perPage, userInfo?.authToken)

    return {
      items: data.items ?? [],
      hasNextPage: data.hasNextPage ?? false,
      total: data.total ?? 0,
    }
  },
})

export const useSyncCategoriesBySearchText = () => {
  const searchText = useRecoilValue(searchTextAtom)
  const response = useRecoilValue(categoriesBySearchTextResponseSelector)
  const { page } = useRecoilValue(paginationSelector)
  const [categoriesBySearchText, setCategoriesBySearchText] = useRecoilState(
    categoriesBySearchTextAtomFamily(searchText)
  )

  useEffect(() => {
    if (page === categoriesBySearchText.page) return

    setCategoriesBySearchText((prev) => {
      return {
        ...prev,
        ...response,
        items: [...prev.items, ...response.items],
        page,
      }
    })
  }, [categoriesBySearchText, page, response, setCategoriesBySearchText])
}

import styled from '@emotion/styled'
import React, { MutableRefObject, useMemo } from 'react'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { slickOverrideStyle } from '@src/styles/slider'

export interface ImageSliderProps {
  height: string
  images: string[]
  onClickDetail?: (idx: number) => void
  sliderRef?: MutableRefObject<Slider | null>
}

const INITIAL_SLIDER_OPTION = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
}

const ImageSlider: React.FC<ImageSliderProps> = ({ height, images, onClickDetail, sliderRef }) => {
  const showBottomOverlay = useMemo(() => images.length < 2, [images])
  return (
    <Base height={height}>
      <Slider {...INITIAL_SLIDER_OPTION} ref={sliderRef}>
        {images.map((image, idx) => (
          <MediaImageWrapper key={idx} height={height} onClick={() => onClickDetail?.(idx)}>
            <MediaImage src={image} />
          </MediaImageWrapper>
        ))}
      </Slider>
      {showBottomOverlay && <BottomOverlay />}
    </Base>
  )
}

const Base = styled.div<Pick<ImageSliderProps, 'height'>>`
  height: ${({ height }) => height};
  position: relative;
  ${slickOverrideStyle}
`
const MediaImageWrapper = styled.div<Pick<ImageSliderProps, 'height'>>`
  height: ${({ height }) => height};
`
const MediaImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`
const BottomOverlay = styled.div`
  position: absolute;
  pointer-events: none;
  bottom: 0px;
  height: 56px;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 3.53%, rgba(0, 0, 0, 0.0001) 100%);
  background-blend-mode: multiply;
`

export default ImageSlider

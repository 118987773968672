import React, { memo, useEffect, useMemo, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { KakaoMarker, useKakaoMarkerImage } from '@src/components/Map/KakaoMap'
import { watchedByUserMapperSelector } from '@src/shared/watch/store'

import { useSelectedComment } from '../hooks/useSelectedComment'

interface Props {
  commentId: string
  poi: {
    id: string
    coordinates: {
      latitude: number
      longitude: number
    }
  }
  onClick: ({ commentId }: { commentId: string }) => void
}
export const Marker = memo(({ commentId, poi, onClick }: Props) => {
  const { selectedPoi } = useSelectedComment()
  const selectedPoiId = selectedPoi?.id

  const { markerImage } = useKakaoMarkerImage({ color: 'carrot' })

  const { contents: watchContentsGlobal, state: watchStateGlobal } = useRecoilValueLoadable(watchedByUserMapperSelector)
  const isWatchedGlobal = !!watchContentsGlobal && watchContentsGlobal[poi.id]
  const [isWatched, setIsWatched] = useState(isWatchedGlobal)

  const coordinates = poi.coordinates
  interface Marker {
    image: any
    zIndex: number
  }

  const marker = useMemo<Marker>(() => {
    const isSelected = selectedPoiId === poi.id

    if (isWatched)
      return isSelected ? { image: markerImage.selectedWatched, zIndex: 3 } : { image: markerImage.watched, zIndex: 2 }

    return isSelected ? { image: markerImage.selected, zIndex: 3 } : { image: markerImage.default, zIndex: 1 }
  }, [
    selectedPoiId,
    poi.id,
    isWatched,
    markerImage.selectedWatched,
    markerImage.watched,
    markerImage.selected,
    markerImage.default,
  ])

  useEffect(() => {
    if (watchStateGlobal !== 'hasValue' || isWatchedGlobal == isWatched) return
    setIsWatched(isWatchedGlobal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchedGlobal, watchStateGlobal])

  if (!poi || !coordinates) return null

  return (
    <KakaoMarker
      position={{
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      }}
      image={marker.image}
      onClick={() => onClick({ commentId })}
      zIndex={marker.zIndex}
    />
  )
})

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { Button } from '@src/components/Button'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'

const NoPois: React.FC = () => {
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const handleClickAddReview = async () => {
    logEvent('click_write_review_button', {
      area: 'poi_card',
      view_type: 'map',
    })

    push('poi_review', { action: 'write' })
  }

  return (
    <Container>
      <div className="message">
        필터에 해당하는 장소가 없어요.
        <br /> 새로운 장소를 이웃에게 추천해보세요.
      </div>
      <Button className="button" fullwidth textSize="16" styleTheme="secondary-outlined" onClick={handleClickAddReview}>
        장소 추천하기
      </Button>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .message {
    ${vars.$semantic.typography.bodyL1Regular}
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
    text-align: center;
    color: ${vars.$scale.color.gray600};
  }
`
export default NoPois

import React, { MouseEvent } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { Category } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'
import { withVisualViewport } from '@src/hocs/withVisualViewport'
import SearchPage from '@src/pages/SearchCategory/SearchCategory'
import { Screen } from '@src/stackflow'
import { selectedCategoryAtom } from '@src/widgets/CategoryInput/state'

import { useAnalytics } from './hooks'
import { searchTextAtom } from './state'

const BizProfileSearchCategoryPage = () => {
  const logEvent = useAnalytics()

  const searchText = useRecoilValue(searchTextAtom)
  const setSelectedCategory = useSetRecoilState(selectedCategoryAtom)

  const handleSelectCategory = (category: Category) => {
    setSelectedCategory(category)

    bridge.analytics.log({
      name: 'click_all_category_search_results',
      params: { from: 'biz_profile', keyword: searchText },
    })
    logEvent('click_select_category_button', {
      query: searchText,
      category_id: category.id,
    })
    bridge.stream.emit('select_category', { category })
    bridge.router.close()
  }

  const handleClickCloseButton = (e: MouseEvent) => {
    e.stopPropagation()

    bridge.router.close()
  }

  return (
    <>
      <Screen
        appBar={{
          title: '카테고리 검색',
          closeButton: {
            onClick: handleClickCloseButton,
          },
        }}>
        <SearchPage onClickSearchResult={handleSelectCategory} />
      </Screen>
    </>
  )
}

export default withVisualViewport(BizProfileSearchCategoryPage)

import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { Button as ButtonComponent } from '@src/components/Button'
import { safeAreaPadding } from '@src/styles/safeArea'
import { PropOf } from '@src/types'

import { PoiCard as PoiCardComponent } from '../List/PoiCard'

type Props = Omit<PropOf<typeof PoiCard>, 'onClick'> & {
  onSelectPoi: (poi: Poi) => void
}
export const Card = ({ poi, onSelectPoi }: Props) => {
  const handleClickSelect = () => {
    console.log('handleClickSelect')
    onSelectPoi(poi)
  }
  return (
    <Wrapper>
      <PoiCard
        useActiveEffect={false}
        poi={poi}
        appendRight={() => (
          <Button styleTheme="secondary" onClick={handleClickSelect}>
            선택
          </Button>
        )}
      />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: ${vars.$semantic.color.paperDefault};
  ${safeAreaPadding('bottom')};
`
const PoiCard = styled(PoiCardComponent)`
  padding: 1.25rem 1rem;
`
const Button = styled(ButtonComponent)`
  padding: 0.53125rem 0.875rem;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Bold};
`

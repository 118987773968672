import { captureException } from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import React, { useCallback, useMemo } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { POIReviewsApi } from '@src/apis/generated/poi/api'
import { ActionSheet } from '@src/components/ActionSheet'
import { useToast } from '@src/components/Toast'
import { APP_SCHEME } from '@src/constants/environmentConstants'
import { useRefetchReviewsForAllSort } from '@src/shared/review/hooks'
import { myPoiReviewSelectorFamily } from '@src/shared/review/store/poiReview'
import { useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { useAnalytics } from '../hooks'
import { interestedSingleReviewAtom } from '../state'

type ActionButtonsType = React.ComponentProps<typeof ActionSheet>['actionButtons']

export const SingleReviewActionSheet = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { replace, pop } = useNavigator()
  const logEvent = useAnalytics()
  const { showToast } = useToast()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const userReviewId = myReview?.id

  const refreshReviews = useRefetchReviewsForAllSort(poiId, undefined)
  const refreshMyReview = useRecoilRefresher_UNSTABLE(myPoiReviewSelectorFamily({ poiId }))

  const { reviewId, reviewUserId } = useRecoilValue(interestedSingleReviewAtom)

  const handleClose = useCallback(() => {
    pop()
  }, [pop])

  const handleRemoveReviewItem = useCallback(async () => {
    logEvent('click_remove_review_button', {
      area: 'card_more_options',
      review_id: reviewId,
    })

    try {
      await POIReviewsApi.removeReviewV2(reviewId, userInfo!.authToken)
      showToast({
        text: '후기가 삭제 되었어요.',
        duration: 'short',
      })

      refreshReviews()
      refreshMyReview()
    } catch (err) {
      captureException(err)
      showToast({
        text: '후기 삭제에 실패했어요. 잠시 후 다시 시도해주세요.',
        duration: 'short',
      })
    } finally {
      handleClose()
    }
  }, [handleClose, logEvent, refreshMyReview, refreshReviews, reviewId, showToast, userInfo])

  const handleReport = useCallback(async () => {
    logEvent('click_report_review_button', {
      area: 'card_more_options',
      review_id: reviewId,
    })

    handleClose()

    /** 인코딩을 하는 이유 - https://daangn.slack.com/archives/CUFCHRMTR/p1659421616081099?thread_ts=1655964153.587849&cid=CUFCHRMTR */
    const encodedQuery = encodeURIComponent(`?kind=poi_review&post_id=${reviewId}`)
    window.location.href = `${APP_SCHEME}local_web?path=report_items${encodedQuery}`
  }, [logEvent, reviewId, handleClose])

  const handleClickUpdate = useCallback(async () => {
    logEvent('click_edit_review_button', {
      area: 'card_more_options',
      review_id: userReviewId,
    })

    replace('poi_review', { action: 'update', poiId, reviewId: userReviewId })
  }, [logEvent, poiId, replace, userReviewId])

  const myReviewActions: ActionButtonsType = useMemo(
    () => [
      {
        label: '수정하기',
        onClick: handleClickUpdate,
      },
      {
        label: '삭제하기',
        type: 'destructive',
        onClick: handleRemoveReviewItem,
      },
    ],
    [handleClickUpdate, handleRemoveReviewItem]
  )

  const otherUserReviewActions: ActionButtonsType = useMemo(
    () => [
      {
        label: '신고하기',
        type: 'destructive',
        onClick: handleReport,
      },
    ],
    [handleReport]
  )

  return (
    <ActionSheet
      onOutsideClick={handleClose}
      dismissButton={{
        label: '닫기',
        onClick: handleClose,
      }}
      actionButtons={reviewUserId === Number(userInfo?.id) ? myReviewActions : otherUserReviewActions}
    />
  )
}

import { getThemeV2, ThemeResponseWrapper } from '@daangn/local-business-network/lib/businessAccount'
import { createLoadableFamily } from '@daangn/recoil-loadable'
import axios from 'axios'

import { IS_PROD } from '@src/constants/environmentConstants'

import { pageKeyPrefix } from './key'
import { ThemeMeta } from './type'

export const themeMetaLoadableFamily = createLoadableFamily<ThemeMeta, string, { data: ThemeMeta }>({
  getStore({ atomFamily }) {
    return atomFamily({
      key: pageKeyPrefix('themeMeta'),
    })
  },
  async fetch({ variables }) {
    const baseUrl = `https://business-feed${IS_PROD ? '' : '.alpha'}.kr.karrotmarket.com`
    return await axios
      .get(`${baseUrl}/api/v1/feeds/contents-curator/purposeful/${variables}/meta`)
      .then((res) => res.data)
  },
  onSuccess({ response }) {
    return response.data
  },
})

export const categoriesByThemeIdLoadableFamily = createLoadableFamily<
  { [categoryId: string]: boolean } | undefined,
  number,
  ThemeResponseWrapper | undefined
>({
  getStore({ atomFamily }) {
    return atomFamily({
      key: pageKeyPrefix('categoriesByThemeId'),
    })
  },
  async fetch({ variables: themeId }) {
    if (!themeId) return undefined
    return await getThemeV2({ params: { themeId } }).then((res) => res.data.data)
  },
  onSuccess({ response }) {
    return response?.theme?.allChildrenCategoryIds?.reduce(
      (mapper, categoryId) => ({ ...mapper, [categoryId]: true }),
      {}
    )
  },
})

import styled from '@emotion/styled'
import { FormikErrors, FormikTouched, useFormik } from 'formik'
import React from 'react'

import { PropOf } from '@src/types'
import { OperatingTimeInput } from '@src/widgets/form/OperatingInformation'

import { AddressInput } from './Address'
import { CategoryInput } from './Category'
import { ImageInput } from './ImageInput'
import { TextInput } from './TextInput/TextInput'
import type { FormValues, OperatingInformation } from './types'
import { WinterSnackMenuInput } from './WinterSnackMenu'

type TextInputField = PropOf<typeof TextInput>['field']
type AddressInputField = PropOf<typeof AddressInput>['field']
type CategoryInputField = PropOf<typeof CategoryInput>['field']
type OperatingTimeInputField = PropOf<typeof OperatingTimeInput>['field']
type ImageInputField = PropOf<typeof ImageInput>['field']
type WinterSnackMenuInputField = PropOf<typeof WinterSnackMenuInput>['field']
interface Props {
  fields: (
    | TextInputField
    | AddressInputField
    | CategoryInputField
    | OperatingTimeInputField
    | ImageInputField
    | WinterSnackMenuInputField
  )[]
  values: FormValues
  errors: FormikErrors<FormValues>
  touched: FormikTouched<FormValues>
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  setFieldTouched: ReturnType<typeof useFormik>['setFieldTouched']
  validateField: ReturnType<typeof useFormik>['validateField']
  validateForm: (values?: FormValues | undefined) => Promise<FormikErrors<FormValues>>
  handleChange: ReturnType<typeof useFormik>['handleChange']
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void
  onClickAddressSearch?: () => void
  onClickPinAddress?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Form = ({
  fields,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  validateForm,
  handleChange,
  handleSubmit,
  onClickAddressSearch,
  onClickPinAddress,
}: Props) => {
  return (
    <StyledForm onSubmit={handleSubmit}>
      {fields.map((field) => {
        const { id, placeholder, isRequired = false } = field
        switch (id) {
          case 'name':
          case 'phone':
          case 'url':
          case 'createSuggestionDescription': {
            return (
              <TextInput
                key={id}
                field={field}
                value={values[id] ?? ''}
                errorMessage={touched[id] ? errors[id] : ''}
                handleChange={handleChange}
                setFieldTouched={setFieldTouched}
              />
            )
          }
          case 'address': {
            return (
              <AddressInput
                key={id}
                field={field}
                addressExtra={values.address?.addressExtra ?? ''}
                onClickAddressSearch={onClickAddressSearch}
                onClickPinAddress={onClickPinAddress}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errorMessage={touched[id] && errors[id]?.roadAddress}
              />
            )
          }
          case 'categoryId':
            return <CategoryInput key={id} field={field} setFieldValue={setFieldValue} />
          case 'operatingInformation': {
            return (
              <OperatingTimeInput
                key={id}
                field={{ id, placeholder, isRequired }}
                operatingInformation={values.operatingInformation ?? ({} as OperatingInformation)}
                setFieldValue={setFieldValue}
                errorMessage={errors[id]}
              />
            )
          }
          case 'pricing':
          case 'photos': {
            return (
              <ImageInput
                key={id}
                field={field}
                images={values[id]?.map((imageUrl: string) => ({
                  id: imageUrl,
                  url: imageUrl,
                }))}
                setFieldValue={setFieldValue}
              />
            )
          }
          case 'winterSnackMenu': {
            return (
              <WinterSnackMenuInput
                field={field}
                value={values[id]}
                errorMessage={touched[id] ? errors[id] : ''}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                validateForm={validateForm}
              />
            )
          }
        }
      })}
    </StyledForm>
  )
}

const StyledForm = styled.form`
  padding: 0 1rem;
`

import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import CreateSuggestionForCommunityTagPage from './CreateSuggestionForCommunityTagPage'
import CreateSuggestionForSearch from './CreateSuggestionForSearch'
import CreateSuggestionForWinterSnackPage from './CreateSuggestionForWinterSnack'
import CreateSuggestionForWriteReviewPage from './CreateSuggestionForWriteReview'

export type DialogType = 'winterSnackCompleteSubmit'
type ActivityParams = {
  variation?: 'writeReview' | 'winterSnack'
  from?: 'search'
  alert?: DialogType
  name?: string
}
const CreateSuggestionPage: ActivityComponentType<ActivityParams> = ({ params: { variation, from } }) => {
  if (from === 'search') {
    return <CreateSuggestionForSearch />
  }

  switch (variation) {
    case 'winterSnack':
      return <CreateSuggestionForWinterSnackPage />
    case 'writeReview':
      return <CreateSuggestionForWriteReviewPage />
    default:
      return <CreateSuggestionForCommunityTagPage />
  }
}

export default CreateSuggestionPage

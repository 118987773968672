import styled from '@emotion/styled'
import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import { withKakaoMap } from '@src/components/Map/KakaoMap'

import { FailedVisitBottomSheet } from './Modal/FailedVisitBottomSheet'
import OptionsBottomSheet from './Modal/OptionsBottomSheet'
import { ModalType } from './type'

export type WinterSnackModalParams = {
  modalId: ModalType
  selectedPoiId: string
  filterId?: string
}

const WinterSnackModal: ActivityComponentType<WinterSnackModalParams> = ({ params: { modalId } }) => {
  return (
    <>
      {modalId === 'poiOptionsBottomSheet' && <OptionsBottomSheet />}
      {modalId === 'failedVisitBottomSheet' && <FailedVisitBottomSheet />}
    </>
  )
}

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export default withKakaoMap(WinterSnackModal)

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { POICategorySuggestionsApi } from '@src/apis/generated/poi/api'
import { Category } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import CategoryListItem from './CategoryListItem'
import { useAnalytics } from './hooks'
import InsufficientResult from './InsufficientResult'
import { categoriesBySearchTextSelector, searchTextAtom } from './state'

interface Props {
  onClickSearchResult: (category: Category) => void
}

function SearchResults({ onClickSearchResult }: Props) {
  const logEvent = useAnalytics()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const searchText = useRecoilValue(searchTextAtom)
  const categoriesBySearchText = useRecoilValue(categoriesBySearchTextSelector)
  const isNotSearched = categoriesBySearchText.page === 0
  const isNoSearchResult = categoriesBySearchText.items.length === 0

  const handleAddCategorySuggestion = async (searchText: string) => {
    const resp = await POICategorySuggestionsApi.createCategorySuggestionV2({ text: searchText }, userInfo?.authToken)

    bridge.analytics.log({
      name: 'click_all_category_search_results',
      params: { from: 'biz_profile', keyword: searchText },
    })
    logEvent('click_suggest_new_category_button', {
      query: searchText,
    })

    bridge.stream.emit('select_category', {
      category: {
        id: 1040,
        levels: ['기타'],
        name: '기타',
      },
    })

    bridge.stream.emit('suggest_category', { suggestionInfo: resp.data })
  }

  if (isNotSearched) {
    return <StyledDefaultPage>주요 서비스, 메뉴, 판매 물건, 특징 등을 의미하는 단어를 검색하세요.</StyledDefaultPage>
  }

  if (isNoSearchResult) {
    return (
      <>
        <StyledNoSearchResult>검색 결과가 없어요. 단어의 철자가 정확한지 확인해 보세요.</StyledNoSearchResult>
        <InsufficientResult onAddCategory={handleAddCategorySuggestion.bind(null, searchText)} />
      </>
    )
  }

  return (
    <>
      <div className="results">
        {categoriesBySearchText.items.map((category) => (
          <CategoryListItem key={category.id} {...category} onClick={onClickSearchResult} />
        ))}
      </div>
      {categoriesBySearchText.items.length > 0 && (
        <InsufficientResult onAddCategory={handleAddCategorySuggestion.bind(null, searchText)} />
      )}
    </>
  )
}

const StyledDefaultPage = styled.div`
  ${vars.$semantic.typography.bodyL2Regular}
  margin: 1rem 1rem 0;
  color: ${vars.$scale.color.gray700};
`

const StyledNoSearchResult = styled.div`
  ${vars.$semantic.typography.subtitle2Regular}
  margin: 1rem 0 0;
  padding: 0 1rem 0.75rem;
  border-bottom: solid 1px ${vars.$semantic.color.divider1};
  color: ${vars.$scale.color.gray700};
`

export default SearchResults

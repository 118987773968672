import { ActivityComponentType } from '@stackflow/react'
import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { POIDefaultApi } from '@src/apis/generated/poi/api'
import { errorMessage } from '@src/components/Error/constants'
import { useToast } from '@src/components/Toast'
import { Screen, useNavigator, useStepNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { checkIsWithBatchim } from '@src/utils/checkIsWithBatchim'

import MapReportEtcForm from './MapReportEtcForm'
import ReportItemList, { ReportItem } from './ReportItemList'
import { curationCategoryByIdSelector } from './state/curationCategory'
import { poiByPoiIdSelector, poiIdAtom } from './state/pois'

type ActivityParams = {
  poiId: string
  curationCategoryId: string
  option?: 'custom'
}
const MapReportPage: ActivityComponentType<ActivityParams> = ({ params: { poiId, curationCategoryId, option } }) => {
  const { pop } = useNavigator()
  const { stepPush, stepPop } = useStepNavigator('daangn_map_report')

  const { showToast } = useToast()
  const setPoiId = useSetRecoilState(poiIdAtom)
  const poi = useRecoilValue(poiByPoiIdSelector)
  const curationCategory = useRecoilValue(curationCategoryByIdSelector(curationCategoryId ? +curationCategoryId : null))
  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const [reportItems, setReportItems] = useState<ReportItem[]>([])

  const handleReport = useCallback(
    async (params: { reportItem: ReportItem; userComment?: string }) => {
      if (!poi?.id || !curationCategory?.id) return

      const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

        await POIDefaultApi.createReportV2(userInfo.authToken, {
          policyKey: 'curation-map',
          resourceId: poi.id,
          subResourceId: curationCategory.id.toString(),
          itemKey: params.reportItem.key,
          userComment: params.userComment,
        })

        showToast({
          text: '따뜻한 당근마켓을 위한 신고 감사드려요.',
          duration: 'long',
        })
        pop()
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        showToast({ text: '신고에 실패했어요. 잠시 후 다시 시도해주세요.', duration: 'long' })
      }
    },
    [curationCategory?.id, poi?.id, pop, showToast, userInfo?.authToken]
  )

  const handleReportItemClick = useCallback(
    async (reportItem: ReportItem) => {
      if (!poi?.id || !curationCategory?.id) {
        return
      }

      switch (reportItem.key) {
        case 'not-recommendation':
        case 'irrelevant-curation-category':
          await handleReport({ reportItem })
          break
        case 'custom':
          stepPush({ poiId, curationCategoryId, option: 'custom' })
          break
      }
    },
    [curationCategory?.id, curationCategoryId, handleReport, poi?.id, poiId, stepPush]
  )

  const handleCustomSubmit = useCallback(
    (userComment: string) => {
      const customItem = reportItems.find((item) => item.key === 'custom')
      if (!customItem) return

      return handleReport({
        reportItem: customItem,
        userComment,
      })
    },
    [handleReport, reportItems]
  )

  const handleClickBackButton = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()

      if (option === 'custom') {
        stepPop()
      } else {
        pop()
      }
    },
    [option, pop, stepPop]
  )

  useEffect(() => {
    if (!poi?.name || !curationCategory?.name) return

    const 와or과 = checkIsWithBatchim(curationCategory.name) ? '과' : '와'
    setReportItems([
      {
        key: 'not-recommendation',
        text: `${poi.name}에 관한 추천글이 아니에요.`,
      },
      {
        key: 'irrelevant-curation-category',
        text: `${curationCategory.name}${와or과} 관련된 장소가 아니에요.`,
      },
      {
        key: 'custom',
        text: `신고 항목 직접 입력`,
      },
    ])
  }, [curationCategory?.name, poi?.name, setReportItems])

  useEffect(() => {
    setPoiId(poiId ?? null)
  }, [poiId, setPoiId])

  return (
    <Screen
      appBar={{
        title: '당근지도 장소 신고',
        backButton: {
          onClick: handleClickBackButton,
        },
      }}>
      {option === 'custom' ? (
        <MapReportEtcForm onSubmit={handleCustomSubmit} />
      ) : (
        <ReportItemList
          items={reportItems}
          title="신고하는 이유를 선택해주세요."
          onReportItemClick={(_, item) => handleReportItemClick(item)}
          showArrowKeys={['custom']}
        />
      )}
    </Screen>
  )
}

export default MapReportPage

import { useEffect } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useResetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import {
  subprofileUserIdAtomFamily,
  userPostsAtom,
  userPostsMetaAtom,
  userPostsQuerySelectorFamily,
  userPostsSortByAtom,
} from '../state'

export const useCleanupUserPostOnUnmount = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))
  const sortBy = useRecoilValue(userPostsSortByAtom(userId))
  const resetUserPosts = useResetRecoilState(userPostsAtom({ userId, sortBy }))
  const resetUserPostsMeta = useResetRecoilState(userPostsMetaAtom({ userId, sortBy }))
  const refreshUserPostQuery = useRecoilRefresher_UNSTABLE(userPostsQuerySelectorFamily(userId))

  useEffect(() => {
    return () => {
      resetUserPosts()
      resetUserPostsMeta()
      refreshUserPostQuery()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])
}

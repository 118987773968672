import React, { useEffect, useRef } from 'react'
import { useRecoilState } from 'recoil'

import { kakaLocalApi } from '@src/apis'
import { addressPickerAtom } from '@src/components/Input/AddressInput/store/addressPicker'
import { KakaoMap, KakaoMarker, useKakaoMap, useKakaoMarkerImage, withKakaoMap } from '@src/components/Map/KakaoMap'
import { useNavigator } from '@src/stackflow'

export const Map = withKakaoMap(
  () => {
    const { push } = useNavigator()
    const { markerImage } = useKakaoMarkerImage({ color: 'carrot', size: { width: 36, height: 36 } })
    const { setCenter, relayout } = useKakaoMap()

    const [searchAddress, setSearchAddress] = useRecoilState(addressPickerAtom)
    const address = searchAddress?.roadAddress || searchAddress?.jibunAddress
    const coordinates = searchAddress?.coordinates

    const isMapRendered = useRef<boolean>(!!coordinates)

    const handleClick = () => {
      push('pin_address', {})
    }

    useEffect(() => {
      if (address) {
        kakaLocalApi.getQuerySearchAddress({ query: address }).then((res) => {
          if (!res[0]?.x || !res[0]?.y) return

          const { x: longitude, y: latitude } = res[0]

          setSearchAddress((prev) => ({ ...prev, coordinates: { latitude: +latitude, longitude: +longitude } }))
        })
      }
    }, [address, setSearchAddress])

    useEffect(() => {
      if (coordinates) {
        const setCenterArgs: Parameters<typeof setCenter> = [
          { latitude: coordinates.latitude, longitude: coordinates.longitude },
          { offset: { x: 0, y: -15 } },
        ]

        if (!isMapRendered.current) {
          setTimeout(() => {
            relayout()
            setCenter(...setCenterArgs)

            isMapRendered.current = true
          }, 100)
        } else {
          setCenter(...setCenterArgs)
        }
      }
    }, [coordinates, relayout, setCenter])

    return coordinates ? (
      <KakaoMap
        center={coordinates}
        draggable={false}
        zoomable={false}
        onClick={handleClick}
        style={{
          width: '100%',
          height: '7.5rem',
          border: '1px solid rgba(0, 0, 0, 0.07)',
          borderRadius: '0.375rem',
          marginBottom: '0.75rem',
        }}>
        <KakaoMarker position={coordinates} image={markerImage.selected} />
      </KakaoMap>
    ) : null
  },
  () => null
)

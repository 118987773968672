import styled from '@emotion/styled'
import IconCheckRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCheckRegular'
import { vars } from '@seed-design/design-token'
import { BottomSheet } from '@stackflow/plugin-basic-ui'
import { ActivityComponentType } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { useNavigator, useStepNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { DROPDOWN_FILTER_NAME_TO_TYPE_FILTER, SORT_OPTION_ID_TO_DETAIL_TYPE } from './constants/eventLogParamMapper'
import { useNewAnalytics, withNewAnalytics } from './hook/Analytics'
import { FilterName, filterOptionsSelectorFamily } from './store'

export interface Option {
  id: string
  name: string
}

interface ActivityParams {
  themeId: string
  filterName: FilterName
  categoryFilterId?: string
  sortBy?: string
}

const OptionsDrawer: ActivityComponentType<ActivityParams> = ({ params: { filterName, ...restActivityParams } }) => {
  const { themeId, categoryFilterId, sortBy } = restActivityParams
  const { pop } = useNavigator()
  const log = useNewAnalytics()
  const { stepReplace } = useStepNavigator('purposeful_category')

  const filterOptions = useRecoilValue(filterOptionsSelectorFamily({ themeId, filterName }))
  const selectedFilterOptionId = filterName === 'category' ? categoryFilterId : sortBy

  const handleClick = (id: string) => {
    log({
      name: 'click_onedepth_filter',
      type: 'click',
      params: {
        contents_type: 'filter',
        filter_type: DROPDOWN_FILTER_NAME_TO_TYPE_FILTER[filterName],
        detail_type: SORT_OPTION_ID_TO_DETAIL_TYPE[id] ?? id,
      },
    })

    pop()

    setTimeout(() => {
      stepReplace({
        ...restActivityParams,
        [filterName === 'category' ? 'categoryFilterId' : 'sortBy']: id,
      })
    }, 0)
  }

  return (
    <BottomSheet>
      <Base>
        {filterOptions.map(({ id, name }) => (
          <Option key={id} isSelected={selectedFilterOptionId === id} onClick={handleClick.bind(null, id)}>
            {name} {selectedFilterOptionId === id && <IconCheckRegular width={18} height={18} />}
          </Option>
        ))}
      </Base>
    </BottomSheet>
  )
}

const Base = styled.div`
  padding: 0.625rem 0;
  ${safeAreaPadding('bottom', '0.625rem')};
`

const Option = styled.div<{ isSelected: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  color: ${({ isSelected }) => (isSelected ? vars.$semantic.color.primary : vars.$scale.color.gray900)};
  ${({ isSelected }) => (isSelected ? vars.$semantic.typography.bodyM1Bold : vars.$semantic.typography.bodyM1Regular)};
`

export default withNewAnalytics(OptionsDrawer, 'purposeful_category_filter_picker')

import styled from '@emotion/styled'
import { ActivityComponentType } from '@stackflow/react'
import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import { useRegisterScreenCommonPayloadEffect } from '@src/components/Analytics'
import { withKakaoMap } from '@src/components/Map/KakaoMap'
import { useCurrentPosition } from '@src/hooks'
import { Screen } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'

import Contents from './Contents'
import RightBottomButtons from './FloatingLayer/RightBottomButtons'
import { ViewTypeToggleButton } from './FloatingLayer/ViewTypeToggleButton'
import { useSelectedWatchedPlacesFilter, useSelectedWatchedPlaceId, useResetWatchedByUser } from './hooks'
import { selectedViewTypeAtomFamily, ViewType } from './state'

type ActivityParams = {
  view_type?: ViewType
}
const WatchedPlacesPage: ActivityComponentType<ActivityParams> = ({ params: { view_type = 'LIST' } }) => {
  const { update: updateCurrentUserPosition } = useCurrentPosition()
  const { setSelectedWatchedPlaceId } = useSelectedWatchedPlaceId()
  const { handleClearWatchedPlacesFilter } = useSelectedWatchedPlacesFilter()
  const resetWatchedByUser = useResetWatchedByUser()
  const screenInstance = useScreenInstance()

  const setSelectedViewType = useSetRecoilState(selectedViewTypeAtomFamily(screenInstance))

  useEffect(() => {
    setSelectedViewType(view_type)
  }, [setSelectedViewType, view_type])

  useEffect(() => {
    resetWatchedByUser()
    updateCurrentUserPosition()

    return () => {
      handleClearWatchedPlacesFilter()
      setSelectedWatchedPlaceId(null)
      setSelectedViewType('LIST')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useRegisterScreenCommonPayloadEffect({ view_type }, [view_type])

  return (
    <Screen
      appBar={{
        title: '관심 가게',
        border: false,
      }}>
      <FlexContainer>
        <Contents />
      </FlexContainer>

      <RightBottomButtons />
      <ViewTypeToggleButton />
    </Screen>
  )
}
export const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`

export default withKakaoMap(WatchedPlacesPage)

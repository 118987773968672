import { ActivityComponentType, useActions, useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { useActivity } from '@src/stackflow'

import { ActivitiesType } from '../activities'

export const useNavigator = () => {
  const action = useActions<ActivitiesType>()
  const activity = useActivity()
  const { referrer } = useActivityParams<{ referrer?: string }>()

  type PushOptions = Parameters<(typeof action)['push']>['2']
  const push = useCallback(
    <K extends Extract<keyof ActivitiesType, string>>(
      activityName: K,
      params: ActivitiesType[K] extends
        | ActivityComponentType<infer U>
        | {
            component: ActivityComponentType<infer U>
          }
        ? U
        : Record<string, unknown>,
      options?: PushOptions
    ) => {
      const modifiedParam = {
        entry: activity.name,
        referrer: referrer ?? null,
        ...params,
      }

      return action.push(activityName, modifiedParam, options)
    },
    [action, activity.name, referrer]
  )

  type PopOptions = Parameters<(typeof action)['pop']>['1']
  const pop = useCallback(
    (depth?: number, options?: PopOptions) => {
      return action.pop(depth ?? 1, options)
    },
    [action]
  )

  type ReplaceOptions = Parameters<(typeof action)['replace']>['2']
  const replace = useCallback(
    <K extends Extract<keyof ActivitiesType, string>>(
      activityName: K,
      params: ActivitiesType[K] extends
        | ActivityComponentType<infer U>
        | {
            component: ActivityComponentType<infer U>
          }
        ? U
        : Record<string, unknown>,
      options?: ReplaceOptions
    ) => {
      const modifiedParams = {
        entry: activity.name,
        referrer: referrer ?? null,
        ...params,
      }

      return action.replace(activityName, modifiedParams, options)
    },
    [action, activity.name, referrer]
  )

  return {
    pop,
    push,
    replace,
  }
}

import { getReviewsByUserIdV2 } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateKey = recoilKeyGenerator('userReview')

type UserReviewRequestParameters = Parameters<typeof getReviewsByUserIdV2>['0']['params']

export const userReviewSelectorFamily = selectorFamily({
  key: generateKey('userReviewSelectorFamily'),
  get: (params: UserReviewRequestParameters) => async () => {
    try {
      const { data } = await getReviewsByUserIdV2({ params })

      return data
    } catch (error) {
      captureException(error)

      throw error
    }
  },
})

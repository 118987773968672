import React from 'react'

import { AlertDialog } from '@src/components/AlertDialog'

interface Props {
  bizProfileName: string
  onConfirm: () => void
}
export const BlockedBizReviewAlert: React.FC<Props> = ({ bizProfileName, onConfirm }) => {
  return (
    <AlertDialog
      contents={`작성한 후기가 신고되어 ${bizProfileName}에는 새로운 후기를 작성할 수 없어요.`}
      CTAButton={{
        label: '확인',
        onClick: onConfirm,
      }}
    />
  )
}

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { range } from 'lodash'
import React from 'react'

import { OpeningHourPropsWeekOfDay } from '@src/apis/generated/poi/models'

const WEEK_IDS = range(0, 7)
export const DEFAULT_SELECTED_WEEKDAYS = WEEK_IDS.reduce(
  (selectedWeekdays, id) => ({
    ...selectedWeekdays,
    [id]: false,
  }),
  {} as SelectedWeekdays
)

export interface SelectedWeekdays {
  [weekdayId: string]: boolean
}

interface Props {
  selectedWeekdays: number[]
  onToggleWeekdays: (weekdayId: number) => void
}

export const WEEK_ID_TEXT_MAPPER = ['월', '화', '수', '목', '금', '토', '일']
export const WEEK_ID_VALUE_MAPPER = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as OpeningHourPropsWeekOfDay[]
export const WEEK = WEEK_IDS.map((id) => ({
  id,
  text: WEEK_ID_TEXT_MAPPER[id],
  value: WEEK_ID_VALUE_MAPPER[id],
}))

const BusinessWeekdaysInput: React.FC<Props> = ({ selectedWeekdays, onToggleWeekdays }) => {
  return (
    <Weekdays id="weekdayOpen">
      {WEEK.map(({ id, text }) => {
        const isSelected = selectedWeekdays.includes(id)
        return (
          <Weekday
            key={id}
            isSelected={isSelected}
            onClick={() => {
              onToggleWeekdays(id)
            }}>
            {text}
          </Weekday>
        )
      })}
    </Weekdays>
  )
}

const Weekdays = styled.ul`
  display: flex;
`

const SIDE_PADDING = 16
const WEEKDAY_MARGIN = 8
const WEEKDAY_SIZE = `calc(${100 / 7}vw - ${(SIDE_PADDING * 2 + WEEKDAY_MARGIN * 6) / 7}px)`

const Weekday = styled.li<{ isSelected: boolean }>`
  margin: 0 0.5rem 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${WEEKDAY_SIZE};
  height: ${WEEKDAY_SIZE};
  border-radius: 50%;
  border: ${({ isSelected }) => (isSelected ? 'none' : `1px solid ${vars.$scale.color.gray300}`)};
  background-color: ${({ isSelected }) => (isSelected ? vars.$semantic.color.grayActive : vars.$scale.color.gray00)};
  ${({ isSelected }) => (isSelected ? vars.$semantic.typography.bodyM2Bold : vars.$semantic.typography.bodyM2Regular)};
  color: ${({ isSelected }) => (isSelected ? vars.$static.color.staticWhite : vars.$scale.color.gray900)};
  &:last-of-type {
    margin: 0;
  }
`

export default BusinessWeekdaysInput

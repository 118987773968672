import { vars } from '@seed-design/design-token'
import React from 'react'

interface Props {
  size?: number
  checked: boolean
  uncheckedFill?: string
  checkedFill?: string
}

const Checkbox: React.FC<Props> = ({
  size = 18,
  checked,
  checkedFill = vars.$scale.color.carrot500,
  uncheckedFill = vars.$scale.color.gray500,
}) => {
  return checked ? (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.4687 6.37457C13.6756 6.11572 13.6334 5.73816 13.3746 5.53128C13.1157 5.3244 12.7382 5.36654 12.5313 5.6254L7.72681 11.637L5.39354 9.60958C5.14341 9.39223 4.76444 9.41881 4.5471 9.66894C4.32975 9.91908 4.35633 10.298 4.60646 10.5154L7.41166 12.9529C7.53501 13.0601 7.69673 13.1123 7.85947 13.0975C8.02221 13.0827 8.17188 13.0022 8.2739 12.8746L13.4687 6.37457Z"
        fill={checkedFill}
      />
    </svg>
  ) : (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none">
      <path
        d="M13.4687 6.37459C13.6756 6.11573 13.6334 5.73818 13.3746 5.5313C13.1157 5.32442 12.7382 5.36655 12.5313 5.62541L7.72681 11.637L5.39354 9.60959C5.14341 9.39225 4.76444 9.41883 4.5471 9.66896C4.32975 9.91909 4.35633 10.2981 4.60646 10.5154L7.41166 12.9529C7.53501 13.0601 7.69673 13.1123 7.85947 13.0975C8.02221 13.0828 8.17188 13.0022 8.2739 12.8746L13.4687 6.37459Z"
        fill={uncheckedFill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM16.8 9C16.8 13.3078 13.3078 16.8 9 16.8C4.69218 16.8 1.2 13.3078 1.2 9C1.2 4.69218 4.69218 1.2 9 1.2C13.3078 1.2 16.8 4.69218 16.8 9Z"
        fill={uncheckedFill}
      />
    </svg>
  )
}

export default React.memo(Checkbox)

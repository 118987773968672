import { useEffect } from 'react'

import { useToast } from '@src/components/Toast'
import { useNavigator } from '@src/stackflow'

const RedirectToAddressSearch = () => {
  const { showToast } = useToast()
  const { replace } = useNavigator()
  useEffect(() => {
    showToast({
      text: '지도 오류로 지도 위에서 장소 지정이 어려워요. 주소 검색을 이용해주세요.',
      duration: 'long',
    })

    replace('search_address', {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return null
}

export default RedirectToAddressSearch

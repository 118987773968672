import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { BIZ_PROFILE_APP_BASE_URL } from '@src/constants/apiUrls'

import CouponCardBase, { CouponCardBaseProps } from './CouponCardBase'
import { couponBoxSelectorFamily } from '../store/couponBox'
import { BizAccountType } from '../types'
import { getCouponStatus } from '../utils/coupon-util'

interface Props extends Pick<CouponCardBaseProps, 'isManager' | 'disableClickAction'> {
  couponId: number
  disableClickContent?: boolean
  forceRenderInactive?: boolean
  isRenderBlock?: boolean
  onClick?: () => Promise<void>
}

const CouponCardContainer: React.FC<Props> = ({
  couponId,
  isManager,
  disableClickContent = false,
  disableClickAction,
  forceRenderInactive = false,
  isRenderBlock = false,
  onClick,
}) => {
  const coupon = useRecoilValue(couponBoxSelectorFamily({ couponBoxId: couponId }))?.data?.coupon_box
  const couponStatus = useMemo(() => coupon && getCouponStatus(coupon, isManager), [coupon, isManager])
  // const { push } = useCustomNavigator()
  const bizId = coupon?.issuer_id ? `${coupon.issuer_id}` : undefined

  if (
    !coupon || // 쿠폰이 없거나 (for ts check)
    !couponStatus || // 쿠폰이 없거나
    (!forceRenderInactive && !isManager && couponStatus.isInactiveStatus) || // 유저가 비활성화 된 쿠폰을 보고있거나
    (!isManager &&
      isRenderBlock &&
      (couponStatus.isInactiveStatus || couponStatus.isLeak || couponStatus.isExpired || couponStatus.isDeleted)) // render block 을 시킬 상태에 포함된 쿠폰인 경우에는
  ) {
    // 렌더링 하지 않겠다
    return null
  }

  const disableShowCouponDetail = Boolean(disableClickContent || isManager || !bizId)
  const handleClickCouponContent = !disableShowCouponDetail
    ? async () => {
        if (!disableClickContent) {
          await onClick?.()

          karrotBridge.pushRouter({
            router: {
              remote: `${BIZ_PROFILE_APP_BASE_URL}/coupons/detail/${couponId}?referrer=place.poi.detail`,
              navbar: false,
              scrollable: false,
            },
          })
        }
      }
    : undefined

  return (
    <CouponCardBase
      couponId={coupon.id}
      issuedCount={coupon.counter.issued_count ?? null}
      claimedCount={coupon.counter.claimed_count}
      couponStatus={couponStatus}
      expiresAt={coupon.expires_at ?? undefined}
      isManager={isManager}
      name={coupon.name}
      bizId={bizId}
      disableClickAction={disableClickAction}
      minConditionPrice={coupon.min_condition_price}
      target={coupon.event_type}
      couponType={coupon.type}
      onClickCouponContent={handleClickCouponContent}
      brandLogoImg={coupon.brand_logo_image_uri}
      bizAccountType={coupon.business_account?.type as BizAccountType}
    />
  )
}

export default CouponCardContainer

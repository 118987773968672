import { useCallback } from 'react'

import { useToast } from '@src/components/Toast'
import { useNavigator } from '@src/stackflow'
import { localStorage } from '@src/utils/localStorage'

import usePushToDetailPage from './usePushToDetailPage'

const useReviewFeedback = () => {
  const { push } = useNavigator()
  const { handlePush } = usePushToDetailPage()
  const { showToast } = useToast()

  const handleReviewFeedback = useCallback(
    async ({ poiId, bizAccountId, reviewId }: Omit<Parameters<typeof handlePush>['0'], 'options'>) => {
      const item = await localStorage.getItem('IS_FIRST_DAANGN_MAP_REVIEW')
      const isFirst = item !== 'false'

      setTimeout(() => {
        if (isFirst) {
          push('daangn_map_modal', { modalId: 'firstMapReviewDialog' })

          return
        }

        showToast({
          text: '소중한 후기 감사해요! 써주신 후기가 당근지도에 등록되면 알려드릴게요.',
          duration: 'long',
          button: {
            text: '후기 보기',
            onClickButton: () => handlePush({ poiId, bizAccountId, reviewId, options: { area: 'snackbar' } }),
          },
        })
      }, 500)
    },
    [handlePush, showToast, push]
  )

  return { handleReviewFeedback }
}

export default useReviewFeedback

import { createOperatingStatusContributionV2 } from '@daangn/local-business-network/lib/poi'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { captureException } from '@sentry/react'
import { BottomSheet } from '@stackflow/plugin-basic-ui'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/components/Analytics'
import { Button } from '@src/components/Button'
import { useToast } from '@src/components/Toast'
import { useCurrentPosition } from '@src/hooks'
import { useNavigator } from '@src/stackflow'
import { userInfoAtom } from '@src/store'
import { safeAreaPadding } from '@src/styles/safeArea'
import { haversineDistance } from '@src/utils/coordinate'

import { usePoi, useSelectedPoi } from '../hooks'

export const FailedVisitBottomSheet = () => {
  const logEvent = useLogEvent()
  const { showToast } = useToast()
  const { replace, pop } = useNavigator()
  const { selectedPoiId, selectedPoi } = useSelectedPoi()
  const [, setPoi] = usePoi(selectedPoiId ?? '')
  const { position, access, handleOnAccessDenied } = useCurrentPosition()

  const user = useRecoilValue(userInfoAtom)
  const regionNameOfPoi = selectedPoi?.poi.region?.name ? `${selectedPoi.poi.region.name} ` : ''

  const isCurrentPositionCloseToPoi = () => {
    if (access === 'denied') {
      handleOnAccessDenied()

      return false
    } else if (access === 'loading' || !selectedPoi) {
      return false
    } else {
      const distanceBetweenCurrentPositionAndPoi = haversineDistance(position, selectedPoi.poi.coordinates!)

      if (distanceBetweenCurrentPositionAndPoi > 1000) {
        showToast({
          text: '가까운 가게만 방문할 수 있어요.',
          duration: 'short',
        })

        return false
      }

      return true
    }
  }

  const handleClose = async ({ reason }: { reason: 'out-of-business' | 'closed' }) => {
    try {
      const xAuthToken = user.authToken
      const currentSelectedPoi = selectedPoi

      if (!xAuthToken || !selectedPoiId) return
      if (!isCurrentPositionCloseToPoi()) return

      await createOperatingStatusContributionV2({
        params: {
          xAuthToken,
          createV2Body: {
            poiId: selectedPoiId,
            operatingStatus: 'closed',
            closedReason: reason === 'out-of-business' ? 'destroyed' : 'closed',
            // eslint-disable-next-line no-warning-comments
            // @todo remove 'openReason' field after the backend is updated
            // openReason: 'visited',
          } as any,
        },
      })

      if (currentSelectedPoi) {
        setPoi((prev) => {
          return {
            ...(prev ?? currentSelectedPoi),
            poi: {
              ...(prev?.poi ?? currentSelectedPoi.poi),
              operatingStatus: {
                status: 'closed',
                contributedAt: new Date().toISOString(),
                user: {
                  // eslint-disable-next-line no-warning-comments
                  // @todo type
                  id: user.id,
                  nickname: user.nickname,
                  profileImageUrl: user.profileImage,
                  phone: user.phone,
                } as any,
              },
            },
          }
        })
      }

      if (reason === 'out-of-business') {
        replace('delete_poi_suggestion', { poiId: selectedPoiId })
      } else {
        pop()

        setTimeout(
          () =>
            showToast({
              text: `감사해요! ${user.nickname}님 덕분에 동네 정보가 풍부해졌어요.`,
              duration: 'short',
            }),
          250
        )
      }
    } catch (error) {
      console.log(error)
      captureException(error)
      showToast({ text: '잠시후 다시 시도해주세요.', duration: 'short' })
    } finally {
      logEvent({
        name: reason === 'out-of-business' ? 'click_suggestion_delete' : 'click_suggestion_opening_hours',
        params: {},
      })
    }
  }

  return (
    <BottomSheet>
      <Container>
        <Title>가게 방문에 실패했나요?</Title>
        <Description>{regionNameOfPoi}이웃들이 헛걸음하지 않도록 정확한 정보를 알려주세요.</Description>
        <Actions>
          <Button styleTheme="secondary" css={buttonCSS} onClick={() => handleClose({ reason: 'closed' })}>
            가게 운영시간이 아니예요
          </Button>
          <Button styleTheme="secondary" css={buttonCSS} onClick={() => handleClose({ reason: 'out-of-business' })}>
            가게가 없어졌어요
          </Button>
        </Actions>
      </Container>
    </BottomSheet>
  )
}

const Container = styled.div`
  padding: 1.5rem 1rem 1rem;
  ${safeAreaPadding('bottom', '1rem')};
`
const Title = styled.h3`
  margin: 0 0 0.375rem;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title3Bold};
`
const Description = styled.p`
  margin: 0 0 1rem;
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.subtitle1Regular};
`
const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`
const buttonCSS = css`
  padding: 0.906rem 0;
  background-color: ${vars.$semantic.color.secondaryLow};
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Bold};
`

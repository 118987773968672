import { BookmarkPoiIdDto, getBookmarksV2 } from '@daangn/local-business-network/lib/poi'
import { selector } from 'recoil'

import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

import { placeWatchIdToString } from '../utils'

const generateWatchRecoilKey = recoilKeyGenerator(['shared', 'watch'])

export const watchedByUserResponseSelector = selector({
  key: generateWatchRecoilKey(['watchedByUser', 'response']),
  get: async ({ get }) => {
    const userInfo = get(bridgeInfoUserAtom)

    if (!userInfo?.id) {
      return []
    }

    const resp = await getBookmarksV2({
      params: { userId: userInfo.id, withTargetDto: false },
    })

    return resp.data
  },
})

export const watchedByUserResponseWithDataSelector = selector({
  key: generateWatchRecoilKey(['watchedByUser', 'response', 'withData']),
  get: async ({ get }) => {
    const userInfo = get(bridgeInfoUserAtom)

    if (!userInfo?.id) {
      return []
    }

    const resp = await getBookmarksV2({
      params: { userId: userInfo.id, withTargetDto: true },
    })

    return resp.data
  },
})

export const watchedByUserMapperSelector = selector<{ [key: string]: boolean }>({
  key: generateWatchRecoilKey(['watchedByUser', 'mapper']),
  get: ({ get }) => {
    const mapper = {}

    return get(watchedByUserResponseSelector).reduce((watchMapper, { targetId, targetType }) => {
      const key = placeWatchIdToString({
        targetId,
        targetType,
      } as BookmarkPoiIdDto)

      return {
        ...watchMapper,
        [key]: true,
      }
    }, mapper)
  },
})

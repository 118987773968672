class AnalyticsSessionIds {
  querySessionId
  visitSessionId

  constructor({
    initialVisitSessionId,
    initialQuerySessionId,
  }: {
    initialVisitSessionId?: string
    initialQuerySessionId?: string
  }) {
    this.querySessionId = initialQuerySessionId
    this.visitSessionId = initialVisitSessionId
  }

  getQuerySessionId() {
    return this.querySessionId
  }
  setQuerySessionId(newSessionId: string) {
    this.querySessionId = newSessionId
  }

  getVisitSessionId() {
    return this.visitSessionId
  }
  setVisitSessionId(newSessionId: string) {
    this.visitSessionId = newSessionId
  }
}

export default AnalyticsSessionIds

import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { withSuspense } from '@src/components/Suspense'
import { reviewsByPoiIdAndUserIdLoadableFamily } from '@src/shared/review'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics, useTab } from './hooks'
import { communityPostsStaticMetaAtomFamily, tabAtomFamily, tabs, communityPostsSortingOptionAtomFamily } from './state'
import type { Tab as ITab } from './state'

type TabType = Omit<ITab, 'render'>
interface Props {
  tabs: TabType[]
}
const Tabs = ({ tabs }: Props) => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const logEvent = useAnalytics()
  const { setTab } = useTab({ poiId })
  const screenInstance = useScreenInstance()
  const currentTab = useRecoilValue(tabAtomFamily(screenInstance))
  const communityPostsSortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const communityPostsStaticMeta = useRecoilValue(
    communityPostsStaticMetaAtomFamily({ poiId, sortingOption: communityPostsSortingOption, screenInstance })
  )
  const { value: reviewsStore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId, sort: 'latestCreation', userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  const postsTotal = Math.max(reviewsStore?.total ?? 0, 0) + Math.max(communityPostsStaticMeta.total, 0)

  const handleTabChange = useCallback(
    (tab: TabType) => () => {
      const logName = {
        ['home']: 'click_home_tab_button',
        ['posts']: 'click_posts_tab_button',
      }

      logEvent(logName[tab.key], {
        count: tab.key === 'posts' ? postsTotal : undefined,
      })
      setTab(tab.key)
    },
    [logEvent, postsTotal, setTab]
  )

  const tabIndicatorWidth = useMemo(() => Number(100 / tabs.length).toFixed(2) + '%', [tabs])
  const currentTabIndex = useMemo(() => tabs.findIndex((tab) => tab.key === currentTab) || 0, [currentTab, tabs])

  return (
    <TabList>
      {tabs.map((tab) => (
        <Tab isActive={tab.key === currentTab} key={tab.key} onClick={handleTabChange(tab)}>
          {tab.key === 'home' && tab.label}
          {tab.key === 'posts' && `${tab.label}${postsTotal > 0 ? ` ${postsTotal}` : ''}`}
        </Tab>
      ))}
      <Indicator width={tabIndicatorWidth} activeIndex={currentTabIndex} />
    </TabList>
  )
}

const TabsLoading = () => {
  const screenInstance = useScreenInstance()

  const currentTab = useRecoilValue(tabAtomFamily(screenInstance))
  const tabIndicatorWidth = useMemo(() => Number(100 / tabs.length).toFixed(2) + '%', [])
  const currentTabIndex = useMemo(() => tabs.findIndex((tab) => tab.key === currentTab) || 0, [currentTab])

  return (
    <TabList>
      {tabs.map((tab, idx) => (
        <Tab key={idx} isActive={tab.key === currentTab}>
          {tab.key === 'home' && tab.label}
          {tab.key === 'posts' && `${tab.label}`}
        </Tab>
      ))}
      <Indicator width={tabIndicatorWidth} activeIndex={currentTabIndex} />
    </TabList>
  )
}

const TabList = styled.div`
  position: relative;
  height: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  flex-direction: row;
  background-color: ${vars.$semantic.color.paperDefault};
`
const Tab = styled.button<{ isActive: boolean }>`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ isActive }) => (isActive ? vars.$scale.color.gray900 : vars.$scale.color.gray600)};
  transition: color 250ms;
  ${vars.$semantic.typography.label3Bold}
`
const Indicator = styled.span<{ width: string; activeIndex: number }>`
  display: block;
  position: absolute;
  bottom: 0;
  width: ${({ width }) => width};
  height: 0.125rem;
  background: ${vars.$scale.color.gray900};
  transform: translateX(${({ activeIndex }) => activeIndex * 100}%);
  transition: transform 250ms ease-in-out;
`

export default withSuspense(Tabs, TabsLoading)
export { TabsLoading }

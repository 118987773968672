import { useCallback } from 'react'
import { useSetRecoilState } from 'recoil'

import { localStorage } from '@src/utils/localStorage'

import { NudgeType, nudgeItemsAtom } from './state'

export function useNudge() {
  const setNudgeItems = useSetRecoilState(nudgeItemsAtom)

  const isNudgeAlreadySeen = useCallback(async (key: string) => {
    const seenNudge = await localStorage.getItem(key)

    return seenNudge === 'true' ? true : false
  }, [])

  const setIsNudgeAlreadySeen = useCallback((key: string) => {
    localStorage.setItem(key, 'true')
  }, [])

  const showNudge = useCallback(
    async (key: string, n: NudgeType) => {
      const seenNudge = await isNudgeAlreadySeen(key)

      if (seenNudge) return

      setNudgeItems((prev) => ({ ...prev, [key]: n }))
    },
    [isNudgeAlreadySeen, setNudgeItems]
  )

  const hideNudge = useCallback(
    async (key: string) => {
      const seenNudge = await isNudgeAlreadySeen(key)

      if (seenNudge) return

      setNudgeItems((prev) => {
        const items = { ...prev }
        delete items[key]

        return items
      })
      setIsNudgeAlreadySeen(key)
    },
    [isNudgeAlreadySeen, setIsNudgeAlreadySeen, setNudgeItems]
  )

  const clearNudge = useCallback(() => {
    setNudgeItems({})
  }, [setNudgeItems])

  return {
    showNudge,
    hideNudge,
    clearNudge,
    isNudgeAlreadySeen,
    setIsNudgeAlreadySeen,
  }
}

import { css } from '@emotion/react'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { InfiniteScrollObserver } from '@src/components/InfiniteScroll'
import { useScreenInstance } from '@src/widgets/screenInstance'

import EmptyStoryArticleTab from './EmptyStoryTab'
import Options from './Options'
import StoryPreviews from './StoryPreviews'
import { useStory } from '../hooks/useStory'
import {
  communityPostsDynamicMetaAtomFamily,
  communityPostsStaticMetaAtomFamily,
  communityPostsSortingOptionAtomFamily,
} from '../state/story'

const StoryTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const { fetchMore } = useStory({ poiId })
  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const staticMeta = useRecoilValue(communityPostsStaticMetaAtomFamily({ poiId, screenInstance, sortingOption }))
  const dynamicMeta = useRecoilValue(communityPostsDynamicMetaAtomFamily({ poiId, screenInstance, sortingOption }))

  if (staticMeta.total <= 0) {
    return <EmptyStoryArticleTab />
  }

  return (
    <div css={tabPanelsWrapper}>
      <Options poiId={poiId} />
      <StoryPreviews />
      {dynamicMeta.hasNextPage && <InfiniteScrollObserver onIntersecting={fetchMore} />}
    </div>
  )
}

const tabPanelsWrapper = css`
  background: ${vars.$semantic.color.paperDefault};
`

export default StoryTab

import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { InfiniteScrollObserver } from '@src/components/InfiniteScroll'
import { reviewsByPoiIdAndUserIdLoadableFamily } from '@src/shared/review'
import { useScreenInstance } from '@src/widgets/screenInstance'

import EmptyReviewTab from './EmptyReviewTab'
import Options from './Options'
import ReviewPreviews from './ReviewPreviews'
import { reviewSortingOptionAtomFamily } from '../state'

const ReviewTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(reviewSortingOptionAtomFamily({ poiId, screenInstance }))

  const { value: reviewStore, loadMore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId, sort: sortingOption, userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  if (!reviewStore) return null

  const isEmpty = (reviewStore.total ?? 0) <= 0 || reviewStore.items.length <= 0
  if (isEmpty) {
    return <EmptyReviewTab />
  }

  return (
    <Container>
      <Options poiId={poiId} />
      <ReviewPreviews reviews={reviewStore.items} />
      {reviewStore.hasNextPage && <InfiniteScrollObserver onIntersecting={loadMore} />}
    </Container>
  )
}

const Container = styled.div`
  background: ${vars.$semantic.color.paperDefault};
`

export default ReviewTab

import { setFetchConfig } from '@daangn/local-business-network/lib/coupon'
import type { AxiosInterceptorManager } from 'axios'

import { COUPON_BASE_URL } from '@src/constants/apiUrls'
import { APP_ENV } from '@src/constants/environmentConstants'
import { tokenStorage } from '@src/utils/tokenStorage'

const authInjector: Parameters<AxiosInterceptorManager<any>['use']>[0] = (config) => {
  if (!config.headers) {
    config.headers = {}
  }
  config.headers['x-auth-token'] = tokenStorage.getAuthToken()
  config.baseURL = COUPON_BASE_URL

  return config
}

const couponAxiosInstance = setFetchConfig(APP_ENV as 'production' | 'alpha')

couponAxiosInstance.interceptors.request.use(authInjector)

import { PurposefulCategoryCard } from '@daangn/local-business-kit'
import { useLoadableFamily } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { bridge } from '@src/bridge'
import { InfiniteScrollObserver } from '@src/components/InfiniteScroll'
import { PageLoading } from '@src/components/Loading'
import { withSuspense } from '@src/components/Suspense'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useActivity, useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { activeEffect } from '@src/styles/utils'
import { MONTH_IN_MS } from '@src/utils/date'
import { createQueryString } from '@src/utils/url'

import { CONTENT_CURATOR_API_KEY_TO_THEME_ID_MAPPER } from './constants/bigCategory'
import { EmptyList } from './EmptyList'
import { useNewAnalytics } from './hook/Analytics'
import useBizProfileAndPoiByThemeLoadableFamily from './hook/useBizProfileAndPoiByThemeLoadableFamily'
import { PurposefulCategoryActivityParams } from './PurposefulCategoryPage'
import { miniAppLoadableFamily } from './store/miniApp'
import { MiniApp, PurposefulCategoryBizAccountAndPoiResponse } from './store/type'

const List = () => {
  const { categoryFilterId, themeId, referrer } = useActivityParams<
    PurposefulCategoryActivityParams & { referrer: string }
  >()
  const { value: bizProfileAndPoi, loadMore, loading } = useBizProfileAndPoiByThemeLoadableFamily()
  const { push } = useNavigator()
  const log = useNewAnalytics()
  const { name: activityName } = useActivity()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const selectedTheme =
    categoryFilterId === 'all' ? CONTENT_CURATOR_API_KEY_TO_THEME_ID_MAPPER[themeId] : categoryFilterId
  const { value: miniApp } = useLoadableFamily({
    loadable: miniAppLoadableFamily,
    variables: { themeId: selectedTheme ?? '', regionId: userInfo?.region.id ?? -1 },
    fetchPolicy: 'store-or-network',
  })

  const handleRouteToDetailPage = (store: any) => {
    const isBiz = !!store.business_account
    log({
      name: 'click_onedepth_section',
      type: 'click',
      params: {
        contents_type: 'biz',
        item_type: isBiz ? 'bizprofile' : 'poi',
        item_id: isBiz ? store.business_account.id : store.poi.id,
      },
    })
    if (store.business_account) {
      const queryString = createQueryString({
        referrer: referrer ?? `place.${activityName}`,
        entry: `place.${activityName}`,
      })

      bridge.router.push({
        app: `${WEBVIEW_BASE_URL}business-platform/home`,
        path: `/biz_accounts/${store.business_account.id}/viewer/home${queryString}`,
        navbar: false,
        scrollable: false,
      })
    } else {
      push('poi_detail', {
        poiId: store.poi.id,
      })
    }
  }

  const handleClickMiniApp = (miniApp: MiniApp) => {
    log({
      name: 'click_onedepth_section',
      type: 'click',
      params: {
        contents_type: 'biz',
        item_type: 'mini',
        item_id: miniApp.name,
      },
    })
    window.location.href = miniApp.app_deeplink_url
  }

  const handleLoadMore = () => {
    if (!bizProfileAndPoi) return
    log({
      name: 'scroll_onedepth_section',
      type: 'click',
      params: {
        items_count: bizProfileAndPoi.business_account_and_poi_base_response.length,
        has_more_items: !!bizProfileAndPoi.cursor,
      },
    })
    loadMore()
  }

  const renderStoreCard = (store: PurposefulCategoryBizAccountAndPoiResponse) => {
    const tags = []

    if (store.business_account) {
      if (store.business_account.has_valid_coupon) tags.push('쿠폰')
      if (store.business_account.on_sale) tags.push('바로결제')
      if (store.business_account.reservable) tags.push('예약')
    }

    return store.type === 'BUSINESS_ACCOUNT' ? (
      <Card
        key={store.business_account.id}
        name={store.business_account.nickname}
        categoryName={store.business_account.category?.name}
        thumbnailURL={store.business_account.profile_image_url.replace('&s=640x640', '&s=192x192')}
        reviewCount={store.business_account.reviews_count}
        followerCount={store.business_account.followers_count}
        isNew={new Date().valueOf() - new Date(store.business_account.created_at).valueOf() < MONTH_IN_MS}
        description={store.business_account.description}
        distance={
          store.business_account.distance && store.business_account.distance < 1000000
            ? store.business_account.distance
            : undefined
        }
        extraFeaturedTags={tags}
        onClick={handleRouteToDetailPage.bind(null, store)}
      />
    ) : (
      <Card
        key={store.poi.id}
        name={store.poi.name}
        categoryName={store.poi.category?.name}
        thumbnailURL={store.poi.image_urls[0]}
        reviewCount={store.poi.reviews_count}
        isNew={new Date().valueOf() - new Date(store.poi.created_at).valueOf() < MONTH_IN_MS}
        distance={store.poi.distance && store.poi.distance < 1000000 ? store.poi.distance : undefined}
        onClick={handleRouteToDetailPage.bind(null, store)}
      />
    )
  }

  useEffect(() => {
    log({
      name: 'show_onedepth_section',
      type: 'show',
      params: {
        contents_type: 'biz',
      },
    })
  }, [log])

  if (!bizProfileAndPoi) return <PageLoading />

  const isEmpty = bizProfileAndPoi.business_account_and_poi_base_response.length <= 0
  if (!loading && isEmpty) return <EmptyList />

  return (
    <>
      {bizProfileAndPoi?.business_account_and_poi_base_response?.slice(0, 4).map(renderStoreCard)}
      {categoryFilterId === 'all' && miniApp && (
        <Card
          key={miniApp.name}
          name={miniApp.name}
          categoryName={'당근미니'}
          thumbnailURL={miniApp.icon_image_url}
          reviewCount={miniApp.reviews_count}
          favoriteCount={miniApp.favorites_count}
          onClick={handleClickMiniApp.bind(null, miniApp)}
          description={miniApp.description}
        />
      )}
      {bizProfileAndPoi?.business_account_and_poi_base_response?.slice(4).map(renderStoreCard)}
      {!loading && bizProfileAndPoi?.cursor && (
        <InfiniteScrollObserver options={{ threshold: 0.9 }} onIntersecting={handleLoadMore} />
      )}
    </>
  )
}

const Card = styled(PurposefulCategoryCard)`
  padding: 1rem 0.75rem;
  margin: 0 0.25rem;
  width: calc(100% - 0.5rem);

  ${activeEffect()};
`

export default withSuspense(List)

import 'core-js/stable'
import './style'
import { Global } from '@emotion/react'
import * as Sentry from '@sentry/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { RecoilRoot } from 'recoil'

import ErrorPage from '@src/components/Error/Base'

import App from './App'
import { PageLoading } from './components/Loading'
import { IS_LOCAL } from './constants/environmentConstants'
import { initSentry } from './sentry'
import globalCss from './styles/global'
// eslint-disable-next-line import/no-unresolved
import '@stackflow/plugin-basic-ui/index.css'
// eslint-disable-next-line import/no-unresolved
import '@daangn/sprout-components-button/index.css'

if (!IS_LOCAL) {
  initSentry()
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <React.Suspense fallback={<PageLoading />}>
        <Sentry.ErrorBoundary fallback={<ErrorPage />}>
          <Global styles={globalCss} />
          <App />
        </Sentry.ErrorBoundary>
      </React.Suspense>
    </RecoilRoot>
  </React.StrictMode>
)

// :)

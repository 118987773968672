import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { IS_ANDROID } from '@src/constants/environmentConstants'

export const getSafeAreaStyle = ({
  direction,
  property,
  converter,
}: {
  direction: 'top' | 'bottom'
  property: string
  converter: (safeAreaValue: string) => string
}) => {
  const safeAreaValue = `safe-area-inset-${direction}`

  return css`
    ${property}: calc(${converter(`constant(${safeAreaValue})`)});
    ${property}: calc(${converter(`env(${safeAreaValue})`)});
  `
}

/**
 * PageBottomSafeArea에 height 100%를 주면 피드와 같은 긴 리스트 페이지에서 스크롤이 정상적으로 동작하지 않을 수 있음.
 * 그렇다고 height 100%를 주지 않자니, 자식 컴포넌트의 height가 너무 작은 경우 세로 가운데 정렬과 같은 것이 정상적으로 동작하지 않음.
 * 따라서 height 100%를 통해 부모의 height를 상속받고, display flex를 통해 자식 컴포넌트에서 flex 1을 통해 height를 갖도록 구현한 컴포넌트가 PageBottomSafeAreaHeightExpander.
 * 이 컴포넌트로 감싸진 PageBottomSafeArea 컴포넌트는 height 프로퍼티 조정 없이 flex 1 만으로 페이지의 height를 가질 수 있게됨.
 */
export const PageBottomSafeAreaHeightExpander = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`

export const PageBottomSafeArea = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: ${({ background }) => (background ? background : '')};

  ${!IS_ANDROID &&
  getSafeAreaStyle({
    direction: 'bottom',
    property: 'padding-bottom',
    converter: (safeAreaValue) => safeAreaValue,
  })}
`

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { forwardRef, MouseEventHandler, useEffect, useRef } from 'react'

import { CurationMapItem } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'
import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useOnScreen } from '@src/hooks/useOnScreen'
import { useWatch } from '@src/shared/watch/hooks'
import { useActivity, useNavigator, useStepNavigator } from '@src/stackflow'
import { createQueryString } from '@src/utils/url'

import Common from './Common'
import { useAnalytics, useSelectedPoi } from '../hooks'
import type { ViewType } from '../types'

type Props = CurationMapItem & {
  index: number
  isHighlighted: boolean
  fillSafeArea?: boolean
  on3DotClick?: () => void
} & { viewType: ViewType }

const PoiContentCardSimplified = forwardRef<HTMLDivElement, Props>(
  ({ index, isHighlighted, fillSafeArea, ...props }, ref) => {
    const { poi, topContent, viewType } = props
    const { id, bizAccountId } = poi

    const localRef = useRef<HTMLDivElement>(document.createElement('div'))
    const onScreen = useOnScreen<HTMLDivElement>(localRef, 1, true)

    const { referrer } = useActivityParams<{ referrer: string }>()
    const { name: activityName } = useActivity()
    const { push } = useNavigator()
    const logEvent = useAnalytics()
    const { spotlightPoiOnMap } = useSelectedPoi()
    const { stepPop } = useStepNavigator('daangn_map')
    const { resetWatchedByUser } = useWatch()
    const { kakao } = useKakaoMap()

    const handleRouteToDetailPage = () => {
      logEvent('click_view_poi_details_page_card', {
        poi_type: bizAccountId ? 'biz_account' : 'poi',
        poi_id: bizAccountId ?? id,
        view_type: 'map',
      })

      const contentType = topContent?.contentType ?? null
      const contentId = topContent?.id ?? null

      if (bizAccountId) {
        const isStory = contentType === 'story_article' || contentType === 'story_comment'
        const isBizReview = contentType === 'biz_review'
        const queryString = createQueryString({
          referrer: referrer ?? `place.${activityName}`,
          entry: `place.${activityName}`,
          tab: isStory ? 'STORY' : 'REVIEW',
          highlightReviewId: isBizReview ? contentId : null,
          highlightStoryId: isStory ? contentId : null,
        })

        bridge.router.push({
          app: `${WEBVIEW_BASE_URL}business-platform/home`,
          path: `/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
          navbar: false,
          scrollable: false,
        })
      } else {
        const isPoiReview = contentType === 'poi_review'

        push('poi_detail', {
          poiId: id,
          tab: isPoiReview ? 'review' : 'story',
          spotlightPostId: contentId ?? undefined,
        })

        resetWatchedByUser()
      }
    }

    const handleClickCard = () => {
      if (!id) return

      // kakao is undefined when map errors
      if (viewType === 'LIST' && kakao) {
        logEvent('click_view_poi_on_map_card', {
          poi_type: bizAccountId ? 'biz_account' : 'poi',
          poi_id: bizAccountId ?? id,
          view_type: 'list',
        })

        stepPop()
        spotlightPoiOnMap(id, true)
      } else {
        handleRouteToDetailPage()
      }
    }

    const handleClickUserProfile: MouseEventHandler = () => {
      logEvent('click_user_profile', {
        poi_type: bizAccountId ? 'biz_account' : 'poi',
        poi_id: bizAccountId ?? id,
        view_type: viewType === 'LIST' ? 'list' : 'map',
        area: viewType === 'LIST' ? 'list' : 'poi_card',
      })
    }

    useEffect(() => {
      if (onScreen && viewType === 'LIST') {
        logEvent('show_poi_card', {
          poi_type: bizAccountId ? 'biz_account' : 'poi',
          poi_id: bizAccountId ?? id,
          position: index + 1,
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onScreen])

    return (
      <Container
        onClick={handleClickCard}
        ref={(element) => {
          if (element) {
            localRef.current = element
          }

          if (typeof ref === 'function') {
            ref(element)
          } else if (ref) {
            ref.current = element
          }
        }}
        isHighlighted={isHighlighted}
        isBoxShadow={!!fillSafeArea}>
        <Common {...props} onClickUserProfile={handleClickUserProfile} />
        {fillSafeArea && <div className="safeAreaCover" />}
      </Container>
    )
  }
)

const Container = styled.div<{ isHighlighted: boolean; isBoxShadow: boolean }>`
  transition: 500ms background;
  background: ${({ isHighlighted }) =>
    isHighlighted ? vars.$semantic.color.paperAccent : vars.$semantic.color.paperDefault};
  border-bottom: 1px solid ${vars.$semantic.color.divider1};
  box-shadow: ${({ isBoxShadow }) => (isBoxShadow ? '0px 2px 12px rgba(0, 0, 0, 0.1)' : 'none')};

  &:last-of-type {
    border-bottom: none;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 14px 0 0;
  }

  .safeAreaCover {
    width: 100%;
    height: 0px;
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
    background: ${vars.$semantic.color.paperDefault};
  }
`

export default PoiContentCardSimplified

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { CategorySuggestionDto } from '../models';
// @ts-ignore
import { SuggestCategoryBody } from '../models';
/**
 * CategorySuggestionsApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @summary 통합 카테고리 추가 제안
   * @param {SuggestCategoryBody} suggestCategoryBody 
   * @param {string} [xAuthToken] karrotauth auth token or JWT
   * @param {string} [xBusinessWebLoginToken] business login web token
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const createCategorySuggestionV2AxiosParamCreator = async (suggestCategoryBody: SuggestCategoryBody, xAuthToken?: string, xBusinessWebLoginToken?: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/category-suggestions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }

      if (xBusinessWebLoginToken !== undefined && xBusinessWebLoginToken !== null) {
          localVarHeaderParameter['x-business-web-login-token'] = String(xBusinessWebLoginToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = suggestCategoryBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * CategorySuggestionsApi - functional programming interface
 * @export
 */
    /**
     * 
     * @summary 통합 카테고리 추가 제안
     * @param {SuggestCategoryBody} suggestCategoryBody 
     * @param {string} [xAuthToken] karrotauth auth token or JWT
     * @param {string} [xBusinessWebLoginToken] business login web token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const createCategorySuggestionV2Fp = async (suggestCategoryBody: SuggestCategoryBody, xAuthToken?: string, xBusinessWebLoginToken?: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CategorySuggestionDto>> => {
        const localVarAxiosArgs = await createCategorySuggestionV2AxiosParamCreator(suggestCategoryBody, xAuthToken, xBusinessWebLoginToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * CategorySuggestionsApi - factory interface
 * @export
 */
    /**
     * 
     * @summary 통합 카테고리 추가 제안
     * @param {SuggestCategoryBody} suggestCategoryBody 
     * @param {string} [xAuthToken] karrotauth auth token or JWT
     * @param {string} [xBusinessWebLoginToken] business login web token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const createCategorySuggestionV2 = (suggestCategoryBody: SuggestCategoryBody, xAuthToken?: string, xBusinessWebLoginToken?: string, options?: any): AxiosPromise<CategorySuggestionDto> => {
        return createCategorySuggestionV2Fp(suggestCategoryBody, xAuthToken, xBusinessWebLoginToken, options).then((request) => request());
    }



import { isDefaultLoadable } from '@daangn/recoil-loadable'
import { atom, selectorFamily } from 'recoil'

import { pageKeyPrefix } from './key'
import { themeMetaLoadableFamily } from './theme'
import { PurposefulCategoryFilterOption, PurposefulCategorySortOption } from './type'

export type FilterName = 'sort' | 'category'

export const selectedFilterNameAtom = atom({
  key: pageKeyPrefix('selectedFilterName'),
  default: '',
})

export const filterOptionsSelectorFamily = selectorFamily({
  key: pageKeyPrefix('filterOptions'),
  get:
    ({ themeId, filterName }: { themeId: string; filterName: FilterName }) =>
    ({ get }) => {
      switch (filterName) {
        case 'category':
          return get(categoryFilterOptionsSelectorFamily(themeId)) ?? []
        case 'sort':
          return get(sortOptionsSelectorFamily(themeId)) ?? []
      }
    },
})

export const sortOptionsSelectorFamily = selectorFamily({
  key: pageKeyPrefix('sortOptions'),
  get:
    (themeId: string) =>
    ({ get }) => {
      const themeMeta = get(themeMetaLoadableFamily.store(themeId))

      if (isDefaultLoadable(themeMeta)) return [] as PurposefulCategorySortOption[]

      return themeMeta.sort_options
    },
})

export const categoryFilterOptionsSelectorFamily = selectorFamily({
  key: pageKeyPrefix('categoryFilterOptions'),
  get:
    (themeId: string) =>
    ({ get }) => {
      const themeMeta = get(themeMetaLoadableFamily.store(themeId))

      if (isDefaultLoadable(themeMeta)) return [] as PurposefulCategoryFilterOption[]

      return [
        {
          id: 'all',
          name: '전체',
          type: 'CATEGORY',
        },
      ].concat(
        themeMeta.filter_options.filter((option: any) => option.type === 'CATEGORY')
      ) as PurposefulCategoryFilterOption[]
    },
})

export const toggleFilterOptionsSelectorFamily = selectorFamily({
  key: pageKeyPrefix('toggleFilterOptions'),
  get:
    (themeId: string) =>
    ({ get }) => {
      const themeMeta = get(themeMetaLoadableFamily.store(themeId))

      if (isDefaultLoadable(themeMeta)) return []

      return themeMeta.filter_options.filter((option: PurposefulCategoryFilterOption) => option.type === 'TOGGLE')
    },
})

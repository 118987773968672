import { WinterSnackMapItem } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRecoilState } from 'recoil'

import { useLogEvent } from '@src/components/Analytics'
import Slide from '@src/components/Slides/Slide'
import Slides from '@src/components/Slides/Slides'

import { SLIDE_GAP, SLIDE_MARGIN, mapPoiCardHeightAtom, CARD_MARGIN, SPACE_FOR_SHADOW } from '../constants/style'
import { useFilteredPois, useSelectedPoi } from '../hooks'
import { WinterSnackPoiCard } from '../Poi'

const PoisCards = () => {
  const logEvent = useLogEvent()
  const { selectedPoiId, spotlightPoiOnMap } = useSelectedPoi()
  const [mapPoiCardHeight, setPoiCardHeight] = useRecoilState(mapPoiCardHeightAtom)
  const filteredPois = useFilteredPois()

  const poiCardsRef = useRef<{ [poiId: string]: HTMLDivElement }>({})

  const poiIdToIndexMapper = useMemo(() => {
    return (
      filteredPois.reduce(
        (mapper, poi, index) =>
          !poi?.poi?.id
            ? mapper
            : {
                ...mapper,
                [poi.poi.id]: index,
              },
        {} as { [id: string]: number }
      ) ?? {}
    )
  }, [filteredPois])

  const focusedCardIndex = useMemo(() => {
    return selectedPoiId ? poiIdToIndexMapper[selectedPoiId] : 0
  }, [selectedPoiId, poiIdToIndexMapper])

  const slicedPois = useMemo(() => {
    const renderCardsLength = 5
    const beginIndex = Math.max(0, focusedCardIndex - 2)
    const endIndex = beginIndex + renderCardsLength

    return filteredPois ? filteredPois.slice(beginIndex, endIndex) : []
  }, [filteredPois, focusedCardIndex])

  const handleSwipeEnd = useCallback(
    (cursor: number) => {
      if (!filteredPois[cursor]) return

      spotlightPoiOnMap(filteredPois[cursor].poi.id!)
      logEvent({ name: 'click_swipe_poi_card', params: {} })
    },
    [filteredPois, spotlightPoiOnMap, logEvent]
  )

  useEffect(() => {
    if (!selectedPoiId || !poiCardsRef.current[selectedPoiId]) return

    const selectedPoiCardElement = poiCardsRef.current[selectedPoiId]
    setPoiCardHeight(selectedPoiCardElement.getBoundingClientRect().height)
  }, [filteredPois.length, selectedPoiId, setPoiCardHeight])

  return (
    <StyledSlides
      maxLength={filteredPois ? filteredPois.length - 1 : 0}
      focusedCardIndex={focusedCardIndex}
      slideMargin={SLIDE_MARGIN}
      slideGap={SLIDE_GAP}
      height={mapPoiCardHeight + CARD_MARGIN + SPACE_FOR_SHADOW}
      onSwipeCardEnd={handleSwipeEnd}>
      {slicedPois.map((item: WinterSnackMapItem) => {
        return (
          <Slide
            key={item.poi.id}
            index={poiIdToIndexMapper[item.poi.id]}
            ref={(element) => {
              if (!element || !item.poi.id) return
              poiCardsRef.current[item.poi.id] = element
            }}>
            <WinterSnackPoiCard item={item} />
          </Slide>
        )
      })}
    </StyledSlides>
  )
}

const StyledSlides = styled(Slides)`
  padding: 0 0 ${CARD_MARGIN}px;
`
export default PoisCards

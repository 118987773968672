import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { forwardRef } from 'react'

interface Props {
  id: string
  isActive: boolean
  onClick: () => void
  children: React.ReactNode
}

const Filter = forwardRef<HTMLButtonElement, Props>(({ isActive, onClick, children }, ref) => {
  return (
    <Button type="button" role="button" isActive={isActive} onClick={onClick} ref={ref}>
      {children}
    </Button>
  )
})

const Button = styled.button<{ isActive: boolean }>`
  margin: 0;
  padding: 0 0.75rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6.25rem;
  border: 1px solid ${vars.$semantic.color.divider2};
  background-color: transparent;
  color: ${vars.$scale.color.gray900};

  ${vars.$semantic.typography.caption1Regular};

  ${({ isActive }) => isActive && active};
`
const active = css`
  border: solid 1px ${vars.$semantic.color.grayActive};
  background-color: ${vars.$semantic.color.grayActive};
  color: ${vars.$static.color.staticWhite};
`

export default Filter

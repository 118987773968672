import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import BoundarySearchButton from './BoundarySearchButton'
import Map from './Map'
import PoisCardSection from './PoisCardSection'
import { EventBanner } from '../EventBanner'
import { useIsCardShown, usePoisStatus, useParamsForShare } from '../hooks'
import { isMapBoundChangedAtomFamily } from '../state'
import type { ViewType } from '../types'

interface Props {
  viewType: ViewType
}

export const MapView: React.FC<Props> = ({ viewType }) => {
  const { bounds: boundsParam } = useParamsForShare()
  const isCardShown = useIsCardShown()
  const poisStatus = usePoisStatus()
  const screenInstance = useScreenInstance()
  const mapBoundsChanged = useRecoilValue(isMapBoundChangedAtomFamily(screenInstance))
  const initialZoomLevel = useMemo(() => boundsParam?.zoomLevel ?? 5, [boundsParam?.zoomLevel])

  return (
    <Container>
      <TopArea>
        <EventBanner viewType="map" />
        {(mapBoundsChanged || poisStatus === 'loading') && <BoundarySearchButton />}
      </TopArea>
      <Map initialZoomLevel={initialZoomLevel} />
      <CardSection>{isCardShown && <PoisCardSection viewType={viewType} />}</CardSection>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
`
const TopArea = styled.div`
  z-index: 2;
  width: 0;
  height: fit-content;
  position: absolute;
  transform: translate3d(50vw, 0, 0);
  top: 0.75rem;
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  align-items: center;
`

const CardSection = styled.div`
  z-index: 2;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

import { atom } from 'recoil'

import { localStorage, LOCAL_STORAGE_KEYS } from '@src/utils/localStorage'

import { generateKey } from './recoilKey'

type WatchTooltipVisibility = 'hidden' | 'visible'
export const watchTooltipVisibilityAtom = atom<WatchTooltipVisibility>({
  key: generateKey('watchTooltipVisibilityAtom'),
  default: 'hidden',
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        if (newValue === 'visible') {
          localStorage.setItem(LOCAL_STORAGE_KEYS.WATCH_PAGE_TOOLTIP_story_article_poi_collection, 'true')
        }
      })
    },
  ],
})

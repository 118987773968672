import { FormikErrors, useFormik } from 'formik'
import * as React from 'react'
import { useRecoilValue } from 'recoil'

import { winterSnackEventSelector } from '@src/pages/WinterSnack/state'

import { FormItemWrapper } from '../FormItemWrapper'
import SelectorInput from '../SelectorInput'
import type { Field, FormValues } from '../types'

interface Props {
  field: Field<'winterSnackMenu'>
  value: FormValues['winterSnackMenu']
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  setFieldTouched: ReturnType<typeof useFormik>['setFieldTouched']
  validateForm: (values?: FormValues | undefined) => Promise<FormikErrors<FormValues>>
  errorMessage?: string
}

export const WinterSnackMenuInput = ({
  field,
  value,
  errorMessage,
  setFieldValue,
  setFieldTouched,
  validateForm,
}: Props) => {
  const { id, isRequired } = field
  const winterSnackEvent = useRecoilValue(winterSnackEventSelector)

  const options = winterSnackEvent?.attributes?.map(({ id, name }) => ({ value: id, label: name })) ?? []

  return (
    <FormItemWrapper key={id} id={id} label="판매하는 메뉴" errorMessage={errorMessage} isOptional={!isRequired}>
      <SelectorInput
        id={id}
        selectedValues={value ?? []}
        options={options}
        setFieldValue={setFieldValue}
        onToggleOption={() => {
          setFieldTouched(id, true, false)
          setTimeout(() => {
            validateForm()
          }, 0)
        }}
      />
    </FormItemWrapper>
  )
}

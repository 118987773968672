import React from 'react'

import { AlertDialog } from '@src/components/AlertDialog'

interface Props {
  onConfirm: () => void
}
export const BizManagerSelfReviewAlert: React.FC<Props> = ({ onConfirm }) => {
  return (
    <AlertDialog
      contents="사장님은 본인 가게에 추천 글을 쓸 수 없어요."
      CTAButton={{
        label: '확인',
        onClick: onConfirm,
      }}
    />
  )
}

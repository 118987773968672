import React from 'react'

import { useImageSliderState } from '@src/hooks/useImageSliderState'

import ImageDetailSlider from './ImageDetailSlider'
import ImageSlider, { ImageSliderProps } from './ImageSlider'

type Props = Omit<ImageSliderProps, 'onClickDetail'>

const ImageContainer: React.FC<Props> = ({ images, height, sliderRef }) => {
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()

  return (
    <>
      <ImageSlider images={images} height={height} onClickDetail={handleSelectDetailImage} sliderRef={sliderRef} />
      <ImageDetailSlider onClose={handleCloseDetailImage} initialIndex={selectedImageIdx} images={images} />
    </>
  )
}

export default ImageContainer

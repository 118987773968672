/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { Bookmark } from '../models';
// @ts-ignore
import { BookmarkPoiIdDto } from '../models';
/**
 * BookmarksApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @param {string} xAuthToken 
   * @param {BookmarkPoiIdDto} bookmarkPoiIdDto 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const createBookmarkV2AxiosParamCreator = async (xAuthToken: string, bookmarkPoiIdDto: BookmarkPoiIdDto, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/bookmarks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = bookmarkPoiIdDto || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {string} targetId 
   * @param {'POI' | 'BA'} targetType 
   * @param {string} xAuthToken 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const deleteBookmarkV2AxiosParamCreator = async (targetId: string, targetType: 'POI' | 'BA', xAuthToken: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/bookmarks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (targetId !== undefined) {
          localVarQueryParameter['targetId'] = targetId;
      }

      if (targetType !== undefined) {
          localVarQueryParameter['targetType'] = targetType;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 유저가 북마크한 poi 조회
   * @param {number} userId 유저 아이디
   * @param {Array<string>} [poiIds] poi 아이디 리스트
   * @param {Array<string>} [baIds] ba 아이디 리스트
   * @param {number} [take] 가져올 북마크 개수
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getBookmarksV2AxiosParamCreator = async (userId: number, poiIds?: Array<string>, baIds?: Array<string>, take?: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/bookmarks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (userId !== undefined) {
          localVarQueryParameter['userId'] = userId;
      }

      if (poiIds) {
          localVarQueryParameter['poiIds'] = poiIds;
      }

      if (baIds) {
          localVarQueryParameter['baIds'] = baIds;
      }

      if (take !== undefined) {
          localVarQueryParameter['take'] = take;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * BookmarksApi - functional programming interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {BookmarkPoiIdDto} bookmarkPoiIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const createBookmarkV2Fp = async (xAuthToken: string, bookmarkPoiIdDto: BookmarkPoiIdDto, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bookmark>> => {
        const localVarAxiosArgs = await createBookmarkV2AxiosParamCreator(xAuthToken, bookmarkPoiIdDto, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {string} targetId 
     * @param {'POI' | 'BA'} targetType 
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const deleteBookmarkV2Fp = async (targetId: string, targetType: 'POI' | 'BA', xAuthToken: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bookmark>> => {
        const localVarAxiosArgs = await deleteBookmarkV2AxiosParamCreator(targetId, targetType, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 유저가 북마크한 poi 조회
     * @param {number} userId 유저 아이디
     * @param {Array<string>} [poiIds] poi 아이디 리스트
     * @param {Array<string>} [baIds] ba 아이디 리스트
     * @param {number} [take] 가져올 북마크 개수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getBookmarksV2Fp = async (userId: number, poiIds?: Array<string>, baIds?: Array<string>, take?: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bookmark>>> => {
        const localVarAxiosArgs = await getBookmarksV2AxiosParamCreator(userId, poiIds, baIds, take, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * BookmarksApi - factory interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {BookmarkPoiIdDto} bookmarkPoiIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const createBookmarkV2 = (xAuthToken: string, bookmarkPoiIdDto: BookmarkPoiIdDto, options?: any): AxiosPromise<Bookmark> => {
        return createBookmarkV2Fp(xAuthToken, bookmarkPoiIdDto, options).then((request) => request());
    }
    /**
     * 
     * @param {string} targetId 
     * @param {'POI' | 'BA'} targetType 
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const deleteBookmarkV2 = (targetId: string, targetType: 'POI' | 'BA', xAuthToken: string, options?: any): AxiosPromise<Bookmark> => {
        return deleteBookmarkV2Fp(targetId, targetType, xAuthToken, options).then((request) => request());
    }
    /**
     * 
     * @summary 유저가 북마크한 poi 조회
     * @param {number} userId 유저 아이디
     * @param {Array<string>} [poiIds] poi 아이디 리스트
     * @param {Array<string>} [baIds] ba 아이디 리스트
     * @param {number} [take] 가져올 북마크 개수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getBookmarksV2 = (userId: number, poiIds?: Array<string>, baIds?: Array<string>, take?: number, options?: any): AxiosPromise<Array<Bookmark>> => {
        return getBookmarksV2Fp(userId, poiIds, baIds, take, options).then((request) => request());
    }



import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import SearchAddressExternalPage from './SearchAddressExternalPage'
import DefaultSearchAddressPage from './SearchAddressPage'

type ActivityParams = {
  variation?: 'external'
}
const SearchAddressPage: ActivityComponentType<ActivityParams> = ({ params: { variation } }) => {
  switch (variation) {
    case 'external':
      return <SearchAddressExternalPage />
    default:
      return <DefaultSearchAddressPage />
  }
}

export default SearchAddressPage

import { atom, useRecoilState } from 'recoil'

import { localStorage } from '@src/utils/localStorage'

const analyticsLogOffAtom = atom({
  key: 'devToolsAnalyticsLogOff',
  default: (async () => {
    const item = await localStorage.getItem('USE_ANALYTICS')

    return item === 'false'
  })(),
  effects: [
    ({ onSet }: any) => {
      onSet((newValue: boolean) => {
        localStorage.setItem('USE_ANALYTICS', newValue ? 'false' : 'true')
      })
    },
  ],
})

export const useAnalyticsLogOff = () => {
  return useRecoilState(analyticsLogOffAtom)
}

const historyLogOffAtom = atom({
  key: 'devToolsHistoryLogOff',
  default: (async () => {
    const item = await localStorage.getItem('USE_HISTORY')

    return item === 'false'
  })(),
  effects: [
    ({ onSet }: any) => {
      onSet((newValue: boolean) => {
        localStorage.setItem('USE_HISTORY', newValue ? 'false' : 'true')
      })
    },
  ],
})

export const useHistoryLogOff = () => {
  return useRecoilState(historyLogOffAtom)
}

const currentUserPositionOffAtom = atom({
  key: 'devToolsCurrentUserPositionOff',
  default: (async () => {
    const item = await localStorage.getItem('USE_CURRENT_USER_POSITION')

    return item === 'false'
  })(),
  effects: [
    ({ onSet }: any) => {
      onSet((newValue: boolean) => {
        localStorage.setItem('USE_CURRENT_USER_POSITION', newValue ? 'false' : 'true')
      })
    },
  ],
})

export const useCurrentUserPositionOff = () => {
  return useRecoilState(currentUserPositionOffAtom)
}

const sentryLogOffAtom = atom({
  key: 'devToolsSentryLogOff',
  default: (async () => {
    const item = await localStorage.getItem('USE_SENTRY')

    return item === 'false'
  })(),
  effects: [
    ({ onSet }: any) => {
      onSet((newValue: boolean) => {
        localStorage.setItem('USE_SENTRY', newValue ? 'false' : 'true')
      })
    },
  ],
})

export const useSentryLogOff = () => {
  return useRecoilState(sentryLogOffAtom)
}

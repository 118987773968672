import { useCallback } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { selectedFilterIdAtomFamily, selectedPoiIdAtomFamily } from '../state'

const useFilter = () => {
  const screenInstance = useScreenInstance()

  const [selectedFilterId, setSelectedFilterId] = useRecoilState(selectedFilterIdAtomFamily(screenInstance))
  const resetFilter = useResetRecoilState(selectedFilterIdAtomFamily(screenInstance))
  const resetSelectedPoiId = useResetRecoilState(selectedPoiIdAtomFamily(screenInstance))

  const selectFilter = useCallback(
    (filterId: number, onSelect?: () => void) => {
      onSelect?.()

      setSelectedFilterId(filterId)
    },
    [setSelectedFilterId]
  )

  const unselectFilter = useCallback(
    (onUnselect?: () => void) => {
      onUnselect?.()

      resetFilter()
      resetSelectedPoiId()
    },
    [resetFilter, resetSelectedPoiId]
  )

  interface FilterToggleHandler {
    filterId: number
    onSelect?: () => void
    onUnselect?: () => void
  }
  const toggleFilter = useCallback(
    ({ filterId, onSelect, onUnselect }: FilterToggleHandler) => {
      if (selectedFilterId && selectedFilterId === filterId) {
        unselectFilter(onUnselect)
      } else {
        selectFilter(filterId, onSelect)
      }
    },
    [selectFilter, selectedFilterId, unselectFilter]
  )

  return {
    unselectFilter,
    toggleFilter,
  }
}

export default useFilter

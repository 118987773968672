import { getReviewsByPoiIdV2 } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateKey = recoilKeyGenerator('poiReview')

type PoiReviewRequestParameters = Parameters<typeof getReviewsByPoiIdV2>['0']['params']

export const poiReviewSelectorFamily = selectorFamily({
  key: generateKey('poiReviewSelectorFamily'),
  get: (params: PoiReviewRequestParameters) => async () => {
    try {
      const { data } = await getReviewsByPoiIdV2({ params })

      return data
    } catch (error) {
      captureException(error)

      throw error
    }
  },
})

export const myPoiReviewSelectorFamily = selectorFamily({
  key: generateKey('myPoiReviewSelectorFamily'),
  get:
    (params: Omit<PoiReviewRequestParameters, 'userId'>) =>
    ({ get }) => {
      if (!params.poiId) return undefined

      const userInfo = get(bridgeInfoUserAtom)

      if (!userInfo || !userInfo.id) return undefined

      const myUserId = userInfo.id
      const myReview = get(poiReviewSelectorFamily({ ...params, userId: myUserId }))

      return myReview.items[0]
    },
})

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useMemo } from 'react'

import { Image } from '@src/apis/generated/poi/models'
import { SectionHeader } from '@src/components'
import { ImageCenterCover, ImageDetailSlider } from '@src/components/Image'
import { Section } from '@src/components/Layout'
import { useImageSliderState } from '@src/hooks/useImageSliderState'
import useResizedWindowSize from '@src/hooks/useResizedWindowSize'

import { useAnalytics } from '../../hooks'

interface Props {
  pricingImages: Image[]
}

const PriceSection: React.FC<Props> = ({ pricingImages }) => {
  const logEvent = useAnalytics()
  const { width: windowWidth } = useResizedWindowSize()
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()

  const pricingImagesTruncated = useMemo(() => pricingImages.slice(0, 3), [pricingImages])
  const imageSize = useMemo(() => {
    const length = pricingImages.length
    if (length === 1) {
      const width = windowWidth - 32
      return {
        width,
        height: (width * 184) / 328,
      }
    } else {
      const width = windowWidth - 32 - 24
      return {
        width,
        height: (width * 184) / 328,
      }
    }
  }, [windowWidth, pricingImages])

  const handleClickMorePhotos = (index: number) => {
    logEvent('click_menu_image', {
      position: index + 1,
    })
    handleSelectDetailImage(index)
  }

  return (
    <Section>
      <SectionHeader type="basic" text="가격표" css={{ marginBottom: '0.75rem' }} />
      <StyledPricingPhotos>
        {pricingImagesTruncated.map(({ id, thumbnail }, index) => {
          return (
            <StyledPricingImage
              key={id}
              totalCount={pricingImages.length}
              index={index}
              width={imageSize.width}
              height={imageSize.height}
              onClick={handleClickMorePhotos.bind(null, index)}>
              <ImageCenterCover src={thumbnail} width={imageSize.width} height={imageSize.height} />
              {index === 2 && pricingImages.length > 3 && (
                <div className="count-overlay">
                  더보기 <span>{pricingImages.length - 3}</span>
                </div>
              )}
            </StyledPricingImage>
          )
        })}
        <div className="margin" />
      </StyledPricingPhotos>
      <ImageDetailSlider
        onClose={handleCloseDetailImage}
        initialIndex={selectedImageIdx}
        images={pricingImages.map((image) => image.url)}
      />
    </Section>
  )
}

const StyledPricingPhotos = styled.div`
  display: flex;
  width: 100%;
  overflow-x: scroll;
  .margin {
    min-width: 8px;
    min-height: 100%;
  }
`

const StyledPricingImage = styled.div<{ index: number; totalCount: number; width: number; height: number }>`
  position: relative;
  margin: 0 8px 0 0;
  border-radius: 5px;
  border: 1px solid ${vars.$semantic.color.divider1};
  height: ${({ height }) => height}px;
  min-width: ${({ width }) => width}px;
  overflow: hidden;
  .count-overlay {
    ${vars.$semantic.typography.caption1Bold}
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${vars.$semantic.color.divider1};
    color: ${vars.$scale.color.gray00};
    span {
      color: ${vars.$scale.color.gray00};
      font-weight: 400;
      margin: 0 0 0 2px;
    }
  }
`

export default PriceSection

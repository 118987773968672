import { useEffect } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import usePois from './usePois'
import { getPoisQuery } from '../state'

export const useSyncPoisEffect = () => {
  const { setPois } = usePois()
  const screenInstance = useScreenInstance()

  const poisLoadable = useRecoilValueLoadable(getPoisQuery(screenInstance))
  // store fetched pois to store(poiIdsAtom and poiAtomFamily)
  useEffect(() => {
    if (poisLoadable.state !== 'hasValue') return

    const pois = poisLoadable.contents

    setPois(pois ?? [])
  }, [poisLoadable.contents, poisLoadable.state, setPois])
}

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { SquareBadge } from '@src/components/Badge'

export const RecommendBadge = () => {
  return (
    <Badge size="small" type="success" bold>
      추천댓글
    </Badge>
  )
}

const Badge = styled(SquareBadge)`
  padding: 0.0625rem 0.25rem;
  ${vars.$semantic.typography.label6Bold};
`

import { searchPoisV2, GetPoisV2200ResponseAllOf } from '@daangn/local-business-network/lib/poi'
import { createLoadableFamily } from '@daangn/recoil-loadable'
import { atom } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'
import type { CreateSerializableParam } from '@src/types/recoil'

import { analyticsSessionIds } from './analytics'

export const generateKey = recoilKeyGenerator(['searchPoi', 'poi'])

type SearchStatus = 'initial' | 'searching' | 'searched'
export const searchStatusAtom = atom<SearchStatus>({
  key: generateKey('status'),
  default: 'initial',
})
type ResultStatus = 'initial' | 'has-result' | 'no-result'
export const resultStatusAtom = atom<ResultStatus>({
  key: generateKey('result'),
  default: 'initial',
})
type RequestParams = Parameters<typeof searchPoisV2>['0']['params']
export type FetchVariablesType = Pick<RequestParams, 'query' | 'regionId' | 'isPredictedKeyword' | 'xAuthToken'>
interface Pagination {
  page: number
  perPage: number
  hasNextPage: boolean
}
export interface ResponseType extends Pagination {
  items: GetPoisV2200ResponseAllOf['items']
}

export const poisLoadableFamily = createLoadableFamily<
  ResponseType,
  CreateSerializableParam<FetchVariablesType>,
  ResponseType | null
>({
  getStore: ({ atomFamily }) =>
    atomFamily({
      key: generateKey('poisLoadableFamily'),
    }),
  fetch: async ({ behavior, prevState, variables }) => {
    const { query, regionId, isPredictedKeyword, xAuthToken } = variables

    if (query.length === 0) {
      return null
    }

    const page = behavior === 'loadMore' && prevState?.page ? prevState.page + 1 : 1
    const perPage = prevState?.perPage ?? 20

    const { data } = await searchPoisV2({
      params: {
        query,
        regionId,
        page,
        limit: perPage,
        servicedFrom: 'poi_web',
        visitSessionId: analyticsSessionIds.getVisitSessionId(),
        querySessionId: analyticsSessionIds.getQuerySessionId(),
        isPredictedKeyword,
        isCurrentAreaSearch: false,
        xAuthToken,
      },
    })

    return {
      items: data.items,
      page,
      perPage,
      hasNextPage: data.hasNextPage,
    }
  },
  onSuccess: ({ response, prevState, behavior, recoilInterface: { set } }) => {
    set(searchStatusAtom, 'searched')

    const items: ResponseType['items'] =
      behavior === 'loadMore' && prevState?.items
        ? [...new Set([...(prevState?.items ?? []), ...(response?.items ?? [])])]
        : response?.items ?? []

    if (items.length === 0) {
      set(resultStatusAtom, 'no-result')
    } else {
      set(resultStatusAtom, 'has-result')
    }

    return {
      items,
      hasNextPage: !!response?.hasNextPage,
      page: behavior === 'loadMore' && prevState?.hasNextPage ? prevState.page + 1 : prevState?.page ?? 1,
      perPage: prevState?.perPage ?? 20,
    }
  },
})

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import axios from 'axios'

import { IS_ALPHA } from './constants/environmentConstants'

export const initSentry = async () => {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
    normalizeDepth: 10,
    maxBreadcrumbs: 50,
    debug: IS_ALPHA && (await localStorage.getItem('USE_SENTRY')) === 'true',
    environment: process.env.APP_ENV,
    release: process.env.SENTRY_VERSION,
    beforeSend(event) {
      if (event.exception?.values) {
        for (let i = 0; i < event.exception.values.length; i++) {
          const value = event.exception.values[i]

          if (value.stacktrace?.frames) {
            value.stacktrace.frames = value.stacktrace.frames.map((frame) => {
              if (!frame.filename) {
                return frame
              }

              const splitted = frame.filename.split('/static/js/')
              frame.filename = 'https://webview-sourcemap.daangn.com/static/js/' + splitted[1]

              return frame
            })
          }
        }
      }

      return event
    },
  })
}

axios.interceptors.request.use(
  (config: any) => {
    /**
     * Breadcrumb type link: https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
     *  */
    Sentry.addBreadcrumb({
      type: 'http',
      level: Sentry.Severity.Log,
      data: {
        method: config.method,
        url: `${config.baseURL}${config.url}`,
        params: config.params,
        data: config.data,
      },
    })
    return config
  },
  (error: any) => {
    Sentry.captureException(error)
    return error
  }
)

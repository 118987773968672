import styled from '@emotion/styled'
import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import React, { useState, useRef, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { fromEvent } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { Category } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'
import { TextInput } from '@src/components/Input'

import { useAnalytics } from './hooks'
import LoadMoreCategories from './LoadMoreCategories'
import SearchResults from './SearchResults'
import { paginationSelector, searchTextAtom } from './state'

export interface ResultComponentBaseProps {
  isActive: boolean
  onClick: (argument: Category) => void
}

interface Props {
  onChangeSearchText?: (searchText: string) => void
  onClickSearchResult: (category: Category, searchText: string) => void
}

function SearchCategory({ onChangeSearchText, onClickSearchResult }: Props) {
  const logEvent = useAnalytics()

  const [searchText, setSearchText] = useRecoilState(searchTextAtom)
  const [pagination, setPagination] = useRecoilState(paginationSelector)

  const [inputText, setInputText] = useState('')

  const inputRef = useRef<HTMLInputElement>(null)

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeSearchText) {
      onChangeSearchText(e.target.value)
    }
    setInputText(e.target.value)
  }

  const handleClearInput = () => {
    setSearchText('')
    setInputText('')
  }

  const handleSelectCategory = (category: Category) => {
    if (category.id) {
      onClickSearchResult(category, searchText)
    }
  }

  useEffect(() => {
    if (!inputRef.current) return

    const keydownSubscription = fromEvent(inputRef.current, 'keydown')
      .pipe(debounceTime(500))
      .subscribe(async (e: any) => {
        if (!e?.target?.value) return
        bridge.analytics.log({ name: 'search_all_category', params: { from: 'poi_webview', keyword: searchText } })
        logEvent('handle_search_category_input', {
          query: e.target.value,
        })
        setSearchText(e.target.value)
        setPagination({
          ...pagination,
          page: 1,
        })
      })

    setTimeout(() => {
      if (!inputRef.current) return
      inputRef.current.focus()
    }, 300)

    return () => {
      keydownSubscription.unsubscribe()
    }
  }, [logEvent, pagination, searchText, setPagination, setSearchText])

  return (
    <>
      <StyledSearchBar>
        <form action="." noValidate onSubmit={(e) => e.preventDefault()}>
          <TextInput
            type="search"
            ref={inputRef}
            value={inputText}
            placeholder="예) 카페, 반찬가게, 용달 등"
            onChange={handleChangeInput}
            appendRight={<IconSearchRegular className="icon" />}
            onClearInputValue={handleClearInput}
          />
        </form>
      </StyledSearchBar>
      <SearchResults onClickSearchResult={handleSelectCategory} />
      <LoadMoreCategories />
    </>
  )
}

const StyledSearchBar = styled.div`
  padding: 24px 16px 0;
`

export default SearchCategory

import { useActivityParams } from '@stackflow/react'
import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useStepNavigator } from '@src/stackflow'

import { CARD_HEIGHT } from '../constants/style'
import type { ActivityParams } from '../index'
import { allCommentsAtomFamily } from '../state'

export const useSelectedComment = () => {
  const { commentId: selectedCommentId, storyArticleId } = useActivityParams<ActivityParams>()
  const { stepReplace } = useStepNavigator('story_article_poi_collection')

  const comments = useRecoilValue(allCommentsAtomFamily({ storyArticleId: +storyArticleId }))

  const { kakao, panTo } = useKakaoMap()

  const getCommentById = useCallback(
    (commentId: string) => comments.find((comment) => comment.id === Number(commentId)),
    [comments]
  )
  const selectedPoi = useMemo(
    () => (selectedCommentId ? getCommentById(selectedCommentId)?.poi ?? null : null),
    [getCommentById, selectedCommentId]
  )
  const setSelectedCommentId = useCallback(
    (commentId: string) => {
      stepReplace({ storyArticleId, commentId })
    },
    [stepReplace, storyArticleId]
  )
  const spotlightPoiOnMap = useCallback(
    ({ commentId, shouldPanTo = true }: { commentId: string; shouldPanTo?: boolean }) => {
      const selectedPoi = getCommentById(commentId)?.poi

      if (!selectedPoi) return

      setSelectedCommentId(commentId)

      const coordinates = selectedPoi.coordinates
      if (coordinates && shouldPanTo && kakao) {
        panTo({
          coordinates,
          yOffset: CARD_HEIGHT / 2 - 32,
        })
      }
    },
    [getCommentById, kakao, panTo, setSelectedCommentId]
  )

  return {
    selectedPoi,
    spotlightPoiOnMap,
  }
}

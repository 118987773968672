import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { useLogEvent } from '@src/components/Analytics'

const useAnalytics = () => {
  const logEvent = useLogEvent()
  const { poiId } = useActivityParams<{ poiId: string }>()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = { poi_id: poiId, ...params }

      logEvent({ name, params: _params })
    },
    [logEvent, poiId]
  )
}

export default useAnalytics

import { SmoothImage } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import defaultThumbnail from '@src/assets/images/category_appliance.svg'
import { activeEffect } from '@src/styles/utils'

interface Props {
  thumbnail: string
  title: string
  tags: string[]
  description: string
  onClickBody: () => void
  favoriteButton: React.ReactNode
  className?: string
}
export const GeneralWatchOverview = ({
  thumbnail,
  title,
  tags,
  favoriteButton,
  description,
  onClickBody,
  ...props
}: Props) => {
  return (
    <Container onClick={onClickBody} className={props.className}>
      <Thumbnail>
        {thumbnail ? (
          <SmoothImage src={thumbnail} width="100%" height="100%" borderRadius="6px" />
        ) : (
          <img
            src={defaultThumbnail}
            css={{
              width: '100%',
              height: '100%',
              borderRadius: '6px',
            }}
          />
        )}
      </Thumbnail>

      <Contents>
        <Top>
          <Header>
            <Title>{title}</Title>
            <Tags>
              {tags?.map((tag, idx) => {
                return <Tag key={idx}>{tag}</Tag>
              })}
            </Tags>
          </Header>
          <FavoriteButtonWrapper>{favoriteButton}</FavoriteButtonWrapper>
        </Top>

        <Body>{description} </Body>
      </Contents>
    </Container>
  )
}

const Container = styled.div`
  background-color: ${vars.$semantic.color.paperDefault};
  width: 100%;
  height: 8.75rem;
  padding: 1rem;
  display: flex;
  flex-flow: row;
  gap: 1rem;

  ${activeEffect()};
`
const Thumbnail = styled.div`
  width: 6.75rem;
  height: 6.75rem;
  border-radius: 6px;
  min-width: 6.75rem;
  min-height: 6.75rem;
`
const Contents = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 0.125rem;
`
const Top = styled.div`
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const Header = styled.div`
  width: 0; // for ellipsis
  min-width: 0;
  flex: 1;
  display: flex;
  flex-flow: column;
`
const Title = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Regular};
`
const Tags = styled.span`
  margin: 0.125rem 0 0;
  display: inline-flex;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
const Tag = styled.span`
  min-width: fit-content;
  display: flex;
  align-items: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption1Regular};

  &:not(:first-of-type) {
    &::before {
      content: '⸱';
      width: 0.188rem;
      height: 0.875rem;
      margin: 0 0.25rem;
      display: flex;
      align-items: center;
      text-align: center;
      font-weight: 700;
      font-size: 0.625rem;
      line-height: 140%;
      color: ${vars.$scale.color.gray600};
    }
  }
`
const FavoriteButtonWrapper = styled.div`
  flex-shrink: 0;
`
const Body = styled.span`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.bodyL2Regular};
`

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { EmptyOrError } from '@src/components'
import { useActivity, useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { useAnalytics } from './hooks'
import { boundsAtom } from './state/map'
import { queryAtom } from './state/query'

export const NoResult = () => {
  const logEvent = useAnalytics()
  const { enteredBy } = useActivity()
  const { entry } = useActivityParams<{ entry?: string }>()
  const { push } = useNavigator()

  const query = useRecoilValue(queryAtom)
  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const bounds = useRecoilValue(boundsAtom)

  const handleClickCreateSuggestion = () => {
    logEvent('click_suggest_new_poi_button', {
      area: 'no_result',
    })
    push('create_poi_suggestion', {
      name: query,
      variation: enteredBy.activityName === 'poi_review' || entry === 'poi_review' ? 'writeReview' : undefined,
    })
  }

  return (
    <EmptyOrError
      title={
        <Title>
          앗! {bounds ? `현 화면에서` : userInfo?.region.name ? `${userInfo?.region.name} 근처에는` : ''}
          <br />
          <span>&apos;{query}&apos;</span> 검색결과가 없어요.
        </Title>
      }
      body="찾고 있는 장소가 없다면 직접 등록해보세요."
      CTAButton={{
        label: '장소 등록하기',
        onClick: handleClickCreateSuggestion,
      }}
    />
  )
}

const Title = styled.h3`
  text-align: center;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Regular};

  span {
    ${vars.$semantic.typography.subtitle1Bold};
  }
`

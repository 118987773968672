import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useCallback, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { karrotBridgeCache } from '@src/bridge'
import { KakaoCustomOverlay, KakaoMap, useKakaoMap } from '@src/components/Map/KakaoMap'
import { useCurrentPosition } from '@src/hooks'
import { Coordinates } from '@src/types/global'
import { convertCoordinatesArrayToObject, convertCoordinatesObjectToArray } from '@src/utils/coordinate'
import { useScreenInstance } from '@src/widgets/screenInstance'

import Marker from './Marker'
import { useAnalytics } from '../hooks'
import {
  centerPositionAtomFamily,
  currentPositionClickedAtomFamily,
  DEFAULT_COORDINATES,
  isMapBoundChangedAtomFamily,
} from '../state'
import { filteredPoisSelectorFamily, selectedPoiIdAtomFamily } from '../state/pois'

interface Props {
  initialZoomLevel: number
}
const Map: React.FC<Props> = ({ initialZoomLevel }) => {
  const logEvent = useAnalytics()
  const { getLevel } = useKakaoMap()
  const { access, position } = useCurrentPosition()
  const screenInstance = useScreenInstance()

  const setSelectedPoiId = useSetRecoilState(selectedPoiIdAtomFamily(screenInstance))
  const centerPosition = useRecoilValue(centerPositionAtomFamily(screenInstance))
  const cachedPosition = karrotBridgeCache.getCurrentPosition()?.geolocation.currentPosition?.position
  const centerPositionWithDefault =
    centerPosition ??
    (cachedPosition ? convertCoordinatesObjectToArray(cachedPosition as Coordinates) : DEFAULT_COORDINATES)
  const filteredPois = useRecoilValue(filteredPoisSelectorFamily(screenInstance))
  const setCurrentPositionClicked = useSetRecoilState(currentPositionClickedAtomFamily(screenInstance))
  const setMapBoundChanged = useSetRecoilState(isMapBoundChangedAtomFamily(screenInstance))

  const zoomLevelRef = useRef<number>(initialZoomLevel)

  const handleClickMap = useCallback(() => {
    setSelectedPoiId(null)
  }, [setSelectedPoiId])

  const handleDragEnd = useCallback(() => {
    logEvent('handle_dragend_map')
    setCurrentPositionClicked(false)
    setMapBoundChanged(true)
  }, [logEvent, setCurrentPositionClicked, setMapBoundChanged])

  const handleZoomChanged = useCallback(() => {
    const previousLevel = zoomLevelRef.current
    const currentLevel = getLevel()

    if (previousLevel === currentLevel) return

    logEvent(previousLevel < currentLevel ? 'handle_zoom_out_map' : 'handle_zoom_in_map', {
      previous_level: previousLevel,
      current_level: currentLevel,
    })

    setMapBoundChanged(true)
    zoomLevelRef.current = getLevel()
  }, [getLevel, logEvent, setMapBoundChanged])

  return (
    <Container>
      <StyledKakaoMap
        center={convertCoordinatesArrayToObject(centerPositionWithDefault)}
        zoomable={true}
        draggable={true}
        watermarkPosition={['top', 'right']}
        onZoomChanged={handleZoomChanged}
        onDragEnd={handleDragEnd}
        onClick={handleClickMap}>
        {filteredPois.map((poi) => {
          return <Marker poi={poi} key={poi.poi.id} />
        })}

        {access === 'granted' && (
          <KakaoCustomOverlay position={position} clickable={false} zIndex={0}>
            <UserPosition className="userPosition">
              <div className="userPosition-background"></div>
              <div className="userPosition-dot"></div>
            </UserPosition>
          </KakaoCustomOverlay>
        )}
      </StyledKakaoMap>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const StyledKakaoMap = styled(KakaoMap)`
  width: 100%;
  height: 100%;
`

const UserPosition = styled.div`
  @keyframes ping {
    0% {
      transform: scale3d(1, 1, 1);
    }
    12.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    25% {
      transform: scale3d(1, 1, 1);
    }
    37.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    50% {
      transform: scale3d(1, 1, 1);
    }
    62.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    75% {
      transform: scale3d(1, 1, 1);
    }
    87.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  .userPosition-dot {
    position: relative;
    height: 16px;
    width: 16px;
    /** @todo: seed-design */
    background-color: #2b6eff;
    border-radius: 8px;
    border: solid 2px ${vars.$static.color.staticWhite};
    display: block;
  }
  .userPosition-background {
    content: '';
    height: 48px;
    width: 48px;
    background: rgba(43, 110, 255, 0.25);
    border-radius: 24px;
    position: absolute;
    display: block;
    top: -16px;
    left: -16px;
    transform: scale3d(0, 0, 1);
    animation-duration: 6s;
    animation-name: ping;
  }

  .content {
    z-index: 2;
    position: fixed;
    bottom: 0;
    bottom: constant(safe-area-inset-bottom);
    bottom: env(safe-area-inset-bottom);
    left: 0;
    right: 0;
    margin: 16px 0 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`

export default Map

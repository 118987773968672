import styled from '@emotion/styled'
import IconRetryRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconRetryRegular'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { useRecoilValue } from 'recoil'

import { Tooltip } from '@src/components/Nudge'
import { withSuspense } from '@src/components/Suspense'
import { usePrevious } from '@src/hooks'
import { useStepNavigator } from '@src/stackflow'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/utils/localStorage'

import DropdownFilter from './DropdownFilter'
import ToggleFilter from './ToggleFilter'
import { FILTER_CONTAINER_PADDING } from '../constants/style'
import { useFilter } from '../hook'
import { useNewAnalytics } from '../hook/Analytics'
import { PurposefulCategoryActivityParams } from '../PurposefulCategoryPage'
import {
  categoryFilterOptionsSelectorFamily,
  sortOptionsSelectorFamily,
  toggleFilterOptionsSelectorFamily,
} from '../store'

const FiltersWithoutSuspense = () => {
  const activityParams = useActivityParams<PurposefulCategoryActivityParams>()
  const { themeId, categoryFilterId, sortBy, toggleFilterIds } = activityParams
  const log = useNewAnalytics()
  const { stepReplace } = useStepNavigator('purposeful_category')
  const { reset } = useFilter()

  const categoryFilterOptions = useRecoilValue(categoryFilterOptionsSelectorFamily(themeId))
  const sortOptions = useRecoilValue(sortOptionsSelectorFamily(themeId))
  const toggleFilterOptions = useRecoilValue(toggleFilterOptionsSelectorFamily(themeId))

  const isFilterModified =
    toggleFilterIds ||
    (!!categoryFilterId && categoryFilterId !== 'all') ||
    (!!sortBy && sortBy !== 'BA_POI_SCORE_DESC')
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)

  const [showTooltip, setShowTooltip] = useState(false)

  const { styles, update } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [{ name: 'offset', options: { offset: [0, FILTER_CONTAINER_PADDING] } }],
  })

  const handleReset = () => {
    log({
      name: 'click_onedepth_filter',
      type: 'click',
      params: {
        contents_type: 'filter',
        filter_type: 'reset',
      },
    })

    reset()
  }

  const handleClickSection = () => {
    log({
      name: 'click_onedepth_section',
      type: 'click',
      params: {
        contents_type: 'filter',
      },
    })
  }

  useLayoutEffect(() => {
    if (categoryFilterId === 'all') return
    ;(async () => {
      const isSeen = await localStorage.getItem(LOCAL_STORAGE_KEYS.PURPOSEFUL_CATEGORY_FILTER_TOOLTIP)

      if (isSeen === 'true') return

      setTimeout(() => {
        setShowTooltip(true)
      }, 500)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    log({
      name: 'show_onedepth_section',
      type: 'show',
      params: {
        contents_type: 'filter',
      },
    })
  }, [log])

  const prev = usePrevious(isFilterModified)

  useEffect(() => {
    if (isFilterModified !== prev) {
      update?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterModified, update])

  useEffect(() => {
    // select default if not there
    stepReplace(
      {
        ...activityParams,
        categoryFilterId: activityParams.categoryFilterId ?? categoryFilterOptions[0].id,
        sortBy: activityParams.sortBy ?? sortOptions[0].id,
      },
      { animate: false }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FixedContainer>
      <OverflowContainer onClick={handleClickSection}>
        {isFilterModified && (
          <ResetButton onClick={handleReset}>
            <IconRetryRegular width="1.125rem" height="1.125rem" color={vars.$scale.color.gray900} />
          </ResetButton>
        )}
        <DropdownFilter filterName="sort" alwaysInactive />

        <DropdownFilter filterName="category" defaultOptionId={categoryFilterOptions[0].id} ref={setReferenceElement} />
        {showTooltip &&
          createPortal(
            <Tooltip
              ref={setPopperElement}
              text="다른 가게도 볼 수 있어요"
              arrowDirection="topLeft"
              uniqueKey={LOCAL_STORAGE_KEYS.PURPOSEFUL_CATEGORY_FILTER_TOOLTIP}
              duration={3000}
              isShowCloseButton={false}
              style={styles.popper}
            />,
            document.body
          )}
        {toggleFilterOptions.map(({ id, name }: { id: string; name: string }) => (
          <ToggleFilter key={id} id={id} name={name} />
        ))}
      </OverflowContainer>
    </FixedContainer>
  )
}

const FixedContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;

  padding: 1rem 0;
  width: 100%;

  background-color: ${vars.$semantic.color.paperDefault};
`

const OverflowContainer = styled.div`
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;

  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`

const ResetButton = styled.button`
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  min-height: 2rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: none;
  background-color: ${vars.$scale.color.gray100};
`

export const Filters = withSuspense(FiltersWithoutSuspense)

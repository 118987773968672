import styled from '@emotion/styled'
import IconMoreVertRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconMoreVertRegular'
import React, { MouseEventHandler } from 'react'

import { CurationMapItem } from '@src/apis/generated/poi/models'

import TopContent from './Content'
import Poi from './Poi'
import UserProfile from './UserProfile'

type Props = CurationMapItem & {
  on3DotClick?: () => void
  onClickUserProfile?: MouseEventHandler
}

const Common: React.FC<Props> = ({
  poi,
  topContent,
  contributedUserProfile,
  distance,
  on3DotClick,
  onClickUserProfile,
}) => {
  const handleClick3Dot: MouseEventHandler = (e) => {
    e.stopPropagation()
    on3DotClick?.()
  }

  return (
    <Container>
      {topContent && <UserProfile topContent={topContent} onClickUserProfile={onClickUserProfile} />}
      <CardClickArea>
        <Body>
          {topContent && <TopContent topContent={topContent} contributedUserProfile={contributedUserProfile} />}
        </Body>
        <Poi poi={poi} distance={distance} />
        {!!on3DotClick && (
          <IconMoreVertRegular width={24} height={24} className="vert-icon" onClick={handleClick3Dot} />
        )}
      </CardClickArea>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  .vert-icon {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
  }
`
const CardClickArea = styled.div`
  padding: 0.1875rem 1rem 1rem;
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 0 1rem;

  > div {
    width: 100%;
  }
`

export default Common

import React from 'react'

import { AlertDialog } from '@src/components/AlertDialog'

interface Props {
  bizProfileName: string
  onConfirm: () => void
  onClose: () => void
}
export const BlockedBizReviewUpdateDialog: React.FC<Props> = ({ bizProfileName, onConfirm, onClose }) => {
  return (
    <AlertDialog
      contents={`작성한 후기가 신고되어 ${bizProfileName}에 쓴 후기를 수정해도 바로 보이지 않아요.`}
      CTAButton={{
        label: '후기 수정',
        onClick: onConfirm,
      }}
      subButton={{
        label: '취소',
        onClick: onClose,
      }}
      buttonAlign="row"
    />
  )
}

import { atomFamily, selectorFamily } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { Bounds, GPS } from '@src/types/global'
import {
  availableCoordinates,
  convertCoordinatesArrayToObject,
  convertCoordinatesObjectToArray,
} from '@src/utils/coordinate'

import { generateMapPageRecoilKey } from './keys'

export const DEFAULT_COORDINATES: GPS = [37.566168, 126.978052]
export const centerPositionAtomFamily = atomFamily<GPS | null, string>({
  key: generateMapPageRecoilKey('centerPosition'),
  default: selectorFamily({
    key: generateMapPageRecoilKey('centerPosition/Default'),
    get:
      () =>
      async ({ get }) => {
        const userInfo = get(bridgeInfoUserAtom)
        const fallbackCoordinates =
          userInfo?.region.center_coordinates ??
          userInfo?.region.centerCoordinates ??
          convertCoordinatesArrayToObject(DEFAULT_COORDINATES)
        try {
          let position: { latitude: number; longitude: number } | undefined
          let timestamp: number | undefined
          karrotBridge
            .getCurrentPosition({})
            .then((res) => {
              position = res.geolocation.currentPosition?.position
              timestamp = res.geolocation.currentPosition?.timestamp
            })
            .catch(() => {
              position = undefined
              timestamp = undefined
            })

          if (!position?.latitude || !position.longitude || !userInfo) {
            throw ''
          } else {
            return convertCoordinatesObjectToArray(
              availableCoordinates({ position, timestamp }, userInfo, fallbackCoordinates)
            )
          }
        } catch {
          return [fallbackCoordinates.latitude, fallbackCoordinates.longitude]
        }
      },
  }),
})

export const boundsAtomFamily = atomFamily<Bounds | null, string>({
  key: generateMapPageRecoilKey('boundsAtom'),
  default: null,
})

export const currentPositionClickedAtomFamily = atomFamily<boolean, string>({
  key: generateMapPageRecoilKey('currentPositionClicked'),
  default: false,
})

export const isMapBoundChangedAtomFamily = atomFamily<boolean, string>({
  key: generateMapPageRecoilKey('isBoundChangedAtom'),
  default: false,
})

export const isCurrentAreaSearchAtomFamily = atomFamily<boolean, string>({
  key: generateMapPageRecoilKey('isCurrentAreaSearchAtom'),
  default: false,
})

export const isInitialFetchAtomFamily = atomFamily<boolean, string>({
  key: generateMapPageRecoilKey('isInitialFetchAtom'),
  default: true,
})

export const isIntroductionDrawerOpenedAtomFamily = atomFamily<boolean, string>({
  key: generateMapPageRecoilKey('isIntroductionDrawerOpenedAtom'),
  default: false,
})

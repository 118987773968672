import styled from '@emotion/styled'
import React, { useRef } from 'react'

import { useInfiniteScroll } from '@src/hooks'
import type { IUseInfiniteScroll } from '@src/hooks/useInfiniteScroll'

import { Loading } from '../Loading'

interface IInfiniteScrollObserver extends Omit<IUseInfiniteScroll, 'ref'> {
  children?: React.ReactNode
}
const InfiniteScrollObserver = ({ onIntersecting, options, children }: IInfiniteScrollObserver) => {
  const ref = useRef<HTMLDivElement>(null)

  useInfiniteScroll({ ref, onIntersecting, options })

  return <Observer ref={ref}>{children ?? <Loading />}</Observer>
}

const Observer = styled.div`
  height: 4rem;
`

export default React.memo(InfiniteScrollObserver)

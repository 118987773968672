import { UserInfo } from '@src/apis/Bridge'
import { BridgeUser } from '@src/store/bridgeInfo'
import { Coordinates } from '@src/types/global'
import { haversineDistance } from '@src/utils/coordinate'

export const availableCoordinates = (
  userPosition: {
    position: Coordinates
    timestamp?: number
  },
  userInfo: UserInfo | BridgeUser,
  fallbackCoordinates: Coordinates
) => {
  const allowedUserPosition = userPosition && userPosition.position.latitude

  const userRegionCenterCoordinates = userInfo?.region.centerCoordinates || userInfo?.region.center_coordinates

  if (!userRegionCenterCoordinates) {
    return fallbackCoordinates
  }

  if (!allowedUserPosition) {
    return userRegionCenterCoordinates
  }

  const distanceBetweenCurrentPositionAndRegionCenter = haversineDistance(
    userPosition.position,
    userRegionCenterCoordinates
  )
  if (distanceBetweenCurrentPositionAndRegionCenter >= 1000) {
    return userRegionCenterCoordinates
  } else {
    return userPosition.position
  }
}

import { useRecoilCallback, useRecoilValue } from 'recoil'

import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { TEMP_SPACING_FOR_CENTER } from '../constants/style'
import { currentPositionClickedAtomFamily, selectedPoiIdAtomFamily } from '../state'
import { poiSelectorFamily } from '../state/pois'

const useSelectedPoi = () => {
  const screenInstance = useScreenInstance()

  const SPACING_FOR_CENTER = useRecoilValue(TEMP_SPACING_FOR_CENTER)

  const { kakao, panTo } = useKakaoMap()

  const spotlightPoiOnMap = useRecoilCallback(
    ({ snapshot, set }) =>
      (poiId: string, shouldPanTo: boolean) => {
        const poi = snapshot.getLoadable(poiSelectorFamily(poiId)).getValue()

        if (!poi) return

        set(selectedPoiIdAtomFamily(screenInstance), poiId)

        const coordinates = poi.poi.coordinates
        if (coordinates && shouldPanTo && kakao) {
          panTo({
            coordinates,
            yOffset: SPACING_FOR_CENTER,
          })

          set(currentPositionClickedAtomFamily(screenInstance), false)
        }
      },
    [screenInstance, kakao, panTo, SPACING_FOR_CENTER]
  )

  return {
    spotlightPoiOnMap,
  }
}

export default useSelectedPoi

import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { AlertDialog } from '@src/components/AlertDialog'
import { APP_SCHEME } from '@src/constants/environmentConstants'
import { useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { useAnalytics } from '../hooks'
import { ModalActivityParams } from '../PoiDetailModal'

interface Props {
  poiName: string
  onConfirm?: () => void
  onClose?: () => void
}

const ConfirmWriteStoryDialog: React.FC<Props> = ({ poiName, onClose, onConfirm }) => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()
  const { poiId } = useActivityParams<ModalActivityParams>()

  const user = useRecoilValue(bridgeInfoUserAtom)

  const handleClose = () => {
    logEvent('click_cancel_write_community_post_button')
    onClose?.()
    pop()
  }

  const handleConfirm = () => {
    logEvent('click_confirm_write_community_post_button')
    onConfirm?.()
    pop()
    window.location.href = `${APP_SCHEME}story_articles/new?poi_id=${poiId}&poi_name=${poiName}`
  }

  return (
    <AlertDialog
      contents={`게시글을 쓰면 동네생활 탭에 ${user?.nickname}님의 장소 추천 글이 보여요.`}
      CTAButton={{
        label: '글쓰기',
        onClick: handleConfirm,
      }}
      subButton={{
        label: '취소',
        onClick: handleClose,
      }}
      buttonAlign="row"
      onOutsideClick={handleClose}
    />
  )
}

export default ConfirmWriteStoryDialog

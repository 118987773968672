import styled from '@emotion/styled'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { useCurrentPosition } from '@src/hooks'
import { useActivity } from '@src/stackflow'

import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeight } from '../constants/style'
import { useSelectedWatchedPlaceId } from '../hooks'
import { GPSButton } from '../MapView/GPSButton'
import { selectedViewTypeAtomFamily } from '../state'

const RightBottomButtons: React.FC = () => {
  const activity = useActivity()
  const { access } = useCurrentPosition()
  const { selectedWatchedPlaceId } = useSelectedWatchedPlaceId()

  const selectedViewType = useRecoilValue(selectedViewTypeAtomFamily(activity.id))

  const bottomSpacing =
    !selectedWatchedPlaceId || selectedViewType === 'LIST'
      ? 16
      : mapPoiCardHeight + DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS

  return (
    <RightSideButtonWrapper bottomSpacing={bottomSpacing}>
      {selectedViewType === 'MAP' && access === 'granted' && <GPSButton />}
    </RightSideButtonWrapper>
  )
}

const RightSideButtonWrapper = styled.div<{ bottomSpacing: number }>`
  z-index: 98;
  position: fixed;
  right: 1rem;
  bottom: ${CARD_MARGIN}px;
  bottom: calc(${CARD_MARGIN}px + env(safe-area-inset-bottom));
  bottom: calc(${CARD_MARGIN}px + constant(safe-area-inset-bottom));

  display: flex;
  flex-direction: column;
  gap: 12px;

  transform: translateY(-${({ bottomSpacing }) => bottomSpacing}px);

  transition: transform 200ms;
`

export default RightBottomButtons

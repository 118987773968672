import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'
import { useRecoilValue } from 'recoil'
import { v4 } from 'uuid'

import { AnalyticsSessionIds, useLogEvent, useRegisterScreenCommonPayloadEffect } from '@src/components/Analytics'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { mapThemeAtomFamily, selectedFilterIdAtomFamily } from '../state'
import type { ViewType } from '../types'

const initialVisitSessionId = v4()
const initialQuerySessionId = v4()

export const analyticsSessionIds = new AnalyticsSessionIds({
  initialVisitSessionId,
  initialQuerySessionId,
})

const useAnalytics = () => {
  const logEvent = useLogEvent()

  const { viewType, theme: mapThemeId } = useActivityParams<{ viewType?: ViewType; theme?: string }>()
  const screenInstance = useScreenInstance()

  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const filterId = useRecoilValue(selectedFilterIdAtomFamily(screenInstance))

  const querySessionId = analyticsSessionIds.getQuerySessionId()
  const visitSessionId = analyticsSessionIds.getVisitSessionId()

  useRegisterScreenCommonPayloadEffect(
    {
      query_session_id: querySessionId ?? null,
      visit_session_id: visitSessionId ?? null,
      view_type: viewType === 'LIST' ? 'list' : 'map',
      category_id: mapThemeId ?? null,
    },
    [querySessionId, visitSessionId, mapThemeId, viewType]
  )

  return useCallback(
    (name: string, params?: { [key: string]: any }, newQuerySessionId?: string, newVisitSessionId?: string) => {
      if (newQuerySessionId) {
        analyticsSessionIds.setQuerySessionId(newQuerySessionId)
      }

      if (newVisitSessionId) {
        analyticsSessionIds.setVisitSessionId(newVisitSessionId)
      }

      const _params = {
        category_id: mapTheme.id,
        filter_id: filterId,
        view_type: viewType === 'LIST' ? 'list' : 'map',
        query_session_id: (newQuerySessionId || querySessionId) ?? null,
        visit_session_id: (newVisitSessionId || visitSessionId) ?? null,
        ...params,
      }

      logEvent({ name, params: _params })
    },
    [filterId, logEvent, mapTheme.id, querySessionId, viewType, visitSessionId]
  )
}

export default useAnalytics

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { GetPostsByUserIdV2200Response } from '../models';
// @ts-ignore
import { GetPostsV2200Response } from '../models';
/**
 * PostsApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @param {string} xAuthToken 
   * @param {string} userId 유저 아이디
   * @param {number} [page] 페이지 수
   * @param {number} [perPage] 페이지 당 아이템 수
   * @param {'createdAt' | 'upvoteCount'} [sortBy] 정렬 기준
   * @param {string} [types] 조회하고싶은 post type list. undefined로 보낼 경우 모든 타입을 조회합니다.         Available values : STORY_ARTICLE, STORY_COMMENT, POI_REVIEW, BIZ_REVIEW
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getPostsByUserIdV2AxiosParamCreator = async (xAuthToken: string, userId: string, page?: number, perPage?: number, sortBy?: 'createdAt' | 'upvoteCount', types?: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/posts/by-user-id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }

      if (userId !== undefined) {
          localVarQueryParameter['userId'] = userId;
      }

      if (sortBy !== undefined) {
          localVarQueryParameter['sortBy'] = sortBy;
      }

      if (types !== undefined) {
          localVarQueryParameter['types'] = types;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {string} xAuthToken 
   * @param {string} poiId 
   * @param {number} [page] 페이지 수
   * @param {number} [perPage] 페이지 당 아이템 수
   * @param {'createdAt' | 'upvoteCount'} [sortBy] 정렬 기준
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getPostsV2AxiosParamCreator = async (xAuthToken: string, poiId: string, page?: number, perPage?: number, sortBy?: 'createdAt' | 'upvoteCount', options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/posts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }

      if (poiId !== undefined) {
          localVarQueryParameter['poiId'] = poiId;
      }

      if (sortBy !== undefined) {
          localVarQueryParameter['sortBy'] = sortBy;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * PostsApi - functional programming interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {string} userId 유저 아이디
     * @param {number} [page] 페이지 수
     * @param {number} [perPage] 페이지 당 아이템 수
     * @param {'createdAt' | 'upvoteCount'} [sortBy] 정렬 기준
     * @param {string} [types] 조회하고싶은 post type list. undefined로 보낼 경우 모든 타입을 조회합니다.         Available values : STORY_ARTICLE, STORY_COMMENT, POI_REVIEW, BIZ_REVIEW
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getPostsByUserIdV2Fp = async (xAuthToken: string, userId: string, page?: number, perPage?: number, sortBy?: 'createdAt' | 'upvoteCount', types?: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostsByUserIdV2200Response>> => {
        const localVarAxiosArgs = await getPostsByUserIdV2AxiosParamCreator(xAuthToken, userId, page, perPage, sortBy, types, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {string} xAuthToken 
     * @param {string} poiId 
     * @param {number} [page] 페이지 수
     * @param {number} [perPage] 페이지 당 아이템 수
     * @param {'createdAt' | 'upvoteCount'} [sortBy] 정렬 기준
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getPostsV2Fp = async (xAuthToken: string, poiId: string, page?: number, perPage?: number, sortBy?: 'createdAt' | 'upvoteCount', options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPostsV2200Response>> => {
        const localVarAxiosArgs = await getPostsV2AxiosParamCreator(xAuthToken, poiId, page, perPage, sortBy, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * PostsApi - factory interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {string} userId 유저 아이디
     * @param {number} [page] 페이지 수
     * @param {number} [perPage] 페이지 당 아이템 수
     * @param {'createdAt' | 'upvoteCount'} [sortBy] 정렬 기준
     * @param {string} [types] 조회하고싶은 post type list. undefined로 보낼 경우 모든 타입을 조회합니다.         Available values : STORY_ARTICLE, STORY_COMMENT, POI_REVIEW, BIZ_REVIEW
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getPostsByUserIdV2 = (xAuthToken: string, userId: string, page?: number, perPage?: number, sortBy?: 'createdAt' | 'upvoteCount', types?: string, options?: any): AxiosPromise<GetPostsByUserIdV2200Response> => {
        return getPostsByUserIdV2Fp(xAuthToken, userId, page, perPage, sortBy, types, options).then((request) => request());
    }
    /**
     * 
     * @param {string} xAuthToken 
     * @param {string} poiId 
     * @param {number} [page] 페이지 수
     * @param {number} [perPage] 페이지 당 아이템 수
     * @param {'createdAt' | 'upvoteCount'} [sortBy] 정렬 기준
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getPostsV2 = (xAuthToken: string, poiId: string, page?: number, perPage?: number, sortBy?: 'createdAt' | 'upvoteCount', options?: any): AxiosPromise<GetPostsV2200Response> => {
        return getPostsV2Fp(xAuthToken, poiId, page, perPage, sortBy, options).then((request) => request());
    }



import { atomFamily, selector, selectorFamily } from 'recoil'

import { POICurationCategoriesApi } from '@src/apis/generated/poi/api'
import { PoiEventDto } from '@src/apis/generated/poi/models'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/utils/localStorage'

import { generateMapPageRecoilKey } from './keys'

/**
 * Global scope
 * Global scoped store shares cache globally.
 * Given the same key or variable or dependent state, the same value will be returned.
 */
export const curationCategoriesSelector = selector({
  key: generateMapPageRecoilKey('curationCategoriesSelector'),
  get: async () => {
    const response = await POICurationCategoriesApi.getCurationCategoriesV2('daangn-map')

    return response.data.data
  },
})

export const curationCategoriesMapperSelector = selector({
  key: generateMapPageRecoilKey('curationCategoriesMapperSelector'),
  get: ({ get }) => {
    const curationCategories = get(curationCategoriesSelector)

    return curationCategories.reduce(
      (mapper, curationCategory) => ({ ...mapper, [curationCategory.id]: curationCategory }),
      {} as { [id: PoiEventDto['id']]: PoiEventDto }
    )
  },
})

export const mapThemeByCurationCategoryIdSelector = selectorFamily({
  key: generateMapPageRecoilKey('mapThemeByCurationCategoryIdSelector'),
  get:
    (curationCategoryId: number) =>
    async ({ get }) => {
      if (curationCategoryId === -1) return

      const curationCategoriesMapper = get(curationCategoriesMapperSelector)

      return curationCategoriesMapper[curationCategoryId]
    },
})

/**
 * Page scope
 *
 */
export interface MapTheme {
  id: number
  name: string
}
export const mapThemeAtomFamily = atomFamily<MapTheme | PoiEventDto, string>({
  key: generateMapPageRecoilKey('theme'),
  default: {
    id: -1,
    name: '로딩중',
  },
  effects: [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(LOCAL_STORAGE_KEYS.LATEST_MAP_THEME, newValue.id.toString())
      })
    },
  ],
})

export const defaultFilterId = -1
export const selectedFilterIdAtomFamily = atomFamily<number, string>({
  key: generateMapPageRecoilKey('selectedFilterIdAtom'),
  default: defaultFilterId,
})

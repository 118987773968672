import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilValue } from 'recoil'

import NavbarWithBottom from '@src/components/Navigation/TransparentNavScreen/NavbarWithBottom'
import { useNavigator } from '@src/stackflow'

import ButtonShare from './ButtonShare'
import ButtonWatchList from './ButtonWatchList'
import CurationCategoryButtons, { THEME_BUTTONS_HEIGHT } from './CurationCategoryButtons'
import FilterButtons from './FilterButtons'
import { TEMP_FILTER_ITEMS_HEIGHT } from '../constants/style'

const MapNavbar: React.FC = () => {
  const FILTER_ITEMS_HEIGHT = useRecoilValue(TEMP_FILTER_ITEMS_HEIGHT)

  const { pop } = useNavigator()

  const handleClickBack = () => {
    pop()
  }

  return (
    <NavbarWithBottom
      title="당근지도"
      appendRight={
        <ButtonWrapper>
          <ButtonWatchList />
          <ButtonShare />
        </ButtonWrapper>
      }
      appendBottom={
        <StyledFilterSection FILTER_ITEMS_HEIGHT={FILTER_ITEMS_HEIGHT}>
          <CurationCategoryButtons />
          <FilterButtons />
        </StyledFilterSection>
      }
      appendBottomHeight={FILTER_ITEMS_HEIGHT + THEME_BUTTONS_HEIGHT}
      onClickBack={handleClickBack}
    />
  )
}

const StyledFilterSection = styled.div<{ FILTER_ITEMS_HEIGHT: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${({ FILTER_ITEMS_HEIGHT }) => `${FILTER_ITEMS_HEIGHT + THEME_BUTTONS_HEIGHT}px`};
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const Button = styled.button`
  width: 24px;
  height: 24px;
  color: ${vars.$scale.color.gray900};
`

export default MapNavbar

import { atom } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

export const writePoiReviewKey = recoilKeyGenerator('writePoi')

export const imageIdsAtom = atom({
  key: writePoiReviewKey('imageIds'),
  default: [] as string[],
})

export const contentAtom = atom({
  key: writePoiReviewKey('content'),
  default: '',
})

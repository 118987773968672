import React, { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import { ActionSheet } from '@src/components/ActionSheet'
import { APP_SCHEME } from '@src/constants/environmentConstants'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { interestedSingleStoryAtom } from '../state'

export const SingleStoryActionSheet = () => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()

  const { postId, resourceType, resourceId, index } = useRecoilValue(interestedSingleStoryAtom)

  const handleClose = useCallback(() => {
    pop()
  }, [pop])

  const handleClickViewStoryArticle = () => {
    logEvent('click_view_in_community_button', {
      area: 'community_card_more_options',
      position: index + 1,
      source_type: resourceType === 'article' ? 'post' : resourceType === 'comment' ? 'comment' : undefined,
      source_id: resourceId,
    })

    window.location.href = `${APP_SCHEME}story_articles/${postId}`
  }

  const selections = [
    {
      label: '동네생활에서 보기',
      onClick: () => {
        handleClose()
        handleClickViewStoryArticle()
      },
    },
  ]

  return (
    <ActionSheet
      onOutsideClick={handleClose}
      dismissButton={{
        label: '닫기',
        onClick: handleClose,
      }}
      actionButtons={selections}
    />
  )
}

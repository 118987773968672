import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { usePoisStatus } from './usePoisStatus'
import { selectedPoiIdAtomFamily } from '../state/pois'

const useIsCardShown = () => {
  const screenInstance = useScreenInstance()
  const selectedPoiId = useRecoilValue(selectedPoiIdAtomFamily(screenInstance))
  const poisStatus = usePoisStatus()

  return useMemo(
    () => !!selectedPoiId || poisStatus === 'no-pois' || poisStatus === 'no-filtered-pois',
    [poisStatus, selectedPoiId]
  )
}

export default useIsCardShown

import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { watchedByUserMapperSelector } from '@src/shared/watch/store'

import AddPoiButton from './AddPoiButton'
import CurrentPositionButton from './CurrentPositionButton'
import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeightAtom } from '../constants/style'
import { WinterSnackParams } from '../WinterSnackPage'

const RightBottomButtons: React.FC = () => {
  const { mode } = useActivityParams<WinterSnackParams>()
  const mapPoiCardHeight = useRecoilValue(mapPoiCardHeightAtom)
  // Dont wait for pois response to fetch watch data, start async
  useRecoilValueLoadable(watchedByUserMapperSelector)

  const bottomSpacing = (mode === 'edit' ? 0 : mapPoiCardHeight + CARD_MARGIN) + DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS

  return (
    <RightSideButtonWrapper bottomSpacing={bottomSpacing}>
      <CurrentPositionButton />
      {mode !== 'edit' && <AddPoiButton />}
    </RightSideButtonWrapper>
  )
}

const RightSideButtonWrapper = styled.div<{ bottomSpacing: number }>`
  z-index: 98;
  position: fixed;
  right: 1rem;
  transform: translateY(-${({ bottomSpacing }) => bottomSpacing}px);
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  bottom: constant(safe-area-inset-bottom);

  transition: transform 200ms;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

export default RightBottomButtons

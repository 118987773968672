import React, { ComponentType, ReactNode, useContext, useMemo, useState } from 'react'

import { Coordinates } from '@src/types/global'

import { Bounds } from './types'

declare global {
  interface Window {
    kakao: any
  }
}

const CreateMapContext = (map: any, setMap: React.Dispatch<any>) => {
  const kakao = window.kakao

  const createKakaoBounds = (bounds: Bounds) =>
    new kakao.maps.LatLngBounds(
      new kakao.maps.LatLng(bounds.bottomLeft.latitude, bounds.bottomLeft.longitude),
      new kakao.maps.LatLng(bounds.topRight.latitude, bounds.topRight.longitude)
    )

  const getCenter = (): Coordinates | null => {
    if (!map) return null

    const kakaoCenterLatLng = map.getCenter()

    return {
      latitude: kakaoCenterLatLng.getLat(),
      longitude: kakaoCenterLatLng.getLng(),
    }
  }

  type SetCenterOptions = {
    offset?: {
      x?: number
      y?: number
    }
  }
  const setCenter = (coordinates: Coordinates, options?: SetCenterOptions) => {
    if (!map) return

    const center = new kakao.maps.LatLng(coordinates.latitude, coordinates.longitude)

    if (options?.offset) {
      const { x: xOffset = 0, y: yOffset = 0 } = options.offset || {}
      const mapProjection = map.getProjection()
      const { x, y } = mapProjection.containerPointFromCoords(center)
      const point = new kakao.maps.Point(x + xOffset, y + yOffset)
      const modifiedCenter = mapProjection.coordsFromContainerPoint(point)

      return map.setCenter(modifiedCenter)
    }

    return map.setCenter(center)
  }

  const panTo = ({
    coordinates,
    xOffset = 0,
    yOffset = 0,
  }: {
    coordinates: Coordinates
    xOffset?: number
    yOffset?: number
  }) => {
    if (!map || !kakao) return

    const mapProjection = map.getProjection()
    const { x, y } = mapProjection.containerPointFromCoords(
      new kakao.maps.LatLng(coordinates.latitude, coordinates.longitude)
    )

    const point = new kakao.maps.Point(x + xOffset, y + yOffset)

    return map.panTo(mapProjection.coordsFromContainerPoint(point))
  }

  const getBoundsFromPoints = (points: Coordinates[]) => {
    if (!map || !kakao) return null

    const bounds = new kakao.maps.LatLngBounds()

    points.forEach((point) => {
      bounds.extend(new kakao.maps.LatLng(point.latitude, point.longitude))
    })

    const modifiedBounds = {
      bottomLeft: {
        latitude: bounds.getSouthWest().getLat(),
        longitude: bounds.getSouthWest().getLng(),
      },
      topRight: {
        latitude: bounds.getNorthEast().getLat(),
        longitude: bounds.getNorthEast().getLng(),
      },
    }

    return modifiedBounds
  }

  const getIsBoundsContained = (containerBounds: Bounds, subsetBounds: Bounds) => {
    if (!map || !kakao) return false

    const containerKakaoBounds = createKakaoBounds(containerBounds)
    const subsetKakaoBounds = createKakaoBounds(subsetBounds)

    return (
      containerKakaoBounds.contain(subsetKakaoBounds.getSouthWest()) &&
      containerKakaoBounds.contain(subsetKakaoBounds.getNorthEast())
    )
  }

  interface Margin {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  interface GetBounds {
    margin?: Margin
  }

  const getBounds = (props?: GetBounds): Bounds | null => {
    if (!map || !kakao) return null

    const bounds = map.getBounds()
    const sw = bounds.getSouthWest()
    const ne = bounds.getNorthEast()

    const mapProjection = map.getProjection()

    const { x: swPointX, y: swPointY } = mapProjection.containerPointFromCoords(sw)
    const { x: nePointX, y: nePointY } = mapProjection.containerPointFromCoords(ne)

    const modifiedSw = mapProjection.coordsFromContainerPoint(
      new kakao.maps.Point(swPointX - (props?.margin?.left ?? 0), swPointY - (props?.margin?.bottom ?? 0))
    )
    const modifiedNe = mapProjection.coordsFromContainerPoint(
      new kakao.maps.Point(nePointX - (props?.margin?.right ?? 0), nePointY - (props?.margin?.top ?? 0))
    )

    const modifiedBounds = {
      bottomLeft: {
        latitude: modifiedSw.getLat(),
        longitude: modifiedSw.getLng(),
      },
      topRight: {
        latitude: modifiedNe.getLat(),
        longitude: modifiedNe.getLng(),
      },
    }

    return modifiedBounds
  }

  const setBounds = (bounds: Bounds) => {
    if (!map || !kakao) return

    const newBounds = createKakaoBounds(bounds)

    return map.setBounds(newBounds, 10, 10, 10, 10)
  }

  const getLevel = () => {
    if (!map || !kakao) return

    return map.getLevel()
  }

  const setLevel = (level: number) => {
    if (!map || !kakao) return

    return map.setLevel(level)
  }

  /**
   *
   * 지도를 표시하는 HTML element의 크기를 변경한 후에는 반드시 이 함수를 호출해야 한다.
   * 단, window의 resize 이벤트에 대해서는 자동으로 호출한다.
   * https://apis.map.kakao.com/web/documentation/#Map_relayout
   */
  const relayout = () => {
    if (!map) return

    return map.relayout()
  }

  return {
    kakao,
    map,
    setMap,
    getCenter,
    setCenter,
    panTo,
    getBounds,
    setBounds,
    getLevel,
    setLevel,
    getBoundsFromPoints,
    getIsBoundsContained,
    relayout,
  }
}

export const MapContext = React.createContext<ReturnType<typeof CreateMapContext>>(null as any)

export const MapProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // const kakao = window.kakao
  const [map, setMap] = useState<any>()

  const value = useMemo(() => CreateMapContext(map, setMap), [map])

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>
}
export function withMapErrorFallback<P extends object>(
  Component: ComponentType<P>,
  FallbackComponent?: ComponentType<P>
) {
  const WithMapErrorFallback: React.FC<P> = (props: P) => {
    if (!!FallbackComponent && !window.kakao) return <FallbackComponent {...props} />

    return <Component {...props} />
  }

  return WithMapErrorFallback
}

export function withMap<P extends object>(Component: ComponentType<P>, FallbackComponent?: ComponentType<P>) {
  const WithMap: React.FC<P> = (props: P) => {
    return (
      <MapProvider>
        <Component {...props} />
      </MapProvider>
    )
  }

  return withMapErrorFallback(
    WithMap,
    FallbackComponent
      ? (props: P) => (
          <MapProvider>
            <FallbackComponent {...props} />
          </MapProvider>
        )
      : undefined
  )
}

export const useMap = () => useContext(MapContext)

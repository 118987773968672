import { vars } from '@seed-design/design-token'
import { ActivityComponentType } from '@stackflow/react'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { Poi } from '@src/apis/generated/poi/models'
import { bizProfileSDK } from '@src/bizProfileSDK'
import { bridge } from '@src/bridge'
import { PageLoading } from '@src/components/Loading'
import { TransparentNavScreen } from '@src/components/Navigation/TransparentNavScreen'
import { APP_SCHEME, WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import { useActivity, useNavigator } from '@src/stackflow'

import { IMAGE_CONTAINER_HEIGHT } from './constants'
import Footer from './Footer'
import Header from './Header/Header'
import NavbarButtons from './Navbar/NavbarButtons'
import FetchAndSyncPosts from './PostsTab/FetchAndSyncPosts'
import { imagesByPoiIdSelectorFamily, PostsTabKey, TabKey, tabs } from './state'
import TabPanel from './TabPanel'

export type ActivityParams = {
  poiId: string
  spotlightPostId?: string
  story_article_id?: string
  story_comment_id?: string
  tab?: TabKey | PostsTabKey
}

const PoiDetailPage: ActivityComponentType<ActivityParams> = ({ params: { poiId, ...params } }) => {
  const { pop } = useNavigator()
  const { isRoot } = useActivity()

  const images = useRecoilValue(imagesByPoiIdSelectorFamily(poiId))
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const poiRef = useRef<Poi | null>(poi)

  useEffect(() => {
    if (!poiId) throw new Error('poi id is not defined')

    bizProfileSDK.event.bizProfileCreateByPOI.on(({ poiId }) => {
      if (poiId !== poiRef.current?.id) return

      if (isRoot) {
        document.onvisibilitychange = () => {
          bridge.router.close()
        }
      } else {
        pop()
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!poi) return

    poiRef.current = poi
    if (poi.bizAccountId) {
      if (isRoot) {
        bridge.router.replace({
          app: `${WEBVIEW_BASE_URL}business-platform/home`,
          path: `/biz_accounts/${poi.bizAccountId}/viewer/home?entry=native.story.tagged_poi&referer=entry=native.story.tagged_poi`,
          navbar: false,
          scrollable: false,
        })
      } else {
        pop()
        window.location.href = `${APP_SCHEME}minikarrot/router?app=${WEBVIEW_BASE_URL}business-platform/home&path=/biz_accounts/${poi.bizAccountId}/viewer/home?entry=native.story.tagged_poi&referer=entry=native.story.tagged_poi`
      }
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poi])

  if (!poi || isEmpty(poi)) return <PageLoading />

  if (poi.bizAccountId) return null

  return (
    <TransparentNavScreen
      appBar={{ title: poi.name ?? '' }}
      breakpoint={images.items.length > 0 ? IMAGE_CONTAINER_HEIGHT : 0}
      appendRight={({ iconColor }) => <NavbarButtons poiData={poi} iconColor={iconColor} />}
      backgroundColor={vars.$semantic.color.paperBackground}>
      <Header tabs={tabs} />
      <TabPanel tabs={tabs} initialTabKey={params.tab} />
      <FetchAndSyncPosts />
      <Footer />
    </TransparentNavScreen>
  )
}

export default PoiDetailPage

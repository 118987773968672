import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'

import { useNavigator, useStepNavigator } from '@src/stackflow'

import CategorySuggestionModal from './Dialogs/CategorySuggestionModal'
import { AlertType } from './index'

interface Props {
  onAddCategory: () => void | Promise<any>
}

const NoDesiredResult: React.FC<Props> = ({ onAddCategory }) => {
  const params = useActivityParams<{ alert?: AlertType }>()
  const { pop } = useNavigator()
  const { stepPush } = useStepNavigator('search_category')

  return (
    <>
      <StyledNoResult>
        찾으시는 카테고리가 없나요?{' '}
        <a
          onClick={() => {
            onAddCategory()
            stepPush({ alert: 'confirmCategorySuggestion' })
          }}>
          추가 요청하기
        </a>
      </StyledNoResult>
      {params.alert === 'confirmCategorySuggestion' && <CategorySuggestionModal onConfirm={pop} />}
    </>
  )
}

const StyledNoResult = styled.div`
  ${vars.$semantic.typography.bodyL2Regular}
  margin: -0.25rem 0 0;
  padding: 1rem;
  color: ${vars.$scale.color.gray700};
  font-weight: 400;
  a {
    color: ${vars.$scale.color.blue800};
    font-weight: 700;
  }
`

export default NoDesiredResult

// returns -1 if timeA is earlier than timeB, 1 if timeA is later than timeB, 0 if timeA is same as timeB
export const compareTimeText = (timeA: string, timeB: string) => {
  const [hourA, minuteA] = timeA.split(':').map((time) => parseInt(time))
  const [hourB, minuteB] = timeB.split(':').map((time) => parseInt(time))
  if (hourA < hourB) {
    return -1
  } else if (hourA > hourB) {
    return 1
  } else {
    if (minuteA < minuteB) {
      return -1
    } else if (minuteA > minuteB) {
      return 1
    } else {
      return 0
    }
  }
}

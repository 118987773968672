import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { atom, useRecoilState, useRecoilValue } from 'recoil'

import ShoppingBagImage from '@src/assets/images/daangn_big_shopping_bag.png'
import { APP_SCHEME, IS_PROD } from '@src/constants/environmentConstants'
import { checkInRegionInfoAtom } from '@src/store'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'
import { isDateWithinAPeriod } from '@src/utils/date'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/utils/localStorage'

import { useAnalytics } from './hooks'

const generateRecoilKey = recoilKeyGenerator('event_banner')

type BannerVisibility = 'visible' | 'hidden'
const bannerVisibilityAtom = atom<BannerVisibility>({
  key: generateRecoilKey('visibility'),
  default: (async () => {
    const visibility = await localStorage.getItem(
      LOCAL_STORAGE_KEYS.MARKETING_EVENT_BANNER_GOOD_RESTAURANT_IN_TOWN_RELAY
    )
    if (!visibility) {
      return 'visible'
    } else {
      return visibility as BannerVisibility
    }
  })(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) =>
        localStorage.setItem(LOCAL_STORAGE_KEYS.MARKETING_EVENT_BANNER_GOOD_RESTAURANT_IN_TOWN_RELAY, newValue)
      )
    },
  ],
})

const EVENT_PAGE_SCHEME = `${APP_SCHEME}community-web/router?remote=https%3A%2F%2Fcommunity.${
  IS_PROD ? '' : 'alpha.'
}daangn.com%2Fmy-town%3Fprevious_screen_name%3Dplace.daangn_map%23participation&navbar=true&scrollable=true&enable_safe_area_insets=true`

const EVENT_START_DATE = '2023-02-08 08:00'
const EVENT_END_DATE = '2023-02-14 23:50'

interface Props {
  viewType: 'map' | 'list'
  onClickBanner?: React.MouseEventHandler<HTMLDivElement>
  onClickClose?: React.MouseEventHandler<HTMLButtonElement>
}
export const EventBanner = ({ viewType, onClickBanner, onClickClose }: Props) => {
  const logEvent = useAnalytics()

  const [bannerVisibility, setBannerVisibility] = useRecoilState(bannerVisibilityAtom)
  const checkInRegion = useRecoilValue(checkInRegionInfoAtom)

  const handleClickBanner = (e: React.MouseEvent<HTMLDivElement>) => {
    logEvent('click_event_banner')

    onClickBanner?.(e)

    window.location.href = EVENT_PAGE_SCHEME
  }
  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onClickClose?.(e)
    setBannerVisibility('hidden')
  }

  const isEventPeriod = isDateWithinAPeriod({ startDate: EVENT_START_DATE, endDate: EVENT_END_DATE })

  // 배너를 숨기는 조건
  // if (IS_PROD) return null
  if (bannerVisibility === 'hidden' || !isEventPeriod) return null

  return (
    <Container viewType={viewType} onClick={handleClickBanner}>
      <Image src={ShoppingBagImage} />
      <div>
        <Title>동네맛집 릴레이 🍽️</Title>
        <Description>
          지금 {checkInRegion.name} 맛집을 공유하면 추첨을 통해 &apos;당근 큰 장바구니&apos;를 드려요!
        </Description>
      </div>
      <CloseButton onClick={handleClose}>
        <IconCloseRegular width="100%" height="100%" />
      </CloseButton>
    </Container>
  )
}

const Container = styled.div<Pick<Props, 'viewType'>>`
  display: flex;
  width: calc(100vw - 2rem);
  padding: 0.75rem;
  border-radius: 0.375rem;
  ${({ viewType }) => (viewType === 'list' ? list : map)}
`
const map = css`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: ${vars.$semantic.color.paperDefault};
`
const list = css`
  margin: 0.25rem 1rem 0.75rem;
  background-color: ${vars.$semantic.color.paperContents};
`
const Image = styled.img`
  margin: 0 0.625rem 0 0;
  width: 4.375rem;
  height: 3.625rem;
  min-width: 4.375rem;
  min-height: 3.625rem;
`

const Title = styled.h3`
  margin: 0 0 0.125rem 0;
  color: ${vars.$semantic.color.primary};
  ${vars.$semantic.typography.label5Bold};
`
const Description = styled.p`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.caption1Regular};
`
const CloseButton = styled.button`
  margin: 0 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  color: ${vars.$scale.color.gray900};
`

import styled from '@emotion/styled'
import IconCopyRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCopyRegular'
import IconLocationRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconLocationRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { bridge } from '@src/bridge'
import { clipboard } from '@src/utils/clipboard'

import { useAnalytics } from '../../hooks'

interface Props {
  roadAddress?: string
  jibunAddress?: string
  addressExtra?: string
}

export const Address: React.FC<Props> = ({ roadAddress, jibunAddress, addressExtra }) => {
  const logEvent = useAnalytics()
  const handleCopyAddress = (text: string) => {
    logEvent('click_copy_address_button')
    clipboard.writeText({
      text,
      onSuccess: () => bridge.toast.open({ body: '주소가 복사되었어요.' }),
    })
  }

  const address = roadAddress ?? jibunAddress

  return (
    <StyledAddressInformation>
      <IconLocationRegular className="address__icon" height={18} width={18} />
      <div>
        <div className="address__main">
          <span className="main__text">
            {address} {addressExtra}
          </span>
          <span className="main__copy">
            <button className="copy-button" onClick={() => handleCopyAddress(`${address} ${addressExtra}`)}>
              <IconCopyRegular className="copy-button-icon" />
              복사
            </button>
          </span>
        </div>
        {roadAddress && jibunAddress && <p className="address__sub">{jibunAddress}</p>}
      </div>
    </StyledAddressInformation>
  )
}

const StyledAddressInformation = styled.div`
  padding: 4px;
  margin: 0 0 12px;
  display: flex;
  flex-flow: row;

  .address__icon {
    position: relative;
    top: 3px;
  }
  .address__main {
    display: block;
    margin: 0 0 0 12px;
    .main__text {
      ${vars.$semantic.typography.subtitle1Regular}
    }
    .main__copy {
      display: inline-block;
      vertical-align: sub;
      margin: 0 0 0 4px;
      .copy-button {
        display: flex;
        align-items: center;
        width: fit-content;
        min-width: 44px;
        /** @todo: seed-design */
        font-size: 14px;
        font-weight: 400;
        color: ${vars.$scale.color.blue800};
        .copy-button-icon {
          margin: 0 2px 0 0;
          width: 18px;
          height: 18px;
          path {
            fill: ${vars.$scale.color.blue800};
          }
        }
      }
    }
  }
  .address__sub {
    ${vars.$semantic.typography.caption1Regular}
    display: block;
    margin: 4px 0 0 12px;
    color: ${vars.$scale.color.gray600};
  }
`

import { Poi } from '@daangn/local-business-network/lib/poi'
import { ActivityComponentType, useActivityParams } from '@stackflow/react'
import React, { useCallback, useState } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { bizSDK } from '@src/bizSDK'
import { bridge } from '@src/bridge'
import { errorMessage } from '@src/components/Error/constants'
import { useToast } from '@src/components/Toast'
import { useNavigator, useStepNavigator } from '@src/stackflow'
import { userInfoAtom } from '@src/store'
import { EMPTY_SELECTED_POI, SelectedPoiInfo, usePoiInputSelectionState } from '@src/widgets/PoiInput/state'

import { BizManagerSelfReviewAlert, BlockedBizReviewAlert, BlockedBizReviewUpdateDialog } from './Modal'
import { SearchPoi } from './SearchPoi'
import type { DialogType } from './types'
import { BizReviewStatus, myBizReviewSelectorFamily } from '../WritePoiReview/state/bizReview'

export type ActivityParams = {
  variation?: 'communityTag'
  alert?: DialogType
  keywords?: string
}
export const SearchPoiPage: ActivityComponentType<ActivityParams> = ({ params: { variation, alert } }) => {
  const { entry } = useActivityParams<{ entry: string }>()
  const { pop } = useNavigator()
  const { stepPush, stepPop } = useStepNavigator('search_poi')
  const { showToast } = useToast()
  const [, setSelectedRecoilState] = usePoiInputSelectionState()

  const userInfo = useRecoilValue(userInfoAtom)

  const [selectedPoi, setSelectedPoi] = useState<SelectedPoiInfo>(EMPTY_SELECTED_POI)

  const handleSelectPoi = useCallback(
    ({ poiId, poiName, bizAccountId }: { poiId: string; poiName: string; bizAccountId?: string }) => {
      setSelectedRecoilState({
        id: poiId,
        name: poiName,
        bizAccountId,
      })

      pop()
    },
    [pop, setSelectedRecoilState]
  )

  const handleBizManager = useCallback(
    async ({ poiId, poiName, bizAccountId }: { poiId: string; poiName: string; bizAccountId: string }) => {
      const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)
        await bizSDK.profileAPI.reqIsValidBizAccountMember(bizAccountId, userInfo?.authToken)

        stepPush({ alert: 'BizManagerSelfReviewAlert' })
      } catch (err) {
        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        /** execute if current user is not one of the biz-managers */
        handleSelectPoi({ poiId, poiName, bizAccountId })
      }
    },
    [handleSelectPoi, showToast, stepPush, userInfo?.authToken]
  )

  const handleBizReview = useCallback(
    ({
      status,
      poiId,
      poiName,
      bizAccountId,
    }: {
      status: BizReviewStatus
      poiId: string
      poiName: string
      bizAccountId: string
    }) => {
      switch (status) {
        case 'BLOCKED':
          return stepPush({ alert: 'BlockedBizReviewUpdateDialog' })
        case 'POST_STOPPED':
          return stepPush({ alert: 'BlockedBizReviewAlert' })
        case 'BLOCKED_DELETED':
          return stepPush({ alert: 'BlockedBizReviewAlert' })
        case 'NORMAL':
        case 'DELETED':
          return handleBizManager({ poiId, poiName, bizAccountId })
        case 'ERROR':
          return showToast({
            text: errorMessage.TEMPORARY_NOT_WORKING_MESSAGE,
            duration: 'long',
          })
      }
    },
    [handleBizManager, showToast, stepPush]
  )

  /**
   * onSelectPoi action for poi review & default
   */
  const handleClickSelectPoi = useRecoilCallback(
    ({ snapshot }) =>
      async (poi: Poi) => {
        const { id: poiId, name: poiName, bizAccountId } = poi
        setSelectedPoi({ id: poiId!, name: poiName!, bizAccountId: bizAccountId ?? undefined })

        if (entry === 'poi_review' && bizAccountId) {
          const { status } = await snapshot.getPromise(myBizReviewSelectorFamily(bizAccountId))

          return handleBizReview({ status, poiId: poiId!, poiName: poiName!, bizAccountId })
        }

        handleSelectPoi({ poiId: poi.id!, poiName: poi.name!, bizAccountId: poi.bizAccountId ?? undefined })
      },
    [handleBizReview, entry, handleSelectPoi]
  )

  /**
   * onSelectPoi action for community tagging
   */
  const handleSelectPoiForCommunityTagging = (poi: Poi) => {
    bridge.stream.emit('SELECT_POI_TAG_FROM_WEBVIEW', {
      poi_id: poi.id,
      poi_name: poi.name,
    })
  }

  return (
    <>
      {(() => {
        switch (variation) {
          case 'communityTag':
            return <SearchPoi onSelectPoi={handleSelectPoiForCommunityTagging} />
          default:
            return <SearchPoi onSelectPoi={handleClickSelectPoi} />
        }
      })()}
      {alert === 'BizManagerSelfReviewAlert' && <BizManagerSelfReviewAlert onConfirm={stepPop} />}
      {alert === 'BlockedBizReviewUpdateDialog' && (
        <BlockedBizReviewUpdateDialog
          bizProfileName={selectedPoi.name}
          onConfirm={() =>
            handleSelectPoi({
              poiId: selectedPoi.id,
              poiName: selectedPoi.name,
              bizAccountId: selectedPoi.bizAccountId,
            })
          }
          onClose={stepPop}
        />
      )}
      {alert === 'BlockedBizReviewAlert' && (
        <BlockedBizReviewAlert bizProfileName={selectedPoi.name} onConfirm={stepPop} />
      )}
    </>
  )
}

import { useSetRecoilState } from 'recoil'
import { v4 } from 'uuid'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { watchedByUserRefreshTokenAtomFamily } from '../state/selectedWatchedPoi'

export const useResetWatchedByUser = () => {
  const screenInstance = useScreenInstance()

  const resetWatchedByUserToken = useSetRecoilState(watchedByUserRefreshTokenAtomFamily(screenInstance))
  return () => {
    resetWatchedByUserToken(v4())
  }
}

import { useScrollToTopEffect } from '@daangn/stackflow-plugin-scroll-to-top'
import { vars } from '@seed-design/design-token'
import { AppScreen, useAppScreen } from '@stackflow/plugin-basic-ui'
import React from 'react'

import { PropOf } from '@src/types'

import { THEME } from './styles'

export interface ScreenProps {
  backgroundColor?: PropOf<typeof AppScreen>['backgroundColor']
  dimBackgroundColor?: PropOf<typeof AppScreen>['dimBackgroundColor']
  appBar?: PropOf<typeof AppScreen>['appBar']
  children: React.ReactNode
}

export const Screen = ({ backgroundColor, dimBackgroundColor, appBar, children }: ScreenProps) => {
  return (
    <AppScreen
      backgroundColor={backgroundColor}
      dimBackgroundColor={dimBackgroundColor}
      appBar={
        appBar && {
          borderColor: THEME === 'cupertino' ? vars.$semantic.color.divider3 : vars.$semantic.color.divider2,
          textColor: vars.$scale.color.gray900,
          iconColor: vars.$scale.color.gray900,
          ...appBar,
        }
      }>
      <ScrollToTop>{children}</ScrollToTop>
    </AppScreen>
  )
}

const ScrollToTop = ({ children }: { children: React.ReactNode }) => {
  const { scroll } = useAppScreen()

  useScrollToTopEffect(() => {
    console.log('scroll to top')
    scroll({ top: 0 })
  }, [])

  return <>{children}</>
}

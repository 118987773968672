import styled from '@emotion/styled'
import IconThumbUpFill from '@karrotmarket/karrot-ui-icon/lib/react/IconThumbUpFill'
import { vars } from '@seed-design/design-token'
import { captureException } from '@sentry/react'
import debounce from 'lodash/debounce'
import React, { useMemo, useRef, useState, useLayoutEffect } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { POIReactionsApi } from '@src/apis/generated/poi/api'
import { errorMessage } from '@src/components/Error/constants'
import { useToast } from '@src/components/Toast'
import { useIsMounted } from '@src/hooks'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { convertResourceTypeToSourceType } from '@src/utils/convert'

import { useAnalytics } from './hooks'
import { Reaction, reactionItemSelector } from './state'

interface Props {
  resourceType: Reaction['resourceType']
  resourceId: Reaction['resourceId']
  type: Reaction['type']
}

const UpvoteButton: React.FC<Props> = ({ resourceType, resourceId, type }) => {
  const isMounted = useIsMounted()
  const logEvent = useAnalytics()
  const { showToast } = useToast()

  /**
   * client reaction state
   */
  const reactionRecoilState = reactionItemSelector({ resourceType, resourceId, type })

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const reaction = useRecoilValue(reactionRecoilState)

  const isInitializedRef = useRef<boolean>(false)

  /**
   * synced state with server data
   */
  const [syncedReaction, setSyncedReaction] = useState<{
    isUpvoted: boolean
    upvoteCount: number
  }>({
    isUpvoted: false,
    upvoteCount: 0,
  })

  const handleUpvoteFetching = useRecoilCallback(
    ({ set }) =>
      ({ prevUpvote }: { prevUpvote: boolean }) => {
        const nextUpvote = !prevUpvote

        if (syncedReaction.isUpvoted === nextUpvote) return

        const upvote = async () => {
          try {
            await POIReactionsApi.createReactionV2(userInfo!.authToken, {
              resourceId,
              resourceType,
              type,
            })

            setSyncedReaction((prev) => ({
              isUpvoted: true,
              upvoteCount: prev.upvoteCount + 1,
            }))

            /** @deprecated */
            logEvent('click_upvote_button', {
              source_type: convertResourceTypeToSourceType(resourceType),
              source_id: resourceId,
            })

            const _resource = convertResourceTypeToSourceType(resourceType)
            if (_resource === 'poi_review') {
              logEvent('click_review_like_button', {
                review_id: resourceId,
              })
            }
            if (_resource === 'community_post' || _resource === 'community_comment') {
              logEvent('click_story_like_button', {
                source_type: _resource === 'community_post' ? 'article' : 'comment',
                source_id: resourceId,
              })
            }
          } catch (err) {
            captureException(err)
            set(reactionRecoilState, (prev) => {
              return {
                ...prev,
                isReactedByMe: false,
                reactionCount: syncedReaction.upvoteCount,
              }
            })
            showToast({
              text: errorMessage.PLEASE_RETRY_MESSAGE,
              duration: 'short',
            })
          }
        }

        const cancelUpvote = async () => {
          try {
            await POIReactionsApi.deleteReactionV2(userInfo!.authToken, {
              resourceId,
              resourceType,
            })

            setSyncedReaction((prev) => ({
              isUpvoted: false,
              upvoteCount: prev.upvoteCount - 1,
            }))

            logEvent('click_cancel_upvote_button', {
              source_type: convertResourceTypeToSourceType(resourceType),
              source_id: resourceId,
            })
          } catch (err) {
            captureException(err)
            set(reactionRecoilState, (prev) => {
              return {
                ...prev,
                isReactedByMe: true,
                reactionCount: syncedReaction.upvoteCount,
              }
            })
            showToast({
              text: errorMessage.PLEASE_RETRY_MESSAGE,
              duration: 'short',
            })
          }
        }

        nextUpvote ? upvote() : cancelUpvote()
      },
    [
      logEvent,
      reactionRecoilState,
      resourceId,
      resourceType,
      showToast,
      syncedReaction.isUpvoted,
      syncedReaction.upvoteCount,
      type,
      userInfo,
    ]
  )

  const debouncedHandleUpvoteFetching = useMemo(() => debounce(handleUpvoteFetching, 300), [handleUpvoteFetching])

  const handleUpvote = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const prevUpvote = snapshot.getLoadable(reactionRecoilState).getValue().isReactedByMe

        set(reactionRecoilState, (prev) => {
          return {
            ...prev,
            isReactedByMe: prevUpvote ? false : true,
            reactionCount: prevUpvote ? prev.reactionCount + -1 : prev.reactionCount + 1,
          }
        })

        debouncedHandleUpvoteFetching({ prevUpvote })
      },
    [debouncedHandleUpvoteFetching, reactionRecoilState]
  )

  useLayoutEffect(() => {
    /**
     * set initial server data to state for sync
     */
    if (isInitializedRef.current === true) return

    if (isMounted()) {
      setSyncedReaction({
        isUpvoted: reaction.isReactedByMe,
        upvoteCount: reaction.reactionCount,
      })

      isInitializedRef.current = true
    }
  }, [isMounted, reaction.isReactedByMe, reaction.reactionCount])

  return (
    <Button onClick={handleUpvote} isUpvoted={reaction.isReactedByMe}>
      <IconThumbUpFill className="icon" />
      도움돼요{reaction.reactionCount > 0 && ` ${reaction.reactionCount}`}
    </Button>
  )
}

const Button = styled.button<{ isUpvoted: boolean }>`
  width: fit-content;
  padding: 0.375rem 0.75rem;
  background-color: ${({ isUpvoted }) => (isUpvoted ? vars.$scale.color.carrotAlpha50 : 'transparent')};
  border: 1px solid ${({ isUpvoted }) => (isUpvoted ? vars.$semantic.color.primary : vars.$scale.color.gray300)};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: ${({ isUpvoted }) => (isUpvoted ? vars.$semantic.color.primary : vars.$scale.color.gray700)};

  .icon {
    width: 16px;
    height: 16px;
    color: ${({ isUpvoted }) => (isUpvoted ? vars.$semantic.color.primary : vars.$scale.color.gray600)};
  }
`

export default UpvoteButton

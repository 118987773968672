import { vars } from '@seed-design/design-token'
import { receive } from '@stackflow/compat-await-push'
import React from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'

import IconWatchList from '@src/assets/icons/IconWatchListRegular'
import { generateGlobalNudgeKey } from '@src/components/Nudge/generateNudgeKey'
import { uniqueKeysForNudge } from '@src/components/Nudge/uniqueKeysForNudge'
import { useNudge } from '@src/components/Nudge/useNudge'
import { useWatch } from '@src/shared/watch/hooks'
import { useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { Button } from './Navbar'
import { useFetchPois } from '../hooks'
import { curationCategoriesMapperSelector, isInitialFetchAtomFamily, selectedPoiIdAtomFamily } from '../state'

const ButtonWatchList: React.FC = () => {
  const { push } = useNavigator()
  const { hideNudge } = useNudge()
  const { resetWatchedByUser } = useWatch()
  const { fetchPois } = useFetchPois()
  const screenInstance = useScreenInstance()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const curationCategoriesMapper = useRecoilValue(curationCategoriesMapperSelector)
  const resetIsInitialFetch = useResetRecoilState(isInitialFetchAtomFamily(screenInstance))
  const resetSelectedPoiId = useResetRecoilState(selectedPoiIdAtomFamily(screenInstance))

  const handleClickWatchList = async () => {
    hideNudge(generateGlobalNudgeKey(uniqueKeysForNudge.watchPage, userInfo?.id ?? 0))
    resetWatchedByUser()

    const data = await receive<{ theme: number }>({
      activityId: push('place_watch', {}).activityId,
    })
    if (data?.theme) {
      resetIsInitialFetch()
      resetSelectedPoiId()
      fetchPois({
        mapTheme: curationCategoriesMapper[data.theme],
        centerPosition: 'reset',
      })
    }
  }

  return (
    <Button onClick={handleClickWatchList}>
      <IconWatchList color={vars.$scale.color.gray900} />
    </Button>
  )
}

export default ButtonWatchList

import { FormikErrors } from 'formik'

import { compareTimeText } from '@src/utils/date'

import type { Field, FormValues } from '../types'

export const validateOperatingInformation = (
  value: FormValues['operatingInformation'],
  field?: Field<'operatingInformation'>
) => {
  const errors = {} as FormikErrors<FormValues>

  if (field) {
    if (
      value &&
      value.businessHours[0] &&
      value.businessHours[1] &&
      compareTimeText(value.businessHours[0], value.businessHours[1]) === 1
    ) {
      errors['operatingInformation'] = '오픈 시간이 마감 시간보다 늦을 수 없어요.'
    }
  }

  return errors
}

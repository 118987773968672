import React from 'react'
import { useRecoilValue } from 'recoil'

import { useKakaoMap } from '@src/components/Map/KakaoMap'
import MapViewTypeToggleButton from '@src/components/Map/ViewTypeToggleButton'
import { useToast } from '@src/components/Toast'
import { useStepNavigator } from '@src/stackflow'

import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeightAtom } from '../constants/style'
import { useAnalytics, useIsCardShown } from '../hooks'
import type { ViewType } from '../types'

interface Props {
  viewType: ViewType
}
const ViewTypeToggleButton = ({ viewType }: Props) => {
  const logEvent = useAnalytics()
  const { stepPush, stepPop } = useStepNavigator('daangn_map')

  const isCardShown = useIsCardShown()
  const { showToast } = useToast()
  const { kakao } = useKakaoMap()

  const mapPoiCardHeight = useRecoilValue(mapPoiCardHeightAtom)

  const bottomSpacing =
    !isCardShown || viewType === 'LIST' ? 16 : mapPoiCardHeight + DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS

  const handleToggleViewType = () => {
    if (!kakao) {
      showToast({
        text: '오류가 발생하여 일시적으로 지도를 이용할 수 없어요.',
        duration: 'short',
      })

      return
    }

    switch (viewType) {
      case 'MAP':
        logEvent('click_view_list_button')
        stepPush({ viewType: 'LIST' })

        return
      case 'LIST':
        logEvent('click_view_map_button')
        stepPop()

        return
    }
  }

  return (
    <MapViewTypeToggleButton
      viewType={viewType}
      bottomSpacing={bottomSpacing}
      cardMargin={CARD_MARGIN}
      onClick={handleToggleViewType}
    />
  )
}

export default ViewTypeToggleButton

import { atom, atomFamily } from 'recoil'

import { POIReviewsApi } from '@src/apis/generated/poi/api'
import { GetReviewsByIdsV2200ResponseAllOf } from '@src/apis/generated/poi/models'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

import { detailPagePrefix } from '../constants/recoilKey'

type ReviewResponseType = GetReviewsByIdsV2200ResponseAllOf
type ReviewRequestType = Parameters<typeof POIReviewsApi.getReviewsByPoiIdV2>
type ReviewRequestSortType = NonNullable<ReviewRequestType['2']>

type PageSerializeParam = { poiId: string; screenInstance: string; sortingOption: ReviewRequestSortType }

const generateKey = recoilKeyGenerator([detailPagePrefix, 'review'])

/**
 * Page scope
 */
const reviewSortingOptionAtomFamily = atomFamily<ReviewRequestSortType, Omit<PageSerializeParam, 'sortingOption'>>({
  key: generateKey('sorting-option'),
  default: 'latestCreation',
})

export type { ReviewResponseType, PageSerializeParam as ReviewPageSerializeParam }
export { reviewSortingOptionAtomFamily }

export const interestedSingleReviewAtom = atom<{
  reviewId: string
  reviewUserId: number
}>({
  key: generateKey('interestedSingleReview'),
  default: undefined,
})

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import defaultProfileImage from '@src/assets/images/profile_default.png'
import { getDateDiffFromNow } from '@src/utils/date'

import { usePoi } from '../hooks'

const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000
interface Props {
  poiId: string
}
export const LatestVisit = ({ poiId }: Props) => {
  const [poi] = usePoi(poiId)
  const operatingStatus = poi?.poi.operatingStatus ?? null

  const contributedAt = operatingStatus?.contributedAt
    ? getDateDiffFromNow(new Date(operatingStatus.contributedAt))
    : undefined
  const contributedWithin30Days = !!contributedAt && contributedAt.diff < THIRTY_DAYS_IN_MS
  const visited = !!operatingStatus?.contributedAt
  const failedVisit = operatingStatus?.status === 'closed'

  const renderText = () => {
    if (visited && contributedWithin30Days) {
      if (failedVisit) {
        return `${contributedAt.text} 전 방문에 실패한 이웃이 있어요.`
      } else {
        return (
          <>
            <ContributedAt>{contributedAt.text}</ContributedAt> 전에 방문한 이웃이 있어요.
          </>
        )
      }
    } else {
      return `최근 한 달간 방문 인증이 없어요.`
    }
  }

  return (
    <Container>
      {!failedVisit && contributedWithin30Days && (
        <Avatar src={operatingStatus?.user?.profileImageUrl || defaultProfileImage} />
      )}
      <Text>{renderText()}</Text>
    </Container>
  )
}

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`
const Avatar = styled.img`
  margin: 0 0.25rem 0 0;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  align-self: center;
`
const Text = styled.span`
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.label5Regular};
`
const ContributedAt = styled.span`
  color: ${vars.$semantic.color.primary};
`

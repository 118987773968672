import { useActivityParams } from '@stackflow/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useActivity, useStepNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { sortPoisByDistance } from '@src/utils/coordinate'
import { localStorage, LOCAL_STORAGE_KEYS } from '@src/utils/localStorage'
import { useScreenInstance } from '@src/widgets/screenInstance'

import useAnalytics from './useAnalytics'
import { useFetchPois } from './useFetchPois'
import { useParamsForShare } from './useParamsForShare'
import { usePoisStatus } from './usePoisStatus'
import useSelectedPoi from './useSelectedPoi'
import { DaangnMapParams } from '../MapPage'
import {
  centerPositionAtomFamily,
  curationCategoriesSelector,
  isInitialFetchAtomFamily,
  MapTheme,
  mapThemeByCurationCategoryIdSelector,
} from '../state'
import { filteredPoisSelectorFamily } from '../state/pois'

const useFetchInitialPois = () => {
  const logEvent = useAnalytics()
  const { isTop } = useActivity()
  const { ...params } = useActivityParams<DaangnMapParams>()
  const { stepReplace } = useStepNavigator('daangn_map')
  const { poiId, mapThemeId, bounds: boundsParam } = useParamsForShare()
  const poisStatus = usePoisStatus()
  const { spotlightPoiOnMap } = useSelectedPoi()
  const {
    kakao,
    getBounds: getMapBounds,
    setBounds: setMapBounds,
    setLevel: setMapLevel,
    getBoundsFromPoints,
    getIsBoundsContained,
  } = useKakaoMap()
  const { fetchPois } = useFetchPois()

  const screenInstance = useScreenInstance()

  const [isInitialFetch, setIsInitialFetch] = useRecoilState(isInitialFetchAtomFamily(screenInstance))
  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const mapThemeFromParam = useRecoilValue(mapThemeByCurationCategoryIdSelector(mapThemeId ?? -1))
  const curationCategories = useRecoilValue(curationCategoriesSelector)
  const filteredPois = useRecoilValue(filteredPoisSelectorFamily(screenInstance))
  const centerPosition = useRecoilValue(centerPositionAtomFamily(screenInstance))
  const [_isFirstFetch, _setIsFirstFetch] = useState(true)

  const initialZoomLevel = useMemo(() => boundsParam?.zoomLevel ?? 5, [boundsParam?.zoomLevel])

  const bounds = useMemo(
    () =>
      boundsParam?.bound
        ? {
            bottomLeft: {
              latitude: boundsParam.bound.sw[0],
              longitude: boundsParam.bound.sw[1],
            },
            topRight: {
              latitude: boundsParam.bound.ne[0],
              longitude: boundsParam.bound.ne[1],
            },
          }
        : null,
    [boundsParam]
  )

  const handleSetMapBoundWithFiveContent = useCallback(
    () => {
      if (!centerPosition) return

      const sortedPois = sortPoisByDistance(filteredPois, centerPosition)
      const points = sortedPois.map((poi) => poi.poi.coordinates!)

      const currentMapBounds = getMapBounds()
      const limit = Math.min(points.length, 5)
      const pointsBound = getBoundsFromPoints(points.slice(0, limit))

      // FAILED TO LOAD KAKAO MAP
      if (!kakao || !currentMapBounds || !pointsBound) return

      if (!getIsBoundsContained(currentMapBounds, pointsBound)) {
        setMapBounds(pointsBound)
      }

      spotlightPoiOnMap(sortedPois[0].poi.id, false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredPois]
  )

  // 초기 로딩 전 변경값을 설정한다
  // initial fetching pois once on page load
  useEffect(() => {
    if (isTop && isInitialFetch && userInfo) {
      const initialFetch = (initialMapTheme: MapTheme) => {
        fetchPois({
          bounds,
          mapTheme: initialMapTheme,
        })
        const categoryId = initialMapTheme.id.toString()
        stepReplace({ ...params, theme: categoryId })
        logEvent('show_map_theme', { category_id: categoryId })
        _setIsFirstFetch(false)
      }

      localStorage
        .getItem(LOCAL_STORAGE_KEYS.LATEST_MAP_THEME)
        .then((latestMapThemeId) => {
          const initialMapTheme =
            mapThemeFromParam ||
            curationCategories.find((curationCategory) => curationCategory.id.toString() === latestMapThemeId) ||
            curationCategories[0]

          initialFetch(initialMapTheme)
        })
        .catch(() => {
          const initialMapTheme = mapThemeFromParam || curationCategories[0]

          initialFetch(initialMapTheme)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTop, isInitialFetch, userInfo])

  // 초기 로딩 이후 동작
  useEffect(() => {
    if (!_isFirstFetch && !isInitialFetch) return
    if (poisStatus === 'initialize' || poisStatus === 'loading' || filteredPois.length === 0) return

    if (initialZoomLevel) setMapLevel(initialZoomLevel)

    if (!!poiId && filteredPois.find((poi) => poi.poi.id === poiId)) {
      spotlightPoiOnMap(poiId, true)
    } else if (bounds) {
      setMapBounds(bounds)
    } else {
      handleSetMapBoundWithFiveContent()
    }

    setIsInitialFetch(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poisStatus, filteredPois, _isFirstFetch])
}

export default useFetchInitialPois

import { IS_PROD } from './environmentConstants'

export const generatePoiUrl = (region: 'kr', version: '1' | '2') =>
  IS_PROD
    ? `https://poi.${region}.karrotmarket.com/api/v${version}`
    : `https://poi.alpha.${region}.karrotmarket.com/api/v${version}`
export const poiVersion = IS_PROD ? '2' : '2'

export const generateBizPostUrl = IS_PROD
  ? `https://business-post.kr.karrotmarket.com`
  : `https://business-post.alpha.kr.karrotmarket.com`

export const generateBigPictureUrl = (region: 'kr') =>
  IS_PROD ? `https://image.${region}.karrotmarket.com` : `https://image.alpha.${region}.karrotmarket.com`

export const SERVER_BASE_URL = ({ region = 'kr' }: { region: 'kr' }) => {
  return {
    POI: IS_PROD ? `https://poi.${region}.karrotmarket.com` : `https://poi.alpha.${region}.karrotmarket.com`,
  }
}

export const BIZ_ACCOUNT_BASE_URL = IS_PROD ? 'https://ba.kr.karrotmarket.com' : 'https://ba.alpha.kr.karrotmarket.com'

export const COUPON_BASE_URL = IS_PROD
  ? 'https://commerce-coupon.kr.karrotmarket.com'
  : 'https://commerce-coupon.alpha.kr.karrotmarket.com'

export const BIZ_POST_BASE_URL = IS_PROD
  ? 'https://business-post.kr.karrotmarket.com'
  : 'https://business-post.alpha.kr.karrotmarket.com'

export const BIZ_PROFILE_APP_BASE_URL = IS_PROD
  ? 'https://bizprofile.karrotwebview.com'
  : 'https://bizprofile.alpha.karrotwebview.com'

export const POI_SERVER_BASE_URL = IS_PROD ? `https://poi.kr.karrotmarket.com` : `https://poi.alpha.kr.karrotmarket.com`

export const COMMUNITY_SERVER_BASE_URL = IS_PROD
  ? 'https://community-api.kr.karrotmarket.com'
  : 'https://community-api.alpha.kr.karrotmarket.com'

export const NEARBY_SERVER_BASE_URL = IS_PROD
  ? 'https://nearby.kr.karrotmarket.com'
  : 'https://nearby.alpha.kr.karrotmarket.com'

export const BUSINESS_FEED_BASE_URL = IS_PROD
  ? 'https://business-feed.kr.karrotmarket.com'
  : 'https://business-feed.alpha.kr.karrotmarket.com'

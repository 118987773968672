import { getWinterSnackPoisV2 } from '@daangn/local-business-network/lib/poi'
import { isDefaultLoadable } from '@daangn/recoil-loadable'
import styled from '@emotion/styled'
import { captureException } from '@sentry/react'
import React, { useReducer } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { BoundarySearchButton as BoundarySearchButtonComponent } from '@src/components/Map'
import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useToast } from '@src/components/Toast'
import { checkInRegionInfoAtom, userInfoAtom } from '@src/store'

import { usePoiVariables } from '../hooks'
import { latestSearchResultAtom, winterSnackPoisLoadable } from '../state'
import { isMapBoundChangedAtom } from '../state/map'

export const BoundarySearchButton = () => {
  const user = useRecoilValue(userInfoAtom)
  const checkInRegion = useRecoilValue(checkInRegionInfoAtom)
  const poisVariables = usePoiVariables()

  const { showToast } = useToast()
  const { getBounds } = useKakaoMap()

  const [isLoading, toggleIsLoading] = useReducer((prev) => !prev, false)

  const handleClick = useRecoilCallback(
    ({ set, reset }) =>
      async () => {
        try {
          toggleIsLoading()

          const bound = getBounds({
            margin: {
              top: 16 + 34,
              bottom: 200,
            },
          })

          if (!bound?.bottomLeft.latitude || !bound.topRight.latitude) throw new Error('failed to get map bounds')

          const { data } = await getWinterSnackPoisV2({
            params: {
              xAuthToken: user.authToken,
              regionId: checkInRegion.id,
              boundNe: `${bound.topRight.latitude},${bound.topRight.longitude}`,
              boundSw: `${bound.bottomLeft.latitude},${bound.bottomLeft.longitude}`,
              isCurrentAreaSearch: true,
            },
          })
          const newPois = data.data.items

          reset(isMapBoundChangedAtom)

          /**
           * boundary search 결과가 없을 경우 toast를 띄운다.
           */
          if (newPois.length === 0) {
            return showToast({
              text: '이 지역 재검색 결과가 없어요.',
              duration: 'short',
            })
          } else {
            set(latestSearchResultAtom, newPois)
            /**
             * boundary search 결과가 있을 경우 기존 pois와 합쳐준다.
             */
            set(winterSnackPoisLoadable.store(poisVariables), (prev) => {
              const prevPois = isDefaultLoadable(prev) ? [] : prev

              return [...new Set([...(prevPois ?? []), ...newPois].map((poi) => JSON.stringify(poi)))].map((poi) =>
                JSON.parse(poi)
              )
            })
          }
        } catch (error) {
          captureException(error)
          showToast({ text: '잠시후 다시 시도해주세요.', duration: 'short' })
        } finally {
          toggleIsLoading()
        }
      },
    [checkInRegion.id, getBounds, showToast, user.authToken, poisVariables]
  )

  return <Button onClick={handleClick} isLoading={isLoading} />
}

const Button = styled(BoundarySearchButtonComponent)`
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 98;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
`

import { useActivityParams } from '@stackflow/react'
import React, { ComponentType, createContext, useContext, useMemo } from 'react'

import { bridge } from '@src/bridge'
import { useLogEvent } from '@src/components/Analytics'
import { useAnalyticsLogOff } from '@src/components/DevTools'
import { IS_PROD } from '@src/constants/environmentConstants'

import { CONTENT_CURATOR_API_KEY_TO_THEME_ID_MAPPER } from '../constants/bigCategory'
import { PurposefulCategoryActivityParams } from '../PurposefulCategoryPage'

export const createNewBridgeAnalytics = ({
  where,
  referrer,
  entry,
  directory_id,
  analyticsLogOff,
  localBusinessLogger,
}: {
  /**
   * 이벤트가 발생한 페이지
   */
  where: string
  /**
   * 유저의 최초 진입점 (플레이스 프로덕트로의 최초 진입점)
   */
  referrer?: string | null
  /**
   * 이벤트가 발생한 페이지의 이전 페이지 (플레이스 프로덕트 내부에서의 진입점 페이지)
   */
  entry?: string | null
  /**
   * 이벤트가 발생한 페이지의 이전 페이지 (플레이스 프로덕트 내부에서의 진입점 페이지)
   */
  directory_id?: string
  analyticsLogOff: boolean
  localBusinessLogger: ReturnType<typeof useLogEvent>
}) => {
  const log = ({
    name,
    params,
  }: {
    /**
     * 이벤트 이름
     */
    name: string
    /**
     * 이벤트 이름
     */
    type: 'show' | 'click'
    /**
     * 각 이벤트의 추가 파라미터
     */
    params?: Record<string, unknown>
  }) => {
    const defaultParams = { name, referrer, entry, where, directory_id }

    if (!IS_PROD && !analyticsLogOff) return console.log({ ...defaultParams, ...params })

    localBusinessLogger({
      name,
      params: {
        referrer: referrer ?? null,
        entry: entry ?? null,
        where,
        directory_id: directory_id ?? null,
        ...params,
      },
    })

    bridge.analytics.log({
      target: 'FIREBASE',
      name: 'nearby_event',
      params: { ...defaultParams, ...params },
    })
  }

  return log
}

const NewAnalyticsContext = createContext<ReturnType<typeof createNewBridgeAnalytics>>(null as any)

export const NewAnalyticsProvider = NewAnalyticsContext.Provider

export const useNewAnalytics = () => useContext(NewAnalyticsContext)

export const withNewAnalytics = <P extends object>(Component: ComponentType<P>, pageCode: string) => {
  const WithAnalytics = (props: P) => {
    const { entry, referrer, themeId } = useActivityParams<
      { entry: string; referrer: string } & PurposefulCategoryActivityParams
    >()
    const [analyticsLogOff] = useAnalyticsLogOff()
    const localBusinessLogger = useLogEvent()

    const analytics = useMemo(
      () =>
        createNewBridgeAnalytics({
          where: pageCode,
          referrer,
          entry,
          directory_id: CONTENT_CURATOR_API_KEY_TO_THEME_ID_MAPPER[themeId],
          analyticsLogOff,
          localBusinessLogger,
        }),
      [referrer, entry, themeId, analyticsLogOff, localBusinessLogger]
    )

    return (
      <NewAnalyticsProvider value={analytics}>
        <Component {...(props as P)} />
      </NewAnalyticsProvider>
    )
  }

  return WithAnalytics
}

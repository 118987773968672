import {
  COMMUNITY_SERVER_BASE_URL,
  generateBigPictureUrl,
  generateBizPostUrl,
  NEARBY_SERVER_BASE_URL,
} from '@src/constants/apiUrls'

import './poiConfig'
import './BizAccountConfig'
import './couponConfig'
import './bizPostConfig'
import BigPictureAPI from './BigPicture'
import BizReviewApi from './BizReview'
import Bridge from './Bridge'
import CommunityApi from './communityApi'
import JusoAPI from './Juso'
import KakaLocalAPI from './KakaoLocal'
import NearbyApi from './nearbyApi'

export const nearbyApi = new NearbyApi(NEARBY_SERVER_BASE_URL)
export const communityApi = new CommunityApi(COMMUNITY_SERVER_BASE_URL)
export const bizReviewApi = new BizReviewApi(generateBizPostUrl)

export const bigPictureApi = new BigPictureAPI(generateBigPictureUrl('kr'), {
  headers: {
    'Content-Type': 'application/json',
  },
})
export const kakaLocalApi = new KakaLocalAPI()
export const jusoApi = new JusoAPI('https://www.juso.go.kr')
export const bridge = new Bridge()

import { SortTab } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { vars } from '@seed-design/design-token'
import React, { useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { myPoiReviewSelectorFamily } from '@src/shared/review/store/poiReview'
import { useNavigator } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics } from '../hooks'
import { reviewSortingOptionAtomFamily } from '../state'

interface Props {
  poiId: string
}
const Options = ({ poiId }: Props) => {
  const logEvent = useAnalytics()
  const { push } = useNavigator()
  const screenInstance = useScreenInstance()

  const setSortOption = useSetRecoilState(reviewSortingOptionAtomFamily({ poiId, screenInstance }))

  const sortingOption = useRecoilValue(reviewSortingOptionAtomFamily({ poiId, screenInstance }))

  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId }))
  const userReviewId = myReview?.id

  const handleClickWriteReview = async () => {
    /** @deprecated */
    logEvent('click_write_review_button', {
      area: 'review_tab',
    })
    logEvent('click_write_profile_review_button', {
      area: 'review_tab',
    })

    push('poi_review', { action: 'create', poiId })
  }

  const handleClickEditReview = async () => {
    logEvent('click_edit_review_button', {
      area: 'review_tab',
      review_id: userReviewId,
    })

    push('poi_review', { action: 'update', poiId, reviewId: userReviewId })
  }

  const tabs = useMemo(
    () => [
      {
        key: 'latestCreation',
        label: '최신순',
        onClick: () => {
          /** @deprecated */
          logEvent('click_sort_by_latest_button', {
            source: 'review',
          })
          logEvent('click_profile_review_tab_order_button', {
            order_filter_from: 'likes_count',
            order_filter_to: 'created_at',
          })
          setSortOption('latestCreation')
        },
      },
      {
        key: 'highestScore',
        label: '도움순',
        onClick: () => {
          /** @deprecated */
          logEvent('click_sort_by_helpful_button', {
            source: 'review',
          })
          logEvent('click_profile_review_tab_order_button', {
            order_filter_from: 'created_at',
            order_filter_to: 'likes_count',
          })
          setSortOption('highestScore')
        },
      },
    ],
    [setSortOption, logEvent]
  )

  return (
    <Container>
      <SortTab tabs={tabs} initialTabKey={sortingOption} />
      <WriteReview onClick={userReviewId ? handleClickEditReview : handleClickWriteReview}>
        <IconWriteRegular width={'1rem'} height={'1rem'} />
        후기 {userReviewId ? '수정' : '작성'}
      </WriteReview>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background: ${vars.$semantic.color.paperDefault};
`
const WriteReview = styled.a`
  display: inline-flex;
  align-items: center;
  text-align: right;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 143%;
  letter-spacing: -0.02em;
  color: ${vars.$semantic.color.primary};

  & > svg {
    margin: 0 0.25rem 0 0;
  }
`

export default Options

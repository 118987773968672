import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { bridge } from '@src/bridge'
import { tokenStorage } from '@src/utils/tokenStorage'

export default class Request {
  public req: AxiosInstance
  constructor(domain: string, options?: AxiosRequestConfig) {
    this.req = axios.create({
      baseURL: domain,
      ...options,
    })

    this.req.interceptors.request.use(
      (config) => {
        /**
         * Breadcrumb type link: https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/#breadcrumb-types
         *  */
        Sentry.addBreadcrumb({
          type: 'http',
          level: Severity.Log,
          data: {
            method: config.method,
            url: `${config.baseURL}${config.url}`,
            params: config.params,
            data: config.data,
          },
        })
        return config
      },
      (error) => {
        Sentry.captureException(error)
        return error
      }
    )

    if (!tokenStorage.getAuthToken()) {
      bridge.info.user().then((info) => {
        if (!info) return
        tokenStorage.setAuthToken(info.authToken)
      })
    }
  }

  getUserAuthHeader = () => {
    const userAuthToken = tokenStorage.getAuthToken()
    return {
      'X-Auth-Token': userAuthToken,
    }
  }

  generateApiHeaders = (authToken: string, otherHeaders: { [key: string]: string } = {}) => ({
    headers: {
      'X-Auth-Token': authToken,
      ...otherHeaders,
    },
  })
}

import { vars } from '@seed-design/design-token'
import React, { ComponentType, ReactNode, useCallback, useMemo, useRef, useState } from 'react'

import { Screen, ScreenProps } from '@src/stackflow'
import { APP_BAR_HEIGHT as _APP_BAR_HEIGHT } from '@src/stackflow/styles'
import { getSafeAreaValue } from '@src/styles/utils'

import { TransparentNavScreenContext } from './context'
import { ScrollContainer } from './ScrollContainer'

interface Props {
  appBar: ScreenProps['appBar']
  appendRight?: ComponentType<{ iconColor: string }>
  backgroundColor?: string
  breakpoint?: number
  children?: ReactNode
}
export const TransparentNavScreen = ({ appBar, backgroundColor, breakpoint = 0, children, ...props }: Props) => {
  const { appendRight: AppendRight } = props
  const [isTransparent, setIsTransparent] = useState(breakpoint > 0 ? true : false)
  const scrollRef = useRef<HTMLDivElement>(null)

  const color = useMemo(
    () => (isTransparent ? vars.$static.color.staticWhite : vars.$scale.color.gray900),
    [isTransparent]
  )
  const SAFE_AREA_TOP = useMemo(() => parseInt(getSafeAreaValue('top'), 10), [])
  const APP_BAR_HEIGHT = useMemo(() => _APP_BAR_HEIGHT + SAFE_AREA_TOP, [SAFE_AREA_TOP])

  const handleScroll = useCallback(() => {
    if (!scrollRef.current) {
      return
    }

    if (scrollRef.current.scrollTop > breakpoint - APP_BAR_HEIGHT) {
      setIsTransparent(false)
    } else {
      setIsTransparent(true)
    }
  }, [breakpoint, APP_BAR_HEIGHT])

  const transparentContextValue = useMemo(() => ({ scrollRef, navbarHeight: APP_BAR_HEIGHT }), [APP_BAR_HEIGHT])

  return (
    <Screen
      backgroundColor={backgroundColor}
      appBar={{
        title: !isTransparent && (appBar?.title ?? ''),
        border: !isTransparent,
        backgroundColor: isTransparent ? 'transparent' : vars.$semantic.color.paperDefault,
        appendRight: AppendRight ? () => <AppendRight iconColor={color} /> : appBar?.appendRight,
        iconColor: color,
        textColor: color,
      }}>
      <TransparentNavScreenContext.Provider value={transparentContextValue}>
        <ScrollContainer
          ref={scrollRef}
          onScroll={handleScroll}
          height={breakpoint > 0 ? `calc(100% + ${APP_BAR_HEIGHT}px)` : '100%'}
          marginTop={breakpoint > 0 ? `calc(calc(${APP_BAR_HEIGHT}px * -1))` : '0px'}>
          {children}
        </ScrollContainer>
      </TransparentNavScreenContext.Provider>
    </Screen>
  )
}

import styled from '@emotion/styled'
import IconExpandMoreThin from '@karrotmarket/karrot-ui-icon/lib/react/IconExpandMoreThin'
import IconRemoveCircleFill from '@karrotmarket/karrot-ui-icon/lib/react/IconRemoveCircleFill'
import { vars } from '@seed-design/design-token'
import React, { useRef } from 'react'

interface Props {
  id: string
  value: string
  onChange: (value: string) => void
  placeholder?: string
}

const TimeInput: React.FC<Props> = ({ id, value, onChange, placeholder = '시간 선택' }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <InputWrapper>
      <InputVisibleLayer
        onClick={() => {
          inputRef?.current?.click()
          inputRef?.current?.focus()
        }}>
        <InputPlaceholder>{!value && placeholder}</InputPlaceholder>
        <ChevronWrapper>
          {value ? (
            <ClearButton
              type="button"
              onClick={(e) => {
                onChange('')
                e.stopPropagation()
              }}>
              <IconRemoveCircleFill className="icon" />
            </ClearButton>
          ) : (
            <IconExpandMoreThin className="icon" />
          )}
        </ChevronWrapper>
      </InputVisibleLayer>
      <StyledTimeInput
        type="time"
        ref={inputRef}
        id={id}
        value={value}
        placeholder={placeholder}
        hasValue={!!value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
`

const InputVisibleLayer = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem;
`

const InputPlaceholder = styled.span`
  ${vars.$semantic.typography.label2Regular}
  line-height: 150%;
  color: ${vars.$scale.color.gray500};
  flex: 1;
  padding: 0 0.5rem;
`
const ChevronWrapper = styled.span`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    width: 18px;
    height: 18px;
  }
`

const ClearButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 23px;
  height: 23px;

  .icon {
    width: 20px;
    height: 20px;
    color: ${vars.$scale.color.gray500};
  }
`

const StyledTimeInput = styled.input<{ width?: string; invalid?: boolean; hasValue: boolean }>`
  ${vars.$semantic.typography.label2Regular}
  height: 3rem;
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid ${vars.$scale.color.gray400};
  box-sizing: border-box;
  border-radius: 5px;
  color: ${({ hasValue }) => (!hasValue ? 'transparent' : vars.$scale.color.gray900)};

  text-align: left;
  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &:focus {
    border: 1px solid ${vars.$scale.color.gray900};
  }
  &:disabled,
  &::placeholder {
    color: ${vars.$scale.color.gray500};
  }
`

export default TimeInput

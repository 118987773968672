import React from 'react'
import { useRecoilValue } from 'recoil'

import Nudge from './Nudge'
import { nudgeItemsAtom } from './state'

function NudgeItems() {
  const nudgeItems = useRecoilValue(nudgeItemsAtom)

  return (
    <>
      {Object.keys(nudgeItems).map((key) => (
        <Nudge key={key} uniqueKey={key} {...nudgeItems[key]} />
      ))}
    </>
  )
}

export default NudgeItems

import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { vars } from '@seed-design/design-token'
import React, { MouseEvent } from 'react'

import { ReportItemKey } from '@src/apis/types/report'

export interface ReportItem {
  key: ReportItemKey
  text: string
}

interface Props {
  title?: string
  items: ReportItem[]
  showArrowKeys?: ReportItemKey[]
  onReportItemClick?: (e: MouseEvent, item: ReportItem) => void
}

const ReportItemList: React.FC<Props> = ({ title, items, onReportItemClick, showArrowKeys }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {items.map((item) => (
        <ReportItemCard key={item.key} onClick={(e) => onReportItemClick?.(e, item)}>
          {item.text}
          {showArrowKeys?.includes(item.key) && (
            <IconChevronRightWrapper>
              <IconChevronRightRegular className="icon" />
            </IconChevronRightWrapper>
          )}
        </ReportItemCard>
      ))}
    </Container>
  )
}

const Container = styled.div`
  padding: 0px 16px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 24px 0;
`

const ReportItemCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${vars.$semantic.color.divider1};
  padding: 16px 0;
`

const IconChevronRightWrapper = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 18px;
    height: 18px;
    color: ${vars.$scale.color.gray600};
  }
`

export default ReportItemList

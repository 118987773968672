import { isUndefined } from 'lodash'
import React, { useImperativeHandle, useLayoutEffect, useMemo } from 'react'

import type { Coordinates } from './types'
import useEventListenerEffect from './useEventListenerEffect'
import { useMap } from './useMap'

interface Props {
  position: Coordinates
  image?: any
  zIndex?: number
  clickable?: boolean
  onClick?: () => void
}

const Marker = React.forwardRef<any, Props>(({ position, image, zIndex, clickable, onClick }, ref) => {
  const { kakao, map } = useMap()

  const markerPosition = useMemo(() => {
    return new kakao.maps.LatLng(position.latitude, position.longitude)
  }, [kakao.maps.LatLng, position.latitude, position.longitude])

  const marker = useMemo(() => {
    const kakaoMarker = new kakao.maps.Marker({
      image,
      clickable,
      position: markerPosition,
    })

    return kakaoMarker
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (!map) return

    marker.setMap(map)

    return () => {
      marker.setMap(null)
    }
  }, [map, marker])

  useLayoutEffect(() => {
    if (!map || !marker || !image) return

    marker.setImage(image)
  }, [map, marker, image])

  useLayoutEffect(() => {
    if (!map || !marker || !markerPosition) return

    marker.setPosition(markerPosition)
  }, [map, marker, markerPosition])

  useLayoutEffect(() => {
    if (!map || !marker || isUndefined(zIndex)) return

    marker.setZIndex(zIndex)
  }, [map, marker, zIndex])

  useEventListenerEffect(marker, 'click', onClick)

  useImperativeHandle(ref, () => marker, [marker])

  return null
})

export default Marker

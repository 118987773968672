import debounce from 'lodash/debounce'
import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilValueLoadable } from 'recoil'

import { BookmarkPoiIdDto } from '@src/apis/generated/poi/models'
import nearbySdk from '@src/apis/NearybySDK'

import { useWatch } from './useWatch'
import { watchedByUserMapperSelector } from '../store'
import { placeWatchIdToString } from '../utils'

interface Props {
  placeWatchId: BookmarkPoiIdDto
  onClick?: (isAddWatch: boolean) => void
  onAddWatch?: () => void
  onRemoveWatch?: () => void
}

export function useInternalWatch({ placeWatchId, onClick, onAddWatch, onRemoveWatch }: Props) {
  const { addWatch, removeWatch } = useWatch()

  const { contents, state } = useRecoilValueLoadable(watchedByUserMapperSelector)
  const isWatched = state === 'hasValue' && !!contents[placeWatchIdToString(placeWatchId)]

  const [watched, setWatched] = useState(isWatched)
  const watchClickedRef = useRef(false)

  const handleClickWatch: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    e.preventDefault()

    watchClickedRef.current = true

    onClick?.(watched)
    setWatched(!watched)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedReqToggleWatch = useCallback(
    debounce(async (setToWatched: boolean) => {
      if (setToWatched) {
        onAddWatch?.()
        addWatch(placeWatchId)
      } else {
        onRemoveWatch?.()
        removeWatch(placeWatchId)
      }

      watchClickedRef.current = false

      nearbySdk.emit('place.poi.bookmark', {
        isBookmarked: setToWatched,
        poiId: placeWatchId.targetId,
        service: 'place',
      })
    }, 300),
    [placeWatchId]
  )

  useEffect(() => {
    if (!watchClickedRef.current) return
    debouncedReqToggleWatch(watched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watched])

  useEffect(() => {
    if (state !== 'hasValue' || isWatched == watched) return
    setWatched(isWatched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatched, state])

  return {
    handleClickWatch,
    watched,
  }
}

import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'
import { useUserPosts } from '@src/widgets/SubprofileHalfview/hooks'
import { subprofileUserIdAtomFamily } from '@src/widgets/SubprofileHalfview/state'

import { myReactionsMapperAtomFamily, ReactionState, reactionStore } from '../state'

export const useSyncReactions = () => {
  const screenInstance = useScreenInstance()
  const { posts } = useUserPosts()

  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))
  const setReactions = useSetRecoilState(reactionStore(userId))
  const myReactionsMapper = useRecoilValue(myReactionsMapperAtomFamily(userId))

  useEffect(() => {
    const postReactions: ReactionState[] =
      posts?.map(({ post }) => ({
        resourceId: post.sourceId,
        resourceType: post.type,
        type: 'UPVOTE',
        reactionCount: post.reaction?.upvoteCount ?? 0,
        isReactedByMe: false,
      })) ?? []

    const reactions: ReactionState[] = postReactions.map((reaction) => {
      const isReactedByMe = !!myReactionsMapper[`${reaction.resourceType as string}${reaction.resourceId}`]

      return {
        ...reaction,
        isReactedByMe,
      }
    })

    setReactions(reactions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myReactionsMapper])
}

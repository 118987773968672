import React from 'react'
import { useRecoilValue } from 'recoil'

import { Category } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'
import { withVisualViewport } from '@src/hocs/withVisualViewport'
import { Screen, useNavigator } from '@src/stackflow'

import { useAnalytics } from './hooks'
import SearchCategory from './SearchCategory'
import { searchTextAtom } from './state'

const SearchCategoryPage = () => {
  const { pop } = useNavigator()
  const logEvent = useAnalytics()

  const searchText = useRecoilValue(searchTextAtom)

  const handleClickSearchResult = (category: Category) => {
    bridge.analytics.log({
      name: 'click_all_category_search_results',
      params: { from: 'poi_webview', keyword: searchText },
    })
    logEvent('click_select_category_button', {
      query: searchText,
      category_id: category.id,
    })
    pop()
  }

  return (
    <Screen appBar={{ title: '카테고리 검색' }}>
      <SearchCategory onClickSearchResult={handleClickSearchResult} />
    </Screen>
  )
}

export default withVisualViewport(SearchCategoryPage)

import styled from '@emotion/styled'
import { send } from '@stackflow/compat-await-push'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue, useResetRecoilState } from 'recoil'

import { PoiEvent } from '@src/apis/generated/poi/models'
import { ActionChip, SectionHeader } from '@src/components'
import { Section } from '@src/components/Layout'
import {
  curationCategoriesMapperSelector,
  curationCategoriesSelector,
  selectedPoiIdAtomFamily,
} from '@src/pages/Map/state'
import { useActivity, useNavigator } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics } from '../../hooks'

interface Props {
  events: PoiEvent[]
}
const MapSection = ({ events }: Props) => {
  const { poiId, entry } = useActivityParams<{ poiId: string; entry: string }>()
  const logEvent = useAnalytics()
  const { pop, push } = useNavigator()
  const activity = useActivity()

  const screenInstance = useScreenInstance()

  const resetSelectedPoiOnMap = useResetRecoilState(selectedPoiIdAtomFamily(screenInstance))
  const curationCategoriesMapper = useRecoilValue(curationCategoriesMapperSelector)
  const curationCategories = useRecoilValue(curationCategoriesSelector)

  const polishedEvents = (events || []).sort((eventA, eventB) => {
    const aIndex = curationCategories.findIndex((curationCategory) => curationCategory.name === eventA.name)
    const bIndex = curationCategories.findIndex((curationCategory) => curationCategory.name === eventB.name)

    return aIndex - bIndex
  })

  const handleViewMap = (eventId: number) => () => {
    resetSelectedPoiOnMap()
    logEvent('click_curation_category_button', {
      category_id: eventId,
    })

    if (entry === 'daangn_map') {
      pop()
      send({
        activityId: activity.id,
        data: {
          theme: eventId,
        },
      })
    } else {
      push('daangn_map', { poi_id: poiId, theme: eventId.toString() })
    }
  }

  return (
    <Section>
      <SectionHeader type="basic" text="당근지도" />
      <ActionChipContainer>
        {polishedEvents.map(({ id, name }) => {
          return (
            <ActionChip
              key={id}
              type="normal"
              text={name}
              appendLeft={
                curationCategoriesMapper[id]?.icon ? (
                  <img src={curationCategoriesMapper[id].icon!.thumbnail} />
                ) : undefined
              }
              onClick={handleViewMap(id)}
            />
          )
        })}
      </ActionChipContainer>
    </Section>
  )
}

const ActionChipContainer = styled.div`
  margin: 1rem 0 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;

  img {
    width: 18px;
    height: 18px;
  }
`

export default MapSection

import { useCallback, useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import {
  communityPostsAtomFamily,
  communityPostsDynamicMetaAtomFamily,
  communityPostsPaginationAtom,
  communityPostsSortingOptionAtomFamily,
  communityPostsStaticMetaAtomFamily,
  PostsRequestSortType,
  storyQuery,
} from '../state'

export const useStory = ({ poiId }: { poiId: string }) => {
  const screenInstance = useScreenInstance()

  const [sortingOption, setSortingOption] = useRecoilState(
    communityPostsSortingOptionAtomFamily({ screenInstance, poiId })
  )
  const setPagination = useSetRecoilState(communityPostsPaginationAtom({ sortingOption, screenInstance, poiId }))
  const dynamicMeta = useRecoilValue(communityPostsDynamicMetaAtomFamily({ screenInstance, sortingOption, poiId }))

  const sortBy = useCallback(
    (option: PostsRequestSortType) => {
      setSortingOption(option)
    },
    [setSortingOption]
  )

  const fetchMore = useCallback(() => {
    if (!dynamicMeta.hasNextPage) return

    setPagination((prev) => ({
      ...prev,
      page: prev.page! + 1,
    }))
  }, [setPagination, dynamicMeta.hasNextPage])

  return {
    sortingOption,
    sortBy,
    fetchMore,
  }
}

export const useSyncStoryEffect = ({ poiId }: { poiId: string }) => {
  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ screenInstance, poiId }))
  const setCommunityPosts = useSetRecoilState(communityPostsAtomFamily({ screenInstance, poiId, sortingOption }))
  const [staticMeta, setStaticMeta] = useRecoilState(
    communityPostsStaticMetaAtomFamily({ sortingOption, screenInstance, poiId })
  )
  const [dynamicMeta, setDynamicMeta] = useRecoilState(
    communityPostsDynamicMetaAtomFamily({ sortingOption, screenInstance, poiId })
  )
  const { page, perPage } = useRecoilValue(communityPostsPaginationAtom({ sortingOption, screenInstance, poiId }))
  const fetchedStories = useRecoilValue(storyQuery({ page, perPage, poiId, sortingOption }))
  const isInitialized = staticMeta.total !== -1

  useEffect(() => {
    if (dynamicMeta.page >= fetchedStories.meta.page) return

    if (!isInitialized) {
      setStaticMeta({
        perPage: fetchedStories.meta.perPage,
        total: fetchedStories.meta.total,
        totalPages: fetchedStories.meta.totalPages,
      })
    }

    setCommunityPosts((prev) => {
      return [...prev, ...fetchedStories.data]
    })
    setDynamicMeta({
      page: fetchedStories.meta.page,
      hasNextPage: fetchedStories.meta.hasNextPage,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedStories])
}

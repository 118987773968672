import { css } from '@emotion/react'
import styled from '@emotion/styled'
import IconMoreVertRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconMoreVertRegular'
import { vars } from '@seed-design/design-token'
import { isEmpty } from 'lodash'
import React from 'react'

import { SquareBadge } from '../Badge'
import { ImageBoard } from '../Image'
import { ReactionDisplay } from '../Reaction'
import type { ReactionType } from '../Reaction/ReactionDisplay'
import { UserInfo } from '../User'
interface PreviewBase {
  id: string
  author: {
    name: string
    id: number
    profileImage?: string
    regionName?: string
    regionCheckInCount?: number
  }
  content: string
  images?: {
    url: string[]
  }
  createdAt: string
}

interface ArticlePreview extends PreviewBase {
  type: 'article'
  reaction: {
    count?: number
    types?: ReactionType[]
  }
}

interface CommentPreview extends PreviewBase {
  type: 'comment'
  articleContent?: string
  articleId?: number
  reaction: {
    count?: number
    types?: ReactionType[]
  }
}

interface ReviewPreview extends PreviewBase {
  type: 'review'
  tags?: string[]
}

export type PostPreviewType = ArticlePreview | CommentPreview | ReviewPreview

interface Props {
  className?: string
  data: PostPreviewType
  onContentClick?: () => void
  on3DotClick?: () => void
}

const PostPreview: React.FC<Props> = ({ className, data, onContentClick, on3DotClick }) => {
  const images = data.images?.url

  return (
    <Container className={className}>
      <Top>
        <UserInfo
          name={data.author.name}
          id={data.author.id}
          profileImage={data.author.profileImage}
          regionName={data.author.regionName}
          regionCheckInCount={data.author.regionCheckInCount}
          createdAt={data.createdAt}
        />
        {on3DotClick && <IconMoreVertRegular width="1.5rem" height="1.5rem" onClick={on3DotClick} />}
      </Top>
      <Content onClick={onContentClick}>{data.content}</Content>
      {data.type === 'review' && (
        <div>
          {data.tags?.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      )}
      {!!images && !isEmpty(images) && <ImageBoard images={images} />}

      {data.type === 'comment' && (
        <CommentOrigin onClick={onContentClick} isDeleted={!data.articleContent}>
          <SquareBadge size="small" type="basic" outlined bold css={customSquareBadgeCSS}>
            원문
          </SquareBadge>
          <p>{data.articleContent ?? '삭제된 게시글이에요.'}</p>
        </CommentOrigin>
      )}
      {data.type !== 'review' && !!data.reaction.count && data.reaction.count > 0 && (
        <ReactionDisplay reactionCount={data.reaction.count} reactionTypes={data.reaction.types ?? []} />
      )}
    </Container>
  )
}

const Container = styled.article`
  width: '100%';
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Content = styled.div`
  font-weight: 400;
  font-size: 1rem;
  color: ${vars.$scale.color.gray900};
  white-space: pre-wrap;
`

const Tag = styled.div`
  display: inline-block;
  margin: 0 4px 4px 0;

  padding: 4px 8px;
  background: ${vars.$scale.color.gray100};
  border-radius: 4px;

  color: ${vars.$scale.color.gray700};
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 16px;
`

const CommentOrigin = styled.div<{ isDeleted: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 154%;
    color: ${({ isDeleted }) => (isDeleted ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};
  }
`

const customSquareBadgeCSS = css`
  color: ${vars.$scale.color.gray600};
  border: 1px solid ${vars.$scale.color.gray400};
`

export default PostPreview

import { atom } from 'recoil'

import { Category } from '@src/apis/generated/poi/models'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

export type CategoryInputState = Partial<Category> & { categorySuggestionId?: number }

const generateCategoryInputRecoilKey = recoilKeyGenerator('categoryInput')

export const selectedCategoryAtom = atom<null | CategoryInputState>({
  key: generateCategoryInputRecoilKey('selectedCategory'),
  default: null,
})

export const servicedFromAtom = atom({
  key: generateCategoryInputRecoilKey('servicedFrom'),
  default: '',
})

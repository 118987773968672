import { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { generateNudgeKey } from '@src/components/Nudge/generateNudgeKey'
import { uniqueKeysForNudge } from '@src/components/Nudge/uniqueKeysForNudge'
import { useNudge } from '@src/components/Nudge/useNudge'
import { useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { subprofileUserIdAtomFamily } from '../state'

export const useSubprofileHalfview = () => {
  const { push, pop } = useNavigator()
  const { hideNudge } = useNudge()
  const screenInstance = useScreenInstance()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const setSubprofileUserId = useSetRecoilState(subprofileUserIdAtomFamily(screenInstance))

  const open = useCallback(
    (userId: string) => {
      setSubprofileUserId(userId)

      push('subprofile_halfview', { userId })
    },
    [push, setSubprofileUserId]
  )

  const close = useCallback(() => {
    if (userInfo) {
      hideNudge(generateNudgeKey(uniqueKeysForNudge.mapUserSubprofile, userInfo.id))
    }

    pop()
  }, [hideNudge, pop, userInfo])

  return {
    open,
    close,
  }
}

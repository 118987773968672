import styled from '@emotion/styled'
import React, { ForwardedRef, forwardRef, ReactNode } from 'react'

interface Props {
  index: number
  children: ReactNode
}

const Slide = ({ index, children }: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const cardStyle = {
    '--card-offset': index,
  } as React.CSSProperties

  return (
    <Card style={cardStyle} ref={ref}>
      {children}
    </Card>
  )
}

const Card = styled.div`
  position: absolute;
  // "카드의 위치"는 "카드 넓이"와 "카드 간격" 하나를 더한 값(+)을 해당 "카드의 인덱스(card-offset)" 만큼 곲한 값 그리고(+) 한쪽의 "지정한 마진"만큼 오른쪽으로 이동한다.
  left: calc((100% - var(--slide-margin) * 2 + var(--slide-gap)) * var(--card-offset) + var(--slide-margin));
  // "카드 넓이"는 "부모의 넓이"에서 양쪽의 "지정한 마진"을 뺀(-) 값이다.
  width: calc(100% - var(--slide-margin) * 2);
  background-color: transparent;
`

export default forwardRef(Slide)

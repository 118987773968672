import { Poi } from '@daangn/local-business-network/lib/poi'
import { atom } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'
import { Bounds } from '@src/types/global'

export const generateKey = recoilKeyGenerator(['searchPoi', 'map'])

export const boundsAtom = atom<Bounds | null>({
  key: generateKey('boundsAtom'),
  default: null,
})

export const isMapBoundChangedAtom = atom({
  key: generateKey('isMapBoundChangedAtom'),
  default: false,
})

export const focusedPoiIdAtom = atom<Poi['id'] | null>({
  key: generateKey('focusedPoiAtom'),
  default: null,
})

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { GetProfileV2200Response } from '../models';
/**
 * ProfilesApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @param {string} userId 유저 아이디
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getProfileV2AxiosParamCreator = async (userId: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/profiles/{userId}`
          .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;


      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * ProfilesApi - functional programming interface
 * @export
 */
    /**
     * 
     * @param {string} userId 유저 아이디
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getProfileV2Fp = async (userId: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfileV2200Response>> => {
        const localVarAxiosArgs = await getProfileV2AxiosParamCreator(userId, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * ProfilesApi - factory interface
 * @export
 */
    /**
     * 
     * @param {string} userId 유저 아이디
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getProfileV2 = (userId: string, options?: any): AxiosPromise<GetProfileV2200Response> => {
        return getProfileV2Fp(userId, options).then((request) => request());
    }



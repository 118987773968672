export function calculateDistance(distance: number) {
  if (isNaN(distance)) return

  if (distance >= 1000) {
    const fixedDistance = (distance / 1000).toFixed(1)
    const splitedFixedDistance = fixedDistance.split('.')
    const isInteger = splitedFixedDistance[1] === '0'
    if (isInteger) {
      return `${splitedFixedDistance[0]}km`
    } else {
      return `${fixedDistance}km`
    }
  }

  if (distance > 1) {
    const fixedDistance = distance.toFixed(0)
    return `${fixedDistance}m`
  }

  return '1m'
}

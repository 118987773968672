import { searchPoisV2 } from '@daangn/local-business-network/lib/poi'
import { isDefaultLoadable } from '@daangn/recoil-loadable'
import { useRecoilCallback, useRecoilValue } from 'recoil'
import { v4 } from 'uuid'

import { checkInRegionInfoAtom, userInfoAtom } from '@src/store'
import { Bounds } from '@src/types/global'

import { useKeywords } from './useKeywords'
import { analyticsSessionIds } from '../state/analytics'
import { boundsAtom, focusedPoiIdAtom, isMapBoundChangedAtom } from '../state/map'
import { FetchVariablesType, poisLoadableFamily, searchStatusAtom, ResponseType } from '../state/poi'
import { queryAtom } from '../state/query'

export const useSearch = () => {
  const { authToken: xAuthToken } = useRecoilValue(userInfoAtom)
  const { id: regionId } = useRecoilValue(checkInRegionInfoAtom)

  const query = useRecoilValue(queryAtom)
  const { hasKeyword } = useKeywords()
  const isPredictedKeyword = hasKeyword(query)

  const variables: FetchVariablesType = Object.freeze({
    query,
    regionId,
    isPredictedKeyword,
    xAuthToken,
  })

  const handleSearch = useRecoilCallback(
    ({ set, reset }) =>
      ({ query }: { query: string }) => {
        set(searchStatusAtom, 'searching')
        set(queryAtom, query)
        reset(boundsAtom)
        reset(focusedPoiIdAtom)
        reset(isMapBoundChangedAtom)

        analyticsSessionIds.setQuerySessionId(v4())
      },
    []
  )

  const searchByInput = (input: string) => {
    handleSearch({ query: input })
  }

  const searchByKeyword = (keyword: string) => {
    handleSearch({ query: keyword })
  }

  const searchByBounds = useRecoilCallback(
    ({ set }) =>
      async (bounds: Bounds) => {
        const { data } = await searchPoisV2({
          params: {
            query,
            regionId,
            boundNe: `${bounds.topRight.latitude},${bounds.topRight.longitude}`,
            boundSw: `${bounds.bottomLeft.latitude},${bounds.bottomLeft.longitude}`,
            isCurrentAreaSearch: true,
            isPredictedKeyword,
            servicedFrom: 'poi_web',
            visitSessionId: analyticsSessionIds.getVisitSessionId(),
            querySessionId: analyticsSessionIds.getQuerySessionId(),
            xAuthToken,
          },
        })

        if (data.items.length > 0) {
          set(poisLoadableFamily.store(variables), (prevState) => {
            const prev = isDefaultLoadable(prevState) ? ({} as ResponseType) : prevState

            return {
              items: [...new Set([...prev.items, ...data.items].map((poi) => JSON.stringify(poi)))]
                .map((poi) => JSON.parse(poi))
                .reverse(),
              hasNextPage: data.hasNextPage,
              page: data.hasNextPage ? prev.page + 1 : prev.page,
              perPage: prev.perPage,
            }
          })
        }

        return { result: data.items }
      },
    [isPredictedKeyword, query, regionId, variables, xAuthToken]
  )

  return {
    variables,
    searchByInput,
    searchByKeyword,
    searchByBounds,
  }
}

import { HeartButton } from '@daangn/karrot-clothes'
import { BookmarkPoiIdDto } from '@daangn/local-business-network/lib/poi'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useMemo } from 'react'

import { withSuspense } from '@src/components/Suspense'

import WatchButtonPlaceholder from './Placeholder'
import { useInternalWatch } from '../hooks/useInternalWatch'

interface Props {
  placeWatchId: BookmarkPoiIdDto
  labels?: {
    default: string
    watched: string
  }
  onClick?: (isAddWatch: boolean) => void
  onAddWatch?: () => void
  onRemoveWatch?: () => void
  className?: string
  customDefaultIconCSS?: string
  customWatchedIconCSS?: string
}

function WatchButton({ placeWatchId, labels, onClick, onAddWatch, onRemoveWatch, ...props }: Props) {
  const { watched, handleClickWatch } = useInternalWatch({
    placeWatchId,
    onClick,
    onAddWatch,
    onRemoveWatch,
  })

  const defaultIconClassName = useMemo(
    () => [defaultIcon, props.customDefaultIconCSS].filter(Boolean).join(' '),
    [props.customDefaultIconCSS]
  )
  const watchedIconClassName = useMemo(
    () => [watchedIcon, props.customWatchedIconCSS].filter(Boolean).join(' '),
    [props.customWatchedIconCSS]
  )

  return (
    <Wrapper className={props.className}>
      <HeartButton
        filled={watched}
        className={watched ? watchedIconClassName : defaultIconClassName}
        onClick={handleClickWatch}
      />
      {labels && (watched ? labels.watched : labels.default)}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${vars.$semantic.typography.label4Regular};
`

const iconStyle = css`
  margin: 0 0 0.125rem;
  padding: 0;
  width: 1.5rem;
  height: 1.5rem;
`

const defaultIcon = css`
  ${iconStyle};
  path:last-of-type {
    fill: ${vars.$scale.color.gray900};
  }
`

const watchedIcon = css`
  ${iconStyle};
  path {
    fill: ${vars.$semantic.color.primary};
  }
`

export default withSuspense(WatchButton, () => <WatchButtonPlaceholder />)

import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { UserNickNameAndProfile } from '@src/apis/generated/poi/models'
import defaultProfileImage from '@src/assets/images/profile_default.png'

interface Props {
  contributors: UserNickNameAndProfile[]
}

const Contributors: React.FC<Props> = ({ contributors }) => {
  return (
    <Container>
      <AvatarGroup>
        {contributors.slice(0, 5).map(({ userId, profileImageUrl }) => (
          <Avatar key={userId} src={profileImageUrl || defaultProfileImage} alt="profileImageUrl" size={20} />
        ))}
      </AvatarGroup>
      <ContributorCount>
        {contributors.length}명 후기 더보기
        <IconChevronRight />
      </ContributorCount>
    </Container>
  )
}

const Container = styled.div`
  margin: 0.375rem 0 0;
  display: flex;
  flex-direction: row;

  & > :not(:first-of-type) {
    margin: 0 0 0 0.25rem;
  }
`
const AvatarGroup = styled.div`
  height: fit-content;
  display: flex;
  align-items: center;
`
const Avatar = styled.img<{ size: number }>`
  width: calc(${({ size }) => size} * 0.0625rem);
  height: calc(${({ size }) => size} * 0.0625rem);
  border-radius: 50%;
  border: 1px solid ${vars.$semantic.color.paperDefault};
  background-color: ${vars.$semantic.color.paperDefault};

  &:not(:first-of-type) {
    margin: 0 0 0 calc(${({ size }) => size} * 0.0625rem / 4 * -1);
  }
`
const ContributorCount = styled.div`
  display: flex;
  align-items: center;
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.caption1Regular};
`
const IconChevronRight = styled(IconChevronRightRegular)`
  width: 0.875rem;
  height: 0.875rem;
  color: ${vars.$scale.color.gray700};
`

export default Contributors

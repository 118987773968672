import { atom } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'

import recoilKeyGenerator from './recoilKeyGenerator'

const generateKey = recoilKeyGenerator('region')

type CheckInRegionInfoType = Awaited<ReturnType<(typeof karrotBridge)['getRegionInfo']>>['info']['region']

export const checkInRegionInfoAtom = atom<CheckInRegionInfoType>({
  key: generateKey('checkin-region-info-atom'),
  default: (async () => {
    if (IS_LOCAL) {
      return {
        id: 366,
        name: '서초4동',
        fullname: '서울 서초구 서초4동',
        name1: '서울측별시',
        name2: '서초구',
        name3: '서초4동',
        name1Id: 1,
        name2Id: 362,
        name3Id: 366,
        parentId: 362,
        centerCoordinates: {
          latitude: 37.500072122029415,
          longitude: 127.03854258877928,
        },
      }
    }

    const region = (await karrotBridge.getRegionInfo({})).info.region

    return region
  })(),
})

import { Poi } from '@daangn/local-business-network/lib/poi'
import React from 'react'

import Slide from '@src/components/Slides/Slide'
import Slides from '@src/components/Slides/Slides'
import { getSafeAreaValue } from '@src/styles/utils'

import { Card } from './Card'
import { CARD_HEIGHT } from '../constants/styles'
import { useAnalytics } from '../hooks'

interface Props {
  pois: Poi[]
  focusedPoiId: string | null
  onSwipeEnd: (poi: Poi) => void
  onSelectPoi: (poi: Poi) => void
}
export const CardCarousel = ({ pois, onSelectPoi, focusedPoiId, onSwipeEnd }: Props) => {
  const logEvent = useAnalytics()

  const focusedCardIndex = focusedPoiId ? pois.findIndex((poi) => poi.id === focusedPoiId) : 0

  const handleSwipeEnd = (index: number) => {
    logEvent('click_swipe_poi_card')

    onSwipeEnd(pois[index])
  }

  return (
    <Slides
      slideGap={0}
      slideMargin={0}
      maxLength={pois.length - 1}
      focusedCardIndex={focusedCardIndex}
      onSwipeCardEnd={handleSwipeEnd}
      height={CARD_HEIGHT + parseInt(getSafeAreaValue('bottom'))}>
      {pois.map((poi, idx) => (
        <Slide key={poi.id} index={idx}>
          <Card poi={poi} onSelectPoi={onSelectPoi} />
        </Slide>
      ))}
    </Slides>
  )
}

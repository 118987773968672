import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useTransparentNavScreenScrollElement } from '@src/components/Navigation/TransparentNavScreen/context'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { IMAGE_CONTAINER_HEIGHT, PROFILE_AREA_HEIGHT, QUICK_MENU_HEIGHT } from '../constants'
import { imagesByPoiIdSelectorFamily, postsTabAtomFamily, PostsTabKey, tabAtomFamily, TabKey } from '../state'

const useTab = ({ poiId }: { poiId: string }) => {
  const scrollRef = useTransparentNavScreenScrollElement()
  const screenInstance = useScreenInstance()

  const setCurrentTab = useSetRecoilState(tabAtomFamily(screenInstance))
  const setPostTab = useSetRecoilState(postsTabAtomFamily(screenInstance))
  const images = useRecoilValue(imagesByPoiIdSelectorFamily(poiId))

  const setTab = (tab: TabKey, postTab?: PostsTabKey) => {
    setCurrentTab(tab)

    if (postTab) {
      setPostTab(postTab)
    }

    if (!scrollRef.current) return

    const scrollTopPixel =
      images.total! > 0
        ? PROFILE_AREA_HEIGHT + QUICK_MENU_HEIGHT + IMAGE_CONTAINER_HEIGHT - 56
        : PROFILE_AREA_HEIGHT + QUICK_MENU_HEIGHT

    scrollRef.current.scrollTop = scrollTopPixel
  }

  return {
    setTab,
  }
}

export default useTab

import React from 'react'
import { useRecoilValue } from 'recoil'

import { withToast } from './components/Toast'
import { useCurrentPosition } from './hooks'
import { Stack } from './stackflow'
import { bridgeInfoUserAtom } from './store/bridgeInfo'

function App() {
  useRecoilValue(bridgeInfoUserAtom)
  useCurrentPosition()

  return (
    <>
      <Stack />
    </>
  )
}

export default withToast(React.memo(App))

import styled from '@emotion/styled'
import IconCallThin from '@karrotmarket/karrot-ui-icon/lib/react/IconCallThin'
import IconChevronRightFill from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightFill'
import IconClockThin from '@karrotmarket/karrot-ui-icon/lib/react/IconClockThin'
import IconGlobalThin from '@karrotmarket/karrot-ui-icon/lib/react/IconGlobalThin'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { SectionHeader } from '@src/components'
import { Section } from '@src/components/Layout'
import { Skeleton } from '@src/components/Loading'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import { useNavigator } from '@src/stackflow'
import { Spacing } from '@src/styles/Spacing'
import { convertCoordinatesObjectToArray } from '@src/utils/coordinate'
import { displayBusinessOpenSchedule } from '@src/utils/date'
import { formatPhoneNumber } from '@src/utils/phone'

import { Address } from './Address'
import { BrandInfo } from './BrandInfo'
import { Direction, DirectionLoading } from './Direction'
import { UpdateSuggestion } from './UpdateSuggestion'
import { useAnalytics } from '../../hooks'

export const InfoSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const logEvent = useAnalytics()
  const { push } = useNavigator()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const brandAccountId = poi?.brandBaId

  const businessHoursText = useMemo(() => {
    if (!poi) return ''

    if (poi.openingHours && poi.openingHours.length > 0) {
      return displayBusinessOpenSchedule(poi.openingHours)
    }

    if (poi.businessHoursFrom && poi.businessHoursTo) {
      return (
        (poi.businessHoursFrom && poi.businessHoursTo ? `${poi.businessHoursFrom} ~ ${poi.businessHoursTo} ` : '') +
        (poi.businessHoursExtra || '')
      )
    }

    return ''
  }, [poi])

  const handleClaimOwnership = () => {
    logEvent('click_claim_ownership_button')
    push('poi_detail_modal', { poiId, modalId: 'claimOwnershipDialog' })
  }

  return (
    <Section
      css={{
        padding: 0,
      }}>
      <Wrapper>
        <SectionHeader
          type="action"
          text="정보"
          action={
            !brandAccountId && (
              <ClaimOwnershipButton onClick={handleClaimOwnership}>
                사장님이신가요? <IconChevronRightFill width={8} height={12} color={vars.$scale.color.gray900} />
              </ClaimOwnershipButton>
            )
          }
          css={{ marginBottom: '1rem' }}
        />
        {poi?.coordinates && (
          <Direction
            address={(poi?.address ?? poi?.jibunAddress) + (poi?.addressExtra ?? '')}
            coordinates={convertCoordinatesObjectToArray(poi.coordinates)}
          />
        )}
        {(poi?.address || poi?.jibunAddress) && (
          <Address
            roadAddress={poi?.address ?? undefined}
            jibunAddress={poi?.jibunAddress ?? undefined}
            addressExtra={poi?.addressExtra ?? undefined}
          />
        )}
        {poi?.phone && (
          <StyleMetaData>
            <IconCallThin />
            <p>{formatPhoneNumber(poi.phone)}</p>
          </StyleMetaData>
        )}
        {businessHoursText && (
          <StyleMetaData>
            <IconClockThin />
            <p>{businessHoursText}</p>
          </StyleMetaData>
        )}
        {poi?.url && (
          <StyleMetaData>
            <IconGlobalThin />
            <a
              href={poi.url}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                /** @deprecated */
                logEvent('click_url_link_button')
                logEvent('click_profile_url', { original_url: poi.url })
              }}>
              {poi.url}
            </a>
          </StyleMetaData>
        )}
      </Wrapper>
      <Divider fullBleed={!!brandAccountId} />
      {brandAccountId ? <BrandInfo brandAccountId={brandAccountId} /> : <UpdateSuggestion />}
    </Section>
  )
}

const InformationSectionLoading: React.FC = () => {
  return (
    <Section>
      <DirectionLoading />
      <Spacing size={10} />
      <Skeleton width={200} />
      <Spacing size={10} />
      <Skeleton width={200} />
      <Spacing size={10} />
      <Skeleton width={200} />
    </Section>
  )
}

const Wrapper = styled.div`
  padding: 1.25rem 1rem 0.75rem;
`
const Divider = styled.hr<{ fullBleed?: boolean }>`
  height: 1px;
  margin: ${({ fullBleed }) => (fullBleed ? '0 auto' : '0 1rem')};
  border: none;
  background-color: ${vars.$semantic.color.divider1};
`

const ClaimOwnershipButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Regular};
`

const StyleMetaData = styled.div<{
  isClickable?: boolean
}>`
  display: flex;
  align-items: flex-start;
  padding: 4px;
  margin: 0 0 12px;

  svg {
    min-width: 18px;
    min-height: 18px;
    width: 18px;
    height: 18px;
  }

  p,
  a {
    display: inline-flex;
    align-items: center;
    flex: 1;
    margin: 0 0 0 12px;
    color: ${vars.$scale.color.gray900};
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
  }
  a {
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export { InformationSectionLoading }

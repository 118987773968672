import styled from '@emotion/styled'
import IconBackwardRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconBackwardRegular'
import { ActivityComponentType } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { withKakaoMap } from '@src/components/Map/KakaoMap'
import { Screen } from '@src/stackflow'

import { Edit } from './Edit/Edit'
import RightBottomButtons from './FloatingLayer/RightBottomButtons'
import { usePois } from './hooks'
import { MapContents } from './Map'
import ButtonShare from './Navbar/ButtonShare'
import FilterButtons from './Navbar/FilterButtons'
import { winterSnackFiltersSelector } from './state'
import View from './View/View'
import NudgeItems from '../../components/Nudge/NudgeItems'

export type WinterSnackParams = {
  filterId?: string
  selectedPoiId?: string
  regionId?: string
  coordinates?: string
  mode?: 'view' | 'edit'
}

const WinterSnackPage: ActivityComponentType<WinterSnackParams> = ({ params: { mode = 'view' } }) => {
  usePois()
  useRecoilValue(winterSnackFiltersSelector)

  return (
    <>
      <Screen
        appBar={{
          title: mode === 'view' ? '겨울 간식 지도' : '장소 등록',
          border: false,
          closeButton:
            mode === 'view'
              ? undefined
              : {
                  render: () => (
                    <IconBackwardRegular
                      width={24}
                      height={24}
                      onClick={(e) => {
                        e.stopPropagation()
                        window.history.back()
                      }}
                    />
                  ),
                },
          appendRight:
            mode === 'view'
              ? () => {
                  return (
                    <ButtonWrapper>
                      <ButtonShare />
                    </ButtonWrapper>
                  )
                }
              : undefined,
        }}>
        <FlexContainer>
          {mode === 'view' && <FilterButtons />}
          <Contents>
            <MapContents />
            {mode === 'view' ? <View /> : <Edit />}
          </Contents>
        </FlexContainer>

        <RightBottomButtons />
      </Screen>
      <NudgeItems />
    </>
  )
}

const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`
const Contents = styled.div`
  flex: 1;
  position: relative;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export default withKakaoMap(WinterSnackPage)

import { useCallback } from 'react'

import { useLogEvent } from '@src/components/Analytics'

const useAnalytics = (entry?: string) => {
  const logEvent = useLogEvent()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = { ...params, entry: entry ?? null }

      logEvent({ name, params: _params })
    },
    [logEvent, entry]
  )
}

export default useAnalytics

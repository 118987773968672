import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { Skeleton } from '@src/components/Loading'
import { Spacing } from '@src/styles/Spacing'

import { PROFILE_AREA_HEIGHT } from '../constants'

interface Props {
  name: string
  regionName?: string
  category?: string
}
const Profile: React.FC<Props> = ({ name, regionName, category }) => {
  return (
    <Container>
      <Name>{name}</Name>
      <Region_Category>
        {[regionName, category].filter(Boolean).map((text, idx) => (
          <span key={idx}>{text}</span>
        ))}
      </Region_Category>
    </Container>
  )
}

const ProfileLoading: React.FC = () => {
  return (
    <Container>
      <Skeleton width={140} height={26} />
      <Spacing size={4} />
      <Skeleton width={100} height={20} />
    </Container>
  )
}

const Container = styled.div`
  padding: 1.5rem 1rem;
  height: calc(${PROFILE_AREA_HEIGHT} * 0.0625rem);
  background-color: ${vars.$semantic.color.paperDefault};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  & > :not(:last-child) {
    margin: 0 0 0.25rem;
  }
`
const Name = styled.h1`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.title2Bold}
`
const Region_Category = styled.h2`
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.caption1Regular};

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }

  span {
    color: ${vars.$scale.color.gray600};
    ${vars.$semantic.typography.caption1Regular};

    &:not(:first-of-type) {
      &::before {
        content: '⸱';
        width: 0.188rem;
        height: 0.875rem;
        margin-right: 0.25rem;
      }
    }
  }
`

export default Profile
export { ProfileLoading }

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { ReactNode, useMemo } from 'react'

import { bridge } from '@src/bridge'
import NavbarCustom from '@src/components/Navigation/TransparentNavScreen/Navbar'
import { useIsWeb } from '@src/hooks/useIsWeb'
import { APP_BAR_HEIGHT } from '@src/stackflow/styles'
import type { Theme } from '@src/types/theme'
interface Props {
  title: string
  appendRight?: ReactNode
  appendBottom?: ReactNode
  appendBottomHeight?: number
  className?: string
  onClickBack?: () => void
}

function NavbarWithBottom({ title, appendRight, appendBottom, onClickBack, ...props }: Props) {
  const isWeb = useIsWeb()
  const deviceType = useMemo(() => (isWeb ? 'Android' : bridge.environment), [isWeb]) as Theme

  return (
    <Container {...props}>
      <NavbarCustom
        title={title}
        iconColor={vars.$scale.color.gray900}
        theme={deviceType}
        appendRight={appendRight}
        hideBoxShadow={true}
        onClickBack={onClickBack}
      />
      {appendBottom && <div className="bottom">{appendBottom}</div>}
    </Container>
  )
}

const Container = styled.div<Pick<Props, 'appendBottomHeight'>>`
  padding: ${APP_BAR_HEIGHT}px 0 0;
  padding: calc(${APP_BAR_HEIGHT}px + constant(safe-area-inset-top)) 0 0;
  padding: calc(${APP_BAR_HEIGHT}px + env(safe-area-inset-top)) 0 0;

  z-index: 98;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${({ appendBottomHeight = 0 }) => `${APP_BAR_HEIGHT + appendBottomHeight}px`};
  height: ${({ appendBottomHeight = 0 }) =>
    `calc(${APP_BAR_HEIGHT + appendBottomHeight}px + constant(safe-area-inset-top))`};
  height: ${({ appendBottomHeight = 0 }) =>
    `calc(${APP_BAR_HEIGHT + appendBottomHeight}px + env(safe-area-inset-top))`};

  background-color: ${vars.$semantic.color.paperDefault};
`

export default NavbarWithBottom

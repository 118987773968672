import styled from '@emotion/styled'
import IconAndroidShareRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconAndroidShareRegular'
import IconIosShareRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconIosShareRegular'
import { vars } from '@seed-design/design-token'
import React, { useCallback } from 'react'

import { useLogEvent } from '@src/components/Analytics'
import { IS_ANDROID } from '@src/constants/environmentConstants'
import { handleShare } from '@src/utils/share'

const ButtonShare: React.FC = () => {
  const logEvent = useLogEvent()

  const handleClick = useCallback(async () => {
    logEvent({
      name: 'click_share_button',
      params: { area: 'navbar' },
    })

    handleShare({
      type: 'WINTER_SNACK',
      data: {
        filterId: 'all',
      },
    })
  }, [logEvent])

  return <Button onClick={handleClick}>{IS_ANDROID ? <IconAndroidShareRegular /> : <IconIosShareRegular />}</Button>
}

const Button = styled.button`
  width: 24px;
  height: 24px;
  color: ${vars.$scale.color.gray900};
`

export default ButtonShare

import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { Callout as CalloutComponent } from '@src/components'
import { useActivity, useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { queryAtom } from '../state/query'

export const CreateSuggestionCallout = () => {
  const logEvent = useAnalytics()
  const { enteredBy } = useActivity()
  const { entry } = useActivityParams<{ entry?: string }>()
  const { push } = useNavigator()

  const query = useRecoilValue(queryAtom)

  const handleClick = () => {
    logEvent('click_suggest_new_poi_button', {
      area: 'list',
    })
    push('create_poi_suggestion', {
      name: query,
      variation: enteredBy.activityName === 'poi_review' || entry === 'poi_review' ? 'writeReview' : undefined,
    })
  }

  return (
    <Callout
      type="action_link"
      state="tip"
      contents="찾고 있는 장소가 없다면 직접 등록해보세요."
      onClick={handleClick}
    />
  )
}

const Callout = styled(CalloutComponent)`
  margin: 0.5rem 1rem;
  width: calc(100% - 2rem);
`

import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import MapIntro from '@src/assets/icons/MapIntro'
import { Drawer } from '@src/components/BottomSheet'
import { Button } from '@src/components/Button'
import { generateGlobalNudgeKey } from '@src/components/Nudge/generateNudgeKey'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { localStorage } from '@src/utils/localStorage'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { isIntroductionDrawerOpenedAtomFamily, mapThemeAtomFamily } from './state'

const NUDGE_KEY = 'mapIntroductionDrawer'

const IntroductionDrawer = () => {
  const screenInstance = useScreenInstance()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const [isOpen, setIsOpen] = useRecoilState(isIntroductionDrawerOpenedAtomFamily(screenInstance))

  const handleClose = () => {
    setIsOpen(false)

    if (!userInfo) return
    localStorage.setItem(generateGlobalNudgeKey(NUDGE_KEY, userInfo.id), 'true')
  }

  useEffect(() => {
    if (!userInfo) return

    const seenKey = generateGlobalNudgeKey(NUDGE_KEY, userInfo.id)
    localStorage.getItem(seenKey).then((value) => {
      if (value === 'true') {
        setIsOpen(false)
      } else {
        if (mapTheme.id === -1) return

        setIsOpen(true)
      }
    })
  }, [mapTheme.id, userInfo, setIsOpen])

  return (
    <Drawer isOpen={isOpen}>
      <Container>
        <CloseButton onClick={handleClose}>
          <IconCloseRegular width={24} height={24} color={vars.$scale.color.gray900} />
        </CloseButton>
        <MapIntro />
        <Content>
          <div className="title">{userInfo?.region.name} 당근지도를 함께 만들어요!</div>
          <p>
            {userInfo?.region.name} 이웃이 직접 추천한 장소를 모았어요.
            <br /> 더 풍성한 당근지도를 만들어보세요.
          </p>
        </Content>
        <Button styleTheme="primary-filled" fullwidth textSize="18" onClick={handleClose}>
          당근지도 시작하기
        </Button>
      </Container>
    </Drawer>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  > svg {
    margin: 16px 0 0;
  }

  .title {
    ${vars.$semantic.typography.title2Bold}
  }

  p {
    ${vars.$semantic.typography.subtitle2Regular}
    text-align: center;
  }
`

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`

export default IntroductionDrawer

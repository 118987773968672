import styled from '@emotion/styled'
import IconGpsRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconGpsRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { useLogEvent } from '@src/components/Analytics'
import { FloatingButton } from '@src/components/Button'
import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useCurrentPosition } from '@src/hooks'

import { TEMP_SPACING_FOR_CENTER } from '../constants/style'

const CurrentPositionButton: React.FC = () => {
  const logEvent = useLogEvent()
  const { panTo } = useKakaoMap()

  const SPACING_FOR_CENTER = useRecoilValue(TEMP_SPACING_FOR_CENTER)
  const { access, position, update, handleOnAccessDenied } = useCurrentPosition()

  const handleClickCurrentPosition = async () => {
    logEvent({ name: 'click_current_position_button', params: {} })
    update()

    if (access === 'denied') {
      handleOnAccessDenied()
    } else if (access === 'granted') {
      panTo({ coordinates: position, yOffset: SPACING_FOR_CENTER })
    }
  }

  return <Button onClick={handleClickCurrentPosition} icon={<IconGpsRegular />} />
}

const Button = styled(FloatingButton)`
  .icon {
    color: ${vars.$scale.color.gray900};
  }
  .icon:active {
    color: ${vars.$semantic.color.primary};
  }
`

export default CurrentPositionButton

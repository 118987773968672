import React from 'react'
import { useSetRecoilState } from 'recoil'

import { addressPickerAtom } from '@src/components/Input/AddressInput/store/addressPicker'
import { useNavigator } from '@src/stackflow'
import { SearchAddressListItem } from '@src/utils/searchAddress'

import { useAnalytics } from './hooks'
import SearchAddressView from './SearchAddressView'

const SearchAddress = () => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()

  const setSearchAddress = useSetRecoilState(addressPickerAtom)

  const handleClickAddress = (address: SearchAddressListItem) => {
    logEvent('click_select_address')
    setSearchAddress({ ...address, coordinates: undefined })
    pop()
    const addressExtraInput = document.getElementById('addressExtra')
    if (!addressExtraInput) return
    addressExtraInput.focus()
  }

  return <SearchAddressView onClickAddress={handleClickAddress} />
}

export default SearchAddress

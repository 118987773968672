import { useActivityParams } from '@stackflow/react'
import { isEmpty } from 'lodash'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { poiByIdSelectorFamily } from '@src/shared/poi/store'

import { BizPostSection } from './BizPostSection'
import { CouponSection } from './CouponSection'
import { InfoSection } from './InfoSection'
import MapSection from './MapSection/MapSection'
import PriceSection from './PriceSection/PriceSection'
import ReviewSection from './ReviewSection/ReviewSection'
import StorySection from './StorySection/StorySection'

const HomeTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const events = poi?.events?.filter((event) => event.name !== 'hospital' && event.name !== 'winter_dessert') ?? []
  const brandAccountId = poi?.brandBaId

  return (
    <>
      <InfoSection />
      {poi?.pricingImages && poi.pricingImages.length > 0 && <PriceSection pricingImages={poi.pricingImages} />}
      {!isEmpty(events) && <MapSection events={events} />}
      {!!brandAccountId && <CouponSection brandAccountId={brandAccountId} />}
      {!!brandAccountId && <BizPostSection brandAccountId={brandAccountId} />}
      <ReviewSection />
      <StorySection />
    </>
  )
}

export default HomeTab

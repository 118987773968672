import React, { useMemo } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import ViewTypeToggleButtonComponent from '@src/components/Map/ViewTypeToggleButton'
import { useToast } from '@src/components/Toast'
import { useActivity, useStepNavigator } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeight } from '../constants/style'
import { useAnalytics, useSelectedWatchedPlaceId } from '../hooks'
import { selectedViewTypeAtomFamily } from '../state'
import { isWatchedPlacesEmptySelectorFamily } from '../state/selectedWatchedPoi'

export const ViewTypeToggleButton = () => {
  const logEvent = useAnalytics()
  const { showToast } = useToast()
  const { stepPush, stepPop } = useStepNavigator('place_watch')
  const activity = useActivity()
  const { selectedWatchedPlaceId } = useSelectedWatchedPlaceId()

  const screenInstance = useScreenInstance()
  const isNoWatchedPois = useRecoilValue(isWatchedPlacesEmptySelectorFamily(screenInstance))

  const selectedViewType = useRecoilValue(selectedViewTypeAtomFamily(activity.id))

  const bottomSpacing = useMemo(
    () =>
      !selectedWatchedPlaceId || selectedViewType === 'LIST'
        ? 16
        : mapPoiCardHeight + DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS,
    [selectedViewType, selectedWatchedPlaceId]
  )

  const handleClickToggleView = useRecoilCallback(
    ({ set, snapshot }) =>
      () => {
        const selectedViewType = snapshot.getLoadable(selectedViewTypeAtomFamily(activity.id)).getValue()

        switch (selectedViewType) {
          case 'MAP':
            logEvent('click_view_list_button')

            set(selectedViewTypeAtomFamily(activity.id), 'LIST')

            if (activity.steps.length > 1) {
              return stepPop()
            } else {
              stepPush({ view_type: 'LIST' })
              return
            }
          case 'LIST':
            logEvent('click_view_map_button')

            if (!window.kakao) {
              showToast({
                text: '오류가 발생하여 일시적으로 지도를 이용할 수 없어요.',
                duration: 'short',
              })

              return
            }

            set(selectedViewTypeAtomFamily(activity.id), 'MAP')

            if (activity.steps.length > 1) {
              return stepPop()
            } else {
              stepPush({ view_type: 'MAP' })
              return
            }
        }
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (isNoWatchedPois) {
    return null
  }

  return (
    <ViewTypeToggleButtonComponent
      viewType={selectedViewType}
      cardMargin={CARD_MARGIN}
      bottomSpacing={bottomSpacing}
      onClick={handleClickToggleView}
    />
  )
}

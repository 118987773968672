import { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { enableFocusingWatchedPlaceAtomFamily } from '../state'
import {
  filteredWatchedPlacesByMapThemeSelectorFamily,
  selectedWatchedPlaceIdAtomFamily,
} from '../state/selectedWatchedPoi'

export function useSelectedWatchedPlaceId() {
  const screenInstance = useScreenInstance()
  const filteredWatchedPlaces = useRecoilValue(filteredWatchedPlacesByMapThemeSelectorFamily(screenInstance))
  const [selectedWatchedPlaceId, setSelectedWatchedPlaceId] = useRecoilState(
    selectedWatchedPlaceIdAtomFamily(screenInstance)
  )
  const setEnableFocusingWatchedPlace = useSetRecoilState(enableFocusingWatchedPlaceAtomFamily(screenInstance))

  // 필터링했을 때 -> 선택한 id가 없으면 새로 검색된 배열의 첫번째, 있으면 그대로
  useEffect(() => {
    if (filteredWatchedPlaces.length <= 0) return

    const isIncludedInFilteredWatchedPlaces = filteredWatchedPlaces?.some(
      ({ targetId }) => targetId === selectedWatchedPlaceId
    )
    const firstPoiId = filteredWatchedPlaces[0].target?.id ?? null

    // 재검색 O & 첫번째 poi 선택
    if (!isIncludedInFilteredWatchedPlaces && selectedWatchedPlaceId && firstPoiId) {
      setSelectedWatchedPlaceId(firstPoiId)
      setEnableFocusingWatchedPlace(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredWatchedPlaces])

  return { selectedWatchedPlaceId, setSelectedWatchedPlaceId }
}

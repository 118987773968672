import { CircularProgress } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { memo } from 'react'

export const Loading: React.FC<{ className?: string }> = memo(({ className }) => {
  return (
    <FullHeightProgressWrapper className={className}>
      <CircularProgress color={vars.$scale.color.grayAlpha200} />
    </FullHeightProgressWrapper>
  )
})

const FullHeightProgressWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

import { Poi } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import React, { useCallback, useMemo, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { CurrentPositionDot } from '@src/components/Map'
import { KakaoCustomOverlay, KakaoMap as KakaoMapComponent, useKakaoMap } from '@src/components/Map/KakaoMap'
import { useCurrentPosition } from '@src/hooks'
import { checkInRegionInfoAtom } from '@src/store'

import { Marker } from './Marker'
import { useAnalytics } from '../hooks'
import { isMapBoundChangedAtom } from '../state/map'

interface Props {
  pois: Poi[]
  focusedPoiId: string | null
  onSelectPoi: (poi: Poi) => void
  onClickMarker: (poi: Poi) => void
  onClickMap: () => void
}

export const Map = ({ pois, focusedPoiId, onSelectPoi, onClickMarker, onClickMap }: Props) => {
  const logEvent = useAnalytics()
  const { getLevel } = useKakaoMap()

  const { position: userPosition, access: userPositionAccess } = useCurrentPosition()
  const { centerCoordinates } = useRecoilValue(checkInRegionInfoAtom)
  const firstPoi = pois.length > 0 ? pois?.[0]?.coordinates : undefined
  const center = useMemo(() => firstPoi || centerCoordinates, [centerCoordinates, firstPoi])
  const zoomLevelRef = useRef<number>(6)

  const setIsMapBoundChanged = useSetRecoilState(isMapBoundChangedAtom)

  const handleDragEnd = useCallback(() => {
    logEvent('click_dragend_map')
    setIsMapBoundChanged(true)
  }, [logEvent, setIsMapBoundChanged])

  const handleZoomChanged = useCallback(() => {
    const previousLevel = zoomLevelRef.current
    const currentLevel = getLevel()

    if (previousLevel === currentLevel) return

    logEvent(previousLevel < currentLevel ? 'click_zoom_out_map' : 'click_zoom_in_map', {
      previous_level: previousLevel,
      current_level: currentLevel,
    })

    setIsMapBoundChanged(true)
    zoomLevelRef.current = getLevel()
  }, [getLevel, logEvent, setIsMapBoundChanged])

  return (
    <KakaoMap
      zoomable={true}
      draggable={true}
      center={center}
      level={6}
      onClick={onClickMap}
      onDragEnd={handleDragEnd}
      onZoomChanged={handleZoomChanged}>
      {userPositionAccess === 'granted' && (
        <KakaoCustomOverlay position={userPosition} clickable={false}>
          <CurrentPositionDot />
        </KakaoCustomOverlay>
      )}
      {pois.map((poi) => (
        <Marker key={poi.id} poi={poi} focusedPoiId={focusedPoiId} onClick={onClickMarker} onSelect={onSelectPoi} />
      ))}
    </KakaoMap>
  )
}

const KakaoMap = styled(KakaoMapComponent)`
  position: relative;
  width: 100%;
  height: 100%;
`

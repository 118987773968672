import styled from '@emotion/styled'
import React, { useEffect, useMemo, useRef } from 'react'

declare global {
  interface Window {
    // https://apis.map.kakao.com/web/guide/
    kakao: any
  }
}

interface Props {
  center?: [number, number] | null
  onMapLoad: (kakao: any, mapRef: any, center: [number, number]) => void
  onMapUnload?: () => void
  onMapFailedToLoad?: () => void
}

const Map: React.FC<Props> = ({ center, onMapLoad, onMapUnload }) => {
  const mapRef = useRef<HTMLDivElement>(null)
  const kakaoMapRef = useRef<any>(null)
  const kakaoRef = useRef<any>(null)

  const isMapLoaded = useRef<boolean>(false)

  const memoCenter = useMemo(() => {
    return center || ([37.566168, 126.978052] as [number, number])
  }, [center])

  useEffect(() => {
    return () => {
      onMapUnload?.()
    }
  }, [onMapUnload])

  useEffect(() => {
    if (isMapLoaded.current) return

    kakaoRef.current = window.kakao
    const kakao = kakaoRef.current

    if (!memoCenter) return

    const targetPoint = new kakao.maps.LatLng(...memoCenter)

    const options = {
      center: targetPoint,
    }

    const map = new kakao.maps.Map(mapRef.current, options)
    kakaoMapRef.current = map

    map.setZoomable(true)
    map.setDraggable(true)

    onMapLoad?.(kakao, map, memoCenter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!kakaoMapRef.current || !kakaoRef.current) return
    const kakaoLatLng = new kakaoRef.current.maps.LatLng(...memoCenter)
    kakaoMapRef.current.setCenter(kakaoLatLng)
  }, [memoCenter])

  return (
    <Container className="kakao-map">
      <MapWrapper ref={mapRef} />
    </Container>
  )
}

export default Map

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

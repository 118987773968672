import styled from '@emotion/styled'
import React from 'react'
import { useRecoilValue } from 'recoil'

import WriteReviewButton from './WriteReviewButton'
import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeightAtom } from '../constants/style'
import { useIsCardShown } from '../hooks'
import CurrentPositionButton from '../MapView/CurrentPositionButton'
import { ViewType } from '../types'

interface Props {
  viewType: ViewType
}
const RightBottomButtons = ({ viewType }: Props) => {
  const mapPoiCardHeight = useRecoilValue(mapPoiCardHeightAtom)

  const isCardShown = useIsCardShown()

  const bottomSpacing =
    !isCardShown || viewType === 'LIST' ? 16 : mapPoiCardHeight + DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS

  return (
    <RightSideButtonWrapper bottomSpacing={bottomSpacing}>
      {viewType === 'MAP' && <CurrentPositionButton />}
      <WriteReviewButton viewType={viewType} bottomSpacing={bottomSpacing} />
    </RightSideButtonWrapper>
  )
}

const RightSideButtonWrapper = styled.div<{ bottomSpacing: number }>`
  z-index: 98;
  position: fixed;
  right: 16px;
  transform: translateY(-${({ bottomSpacing }) => bottomSpacing}px);
  bottom: ${CARD_MARGIN}px;
  bottom: calc(${CARD_MARGIN}px + env(safe-area-inset-bottom));
  bottom: calc(${CARD_MARGIN}px + constant(safe-area-inset-bottom));

  transition: transform 200ms;

  display: flex;
  flex-direction: column;
  gap: 12px;
`

export default RightBottomButtons

import { atom, useRecoilState } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

export const writePoiReviewKey = recoilKeyGenerator('writePoi')

export const EMPTY_SELECTED_POI = {
  id: '',
  name: '',
  bizAccountId: undefined,
}

export interface SelectedPoiInfo {
  id: string
  name: string
  bizAccountId?: string
}
const selectedPoiInfoAtom = atom<SelectedPoiInfo>({
  key: writePoiReviewKey('selectedPoiInfo'),
  default: EMPTY_SELECTED_POI,
})

export const usePoiInputSelectionState = () => {
  return useRecoilState(selectedPoiInfoAtom)
}

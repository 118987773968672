import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { isEmpty } from 'lodash'
import React from 'react'

import { PostPreviewType } from './PostPreview'
import { SquareBadge } from '../Badge'
import { ReactionDisplay } from '../Reaction'
import { UserInfo } from '../User'

interface Props {
  className?: string
  data: PostPreviewType
  onContentClick?: () => void
}

const PostPreviewSimplified: React.FC<Props> = ({ className, data, onContentClick }) => {
  const images = data.images?.url

  return (
    <Container className={className}>
      <UserInfo
        name={data.author.name}
        id={data.author.id}
        profileImage={data.author.profileImage}
        regionName={data.author.regionName}
        regionCheckInCount={data.author.regionCheckInCount}
        createdAt={data.createdAt}
      />
      <Content onClick={onContentClick}>
        <p>{data.content}</p>
        {!!images && !isEmpty(images) && (
          <ImageContainer>
            <img src={images[0]} />
            {images.length > 1 && <span>{images.length}</span>}
          </ImageContainer>
        )}
      </Content>
      {data.type === 'comment' && (
        <CommentOrigin onClick={onContentClick} isDeleted={!data.articleContent}>
          <SquareBadge size="small" type="basic" outlined bold css={customSquareBadgeCSS}>
            원문
          </SquareBadge>
          <p>{data.articleContent ?? '삭제된 게시글이에요.'}</p>
        </CommentOrigin>
      )}
      {data.type !== 'review' && !!data.reaction.count && data.reaction.count > 0 && (
        <ReactionDisplay reactionCount={data.reaction.count} reactionTypes={data.reaction.types ?? []} />
      )}
    </Container>
  )
}

const Container = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 138%;
  color: ${vars.$scale.color.gray900};
  p {
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
`

const ImageContainer = styled.div`
  position: relative;

  img {
    height: 4.375rem;
    width: 4.375rem;
    object-fit: cover;
    border-radius: 6px;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: ${vars.$static.color.staticBlackAlpha500};
    font-weight: 700;
    font-size: 0.688rem;
    color: ${vars.$static.color.staticWhite};
    line-height: 155%;
    letter-spacing: -0.2px;
  }
`
const CommentOrigin = styled.div<{ isDeleted: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 154%;
    color: ${({ isDeleted }) => (isDeleted ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};
  }
`

const customSquareBadgeCSS = css`
  color: ${vars.$scale.color.gray600};
  border: 1px solid ${vars.$scale.color.gray400};
`

export default PostPreviewSimplified

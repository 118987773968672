import styled from '@emotion/styled'
import React from 'react'

import { withKakaoMap } from '@src/components/Map/KakaoMap'

import { CardCarousel } from './CardCarousel'
import Map from './Map'

export const Contents = withKakaoMap(() => {
  return (
    <Container>
      <ContentWrapper zIndex={1}>
        <Map />
      </ContentWrapper>
      <CardSection>
        <CardCarousel />
      </CardSection>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const ContentWrapper = styled.div<{ zIndex: number }>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  width: 100%;
  height: 100%;
`

const CardSection = styled.div`
  z-index: 2;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 16px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

import { createLoadableFamily } from '@daangn/recoil-loadable'

import { POIReviewsApi } from '@src/apis/generated/poi/api'
import { GetReviewsByIdsV2200ResponseAllOf } from '@src/apis/generated/poi/models'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'
import { CreateSerializableParam } from '@src/types/recoil'

const generateKey = recoilKeyGenerator('reviews')

type ReviewRequestType = Parameters<typeof POIReviewsApi.getReviewsByPoiIdV2>
type ReviewResponseType = GetReviewsByIdsV2200ResponseAllOf

interface ReviewPagination {
  page: number
  perPage: number
}

interface ReviewFetchVariables {
  poiId: ReviewRequestType['0']
  userId: ReviewRequestType['1']
  sort: NonNullable<ReviewRequestType['2']>
}

export const reviewsByPoiIdAndUserIdLoadableFamily = createLoadableFamily<
  (ReviewPagination & ReviewResponseType) | null,
  CreateSerializableParam<ReviewFetchVariables>,
  ReviewResponseType | null
>({
  getStore: ({ atomFamily }) => atomFamily({ key: generateKey('reviews-by-poi-id-and-user-id-loadable-family') }),
  fetch: async ({ behavior, prevState, variables }) => {
    const page = behavior === 'initial' ? 1 : 'loadMore' && prevState?.hasNextPage ? prevState.page + 1 : 1
    const perPage = prevState?.perPage ?? 10

    if (!variables.poiId) return null

    const { data } = await POIReviewsApi.getReviewsByPoiIdV2(
      variables.poiId,
      variables.userId,
      variables.sort,
      page,
      perPage
    )

    return data
  },
  onSuccess: ({ response, prevState, behavior }) => {
    if (!response) return prevState

    return {
      items:
        behavior === 'loadMore' && prevState?.items
          ? [...new Set([...(prevState?.items ?? []), ...response.items])]
          : response.items,
      hasNextPage: response.hasNextPage,
      page: behavior === 'loadMore' && prevState?.hasNextPage ? prevState.page + 1 : prevState?.page ?? 1,
      perPage: prevState?.perPage ?? 10,
      total: response.total ?? 0,
    }
  },
})

import React, { SVGProps } from 'react'

const IconWatchListRegular = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3.33049 4.07031C2.87209 4.07031 2.50049 4.44192 2.50049 4.90031C2.50049 5.35871 2.87209 5.73031 3.33049 5.73031H20.1305C20.5889 5.73031 20.9605 5.35871 20.9605 4.90031C20.9605 4.44192 20.5889 4.07031 20.1305 4.07031H3.33049Z"
      fill="currentColor"
    />
    <path
      d="M3.33049 11.0703C2.87209 11.0703 2.50049 11.4419 2.50049 11.9003C2.50049 12.3587 2.87209 12.7303 3.33049 12.7303H9.13049C9.58888 12.7303 9.96049 12.3587 9.96049 11.9003C9.96049 11.4419 9.58888 11.0703 9.13049 11.0703H3.33049Z"
      fill="currentColor"
    />
    <path
      d="M3.33049 18.0703C2.87209 18.0703 2.50049 18.4419 2.50049 18.9003C2.50049 19.3587 2.87209 19.7303 3.33049 19.7303H9.13049C9.58888 19.7303 9.96049 19.3587 9.96049 18.9003C9.96049 18.4419 9.58888 18.0703 9.13049 18.0703H3.33049Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9949 12.0473C15.8744 10.9014 14.0651 10.9181 12.9647 12.0974C11.8189 13.3255 12.0988 15.0939 12.8947 16.2286L12.8957 16.23C13.6548 17.3072 14.5749 18.1671 15.2941 18.753C15.6559 19.0476 15.9721 19.2772 16.1999 19.4343C16.3139 19.513 16.4061 19.5737 16.4712 19.6157C16.5037 19.6366 16.5295 19.6529 16.5479 19.6644L16.5698 19.678L16.5765 19.6821L16.5787 19.6834L16.5799 19.6841C16.5799 19.6841 16.5802 19.6843 16.9949 19.0002L16.5799 19.6841C16.8392 19.8413 17.1653 19.8386 17.4217 19.6768L16.9949 19.0002C17.4217 19.6768 17.4217 19.6768 17.4217 19.6768L17.4231 19.6759L17.4252 19.6746L17.4315 19.6706L17.4525 19.6571C17.4701 19.6456 17.4948 19.6294 17.5261 19.6086C17.5887 19.5668 17.6775 19.5063 17.7877 19.4279C18.0081 19.2713 18.3152 19.0426 18.6701 18.7493C19.3766 18.1653 20.2886 17.3119 21.0817 16.247L21.0829 16.2454C21.9349 15.0954 22.1593 13.3215 21.0268 12.0994L21.025 12.0974C19.9246 10.9181 18.1153 10.9014 16.9949 12.0473ZM16.9874 18.0326C17.1671 17.9012 17.3946 17.7278 17.6507 17.516C18.2952 16.9834 19.1055 16.2216 19.7978 15.2922C20.3276 14.5765 20.3204 13.6922 19.8542 13.1879C19.3706 12.6708 18.6005 12.671 18.1172 13.1885L17.5806 13.7654C17.4293 13.9281 17.2171 14.0206 16.9949 14.0206C16.7726 14.0206 16.5604 13.9281 16.4091 13.7654L15.8729 13.1889C15.3893 12.671 14.6181 12.6707 14.1346 13.1889C13.6808 13.6754 13.6751 14.5544 14.2041 15.3091C14.8505 16.2262 15.6527 16.9815 16.3046 17.5124C16.5672 17.7263 16.802 17.9011 16.9874 18.0326Z"
      fill="currentColor"
    />
  </svg>
)

export default IconWatchListRegular

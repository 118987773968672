import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { ImageContainer } from '@src/components/Image'
import { useTransparentNavbarState } from '@src/components/Navigation/TransparentNavScreen/context'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import { Spacing } from '@src/styles/Spacing'

import Profile, { ProfileLoading } from './Profile'
import QuickMenu, { QuickMenuLoading } from './QuickMenu'
import { IMAGE_CONTAINER_HEIGHT, PROFILE_AREA_HEIGHT, QUICK_MENU_HEIGHT } from '../constants'
import { imagesByPoiIdSelectorFamily } from '../state'
import { Tab } from '../state/tabs'
import Tabs, { TabsLoading } from '../Tabs'

interface Props {
  tabs: Tab[]
}
const Header = ({ tabs }: Props) => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { scrollRef, navbarHeight } = useTransparentNavbarState()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))
  const images = useRecoilValue(imagesByPoiIdSelectorFamily(poiId))

  const imageCount = useMemo(() => images.total ?? images.items.length ?? 0, [images.items.length, images.total])

  const [showFloatingTabs, setShowFloatingTabs] = useState<boolean>(false)

  useEffect(() => {
    const conditionalNavbarHeight = imageCount > 0 ? navbarHeight : 0
    const conditionalImageContainerHeight = imageCount > 0 ? IMAGE_CONTAINER_HEIGHT : 0

    const floatingTabBreakPoint =
      PROFILE_AREA_HEIGHT + QUICK_MENU_HEIGHT + conditionalImageContainerHeight - conditionalNavbarHeight

    const handleScrollPage = () => {
      if ((scrollRef.current?.scrollTop || 0) > floatingTabBreakPoint) {
        setShowFloatingTabs(true)
      } else {
        setShowFloatingTabs(false)
      }
    }

    const eventAttachedRef = scrollRef?.current
    eventAttachedRef?.addEventListener('scroll', handleScrollPage)

    return () => {
      eventAttachedRef?.removeEventListener('scroll', handleScrollPage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navbarHeight, images])

  return (
    <>
      <FloatingTab navbarHeight={navbarHeight} showFloatingTabs={showFloatingTabs}>
        <Tabs tabs={tabs} />
      </FloatingTab>

      {imageCount > 0 && (
        <ImageContainer images={images.items.map((item) => item.image.url)} height={`${IMAGE_CONTAINER_HEIGHT}px`} />
      )}

      {poi && <Profile name={poi.name ?? ''} regionName={poi.region?.name} category={poi.category?.name} />}
      <QuickMenu />
      <Tabs tabs={tabs} />
    </>
  )
}

const HeaderLoading: React.FC = () => {
  return (
    <StyledCard>
      <Spacing size={56} />
      <ProfileLoading />
      <QuickMenuLoading />
      <TabsLoading />
    </StyledCard>
  )
}

const StyledCard = styled.section`
  margin: 0 0 8px;
  /** @todo: seed-design */
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.03);
  background-color: ${vars.$semantic.color.paperDefault};
`

const FloatingTab = styled.div<{ navbarHeight: number; showFloatingTabs: boolean }>`
  display: ${({ showFloatingTabs }) => !showFloatingTabs && 'none'};
  top: ${({ navbarHeight }) => navbarHeight}px;
  position: fixed;
  width: 100%;
  background-color: ${vars.$semantic.color.paperDefault};
  box-shadow: 0 4px 2px -2px ${vars.$scale.color.gray100};
  z-index: 2;
`

export default Header
export { HeaderLoading }

import { v4 } from 'uuid'

import { AnalyticsSessionIds } from '@src/components/Analytics'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

export const generateSearchPoiAnalyticsKey = recoilKeyGenerator(['searchPoi', 'analytics'])

const initialQuerySessionId = v4()
const initialVisitSessionId = v4()

export const analyticsSessionIds = new AnalyticsSessionIds({
  initialQuerySessionId,
  initialVisitSessionId,
})

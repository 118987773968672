import { addBreadcrumb, captureException } from '@sentry/react'
import exif from 'blueimp-load-image'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { EMPTY, from as from$, lastValueFrom } from 'rxjs'
import { catchError, concatMap, finalize, map, mergeMap, tap, toArray } from 'rxjs/operators'
import { v4 } from 'uuid'

import { bigPictureApi } from '@src/apis'
import BigPictureAPI from '@src/apis/BigPicture'
import { BigPictureUploadRespone } from '@src/apis/types/bigPicture'
import { bridge } from '@src/bridge'
import { IS_IOS } from '@src/constants/environmentConstants'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { uniqByArrayObject } from '@src/utils/array'

import { FILE_RESIZE_OPTIONS, toBlobPromise, validateFileFormat } from './utils/image'

export interface ImageUploadPicture {
  url: string
  id: string
  imageId?: number
  loading: boolean
}

export type Image = Omit<ImageUploadPicture, 'loading'>
interface ImageUploadOptions {
  formOptions?: Parameters<BigPictureAPI['imageUpload']>[0]['formOptions']
  fileResizeOptions?: Parameters<exif>[1]
}

export const useImageUpload = (
  maxCount = 1,
  images?: Image[],
  onImageUploaded?: (pictures: BigPictureUploadRespone[]) => void,
  options: ImageUploadOptions = {}
) => {
  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const [imageUploading, setImageUploading] = useState(false)
  const [pictures, setPictures] = useState<ImageUploadPicture[]>([])

  useEffect(() => {
    if (images && images.length) {
      setPictures((prevPictures) =>
        uniqByArrayObject(
          [
            ...images.map((image) => ({
              url: image.url,
              id: image.id,
              loading: false,
              imageId: image.imageId,
            })),
            ...prevPictures,
          ],
          'id'
        ).slice(0, maxCount)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChangeFileInput = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target || !e.target.files || !userInfo) {
        return
      }

      const { id: userId, authToken } = userInfo

      const isMaxOnly = maxCount === 1

      const fileLength = e.target.files.length
      const availableFileLength = isMaxOnly ? 1 : maxCount - pictures.length
      if (maxCount > 1 && fileLength > availableFileLength) {
        bridge.toast.open({ body: `사진은 최대 ${maxCount}장까지만 올릴 수 있어요.` })
      }
      const allowFormatList = Array.from(e.target.files).filter(validateFileFormat)
      if (fileLength !== allowFormatList.length) {
        bridge.toast.open({ body: '지원되지 않는 포맷이에요.' })
      }
      if (allowFormatList.length) {
        setImageUploading(true)
      }

      lastValueFrom(
        from$(allowFormatList.slice(0, availableFileLength)).pipe(
          concatMap((file) => {
            const id = v4()
            return from$(exif(file, options.fileResizeOptions || FILE_RESIZE_OPTIONS)).pipe(
              catchError(() => EMPTY),
              tap(({ image }) => {
                setPictures((prevPictures) =>
                  isMaxOnly
                    ? [{ url: image.toDataURL(), loading: true, id }]
                    : [...prevPictures, { url: image.toDataURL(), loading: true, id }]
                )
              }),
              map((resizeExifData) => ({ resizeExifData, file, id }))
            )
          }),
          mergeMap(({ resizeExifData, file, id }) =>
            from$(toBlobPromise(resizeExifData.image, file.type, IS_IOS)).pipe(
              mergeMap((blob) =>
                from$(
                  bigPictureApi.imageUpload({
                    authToken,
                    userId,
                    blob,
                    file,
                    image: resizeExifData.image,
                    formOptions: options.formOptions,
                  })
                )
              ),
              map(({ data: { image } }) => {
                return image as BigPictureUploadRespone & { old_image_id: number }
              }),
              tap((image) =>
                setPictures((prevPictures) =>
                  prevPictures.map((currentImage) =>
                    currentImage.id === id
                      ? {
                          ...currentImage,
                          loading: false,
                          id: image.id,
                          imageId: image.old_image_id,
                          url: image.medium,
                        }
                      : currentImage
                  )
                )
              ),
              catchError(() => {
                bridge.toast.open({ body: '이미지 업로드에 실패했어요.' })
                setPictures((prevPictures) => prevPictures.filter((currentImage) => currentImage.id !== id))
                return EMPTY
              })
            )
          ),
          toArray(),
          finalize(() => {
            setImageUploading(false)
            e.target.value = ''
          })
        )
      )
        .then((data) => onImageUploaded?.(data))
        .catch((err) => {
          addBreadcrumb({ message: 'Image upload failure' })
          captureException(err)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxCount, pictures, userInfo]
  )

  const handleDeletePicture = useCallback((id: string) => {
    setPictures((prevPictures) => prevPictures.filter((picture) => picture.id !== id))
  }, [])
  const handleAddPictures = useCallback(
    (images: Image[]) => {
      setPictures((prevPictures) =>
        uniqByArrayObject(
          [
            ...images.map((image) => ({
              url: image.url,
              id: image.id,
              loading: false,
              imageId: image.imageId,
            })),
            ...prevPictures,
          ],
          'id'
        ).slice(0, maxCount)
      )
    },
    [maxCount]
  )
  const reset = useCallback(() => setPictures([]), [])

  return useMemo(
    () => ({
      handleDeletePicture,
      handleAddPictures,
      pictures,
      imageUploading,
      onChangeFileInput,
      reset,
    }),
    [handleDeletePicture, handleAddPictures, pictures, imageUploading, onChangeFileInput, reset]
  )
}

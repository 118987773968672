import styled from '@emotion/styled'
import React, { useState } from 'react'

import { Button } from '@src/components/Button'
import { MultiLineTextInput } from '@src/components/Input'

interface Props {
  onSubmit?: (userComment: string) => void
}

const MapReportEtcForm: React.FC<Props> = ({ onSubmit }) => {
  const [userComment, setUserComment] = useState<string>('')
  const handleSubmit = () => onSubmit?.(userComment)

  return (
    <Container>
      <Title>신고 항목 직접 입력</Title>
      <MultiLineTextInput
        id="user-comment"
        onChange={(e) => setUserComment(e.target.value)}
        value={userComment}
        placeholder="신고하는 이유를 직접 입력해주세요."
        maxTextLength={300}
        fixedHeight={180}
        rowsLength={6}
      />
      <ActionButton
        styleTheme="primary-filled"
        textSize="18"
        onClick={handleSubmit}
        type="submit"
        disabled={userComment.length < 1}
        fullwidth>
        신고하기
      </ActionButton>
    </Container>
  )
}

const Container = styled.div`
  padding: 0px 16px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding: 24px 0;
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`

export default MapReportEtcForm

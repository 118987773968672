import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useLayoutEffect, useRef, useState } from 'react'

import { Button as ButtonComponent } from './Button'

interface Props {
  title?: string | React.ReactNode
  body: string | React.ReactNode
  CTAButton?: {
    label: string
    onClick: () => void
  }
}
export const EmptyOrError = ({ title, body, CTAButton }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [offsetHeight, setOffsetHeight] = useState(0)

  const renderTitle = () => {
    if (!title) return null

    if (typeof title === 'string') {
      return (
        <TitleWrapper>
          <Title>{title}</Title>
        </TitleWrapper>
      )
    }

    return <TitleWrapper>{title}</TitleWrapper>
  }

  const renderBody = () => {
    if (!body) return null

    if (typeof body === 'string') {
      return <Body>{body}</Body>
    }

    return body
  }

  const renderCTAButton = () => {
    if (!CTAButton) return null

    return (
      <CTAButtonWrapper>
        <Button styleTheme="secondary" onClick={CTAButton.onClick}>
          {CTAButton.label}
        </Button>
      </CTAButtonWrapper>
    )
  }

  useLayoutEffect(() => {
    setOffsetHeight(ref.current?.offsetHeight ?? 0)
  }, [])

  return (
    <Container ref={ref} offsetHeight={offsetHeight}>
      {renderTitle()}
      {renderBody()}
      {renderCTAButton()}
    </Container>
  )
}

const Container = styled.div<{ offsetHeight: number }>`
  margin: auto 3.375rem;
  position: relative;
  top: ${({ offsetHeight }) => `calc(40% - (${offsetHeight}px) / 2)`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TitleWrapper = styled.div`
  margin: 0 0 0.25rem;
`
const Title = styled.h3`
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Regular};
`
const Body = styled.p`
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.subtitle2Regular};
`
const CTAButtonWrapper = styled.div`
  margin: 2.375rem 0 0;
`
const Button = styled(ButtonComponent)`
  padding: 0.656rem 1rem;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Bold};
`

import { atom } from 'recoil'

import { bridge } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { Coordinates } from '@src/types/global'
import { tokenStorage } from '@src/utils/tokenStorage'

export type BridgeUser = {
  id: number
  authToken: string
  nickname: string
  region: {
    id: number
    name: string
    center_coordinates?: Coordinates
    centerCoordinates?: Coordinates
  }
}

export type BridgeApp = {
  deviceIdentity: string
  userAgent: string
  locale: string
  appHost: string
  country: 'KR' | 'GB' | 'CA' | 'JP'
}

export interface BridgeUserPosition {
  position: { latitude: number; longitude: number }
  timestamp: number
}

const BRIDGE_PREFIX = '/bridge'

const INFO_PREFIX = '/info'

export const bridgeInfoUserAtom = atom<BridgeUser | null>({
  key: `${BRIDGE_PREFIX}${INFO_PREFIX}/user/selector`,
  default: (async () => {
    if (IS_LOCAL) {
      if (!process.env.LOCAL_AUTH_TOKEN) throw new Error('LOCAL_AUTH_TOKEN does not exist in the environment')
      if (!process.env.LOCAL_USER_ID) throw new Error('LOCAL_USER_ID does not exist in the environment')

      tokenStorage.setAuthToken(process.env.LOCAL_AUTH_TOKEN)

      return {
        id: process.env.LOCAL_USER_ID,
        nickname: '테빅',
        authToken: process.env.LOCAL_AUTH_TOKEN,
        region: {
          id: 366,
          name: '역삼1동',
          center_coordinates: { latitude: 37.500072122029415, longitude: 127.03854258877928 },
        },
      }
    }

    return await bridge.info.user()
  })(),
})

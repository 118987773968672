import IconAndroidShareRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconAndroidShareRegular'
import IconIosShareRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconIosShareRegular'
import { makeJsonEncoder } from '@urlpack/json'
import omitBy from 'lodash/omitBy'
import React, { useCallback } from 'react'
import { useRecoilValue } from 'recoil'

import { bridge } from '@src/bridge'
import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { VIEW_BUTTON_HEIGHT } from '@src/components/Map/ViewTypeToggleButton'
import { SERVER_BASE_URL } from '@src/constants/apiUrls'
import { IS_ANDROID, IS_LOCAL } from '@src/constants/environmentConstants'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { Button } from './Navbar'
import { CARD_MARGIN, DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS, mapPoiCardHeightAtom, SAFE_AREA } from '../constants/style'
import { useAnalytics, usePois, useIsCardShown } from '../hooks'
import { mapThemeAtomFamily, selectedPoiIdAtomFamily } from '../state'

const urlPrefixForSharedPage = `${SERVER_BASE_URL({ region: 'kr' }).POI}/shared/map`

const ButtonShare: React.FC = () => {
  const screenInstance = useScreenInstance()

  const selectedPoiId = useRecoilValue(selectedPoiIdAtomFamily(screenInstance))
  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const mapPoiCardHeight = useRecoilValue(mapPoiCardHeightAtom)
  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const isCardShown = useIsCardShown()
  const { getBounds, getLevel, getCenter } = useKakaoMap()
  const logEvent = useAnalytics()
  const { getPoi } = usePois()
  const encoder = makeJsonEncoder()

  const handleClick = useCallback(async () => {
    logEvent('click_share_button', {
      share_type: selectedPoiId ? 'poi' : 'map',
      poi_id: selectedPoiId,
      area: 'navbar',
    })

    const selectedPoi = selectedPoiId ? await getPoi(selectedPoiId).poi : null

    const getBoundToShare = () => {
      const currentBounds = getBounds({
        margin: {
          bottom: isCardShown
            ? VIEW_BUTTON_HEIGHT +
              DISTANCE_BETWEEN_POI_CARD_AND_BUTTONS +
              mapPoiCardHeight +
              CARD_MARGIN +
              SAFE_AREA.getBottom()
            : 0,
        },
      })

      if (!currentBounds) return undefined

      const { bottomLeft: SW, topRight: NE } = currentBounds

      const swLat = parseFloat(SW.latitude.toFixed(6))
      const swLng = parseFloat(SW.longitude.toFixed(6))
      const neLat = parseFloat(NE.latitude.toFixed(6))
      const neLng = parseFloat(NE.longitude.toFixed(6))

      const zoomLevel = getLevel()

      return [swLat, swLng, neLat, neLng, zoomLevel]
    }

    const centerCoordinates = getCenter() ?? userInfo?.region.centerCoordinates ?? userInfo?.region.center_coordinates

    const encodedParams = encoder.encode(
      omitBy(
        {
          b: getBoundToShare(),
          t: mapTheme.id,
          i: selectedPoi?.id ?? undefined,
        },
        (value) => !value
      )
    )

    const urlQueryParam = `?p=${encodedParams}${
      selectedPoi || !centerCoordinates
        ? ''
        : `&c=${centerCoordinates.latitude.toFixed(6)},${centerCoordinates.longitude.toFixed(6)}`
    }`

    if (IS_LOCAL) {
      console.log(`http:localhost:3000/#/map${urlQueryParam}`)
    }

    bridge.share.open({
      url: `${urlPrefixForSharedPage}${urlQueryParam}`,
      text: selectedPoi?.name ?? `${mapTheme.name} | 당근지도`,
    })
  }, [
    mapPoiCardHeight,
    mapTheme,
    encoder,
    getBounds,
    getLevel,
    getPoi,
    isCardShown,
    logEvent,
    selectedPoiId,
    getCenter,
    userInfo,
  ])

  return <Button onClick={handleClick}>{IS_ANDROID ? <IconAndroidShareRegular /> : <IconIosShareRegular />}</Button>
}

export default ButtonShare

import { useActivityParams } from '@stackflow/react'
import { useRecoilCallback } from 'recoil'

import { useNavigator } from '@src/stackflow'

import { PurposefulCategoryActivityParams } from '../PurposefulCategoryPage'
import { categoryFilterOptionsSelectorFamily, sortOptionsSelectorFamily } from '../store'

export const useFilter = () => {
  const activityParams = useActivityParams<PurposefulCategoryActivityParams>()
  const { themeId } = activityParams
  const { replace } = useNavigator()
  const reset = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const sortOptions = snapshot.getLoadable(sortOptionsSelectorFamily(themeId)).getValue()
        const categoryFilterOptions = snapshot.getLoadable(categoryFilterOptionsSelectorFamily(themeId)).getValue()

        replace(
          'purposeful_category',
          {
            ...activityParams,
            categoryFilterId: categoryFilterOptions[0].id,
            sortBy: sortOptions[0].id,
            toggleFilterIds: '',
          },
          { animate: false }
        )
      },
    [activityParams, replace, themeId]
  )
  return { reset }
}

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { TopContent, UserNickNameAndProfile } from '@src/apis/generated/poi/models'
import { EllipsisText } from '@src/components/Map/EllipsisText'

import Contributors from './Contributors'

interface Props {
  topContent: TopContent
  contributedUserProfile?: UserNickNameAndProfile[]
}

const TopContentView: React.FC<Props> = ({ topContent, contributedUserProfile }) => {
  const {
    content,
    user: { id },
  } = topContent!

  const otherContributors = contributedUserProfile?.filter(({ userId }) => userId !== id.toString()) || []

  return (
    <Container>
      <Text line={2}>{content}</Text>
      {otherContributors.length !== 0 && <Contributors contributors={otherContributors} />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`

const Text = styled(EllipsisText)`
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.bodyM1Regular};
`

export default TopContentView

import { isUndefined } from 'lodash'
import { useRecoilCallback, useResetRecoilState, useSetRecoilState } from 'recoil'

import { Bounds } from '@src/components/Map/KakaoMap/types'
import { GPS } from '@src/types/global'
import { useScreenInstance } from '@src/widgets/screenInstance'

import {
  boundsAtomFamily,
  centerPositionAtomFamily,
  isCurrentAreaSearchAtomFamily,
  MapTheme,
  mapThemeAtomFamily,
} from '../state'

export const useFetchPois = () => {
  const screenInstance = useScreenInstance()

  const setBounds = useSetRecoilState(boundsAtomFamily(screenInstance))
  const setMapTheme = useSetRecoilState(mapThemeAtomFamily(screenInstance))
  const setCenterPosition = useSetRecoilState(centerPositionAtomFamily(screenInstance))
  const resetCenterPosition = useResetRecoilState(centerPositionAtomFamily(screenInstance))
  const setIsCurrentAreaSearch = useSetRecoilState(isCurrentAreaSearchAtomFamily(screenInstance))
  interface FetchPois {
    mapTheme?: MapTheme
    bounds?: Bounds | null
    centerPosition?: GPS | null | 'reset'
    isCurrentAreaSearch?: boolean
  }
  const fetchPois = useRecoilCallback(
    () =>
      ({ mapTheme, centerPosition, bounds, isCurrentAreaSearch = false }: FetchPois) => {
        setIsCurrentAreaSearch(isCurrentAreaSearch)
        !isUndefined(mapTheme) && setMapTheme(mapTheme)
        !isUndefined(bounds) && setBounds(bounds)
        if (centerPosition === 'reset') {
          resetCenterPosition()
        } else if (!isUndefined(centerPosition)) {
          setCenterPosition(centerPosition)
        }
      },
    [resetCenterPosition, setBounds, setCenterPosition, setIsCurrentAreaSearch, setMapTheme]
  )

  return {
    fetchPois,
  }
}

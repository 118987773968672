import { captureException } from '@sentry/react'
import { atom, selector } from 'recoil'

import { nearbyApi } from '@src/apis'
import { karrotBridge, karrotBridgeCache } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { localStorage } from '@src/utils/localStorage'
import { tokenStorage } from '@src/utils/tokenStorage'

import recoilKeyGenerator from './recoilKeyGenerator'
import { checkInRegionInfoAtom } from './region'

const generateKey = recoilKeyGenerator('user')

type UserInfo = Awaited<ReturnType<(typeof karrotBridge)['getUserInfo']>>['info']['user']
export const userInfoAtom = atom<UserInfo>({
  key: generateKey('user-info-atom'),
  default: (async () => {
    if (IS_LOCAL) {
      if (!process.env.LOCAL_AUTH_TOKEN) throw new Error('LOCAL_AUTH_TOKEN does not exist in the environment')
      if (!process.env.LOCAL_USER_ID) throw new Error('LOCAL_USER_ID does not exist in the environment')

      tokenStorage.setAuthToken(process.env.LOCAL_AUTH_TOKEN)

      return {
        id: +process.env.LOCAL_USER_ID,
        nickname: '로컬제이슨',
        phone: '010-1234-5678',
        profileImage: 'https://picsum.photos/200/300',
        authToken: process.env.LOCAL_AUTH_TOKEN,
      }
    }

    return (await karrotBridge.getUserInfo({})).info.user
  })(),
})

type BridgeCurrentPosition = NonNullable<
  Awaited<ReturnType<(typeof karrotBridge)['getCurrentPosition']>>['geolocation']['currentPosition']
>
type AccessStatus = 'granted' | 'denied' | 'loading'
type CurrentPosition<T extends AccessStatus> = {
  access: T
  position: T extends 'granted' ? BridgeCurrentPosition['position'] : undefined
  timestamp: BridgeCurrentPosition['timestamp']
}

export const getDeniedCurrentPosition: () => CurrentPosition<'denied'> = () => ({
  access: 'denied',
  position: undefined,
  timestamp: Date.now(),
})

export const getLoadingCurrentPosition: () => CurrentPosition<'loading'> = () => ({
  access: 'loading',
  position: undefined,
  timestamp: Date.now(),
})

export const currentPositionSelector = selector<CurrentPosition<'granted'> | CurrentPosition<'denied'>>({
  key: generateKey('current-position-atom'),
  get: () =>
    (async () => {
      if (IS_LOCAL) {
        const currentUserPositionOff = (await localStorage.getItem('USE_CURRENT_USER_POSITION')) === 'false'

        if (currentUserPositionOff) {
          /**
           * 로컬에서는 현재 위치를 가져오지 못하도록 처리
           */
          return getDeniedCurrentPosition()
        } else {
          /**
           * 로컬에서는 현재 위치를 임의로 설정
           */
          return {
            access: 'granted',
            position: {
              latitude: 37.50261221207654,
              longitude: 127.02480110495101,
            },
            timestamp: Date.now(),
          }
        }
      }

      try {
        const karrotBridgeCurrentPosition = await karrotBridge.getCurrentPosition({})

        const positionInfo = karrotBridgeCurrentPosition?.geolocation?.currentPosition

        if (!positionInfo) {
          return {
            access: 'denied',
            position: undefined,
            timestamp: undefined,
          }
        }

        // update karrot bridge cache
        karrotBridgeCache.setCurrentPosition(karrotBridgeCurrentPosition)

        const { position, timestamp } = positionInfo
        const isAccessGranted = !!position.latitude && !!position.longitude

        const currentPosition:
          | Omit<CurrentPosition<'granted'>, 'timestamp'>
          | Omit<CurrentPosition<'denied'>, 'timestamp'> = isAccessGranted
          ? { access: 'granted', position }
          : { access: 'denied', position: undefined }

        return {
          ...currentPosition,
          timestamp,
        }
      } catch (error) {
        captureException(error)

        return {
          access: 'denied',
          position: undefined,
          timestamp: undefined,
        }
      }
    })(),
})

type DebugUser = {
  isDeveloper: boolean
  isDebugUiVisible: boolean
}
export const debugUserAtom = selector<DebugUser>({
  key: generateKey('debug-user'),
  get: async ({ get }) => {
    if (IS_LOCAL)
      return {
        isDeveloper: true,
        isDebugUiVisible: true,
      }

    try {
      const userId = get(userInfoAtom).id
      const regionId = get(checkInRegionInfoAtom).id

      const { data } = await nearbyApi.getDebugUser({ userId, regionId })
      const { isDeveloper, isDebugUiVisible } = data.data.viewer

      return { isDeveloper, isDebugUiVisible }
    } catch {
      return {
        isDeveloper: false,
        isDebugUiVisible: false,
      }
    }
  },
})

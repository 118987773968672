import { BookmarkDto } from '@daangn/local-business-network/lib/poi'
import { atomFamily, selectorFamily } from 'recoil'
import { v4 } from 'uuid'

import { watchedByUserResponseWithDataSelector } from '@src/shared/watch/store'

import { ScreenInstance } from '.'
import { generateWatchedPlacesPageRecoilKey } from './keys'

export const selectedWatchedPlaceIdAtomFamily = atomFamily<string | null, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey('selectedWatchedPoiId'),
  default: null,
})

export const watchedByUserRefreshTokenAtomFamily = atomFamily<string, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey(['watchedByUser', 'response']),
  default: v4(),
})

export const watchedByUserSelectorFamily = selectorFamily<BookmarkDto[], ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey(['watchedByUser']),
  get:
    (screenInstance) =>
    async ({ get }) => {
      get(watchedByUserRefreshTokenAtomFamily(screenInstance))

      return get(watchedByUserResponseWithDataSelector)
    },
})

export const watchedByUserMapperSelectorFamily = selectorFamily<{ [poiId: string]: boolean }, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey(['watchedByUser', 'mapper']),
  get:
    (screenInstance) =>
    ({ get }) => {
      return get(watchedByUserSelectorFamily(screenInstance)).reduce(
        (watchMapper, watchInfo) => ({
          ...watchMapper,
          [watchInfo.targetId]: true,
        }),
        {}
      )
    },
})

export const selectedWatchedPlaceSelectorFamily = selectorFamily({
  key: generateWatchedPlacesPageRecoilKey('selectedWatchedPlace'),
  get:
    (screenInstance: string) =>
    async ({ get }) => {
      const watchedPois = get(watchedByUserSelectorFamily(screenInstance))
      const selectedWatchId = get(selectedWatchedPlaceIdAtomFamily(screenInstance))

      return watchedPois.find(({ targetId }) => targetId === selectedWatchId) ?? null
    },
})

export const filteredWatchedPlacesByMapThemeSelectorFamily = selectorFamily({
  key: generateWatchedPlacesPageRecoilKey('filteredWatchPlaces'),
  get:
    (screenInstance: string) =>
    ({ get }) => {
      const watchedPlaces = get(watchedByUserSelectorFamily(screenInstance))
      // temp spec out
      // const selectedWatchFilter = get(selectedWatchedPlacesFilterAtomFamily(screenInstance))

      // return selectedWatchFilter
      //   ? watchedPlaces.filter((watch) => watch.target?.events.some((event) => event.id === selectedWatchFilter.eventId))
      //   : watchedPlaces

      return watchedPlaces
    },
})

export const isWatchedPlacesEmptySelectorFamily = selectorFamily({
  key: generateWatchedPlacesPageRecoilKey('isWatchedPlacesEmpty'),
  get:
    (screenInstance: string) =>
    ({ get }) => {
      const watchedPlaces = get(filteredWatchedPlacesByMapThemeSelectorFamily(screenInstance))

      return watchedPlaces.length <= 0
    },
})

import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import BizProfileSearchCategoryPage from './BizProfileSearchCategoryPage'
import DefaultSearchCategoryPage from './SearchCategoryPage'

export type AlertType = 'confirmCategorySuggestion'
type ActivityParams = {
  variation?: 'biz'
  alert?: AlertType
}
const SearchCategoryPage: ActivityComponentType<ActivityParams> = ({ params: { variation } }) => {
  switch (variation) {
    case 'biz':
      return <BizProfileSearchCategoryPage />
    default:
      return <DefaultSearchCategoryPage />
  }
}

export default SearchCategoryPage

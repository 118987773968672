import React from 'react'

const MapIntro = ({ size = 98 }) => {
  return (
    <svg width={(size * 99) / 98} height={size} viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M93.8445 50.727C93.8445 24.6188 72.8367 3.45396 46.9222 3.45396C21.0078 3.45396 0 24.6188 0 50.727C0 76.8352 21.0078 98 46.9222 98C72.8367 98 93.8445 76.8352 93.8445 50.727Z"
        fill="#D8D5D4"
      />
      <mask id="mask0_94661_16248" maskUnits="userSpaceOnUse" x="0" y="3" width="94" height="95">
        <path
          d="M93.8494 50.722C93.8494 24.6139 72.8416 3.44901 46.9272 3.44901C21.0127 3.44901 0.00491333 24.6139 0.00491333 50.722C0.00491333 76.8302 21.0127 97.995 46.9272 97.995C72.8416 97.995 93.8494 76.8302 93.8494 50.722Z"
          fill="#D8D5D4"
        />
      </mask>
      <g mask="url(#mask0_94661_16248)">
        <path d="M33.8329 3.45396H18.9057V98H33.8329V3.45396Z" fill="#EAEAEA" />
        <path d="M74.6681 3.45396H59.7409V98H74.6681V3.45396Z" fill="#EAEAEA" />
        <path d="M0 73.4079V58.3691L93.842 35.0214L87.4277 43.5955L77.1433 54.2146L0 73.4079Z" fill="#EAEAEA" />
      </g>
      <path
        d="M61.3279 3.77869C58.1128 5.68604 55.4233 8.3746 53.5051 11.5988C51.587 14.823 50.5012 18.48 50.3471 22.2358C49.7247 40.2343 66.8712 46.1784 74.6682 67.6546C82.1281 47.098 98.16 40.7722 98.972 24.484C99.8651 6.58468 79.2765 -6.87763 61.3279 3.77869Z"
        fill="#F47D3A"
      />
      <path
        d="M74.7764 33.2763C72.936 33.2763 71.1369 32.7265 69.6067 31.6963C68.0765 30.6662 66.8838 29.2021 66.1795 27.4891C65.4752 25.7761 65.2909 23.8911 65.65 22.0726C66.009 20.254 66.8953 18.5836 68.1966 17.2725C69.498 15.9614 71.156 15.0686 72.9611 14.7068C74.7661 14.3451 76.6371 14.5308 78.3374 15.2403C80.0377 15.9499 81.491 17.1515 82.5134 18.6931C83.5359 20.2348 84.0816 22.0473 84.0816 23.9015C84.082 25.1327 83.8415 26.3519 83.374 27.4895C82.9065 28.627 82.2211 29.6606 81.3569 30.5312C80.4928 31.4018 79.4669 32.0923 78.3378 32.5633C77.2086 33.0343 75.9985 33.2766 74.7764 33.2763Z"
        fill="#F8F5F4"
      />
    </svg>
  )
}

export default MapIntro

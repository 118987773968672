import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { ActionChip } from '@src/components'

import { useAnalytics } from '../hooks'

interface Props {
  keywords: string[]
  onClickKeyword: (keyword: string) => void
}

export const PredictedKeywords = ({ keywords, onClickKeyword }: Props) => {
  const logEvent = useAnalytics()

  const handleClickKeyword = (keyword: string) => {
    logEvent('click_predicted_keyword', { is_predicted_keyword: true, query: keyword })
    onClickKeyword(keyword)
  }
  return (
    <Container>
      <Title>혹시 이 장소를 찾고 계신가요?</Title>
      {keywords.map((keyword, idx) => (
        <Button key={idx} type="normal" text={keyword} onClick={() => handleClickKeyword(keyword)} />
      ))}
    </Container>
  )
}

const Container = styled.section`
  margin: 0.75rem 1rem;
`
const Title = styled.h3`
  margin: 0 0 0.5rem;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.subtitle1Bold};
`
const Button = styled(ActionChip)`
  margin: 0 0.25rem 0 0;
`

import { useLoadableFamily, usePreloadLoadableFamily } from '@daangn/recoil-loadable'
import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import { Screen } from '@src/stackflow'

import { Filters } from './Filter'
import { withNewAnalytics } from './hook/Analytics'
import List from './List'
import { themeMetaLoadableFamily } from './store'

export type ToggleFilterType = 'isNew' | 'hasCoupon' | 'purchaseable' | 'reservable'

export type PurposefulCategoryActivityParams = {
  themeId: string
  categoryFilterId?: string
  toggleFilterIds?: string
  sortBy?: string
}

const PurposefulCategoryPage: ActivityComponentType<PurposefulCategoryActivityParams> = ({ params: { themeId } }) => {
  const { value: themeMetaInfo } = useLoadableFamily({
    loadable: themeMetaLoadableFamily,
    variables: themeId,
    fetchPolicy: 'store-or-network',
  })

  usePreloadLoadableFamily({
    loadable: themeMetaLoadableFamily,
    variables: themeId,
  })

  return (
    <Screen appBar={{ title: themeMetaInfo.name }}>
      <Filters />
      <List />
    </Screen>
  )
}

export default withNewAnalytics(PurposefulCategoryPage, 'purposeful_category')

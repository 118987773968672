import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

interface Props {
  Icon: React.ElementType
  label: string
  onClick: () => void
  children?: React.ReactNode
}
const QuickMenuButton: React.FC<Props> = ({ Icon, label, onClick, children }) => {
  return (
    <Button onClick={onClick}>
      <Icon css={iconStyle} />
      {label}
      {children}
    </Button>
  )
}

export const Button = styled.button<{ iconColor?: string }>`
  background: none;
  border: none;
  flex: 1 100%;
  height: 3rem;
  margin: 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${vars.$scale.color.gray700};
  ${vars.$semantic.typography.caption1Regular};

  &:not(:last-child) {
    border-right: 0.0625rem solid ${vars.$semantic.color.divider1};
  }
`
const iconStyle = css`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0 0.25rem;
  color: ${vars.$scale.color.gray900};
`

export default QuickMenuButton

import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { useActivityParams } from '@stackflow/react'
import React, { forwardRef, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useNavigator } from '@src/stackflow'

import Filter from './Filter'
import { DROPDOWN_FILTER_NAME_TO_TYPE_FILTER } from '../constants/eventLogParamMapper'
import { useNewAnalytics } from '../hook/Analytics'
import { PurposefulCategoryActivityParams } from '../PurposefulCategoryPage'
import { FilterName, filterOptionsSelectorFamily } from '../store'
import { PurposefulCategoryFilterOption, PurposefulCategorySortOption } from '../store/type'

interface Props {
  filterName: FilterName
  alwaysInactive?: boolean
  defaultOptionId?: string
  id?: string
}

const DropdownFilter = forwardRef<HTMLButtonElement, Props>(
  ({ filterName, defaultOptionId, alwaysInactive, ...props }, ref) => {
    const activityParams = useActivityParams<PurposefulCategoryActivityParams>()
    const { themeId, categoryFilterId, sortBy } = activityParams
    const { push } = useNavigator()
    const log = useNewAnalytics()

    const filterOptions = useRecoilValue(filterOptionsSelectorFamily({ themeId, filterName })) as (
      | PurposefulCategoryFilterOption
      | PurposefulCategorySortOption
    )[]
    const selectedFilterOptionId = filterName === 'category' ? categoryFilterId : sortBy

    const selectedOption = useMemo(() => {
      const defaultFilter =
        filterName === 'category' ? { id: 'all', name: '전체' } : { id: 'BA_POI_SCORE_DESC', name: '추천순' }

      return filterOptions.find((option) => option.id === selectedFilterOptionId) ?? defaultFilter
    }, [filterName, filterOptions, selectedFilterOptionId])

    const isActive = !alwaysInactive && !!selectedOption && defaultOptionId !== selectedOption.id

    const handleClick = () => {
      log({
        name: 'click_onedepth_filter',
        type: 'click',
        params: {
          filter_type: DROPDOWN_FILTER_NAME_TO_TYPE_FILTER[filterName],
        },
      })

      push('purposeful_category_filter_picker', { ...activityParams, filterName })
    }
    return (
      <Filter ref={ref} id={props.id || filterName} isActive={isActive} onClick={handleClick}>
        {selectedOption.name} <ChevronDown height={20} width={14} />
      </Filter>
    )
  }
)

const ChevronDown = styled(IconChevronRightRegular)`
  transform: rotate(90deg);
  margin-left: 0.25rem;
`

export default DropdownFilter

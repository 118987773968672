import { useActivityParams } from '@stackflow/react'
import React, { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useStepNavigator } from '@src/stackflow'

import Filter from './Filter'
import { useNewAnalytics } from '../hook/Analytics'
import { PurposefulCategoryActivityParams, ToggleFilterType } from '../PurposefulCategoryPage'
import { toggleFilterOptionsSelectorFamily } from '../store'

interface Props {
  id: string
  name: string
}

const ToggleFilter = ({ id, name }: Props) => {
  const { themeId, toggleFilterIds } = useActivityParams<PurposefulCategoryActivityParams>()
  const { stepReplace } = useStepNavigator('purposeful_category')
  const log = useNewAnalytics()

  const toggleFilterOptions = useRecoilValue(toggleFilterOptionsSelectorFamily(themeId))
  const toggleFiltersOnOffStatusMapper = useMemo(() => {
    const toggleFiltersOnArray = toggleFilterIds?.split(',') ?? []
    return toggleFilterOptions.reduce<Record<string, boolean>>((mapper, option) => {
      return {
        ...mapper,
        [option.id]: toggleFiltersOnArray.includes(option.id),
      }
    }, {})
  }, [toggleFilterIds, toggleFilterOptions])

  const handleClick = () => {
    const NAME_TO_LOG_FILTER_TYPE: Record<string, ToggleFilterType> = {
      '새로운 가게': 'isNew',
      쿠폰: 'hasCoupon',
      예약: 'reservable',
      바로결제: 'purchaseable',
    }

    log({
      name: 'click_onedepth_filter',
      type: 'click',
      params: {
        filter_type: NAME_TO_LOG_FILTER_TYPE[name],
      },
    })

    toggleFiltersOnOffStatusMapper[id] = !toggleFiltersOnOffStatusMapper[id]

    const newToggleFilterIds = Object.entries(toggleFiltersOnOffStatusMapper)
      .filter(([_, value]) => value)
      .map(([key]) => key)
      .join(',')

    stepReplace({
      themeId,
      toggleFilterIds: newToggleFilterIds,
    })
  }

  return (
    <Filter id={id} isActive={toggleFiltersOnOffStatusMapper[id]} onClick={handleClick}>
      {name}
    </Filter>
  )
}

export default ToggleFilter

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

interface Props {
  className?: string
  children: React.ReactNode
}

const Section: React.FC<Props> = ({ className, children }) => {
  return <Layout className={className}>{children}</Layout>
}

const Layout = styled.section`
  margin: 0.5rem 0 0;
  padding: 1rem 1rem 1.25rem;
  background-color: ${vars.$semantic.color.paperDefault};
  box-shadow: 0px 1px 0px rgba(33, 33, 36, 0.03);
`

export default Section

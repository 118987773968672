import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilValue } from 'recoil'

import S_FilledBadge from './styles'
import { couponBoxBizCoordinatesSelector } from '../store'
import { BizAccountType, CouponEventType, CouponType } from '../types'

export interface CouponCardProps {
  target: CouponEventType
  uiDisabled: boolean
  couponType: CouponType
  couponBoxId: number
  isManager: boolean
  bizAccountType?: BizAccountType
}

const CouponCardType: React.FC<CouponCardProps> = ({
  uiDisabled,
  bizAccountType,
  couponType,
  target,
  couponBoxId,
  isManager,
}) => {
  const isBrandProfileCoupon = bizAccountType && bizAccountType === 'BRAND'
  const isFollowerOnlyCoupon = target === 'FOLLOWER_ONLY'
  const isOnlineCoupon = couponType === 'ONLINE'
  const isBarcodeCopuon = couponType === 'THIRD_BARCODE'
  const coordinates = useRecoilValue(couponBoxBizCoordinatesSelector(couponBoxId))

  return (
    <S_Base>
      {isBrandProfileCoupon && (
        <S_CustomGreenBadge margin="0 0.375rem 0.625rem 0" uiDisabled={uiDisabled}>
          브랜드
        </S_CustomGreenBadge>
      )}
      {isOnlineCoupon && (
        <S_FilledBadge margin="0 0.375rem 0.625rem 0" uiDisabled={uiDisabled}>
          앱결제 전용
        </S_FilledBadge>
      )}
      {isFollowerOnlyCoupon && !isBarcodeCopuon && (
        <S_FilledBadge margin="0 0.375rem 0.625rem 0" uiDisabled={uiDisabled}>
          단골전용
        </S_FilledBadge>
      )}
      {!isManager && coordinates.key === 'VISIBLE' && (
        <S_CustomGreenBadge margin="0 0.375rem 0.625rem 0" uiDisabled={uiDisabled}>
          걸어서 10분
        </S_CustomGreenBadge>
      )}
    </S_Base>
  )
}

const S_Base = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0.125rem;
`
const S_CustomGreenBadge = styled(S_FilledBadge)`
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.green500 : vars.$scale.color.green700)};
  background: ${vars.$semantic.color.successLow};
  border-color: transparent;
`

export default CouponCardType

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { CurationMapItem } from '@src/apis/generated/poi/models'
import { Skeleton } from '@src/components/Loading'
import Slide from '@src/components/Slides/Slide'
import Slides from '@src/components/Slides/Slides'
import { useNavigator } from '@src/stackflow'
import { Spacing } from '@src/styles/Spacing'
import { getSafeAreaValue } from '@src/styles/utils'
import { useScreenInstance } from '@src/widgets/screenInstance'

import NoPois from './NoPois'
import { SLIDE_GAP, SLIDE_MARGIN, DEFAULT_MAP_POI_CARD_HEIGHT, mapPoiCardHeightAtom } from '../constants/style'
import { useAnalytics, useSelectedPoi, usePoisStatus } from '../hooks'
import UserCardSimplified from '../PoiCard/Simplified'
import { mapThemeAtomFamily, selectedPoiIdAtomFamily } from '../state'
import { filteredPoisSelectorFamily } from '../state/pois'
import type { ViewType } from '../types'

interface Props {
  viewType: ViewType
}

const PoisCardSection: React.FC<Props> = ({ viewType }) => {
  const poisStatus = usePoisStatus()

  const mapPoiCardHeight = useRecoilValue(mapPoiCardHeightAtom)

  switch (poisStatus) {
    case 'no-pois':
    case 'no-filtered-pois': {
      return (
        <Container mapPoiCardHeight={mapPoiCardHeight}>
          <NoPois />
          <div className="safeAreaCover" />
        </Container>
      )
    }
    case 'exists': {
      return <PoisCards viewType={viewType} />
    }
    case 'loading':
    default: {
      return (
        <Container mapPoiCardHeight={mapPoiCardHeight}>
          <Skeleton width={100} />
          <Spacing size={10} />
          <Skeleton height={110} />
        </Container>
      )
    }
  }
}

const PoisCards: React.FC<Props> = ({ viewType }) => {
  const poisStatus = usePoisStatus()
  const logEvent = useAnalytics()
  const { spotlightPoiOnMap } = useSelectedPoi()
  const screenInstance = useScreenInstance()
  const { push } = useNavigator()

  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const selectedPoiId = useRecoilValue(selectedPoiIdAtomFamily(screenInstance))
  const filteredPois = useRecoilValue(filteredPoisSelectorFamily(screenInstance))
  const [mapPoiCardHeight, setPoiCardHeight] = useRecoilState(mapPoiCardHeightAtom)

  const poiCardsRef = useRef<{ [poiId: string]: HTMLDivElement }>({})

  const poiIdToIndexMapper = useMemo(() => {
    return filteredPois.reduce(
      (mapper, poi, index) =>
        !poi?.poi?.id
          ? mapper
          : {
              ...mapper,
              [poi.poi.id]: index,
            },
      {} as { [id: string]: number }
    )
  }, [filteredPois])

  const focusedCardIndex = useMemo(() => {
    return selectedPoiId ? poiIdToIndexMapper[selectedPoiId] : 0
  }, [selectedPoiId, poiIdToIndexMapper])

  const slicedPois = useMemo(() => {
    const renderCardsLength = 5
    const beginIndex = Math.max(0, focusedCardIndex - 2)
    const endIndex = beginIndex + renderCardsLength

    return filteredPois ? filteredPois.slice(beginIndex, endIndex) : []
  }, [filteredPois, focusedCardIndex])

  const handleSwipeEnd = useCallback(
    (cursor: number) => {
      if (!filteredPois[cursor]) return

      spotlightPoiOnMap(filteredPois[cursor].poi.id, true)
      logEvent('handle_swipe_poi_card')
    },
    [filteredPois, spotlightPoiOnMap, logEvent]
  )

  const handleClickMoreOptions = useCallback(
    (poiId: string) => {
      push('daangn_map_modal', { modalId: 'poiOptionsBottomSheet', poiId, curationCategoryId: mapTheme.id.toString() })
    },
    [mapTheme.id, push]
  )

  useEffect(() => {
    if (poisStatus !== 'exists') {
      setPoiCardHeight(DEFAULT_MAP_POI_CARD_HEIGHT)
      return
    }

    if (!selectedPoiId || !poiCardsRef.current[selectedPoiId]) return

    const selectedPoiCardElement = poiCardsRef.current[selectedPoiId]
    setPoiCardHeight(selectedPoiCardElement.getBoundingClientRect().height - parseInt(getSafeAreaValue('bottom'), 10))
  }, [selectedPoiId, setPoiCardHeight, poisStatus])

  return (
    <Slides
      maxLength={filteredPois ? filteredPois.length - 1 : 0}
      focusedCardIndex={focusedCardIndex}
      slideMargin={SLIDE_MARGIN}
      slideGap={SLIDE_GAP}
      height={mapPoiCardHeight + parseInt(getSafeAreaValue('bottom'))}
      onSwipeCardEnd={handleSwipeEnd}>
      {slicedPois.map((mapPoi: CurationMapItem) => {
        if (!mapPoi.poi.id) return null

        return (
          <Slide key={mapPoi.poi.id} index={poiIdToIndexMapper[mapPoi.poi.id]}>
            <UserCardSimplified
              {...mapPoi}
              viewType={viewType}
              on3DotClick={handleClickMoreOptions.bind(null, mapPoi.poi.id)}
              ref={(element) => {
                if (!element || !mapPoi.poi.id) return
                poiCardsRef.current[mapPoi.poi.id] = element
              }}
              isHighlighted={false}
              index={0}
              fillSafeArea
            />
          </Slide>
        )
      })}
    </Slides>
  )
}

const Container = styled.div<{ mapPoiCardHeight: number }>`
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  background-color: ${vars.$semantic.color.paperDefault};
  height: ${({ mapPoiCardHeight }) => `${mapPoiCardHeight}px`};
  height: ${({ mapPoiCardHeight }) => `calc(${mapPoiCardHeight}px + constant(safe-area-inset-bottom))`};
  height: ${({ mapPoiCardHeight }) => `calc(${mapPoiCardHeight}px + env(safe-area-inset-bottom))`};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);

  .safeAreaCover {
    width: 100%;
    height: 0px;
    height: constant(safe-area-inset-bottom);
    height: env(safe-area-inset-bottom);
    background: ${vars.$semantic.color.paperDefault};
  }
`
export default PoisCardSection

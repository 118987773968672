export const prefix = 'poi-web'

export const keys = {
  USE_ANALYTICS: 'USE_ANALYTICS',
  USE_HISTORY: 'USE_HISTORY',

  IS_FIRST_DAANGN_MAP_REVIEW: 'IS_FIRST_DAANGN_MAP_REVIEW',
  LATEST_MAP_THEME: 'latest-map-theme',
  MARKETING_EVENT_BANNER_GOOD_RESTAURANT_IN_TOWN_RELAY: 'MARKETING_EVENT_BANNER_GOOD_RESTAURANT_IN_TOWN_RELAY',

  PURPOSEFUL_CATEGORY_FILTER_TOOLTIP: 'PURPOSEFUL_CATEGORY_FILTER_TOOLTIP',

  WATCH_PAGE_TOOLTIP_story_article_poi_collection: 'WATCH_PAGE_TOOLTIP_story_article_poi_collection',
}

import type { XCommunityPostCard } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useNavigator } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'
import { useSubprofileHalfview } from '@src/widgets/SubprofileHalfview/hooks'

import StoryCard from './StoryCard'
import { useAnalytics } from '../hooks'
import { communityPostsAtomFamily, communityPostsSortingOptionAtomFamily, interestedSingleStoryAtom } from '../state'

export const emojiMapper = {
  ['thumbs_up']: 'recommended',
  ['heart']: 'heart',
  ['smile']: 'smile',
  ['surprise']: 'wow',
  ['sob']: 'sad',
  ['rage']: 'angry',
} as const

const StoryPreviews = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { push } = useNavigator()
  const logEvent = useAnalytics()
  const { open } = useSubprofileHalfview()
  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const communityPosts = useRecoilValue(communityPostsAtomFamily({ poiId, screenInstance, sortingOption }))

  const setInterestedSingleStory = useSetRecoilState(interestedSingleStoryAtom)

  const handleViewMoreOptions = ({
    postId,
    resourceType,
    resourceId,
    index,
  }: {
    postId?: string
    resourceType: XCommunityPostCard['data']['type']
    resourceId: string
    index: number
  }) => {
    logEvent('click_community_card_more_options_button')

    setInterestedSingleStory({
      postId,
      resourceType,
      resourceId,
      index,
    })

    push('poi_detail_modal', { poiId, modalId: 'singleStoryOptionsBottomSheet' })
  }

  return (
    <>
      <Container>
        {communityPosts.map(({ type, ...post }, idx) => {
          return (
            <React.Fragment key={idx}>
              <StoryCard
                index={idx}
                data={{
                  type: type === 'STORY_COMMENT' ? 'comment' : 'article',
                  id: post.sourceId,
                  author: {
                    name: post.user?.nickname ?? '',
                    profileImage: post.user?.profileImageUrl,
                    regionName: post.user?.region?.name ?? '',
                    regionCheckInCount: post.user?.displayRegionCheckinsCount,
                  },
                  content: post.content ?? '',
                  images: post.images.map((image) => Object.assign({ url: image.url })),
                  createdAt: new Date(post.createdAt ?? ''),
                  reaction: {
                    emoji: {
                      totalCount: post.emotionsCount ?? 0,
                      types: post.topEmotions?.map((type) => emojiMapper[type as keyof typeof emojiMapper]) ?? [],
                    },
                    comment: {
                      count: post.commentsCount ?? 0,
                    },
                  },
                  ...(type === 'STORY_COMMENT' &&
                    'article' in post && {
                      articleContent: post.article?.content,
                      articleId: post.article?.id,
                    }),
                }}
                onClick3Dot={() =>
                  handleViewMoreOptions({
                    postId:
                      type === 'STORY_COMMENT' && 'article' in post ? post.article?.id?.toString() : post.sourceId,
                    resourceType: type === 'STORY_COMMENT' ? 'comment' : 'article',
                    resourceId: post.sourceId,
                    index: idx,
                  })
                }
                onClickUserProfile={() => {
                  open(post.user!.id.toString())
                }}
              />
              <Divider />
            </React.Fragment>
          )
        })}
      </Container>
    </>
  )
}

const Container = styled.div`
  width: 100%;
  box-shadow: 0px 1px 0px rgba(33, 33, 36, 0.03);
`
const Divider = styled.hr`
  height: 0.063rem;
  inline-size: calc(100% - 2rem);
  margin: 0 auto;
  border: none;
  background-color: ${vars.$semantic.color.divider1};
  &:last-of-type {
    display: none;
  }
`

export default StoryPreviews

import { isEmpty } from 'lodash'

import { bridge } from '@src/bridge'
import { IS_PROD } from '@src/constants/environmentConstants'

import { generateKey } from './generateKey'
import { isDefinedKey } from './isDefinedKey'
import { prefix } from './keys'

export const getItem = async (key: string): Promise<string | null> => {
  if (!IS_PROD && !isDefinedKey(key)) {
    console.warn(`[localStorage] Following key is not defined in utils/localStorage/keys.ts \n key: ${key}`)
  }

  const _key = generateKey(key)

  const item = localStorage.getItem(_key)

  const bridgeItem = (await bridge.storage.get(_key)) as string | null

  return item || bridgeItem
}

export const setItem = (key: string, value: string) => {
  if (!IS_PROD && !isDefinedKey(key)) {
    console.warn(`[localStorage] Following key is not defined in utils/localStorage/keys.ts \n key: ${key}`)
  }

  const _key = generateKey(key)

  bridge.storage.set(_key, value)
  localStorage.setItem(_key, value)
}

export const removeItem = (key: string) => {
  if (!IS_PROD && !isDefinedKey(key)) {
    console.warn(`[localStorage] Following key is not defined in utils/localStorage/keys.ts \n key: ${key}`)
  }

  const _key = generateKey(key)

  localStorage.removeItem(_key)
  bridge.storage.remove(_key)
}

export const getAll = async () => {
  const localStorageItems = Object.fromEntries(Object.entries(localStorage))
  const items = isEmpty(localStorageItems) ? null : localStorageItems

  const bridgeItems = await bridge.storage.getAll()

  return items || bridgeItems
}

export const clear = async () => {
  const items = await getAll()

  if (items) {
    Object.keys(items).forEach((key) => {
      if (key.startsWith(prefix)) {
        localStorage.removeItem(key)
        bridge.storage.remove(key)
      }
    })
  }
}

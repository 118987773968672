import styled from '@emotion/styled'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { BoundarySearchButton } from './BoundarySearchButton'
import Map from './Map'
import { isMapBoundChangedAtom } from '../state/map'
import { WinterSnackParams } from '../WinterSnackPage'

export const MapContents = () => {
  const activityParams = useActivityParams<WinterSnackParams>()

  const isMapBoundChanged = useRecoilValue(isMapBoundChangedAtom)

  return (
    <Container>
      {activityParams.mode !== 'edit' && isMapBoundChanged && <BoundarySearchButton />}
      <Map initialZoomLevel={5} />
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
`

import { selectorFamily } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

import { bizProfileSelectorFamily } from './bizProfile'

const generateKey = recoilKeyGenerator('brandProfile')

export const brandProfileSelectorFamily = selectorFamily({
  key: generateKey('brandProfileSelectorFamily'),
  get:
    ({ brandAccountId }: { brandAccountId: string }) =>
    ({ get }) => {
      if (!brandAccountId) return undefined

      const bizProfile = get(bizProfileSelectorFamily({ businessAccountId: +brandAccountId }))
      const brandMetadata = bizProfile?.brandMetadata

      return brandMetadata
    },
})

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useRef } from 'react'

export const EmptyState = () => {
  const ref = useRef<HTMLParagraphElement>(null)
  const offsetHeight = ref.current?.offsetHeight || 52

  return (
    <Wrapper>
      <Text ref={ref} offsetHeight={offsetHeight}>{`아직 저장한 장소가 없어요\n마음에 드는 장소를 저장해보세요!`}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
`
const Text = styled.p<{ offsetHeight: number }>`
  margin: 0 min(54px);
  position: relative;
  top: ${({ offsetHeight }) => `calc(40% - ${offsetHeight}px)`};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-align: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.bodyL1Regular};
`

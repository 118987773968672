import { BookmarkDto } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useCallback, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import { KakaoCustomOverlay, KakaoMap, useKakaoMap } from '@src/components/Map/KakaoMap'
import { useCurrentPosition } from '@src/hooks'
import { GPS } from '@src/types/global'
import { getBoundingBoxOfCircle, convertCoordinatesArrayToObject } from '@src/utils/coordinate'
import { useScreenInstance } from '@src/widgets/screenInstance'

import Marker from './Marker'
import { useSelectedWatchedPlaceId } from '../hooks/useSelectedWatchedPlaceId'
import { centerPositionSelectorFamily } from '../state'
import { filteredWatchedPlacesByMapThemeSelectorFamily } from '../state/selectedWatchedPoi'

export const Map = () => {
  const screenInstance = useScreenInstance()
  const { setSelectedWatchedPlaceId } = useSelectedWatchedPlaceId()
  const { access, position } = useCurrentPosition()
  const { setBounds } = useKakaoMap()

  const centerPosition = useRecoilValue(centerPositionSelectorFamily(screenInstance))
  const filteredWatchedPlaces = useRecoilValue(filteredWatchedPlacesByMapThemeSelectorFamily(screenInstance))

  const handleClickMarker = useCallback(
    (placeId: string) => {
      setSelectedWatchedPlaceId(placeId)
    },
    [setSelectedWatchedPlaceId]
  )

  const handleClickMap = useCallback(() => {
    setSelectedWatchedPlaceId(null)
  }, [setSelectedWatchedPlaceId])

  useEffect(() => {
    if (!centerPosition) return

    const { sw, ne } = getBoundingBoxOfCircle(centerPosition, 0.5)

    setBounds({
      topRight: convertCoordinatesArrayToObject(ne as GPS),
      bottomLeft: convertCoordinatesArrayToObject(sw as GPS),
    })
  }, [centerPosition, setBounds])

  return (
    <>
      <Container>
        <StyledKakaoMap
          center={
            centerPosition || {
              latitude: 37.5,
              longitude: 121,
            }
          }
          onClick={handleClickMap}>
          {filteredWatchedPlaces.map((watchedPlace: BookmarkDto) => {
            return (
              <Marker
                key={watchedPlace.targetId}
                place={watchedPlace}
                onClick={handleClickMarker.bind(null, watchedPlace.targetId)}
              />
            )
          })}
          {access === 'granted' && (
            <KakaoCustomOverlay position={position} clickable={false} zIndex={0}>
              <UserPosition className="userPosition">
                <div className="userPosition-background"></div>
                <div className="userPosition-dot"></div>
              </UserPosition>
            </KakaoCustomOverlay>
          )}
        </StyledKakaoMap>
      </Container>
    </>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const StyledKakaoMap = styled(KakaoMap)`
  width: 100%;
  height: 100%;
`

const UserPosition = styled.div`
  @keyframes ping {
    0% {
      transform: scale3d(1, 1, 1);
    }
    12.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    25% {
      transform: scale3d(1, 1, 1);
    }
    37.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    50% {
      transform: scale3d(1, 1, 1);
    }
    62.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    75% {
      transform: scale3d(1, 1, 1);
    }
    87.5% {
      transform: scale3d(0.7, 0.7, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }
  .userPosition-dot {
    position: relative;
    height: 1rem;
    width: 1rem;
    /** @todo: seed-design */
    background-color: #2b6eff;
    border-radius: 0.5rem;
    border: solid 2px ${vars.$static.color.staticWhite};
    display: block;
  }
  .userPosition-background {
    content: '';
    position: absolute;
    display: block;

    height: 3rem;
    width: 3rem;
    background: rgba(43, 110, 255, 0.25);
    border-radius: 1.5rem;
    top: -1rem;
    left: -1rem;
    transform: scale3d(0, 0, 1);
    animation-duration: 6s;
    animation-name: ping;
  }
`

export default function MapWithErrorBoundary() {
  return (
    <ErrorBoundary fallback={<></>}>
      <Map />
    </ErrorBoundary>
  )
}

import { useScrollToTopEffect } from '@daangn/stackflow-plugin-scroll-to-top'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SwipeEventData } from 'react-swipeable'
import { useRecoilValue } from 'recoil'

import { Loading } from '@src/components/Loading'
import ListViewComponent from '@src/components/Map/ListView'
import useScrollElementIntoView from '@src/hooks/useScrollElementIntoView'
import { useNavigator } from '@src/stackflow'
import { APP_BAR_HEIGHT } from '@src/stackflow/styles'
import { safeAreaPadding } from '@src/styles/safeArea'
import { getSafeAreaValue } from '@src/styles/utils'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { EmptyState } from './EmptyState'
import { TEMP_FILTER_ITEMS_HEIGHT } from '../constants/style'
import { EventBanner } from '../EventBanner'
import { useAnalytics, usePoisStatus } from '../hooks'
import { THEME_BUTTONS_HEIGHT } from '../Navbar/CurationCategoryButtons'
import UserCardSimplified from '../PoiCard/Simplified'
import { mapThemeAtomFamily, selectedPoiIdAtomFamily } from '../state'
import { filteredPoisSelectorFamily } from '../state/pois'
import type { ViewType } from '../types'

interface Props {
  viewType: ViewType
}

export const ListView = ({ viewType }: Props) => {
  const poisStatus = usePoisStatus()
  const screenInstance = useScreenInstance()
  const logEvent = useAnalytics()
  const scrollIntoView = useScrollElementIntoView()
  const { push } = useNavigator()

  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const filteredPois = useRecoilValue(filteredPoisSelectorFamily(screenInstance))
  const FILTER_ITEMS_HEIGHT = useRecoilValue(TEMP_FILTER_ITEMS_HEIGHT)
  const selectedPoiId = useRecoilValue(selectedPoiIdAtomFamily(screenInstance))

  const [isHighlightOn, setIsHighlightOn] = useState(false)
  const scrollRef = useRef<HTMLDivElement>(null)
  const listItemsRef = useRef<{ [poiId: string]: HTMLDivElement }>({})

  const animateHighlightPoi = () => {
    const HIGHLIGHT_DURATION_TIME = 3000

    setIsHighlightOn(true)
    const timerId = setTimeout(() => setIsHighlightOn(false), HIGHLIGHT_DURATION_TIME)

    const dispose = () => clearTimeout(timerId)
    return dispose
  }

  const swipeableOptions = useMemo(
    () => ({
      onSwiped: (eventData: SwipeEventData) => {
        if (eventData.dir !== 'Left' && eventData.dir !== 'Right') {
          logEvent('handle_scroll_list', {
            direction: eventData.dir === 'Down' ? 'up' : 'down',
            scrolled_pixels: eventData.deltaY,
          })
        }
      },
    }),
    [logEvent]
  )

  const handleClickMoreOptions = useCallback(
    (poiId: string) => {
      push('daangn_map_modal', { modalId: 'poiOptionsBottomSheet', poiId, curationCategoryId: mapTheme.id.toString() })
    },
    [mapTheme.id, push]
  )

  useEffect(() => {
    if (!selectedPoiId || !scrollRef.current) return
    const targetElement = listItemsRef.current[selectedPoiId]

    if (!targetElement) return
    const paddingMargin =
      window.innerHeight -
      (APP_BAR_HEIGHT +
        FILTER_ITEMS_HEIGHT +
        THEME_BUTTONS_HEIGHT +
        parseInt(getSafeAreaValue('top').slice(0, getSafeAreaValue('top').length - 2))) -
      targetElement.getBoundingClientRect().height
    scrollIntoView(scrollRef.current, targetElement, 'vertical', { paddingMarginEnd: paddingMargin })
    animateHighlightPoi()
  }, [selectedPoiId, scrollIntoView, FILTER_ITEMS_HEIGHT])

  useScrollToTopEffect(() => {
    scrollRef?.current?.scroll({ top: 0, behavior: 'smooth' })
  }, [scrollRef])

  return (
    <Container swipeableOptions={swipeableOptions} ref={scrollRef}>
      <EventBanner viewType="list" />
      {(() => {
        switch (poisStatus) {
          case 'loading':
            return <Loading />
          case 'no-pois':
          case 'no-filtered-pois': {
            return <EmptyState />
          }
          case 'exists': {
            return filteredPois.map((poi, idx) => {
              return (
                <UserCardSimplified
                  index={idx}
                  ref={(element) => {
                    if (!element || !poi.poi.id) return
                    listItemsRef.current[poi.poi.id] = element
                  }}
                  {...poi}
                  key={poi.poi.id}
                  isHighlighted={selectedPoiId === poi.poi.id && isHighlightOn}
                  on3DotClick={handleClickMoreOptions.bind(null, poi.poi.id)}
                  viewType={viewType}
                />
              )
            })
          }
        }
      })()}
    </Container>
  )
}

const Container = styled(ListViewComponent)`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: ${vars.$semantic.color.paperDefault};

  ${safeAreaPadding('bottom', '4rem')};
`

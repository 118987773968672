import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { Map } from './Map'
import { PlaceCardsSection } from './PlaceCardsSection'
import { useSelectedWatchedPlaceId } from '../hooks'

export const MapView = () => {
  const { selectedWatchedPlaceId } = useSelectedWatchedPlaceId()

  return (
    <StyledMapViewWrapper>
      <Map />
      <div className="poiCards">{selectedWatchedPlaceId && <PlaceCardsSection />}</div>
    </StyledMapViewWrapper>
  )
}

const StyledMapViewWrapper = styled.div`
  height: 100%;
  .poiCards {
    z-index: 2;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .safeAreaCover {
      width: 100%;
      height: 0px;
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
      background: ${vars.$semantic.color.paperDefault};
    }
  }
`

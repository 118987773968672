import { bridge } from '@src/bridge'

export const APP_ENV = process.env.APP_ENV || 'development'

export const IS_PROD = APP_ENV === 'production' || APP_ENV === 'stage'
export const IS_ALPHA = APP_ENV === 'alpha'
export const IS_LOCAL = APP_ENV === 'development'

export const APP_SCHEME = IS_PROD ? 'karrot://' : 'karrot.alpha://'
export const WEBVIEW_BASE_URL = IS_PROD
  ? 'https://webview.prod.kr.karrotmarket.com/'
  : 'https://webview.alpha.kr.karrotmarket.com/'

export const IS_WEB = bridge.environment === 'Web'
export const IS_ANDROID = bridge.environment === 'Android'
export const IS_IOS = bridge.environment === 'Cupertino'

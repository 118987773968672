/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { PoiSuggestion } from '../models';
// @ts-ignore
import { SuggestCreationBody } from '../models';
// @ts-ignore
import { SuggestRemovalBody } from '../models';
// @ts-ignore
import { SuggestUpdateBody } from '../models';
/**
 * SuggestionsApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @summary 장소 생성 제안
   * @param {string} xAuthToken 
   * @param {SuggestCreationBody} suggestCreationBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const suggestCreateV2AxiosParamCreator = async (xAuthToken: string, suggestCreationBody: SuggestCreationBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/suggestions/creations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = suggestCreationBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 장소 삭제 제안
   * @param {string} xAuthToken 
   * @param {SuggestRemovalBody} suggestRemovalBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const suggestRemovalV2AxiosParamCreator = async (xAuthToken: string, suggestRemovalBody: SuggestRemovalBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/suggestions/removals`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = suggestRemovalBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 장소 수정 제안
   * @param {string} xAuthToken 
   * @param {SuggestUpdateBody} suggestUpdateBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const suggestUpdateV2AxiosParamCreator = async (xAuthToken: string, suggestUpdateBody: SuggestUpdateBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/suggestions/updates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = suggestUpdateBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * SuggestionsApi - functional programming interface
 * @export
 */
    /**
     * 
     * @summary 장소 생성 제안
     * @param {string} xAuthToken 
     * @param {SuggestCreationBody} suggestCreationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const suggestCreateV2Fp = async (xAuthToken: string, suggestCreationBody: SuggestCreationBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoiSuggestion>> => {
        const localVarAxiosArgs = await suggestCreateV2AxiosParamCreator(xAuthToken, suggestCreationBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 장소 삭제 제안
     * @param {string} xAuthToken 
     * @param {SuggestRemovalBody} suggestRemovalBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const suggestRemovalV2Fp = async (xAuthToken: string, suggestRemovalBody: SuggestRemovalBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoiSuggestion>> => {
        const localVarAxiosArgs = await suggestRemovalV2AxiosParamCreator(xAuthToken, suggestRemovalBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 장소 수정 제안
     * @param {string} xAuthToken 
     * @param {SuggestUpdateBody} suggestUpdateBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const suggestUpdateV2Fp = async (xAuthToken: string, suggestUpdateBody: SuggestUpdateBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PoiSuggestion>> => {
        const localVarAxiosArgs = await suggestUpdateV2AxiosParamCreator(xAuthToken, suggestUpdateBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * SuggestionsApi - factory interface
 * @export
 */
    /**
     * 
     * @summary 장소 생성 제안
     * @param {string} xAuthToken 
     * @param {SuggestCreationBody} suggestCreationBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const suggestCreateV2 = (xAuthToken: string, suggestCreationBody: SuggestCreationBody, options?: any): AxiosPromise<PoiSuggestion> => {
        return suggestCreateV2Fp(xAuthToken, suggestCreationBody, options).then((request) => request());
    }
    /**
     * 
     * @summary 장소 삭제 제안
     * @param {string} xAuthToken 
     * @param {SuggestRemovalBody} suggestRemovalBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const suggestRemovalV2 = (xAuthToken: string, suggestRemovalBody: SuggestRemovalBody, options?: any): AxiosPromise<PoiSuggestion> => {
        return suggestRemovalV2Fp(xAuthToken, suggestRemovalBody, options).then((request) => request());
    }
    /**
     * 
     * @summary 장소 수정 제안
     * @param {string} xAuthToken 
     * @param {SuggestUpdateBody} suggestUpdateBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const suggestUpdateV2 = (xAuthToken: string, suggestUpdateBody: SuggestUpdateBody, options?: any): AxiosPromise<PoiSuggestion> => {
        return suggestUpdateV2Fp(xAuthToken, suggestUpdateBody, options).then((request) => request());
    }



import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { haversineDistance } from '@src/utils/coordinate'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { usePoisStatus } from './usePoisStatus'
import useSelectedPoi from './useSelectedPoi'
import { filteredPoisSelectorFamily, isInitialFetchAtomFamily } from '../state'

export const useOnFilteredPoisChangeEffect = () => {
  const { kakao, getBounds: getMapBounds, getCenter: getMapCenter } = useKakaoMap()
  const poisStatus = usePoisStatus()
  const { spotlightPoiOnMap } = useSelectedPoi()
  const screenInstance = useScreenInstance()
  const filteredPois = useRecoilValue(filteredPoisSelectorFamily(screenInstance))
  const isInitialFetch = useRecoilValue(isInitialFetchAtomFamily(screenInstance))

  useEffect(() => {
    if (isInitialFetch) return
    if (poisStatus === 'loading') return

    // filteredPois가 바뀌면, 유저가 보는 지도의 중심점에서 가장 가까운 poi를 자동으로 선택
    if (filteredPois.length > 0) {
      const mapCenter = getMapCenter()
      const mapBounds = getMapBounds()

      // FAILED TO LOAD KAKAO MAP
      if (!kakao || !mapCenter || !mapBounds) return

      const userViewingMapCenter = {
        longitude: mapCenter.longitude,
        latitude: mapCenter.latitude + (mapBounds.topRight.latitude - mapCenter.latitude) / 3,
      }
      const nearestPoiToMapCenter = filteredPois
        .slice()
        .sort(
          ({ poi: poiA }, { poi: poiB }) =>
            haversineDistance(poiA.coordinates!, userViewingMapCenter) -
            haversineDistance(poiB.coordinates!, userViewingMapCenter)
        )[0]
      spotlightPoiOnMap(nearestPoiToMapCenter.poi.id, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredPois, poisStatus])
}

import { Modal } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import { vars } from '@seed-design/design-token'
import React, { useMemo, useState } from 'react'
import Slider from 'react-slick'

import { slickOverrideStyle } from '@src/styles/slider'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ImageZoom from './ImageZoom'

interface Props {
  images: string[]
  initialIndex: number | null
  onClose: (e?: React.MouseEvent) => void
}
const INITIAL_SLIDER_OPTION = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  accessibility: false,
}

const ImageDetailSlider: React.FC<Props> = ({ images, initialIndex, onClose }) => {
  const isOpen = useMemo(() => initialIndex !== null, [initialIndex])

  const [activeIndex, setIndex] = useState(0)
  return (
    <Modal isOpen={isOpen} bodyClassName={'modal-body'}>
      <Base>
        {initialIndex !== null && (
          <Slider {...INITIAL_SLIDER_OPTION} initialSlide={initialIndex} afterChange={setIndex}>
            {images.map((image, idx) => (
              <ImageZoom key={idx} url={image} disabled={idx !== activeIndex} />
            ))}
          </Slider>
        )}
        <CloseButton onClick={onClose}>
          <IconCloseRegular className="icon" />
        </CloseButton>
      </Base>
    </Modal>
  )
}

const Base = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: ${vars.$static.color.staticBlack};
  ${slickOverrideStyle}
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  top: constant(safe-area-inset-top);
  top: env(safe-area-inset-top);
  left: 0;

  width: 80px;
  height: 80px;

  display: flex;
  justify-content: flex-start;

  padding: 16px 0 0 16px;

  .icon {
    width: 24px;
    height: 24px;
    color: ${vars.$static.color.staticWhite};
  }
`

export default ImageDetailSlider

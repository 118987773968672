import { useActivityParams } from '@stackflow/react'
import React, { Suspense } from 'react'
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useSetRecoilState } from 'recoil'

import { bizReviewApi } from '@src/apis'
import { Payload as BizReviewRequestPayload } from '@src/apis/BizReview'
import { POIReviewsApi } from '@src/apis/generated/poi/api'
import { CreateReviewResponse, ReviewDtoV2 } from '@src/apis/generated/poi/models'
import type { Review as BizReviewType } from '@src/apis/types/BizReview'
import { useToast } from '@src/components/Toast'
import { myPoiReviewSelectorFamily } from '@src/shared/review/store/poiReview'
import { useNavigator, Screen } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { usePoiInputSelectionState } from '@src/widgets/PoiInput/state'

import { reviewableBizStatusSelectorFamily } from './state/bizReview'
import { SubmitButton, SubmitParams } from './Submit'
import WritePoiReview from './WritePoiReview'
import { submittedReviewAtom } from '../Map/state'

export const WritePoiReviewPage = () => {
  return (
    <Screen
      appBar={{
        title: '장소 추천하기',
        appendRight: () => {
          return (
            <Suspense>
              <PageSubmitButton />
            </Suspense>
          )
        },
      }}>
      <WritePoiReview />
    </Screen>
  )
}

const PageSubmitButton = () => {
  const { entry } = useActivityParams<{
    entry: string
  }>()

  const { showToast } = useToast()
  const [{ id, bizAccountId: selectedBizAccountId }] = usePoiInputSelectionState()
  const { pop } = useNavigator()
  const myReview = useRecoilValue(myPoiReviewSelectorFamily({ poiId: id }))
  const refreshMyReview = useRecoilRefresher_UNSTABLE(myPoiReviewSelectorFamily({ poiId: id }))

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const { reviewId: myBizReviewId } = useRecoilValue(reviewableBizStatusSelectorFamily(selectedBizAccountId ?? ''))
  const setSubmittedReview = useSetRecoilState(submittedReviewAtom)

  const handlePoiReview = async ({ params }: { params: SubmitParams }) => {
    const isNewReview = !myReview

    try {
      const response = isNewReview
        ? await POIReviewsApi.createReviewV2(userInfo!.authToken, { ...params, tagIds: [] }, userInfo?.authToken)
        : await POIReviewsApi.updateReviewV2(myReview.id, userInfo!.authToken, { ...params, tagIds: [] })

      handleSubmitSuccess({ type: 'POI_REVIEW', submittedReview: response.data, isNewReview })
    } catch (err) {
      handleSubmitFailure({ isNewReview })

      throw err
    }
  }

  const handleBizProfileReview = async ({
    bizAccountId,
    content,
    imageIds,
  }: {
    bizAccountId: string
    content: string
    imageIds: string[]
  }) => {
    const isNewReview = !myBizReviewId
    const payload: BizReviewRequestPayload = {
      business_account_id: bizAccountId,
      content,
      picture_ids: imageIds,
      tag_ids: [],
      region_id: String(userInfo?.region.id),
      review_type: 'BUSINESS_PROFILE',
    }
    try {
      const resp = isNewReview ? await bizReviewApi.create(payload) : await bizReviewApi.update(myBizReviewId, payload)

      handleSubmitSuccess({ type: 'BIZ_REVIEW', submittedReview: resp.data.data, isNewReview })
    } catch (err) {
      handleSubmitFailure({ isNewReview })

      throw err
    }
  }

  type ReviewType = 'BIZ_REVIEW' | 'POI_REVIEW'
  const handleSubmitSuccess = async <T extends ReviewType>({
    submittedReview,
    isNewReview,
  }: {
    type: T
    submittedReview: T extends 'BIZ_REVIEW' ? BizReviewType : CreateReviewResponse | ReviewDtoV2
    isNewReview: boolean
  }) => {
    if (entry === 'daangn_map') {
      setSubmittedReview({
        poiId: id,
        bizAccountId: selectedBizAccountId,
        reviewId: submittedReview.id,
      })
    } else {
      showToast({
        text: `${
          isNewReview
            ? `소중한 후기 감사해요. ${userInfo?.nickname}님 덕분에 동네 정보가 풍부해졌어요.`
            : `후기를 수정 했어요.`
        }`,
        duration: isNewReview ? 'long' : 'short',
      })
    }

    refreshMyReview()

    pop()
  }

  const handleSubmitFailure = ({ isNewReview }: { isNewReview: boolean }) => {
    showToast({
      text: `후기 ${isNewReview ? '등록' : '수정'}에 실패했어요. 다시 한번 시도해주세요.`,
      duration: 'long',
    })
  }

  const handleSubmit = async (params: SubmitParams) => {
    return params.bizAccountId
      ? handleBizProfileReview({
          bizAccountId: params.bizAccountId,
          content: params.content,
          imageIds: params.imageIds,
        })
      : handlePoiReview({ params })
  }

  return <SubmitButton onSubmit={handleSubmit}>완료</SubmitButton>
}

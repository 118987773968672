import { createLoadableFamily } from '@daangn/recoil-loadable'
import axios from 'axios'

import { BUSINESS_FEED_BASE_URL } from '@src/constants/apiUrls'

import { pageKeyPrefix } from './key'
import { PurposefulCategoryResponse } from './type'

export const bizProfileAndPoiByThemeLoadableFamily = createLoadableFamily<
  PurposefulCategoryResponse | null,
  {
    themeId: string
    sort: string
    filterIds: string[]
    regionId: number
    userPositionLat?: number
    userPositionLng?: number
  },
  PurposefulCategoryResponse
>({
  getStore: ({ atomFamily }) =>
    atomFamily({
      key: pageKeyPrefix('bizProfileAndPoi'),
    }),
  fetch: async ({ behavior, prevState, variables }) => {
    if (variables.sort === '' || variables.regionId === -1) return null

    return await axios
      .post(`${BUSINESS_FEED_BASE_URL}/api/v1/feeds/contents-curator/purposeful/${variables.themeId}`, {
        filter_options: variables.filterIds,
        sort_option: variables.sort,
        region_id: variables.regionId,
        region_range: 3,
        coordinates:
          variables.userPositionLat && variables.userPositionLng
            ? {
                lat: variables.userPositionLat,
                lng: variables.userPositionLng,
              }
            : undefined,
        cursor: behavior === 'loadMore' && prevState?.cursor ? prevState.cursor : undefined,
      })
      .then((res) => res.data.data)
  },
  onSuccess: ({ response, prevState }) => {
    if (!response) return null

    return {
      ...response,
      business_account_and_poi_base_response: [
        ...(prevState?.business_account_and_poi_base_response ?? []),
        ...(response?.business_account_and_poi_base_response ?? []),
      ],
    }
  },
})

import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useSetRecoilState } from 'recoil'

import { useNavigator } from '@src/stackflow'

import ReviewCard from './ReviewCard'
import { useAnalytics } from '../hooks'
import { interestedSingleReviewAtom, ReviewResponseType } from '../state'

interface Props {
  reviews: ReviewResponseType['items']
}
const ReviewPreviews = ({ reviews }: Props) => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const setInterestedSingleReview = useSetRecoilState(interestedSingleReviewAtom)

  const handleClickMyReviewMoreOptions = ({ reviewId, reviewUserId }: { reviewId: string; reviewUserId: number }) => {
    logEvent('click_review_card_more_options_button')
    setInterestedSingleReview({
      reviewId,
      reviewUserId,
    })
    push('poi_detail_modal', { poiId, modalId: 'singleReviewOptionsBottomSheet' })
  }

  return (
    <>
      {reviews.map((review, idx) => (
        <ReviewCard
          key={idx}
          data={{
            type: 'review' as const,
            id: String(review.id!),
            author: {
              id: review.user!.id,
              name: review.user!.nickname,
              profileImage: review.user?.profileImageUrl,
              regionName: review.user?.region?.name ?? '',
              regionCheckInCount: review.user?.displayRegionCheckinsCount,
            },
            content: review.content!,
            images: {
              url: review.images?.map((image) => image.thumbnail) || ([] as string[]),
            },
            tags: review.tagNames,
            createdAt: review.createdAt!,
          }}
          onClick3Dot={() =>
            handleClickMyReviewMoreOptions({
              reviewId: review.id,
              reviewUserId: review.userId,
            })
          }
        />
      ))}

      {/* {optionsOpenedReview && (
        <OptionBottomSheet
          poiId={poiId}
          isOpen={!!optionsOpenedReview}
          reviewId={String(optionsOpenedReview.id)}
          reviewUserId={String(optionsOpenedReview.userId)}
          onClose={() => {
            setOptionsOpenedReview(null)
          }}
        /> */}
      {/* )} */}
    </>
  )
}

export default ReviewPreviews

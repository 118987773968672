import { isDefaultLoadable, useLoadableFamily } from '@daangn/recoil-loadable'
import { useEffect } from 'react'
import { atom, DefaultValue, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil'

import { POIReactionsApi } from '@src/apis/generated/poi/api'
import { ReactionDto } from '@src/apis/generated/poi/models'
import { reviewsByPoiIdAndUserIdLoadableFamily } from '@src/shared/review'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { ReviewPageSerializeParam, reviewSortingOptionAtomFamily } from './review'
import {
  communityArticlesSelectorFamily,
  communityCommentsSelectorFamily,
  communityPostsAtomFamily,
  CommunityPostsPageSerializeParam,
  communityPostsSortingOptionAtomFamily,
} from './story'
import { detailPagePrefix } from '../constants/recoilKey'

const prefix = `${detailPagePrefix}/reaction`
const generateKey = recoilKeyGenerator(prefix)

const myReactionQuery = selectorFamily({
  key: generateKey('getReviewReactions'),
  get:
    ({
      resourceIds,
      resourceType,
    }: {
      resourceIds: string[]
      resourceType: Parameters<typeof POIReactionsApi.getReactionsV2>['1']
    }) =>
    async ({ get }) => {
      const userInfo = get(bridgeInfoUserAtom)

      if (!userInfo || resourceIds.length <= 0) return []

      const { data } = await POIReactionsApi.getReactionsV2(userInfo.authToken, resourceType, resourceIds)

      return data.data.items
    },
})

const myArticleReactionQuery = selectorFamily({
  key: generateKey('getArticleReactions'),
  get:
    (param: CommunityPostsPageSerializeParam) =>
    async ({ get }) => {
      const communityArticles = get(communityArticlesSelectorFamily(param))

      if (communityArticles.length <= 0) return []

      const resourceIds = communityArticles.map((article) => article.sourceId)

      return get(myReactionQuery({ resourceIds, resourceType: 'STORY_ARTICLE' }))
    },
})

const myCommentReactionQuery = selectorFamily({
  key: generateKey('getCommentReactions'),
  get:
    (param: CommunityPostsPageSerializeParam) =>
    async ({ get }) => {
      const communityComments = get(communityCommentsSelectorFamily(param))

      if (communityComments.length <= 0) return []

      const resourceIds = communityComments.map((comment) => comment.sourceId)

      return get(myReactionQuery({ resourceIds, resourceType: 'STORY_COMMENT' }))
    },
})

const myReviewReactionQuery = selectorFamily({
  key: generateKey('getReviewReactions'),
  get:
    (param: ReviewPageSerializeParam) =>
    async ({ get }) => {
      const reviewList = get(
        reviewsByPoiIdAndUserIdLoadableFamily.store({
          poiId: param.poiId,
          sort: param.sortingOption,
          userId: undefined,
        })
      )

      if (isDefaultLoadable(reviewList)) return []

      if (!reviewList || (reviewList?.total ? reviewList.total : 0) <= 0) return []

      const resourceIds = reviewList.items.map((review) => review.id)

      return get(myReactionQuery({ resourceIds, resourceType: 'POI_REVIEW' }))
    },
})

const getMyReactions = selectorFamily({
  key: generateKey('myReactions'),
  get:
    ({
      poiId,
      screenInstance,
      reviewSortingOption,
      communityPostsSortingOption,
    }: {
      poiId: string
      screenInstance: string
      reviewSortingOption: ReviewPageSerializeParam['sortingOption']
      communityPostsSortingOption: CommunityPostsPageSerializeParam['sortingOption']
    }) =>
    ({ get }) => {
      const myArticleReactions = get(
        myArticleReactionQuery({ poiId, screenInstance, sortingOption: communityPostsSortingOption })
      )
      const myCommentReactions = get(
        myCommentReactionQuery({ poiId, screenInstance, sortingOption: communityPostsSortingOption })
      )
      const myReviewReactions = get(
        myReviewReactionQuery({ poiId, screenInstance, sortingOption: reviewSortingOption })
      )

      return [...myArticleReactions, ...myCommentReactions, ...myReviewReactions]
    },
})

export type Reaction = ReactionDto
interface ReactionState extends Omit<Reaction, 'userId' | 'reactedAt'> {
  reactionCount: number
  isReactedByMe: boolean
}

export const reactionStore = atom<ReactionState[]>({
  key: generateKey('reactionStore'),
  default: [],
})

const reactionItemSelector = selectorFamily<
  ReactionState,
  { resourceType: Reaction['resourceType']; resourceId: Reaction['resourceId']; type: Reaction['type'] }
>({
  key: generateKey('reactionItemSelector'),
  get:
    ({ resourceType, resourceId, type }) =>
    ({ get }) => {
      const item = get(reactionStore).find(
        (item) => item.resourceId === resourceId && item.resourceType === resourceType && item.type === type
      )

      return item ?? { resourceType, resourceId, type, reactionCount: 0, isReactedByMe: false }
    },
  set:
    ({ resourceType, resourceId, type }) =>
    ({ set }, newValue) => {
      if (!(newValue instanceof DefaultValue) && newValue) {
        set(reactionStore, (prevState) =>
          prevState.map((item) =>
            item.resourceId === resourceId && item.resourceType === resourceType && item.type === type
              ? { ...item, isReactedByMe: newValue.isReactedByMe, reactionCount: newValue.reactionCount }
              : item
          )
        )
      }
    },
})

const useSyncReactions = ({ poiId }: { poiId: string }) => {
  const screenInstance = useScreenInstance()

  const communityPostsSortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const communityPosts = useRecoilValue(
    communityPostsAtomFamily({ poiId, screenInstance, sortingOption: communityPostsSortingOption })
  )

  const reviewSortingOption = useRecoilValue(reviewSortingOptionAtomFamily({ poiId, screenInstance }))
  const { value: reviewList } = useLoadableFamily({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId, sort: reviewSortingOption, userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  const myReactions = useRecoilValue(
    getMyReactions({ poiId, screenInstance, reviewSortingOption, communityPostsSortingOption })
  )
  const setReactions = useSetRecoilState(reactionStore)

  useEffect(() => {
    const storyReactions: ReactionState[] = communityPosts.map((post) => ({
      resourceId: post.sourceId,
      resourceType: post.type === 'STORY_COMMENT' ? 'STORY_COMMENT' : 'STORY_ARTICLE',
      type: 'UPVOTE',
      reactionCount: post.reaction?.upvoteCount ?? 0,
      isReactedByMe: false,
    }))

    const reviewReactions: ReactionState[] =
      isDefaultLoadable(reviewList) || !reviewList
        ? []
        : reviewList.items.map(({ id, reaction }) => ({
            resourceId: String(id),
            resourceType: 'POI_REVIEW',
            type: 'UPVOTE',
            reactionCount: reaction?.upvoteCount ?? 0,
            isReactedByMe: false,
          }))

    const allReactions = storyReactions.concat(reviewReactions)

    const reactions: ReactionState[] = allReactions.map((reaction) => {
      const isReactedByMe = !!myReactions.find(
        (item) => item.resourceId === reaction.resourceId && item.resourceType === reaction.resourceType
      )

      if (isReactedByMe) {
        return {
          ...reaction,
          isReactedByMe: true,
        }
      }
      return reaction
    })

    setReactions(reactions)
  }, [myReactions, setReactions, communityPosts, reviewList])
}

export { reactionItemSelector }
export { useSyncReactions }

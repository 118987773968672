import { selectorFamily } from 'recoil'

import { curationCategorySelectorFamily } from '@src/shared/curationCategory'

import { generateReportPageRecoilKey } from './keys'

export const curationCategoryByIdSelector = selectorFamily({
  key: generateReportPageRecoilKey('curationCategoriesSelector'),
  get:
    (id: number | null) =>
    async ({ get }) => {
      if (!id) return null

      return (
        get(curationCategorySelectorFamily('daangn-map')).find((curationCategory) => curationCategory.id === id) ?? null
      )
    },
})

import { bridge } from '@src/bridge'
import { SERVER_BASE_URL } from '@src/constants/apiUrls'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { MAP_ID } from '@src/constants/id'
import { Coordinates } from '@src/types/global'

type MapTheme = 'COMMUNITY_RESTAURANT' | 'WINTER_DESSERT'
type Share =
  | {
      type: 'POI'
      data: {
        id: string
        name: string
        address: string
      }
    }
  | {
      type: 'MAP'
      theme: MapTheme
      data: {
        region: {
          id: number
          name: string
        }
      }
    }
  | {
      type: 'WINTER_SNACK'
      data: {
        filterId: string
        selectedPoiId?: string
        coordinates?: Coordinates
      }
    }

export const handleShare = (share: Share) => {
  const baseUrl = SERVER_BASE_URL({ region: 'kr' }).POI
  switch (share.type) {
    case 'POI':
      if (IS_LOCAL) alert(`${baseUrl}/shared/pois/${share.data.id}`)
      return share.data.id
        ? bridge.share.open({
            url: `${baseUrl}/shared/pois/${share.data.id}`,
            text: share.data.name,
          })
        : bridge.toast.open({ body: '공유하기를 실패했어요.' })
    case 'MAP':
      if (IS_LOCAL) alert(`[당근마켓] ${share.data.region.name || '우리동네'} 맛집 지도`)
      return share.theme === 'COMMUNITY_RESTAURANT'
        ? bridge.share.open({
            url: `${baseUrl}/shared/maps/${MAP_ID.COMMUNITY_RESTAURANT}?region=${share.data.region.id}`,
            text: `[당근마켓] ${share.data.region.name || '우리동네'} 맛집 지도`,
          })
        : bridge.toast.open({ body: `공유하기를 실패했어요.` })
    case 'WINTER_SNACK': {
      const { filterId, coordinates, selectedPoiId } = share.data

      const queryParams = new URLSearchParams({
        filterId,
      })
      if (selectedPoiId) queryParams.append('selectedPoiId', selectedPoiId)
      if (coordinates) queryParams.append('coordinates', `${coordinates.latitude},${coordinates.longitude}`)

      if (IS_LOCAL) alert(`${baseUrl}/shared/winter-snack?${queryParams}`)

      return bridge.share.open({
        url: `${baseUrl}/shared/winter-snack?${queryParams}`,
        text: `[당근마켓] 겨울 간식 지도`,
      })
    }
  }
}

import { UserInfoHeader } from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { ActivityComponentType } from '@stackflow/react'
import React, { useEffect } from 'react'
import { SwipeEventData, useSwipeable } from 'react-swipeable'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useRegisterScreenCommonPayloadEffect } from '@src/components/Analytics'
import { InfiniteScrollObserver } from '@src/components/InfiniteScroll'
import { APP_SCHEME } from '@src/constants/environmentConstants'
import { Screen } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { useScreenInstance } from '@src/widgets/screenInstance'
import {
  useCleanupUserPostOnUnmount,
  useSubprofile,
  useSyncUserPosts,
  useUserPosts,
} from '@src/widgets/SubprofileHalfview/hooks'
import { subprofileUserIdAtomFamily } from '@src/widgets/SubprofileHalfview/state'

import SubprofileCard from './Card'
import { useAnalytics, useCleanupReactions, useSyncMyReactionMapper, useSyncReactions } from './hooks'

export type ActivityParams = {
  userId: string
  referrer?: string
}

const SubprofilePage: ActivityComponentType<ActivityParams> = ({ params: { userId } }) => {
  const subprofile = useSubprofile()
  const { posts, meta, fetchMore } = useUserPosts()
  const logEvent = useAnalytics()
  const { ref } = useSwipeable({
    onSwiped: (eventData: SwipeEventData) => {
      logEvent('handle_scroll_list', {
        direction: eventData.dir === 'Down' ? 'up' : 'down',
        scrolled_pixels: eventData.deltaY,
      })
    },
  })
  const screenInstance = useScreenInstance()

  const setSubprofileUserId = useSetRecoilState(subprofileUserIdAtomFamily(screenInstance))
  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const isMySubprofile = userInfo?.id?.toString() === userId

  useSyncUserPosts()
  useSyncMyReactionMapper()
  useSyncReactions()
  useCleanupUserPostOnUnmount()
  useCleanupReactions()

  const handleClickUserProfile = () => {
    if (!subprofile) return

    logEvent('click_user_profile', {
      viewer_id: userInfo?.id,
      subprofile_user_id: subprofile.user.id,
    })

    if (isMySubprofile) {
      window.location.href = `${APP_SCHEME}my_profile`
    } else {
      window.location.href = `${APP_SCHEME}users/${subprofile.user.id}`
    }
  }

  useEffect(() => {
    if (!userId) return
    setSubprofileUserId(userId)
  }, [userId, setSubprofileUserId])

  useRegisterScreenCommonPayloadEffect(
    {
      viewer_id: userInfo?.id ?? null,
      subprofile_user_id: subprofile?.user.id ?? null,
    },
    []
  )

  return subprofile ? (
    <Screen
      appBar={{
        title: '동네 가게 후기',
      }}>
      {!isMySubprofile && (
        <UserContainer onClick={handleClickUserProfile}>
          <UserInfoHeader
            name={subprofile.user.nickname}
            info={[
              `${subprofile.user.region!.name}${
                subprofile.user.displayRegionCheckinsCount > 0
                  ? ` 인증 ${subprofile.user.displayRegionCheckinsCount}회`
                  : ''
              }`,
            ]}
            temperature={Math.round(subprofile.user.temperature! * 10) / 10}
            profileImage={subprofile.user.profileImageUrl}
          />
        </UserContainer>
      )}
      <Summary>
        <div className="total">
          {isMySubprofile ? '내가 ' : ''}남긴 후기<span className="count">{meta?.total}</span>
        </div>
        <div className="detail">
          가게 후기 {subprofile.poiReviewTotalCount + subprofile.bizReviewTotalCount} &middot; 동네생활{' '}
          {subprofile.storyArticleTotalCount + subprofile.storyCommentTotalCount}
        </div>
      </Summary>
      <div ref={ref}>
        {posts?.map(({ pois, post }, index) => {
          return <SubprofileCard key={post.id} poi={pois[0]} post={post} index={index} />
        })}
        {meta?.hasNextPage ? <InfiniteScrollObserver onIntersecting={fetchMore} /> : <BottomPlaceholder />}
      </div>
    </Screen>
  ) : null
}

const UserContainer = styled.div`
  padding: 1rem 1rem 0;
`

const Summary = styled.div`
  padding: 1rem 1rem;
  .total {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${vars.$scale.color.gray900};
    margin: 0 0 0.125rem;
    .count {
      margin: 0 0 0 0.25rem;
      color: ${vars.$semantic.color.primary};
    }
  }
  .detail {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    color: ${vars.$scale.color.gray600};
  }
`

const BottomPlaceholder = styled.div`
  height: 4rem;
`

export default SubprofilePage

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'

import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../../hooks'

export const UpdateSuggestion = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const goToUpdateSuggestionPage = () => {
    logEvent('click_suggest_edit_info_button', {
      area: 'home_tab',
    })
    push('update_poi_suggestion', { poiId })
  }

  return (
    <Wrapper>
      이 장소에 대해 알고 계신가요?
      <a onClick={goToUpdateSuggestionPage}>정보 수정 제안</a>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 1.25rem 1rem;
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label3Regular};
  background-color: ${vars.$semantic.color.paperDefault};

  a {
    color: ${vars.$scale.color.blue800};
    ${vars.$semantic.typography.label3Bold};
  }
`

import { getBusinessAccountV2 } from '@daangn/local-business-network/lib/businessAccount'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateKey = recoilKeyGenerator('bizProfile')

type BizProfileRequestParameters = Parameters<typeof getBusinessAccountV2>['0']['params']

export const bizProfileSelectorFamily = selectorFamily({
  key: generateKey('couponBoxSelectorFamily'),
  get: (params: BizProfileRequestParameters) => async () => {
    const { businessAccountId } = params

    if (!businessAccountId) return undefined

    try {
      const { data } = await getBusinessAccountV2({ params })

      return data.data?.businessAccount
    } catch (error) {
      captureException(error)

      throw error
    }
  },
})

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

import { Button } from '@src/components/Button'

import { FILTER_HEIGHT } from './constants/style'
import { useFilter } from './hook'

export const EmptyList = () => {
  const { reset: resetFilter } = useFilter()

  return (
    <Base>
      <Wrapper>
        <Text>
          필터에 해당하는 가게가 없어요.
          <br />
          필터를 바꾸거나 초기화해 보세요.
        </Text>
        <ResetButton onClick={resetFilter} styleTheme="secondary">
          필터 초기화
        </ResetButton>
      </Wrapper>
    </Base>
  )
}

const Base = styled.div`
  margin: 5rem 0 0;
  position: relative;
  height: calc(100% - calc(${FILTER_HEIGHT} * 0.0625rem));
  margin: 0;
`
const Wrapper = styled.div`
  position: absolute;
  inset: 0;
  top: calc(40% - 3.5rem);

  height: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.p`
  text-align: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.subtitle1Regular};
`
const ResetButton = styled(Button)`
  margin: 1.75rem 0 0;
  ${vars.$semantic.typography.label3Bold};
`

import { bridge } from '@src/bridge'
import { IS_LOCAL } from '@src/constants/environmentConstants'
import { Coordinates } from '@src/types/global'
import { tokenStorage } from '@src/utils/tokenStorage'

export interface UserInfo {
  id: string
  nickname: string
  authToken: string
  region: {
    id: number
    name: string
    centerCoordinates?: Coordinates
    center_coordinates?: Coordinates
  }
}
export default class Bridge {
  private bridge = bridge

  constructor() {
    this.bridge = bridge
  }

  getUserInfo = async (): Promise<UserInfo> => {
    if (IS_LOCAL) {
      if (!process.env.LOCAL_AUTH_TOKEN) throw new Error('LOCAL_AUTH_TOKEN does not exist in the environment')
      if (!process.env.LOCAL_USER_ID) throw new Error('LOCAL_USER_ID does not exist in the environment')

      tokenStorage.setAuthToken(process.env.LOCAL_AUTH_TOKEN)

      return {
        id: process.env.LOCAL_USER_ID,
        nickname: '테빅',
        authToken: process.env.LOCAL_AUTH_TOKEN,
        region: {
          id: 366,
          name: '역삼1동',
          center_coordinates: { latitude: 37.500072122029415, longitude: 127.03854258877928 },
        },
      }
    }

    return await this.bridge.info.user()
  }
}

import styled from '@emotion/styled'
import { useFormik } from 'formik'
import React from 'react'

import Checkbox from '@src/assets/icons/Checkbox'
import RadioUnSelected from '@src/assets/icons/RadioUnSelected'

export type SelectorOptionValue = string | number

interface Option {
  value: SelectorOptionValue
  label: string
}

interface Props {
  id: string
  options: Option[]
  selectedValues: SelectorOptionValue[]
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  onToggleOption?: (value: SelectorOptionValue, checked: boolean) => void
}

const SelectorInput = ({ id, options, selectedValues, setFieldValue, onToggleOption }: Props) => {
  return (
    <Options>
      {options.map(({ label, value }) => {
        const checked = selectedValues.indexOf(value) !== -1
        return (
          <Option
            key={value}
            checked={checked}
            onClick={() => {
              const newValues = checked
                ? selectedValues?.filter((selectedId: SelectorOptionValue) => {
                    return selectedId !== value
                  })
                : [value].concat(selectedValues ?? [])
              setFieldValue(id, newValues)

              onToggleOption?.(value, checked)
            }}>
            {checked ? <Checkbox checked={checked} size={20} /> : <RadioUnSelected />}
            <input type="checkbox" id={label} value={value} defaultChecked={checked} />
            <div>{label}</div>
          </Option>
        )
      })}
    </Options>
  )
}

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Option = styled.div<{ checked: boolean }>`
  display: flex;
  align-items: center;
  flex-basis: 50%;
  padding: 0.5rem 0;
  input {
    display: none;
  }
  svg {
    margin: ${({ checked }) => (checked ? '0.125rem 0.625rem 0.125rem 0.125rem' : '0 0.5rem 0 0')};
  }
`
export default SelectorInput

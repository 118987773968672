import { atom, selector } from 'recoil'

import { poiByIdSelectorFamily } from '@src/shared/poi/store'

import { generateReportPageRecoilKey } from './keys'

type PoiId = string

export const poiIdAtom = atom<PoiId | null>({
  key: generateReportPageRecoilKey('poiIdAtom'),
  default: null,
})

export const poiByPoiIdSelector = selector({
  key: generateReportPageRecoilKey('poisSelector'),
  get: async ({ get }) => {
    const poiId = get(poiIdAtom)
    if (!poiId) return

    return get(poiByIdSelectorFamily({ poiId }))
  },
})

import { useCallback } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { selectedWatchedPlacesFilterAtomFamily, MapThemeFilterType } from '../state'

export function useSelectedWatchedPlacesFilter() {
  const screenInstance = useScreenInstance()
  const [selectedWatchedPlacesFilter, setSelectedWatchedPlacesFilter] = useRecoilState(
    selectedWatchedPlacesFilterAtomFamily(screenInstance)
  )
  const resetFilter = useResetRecoilState(selectedWatchedPlacesFilterAtomFamily(screenInstance))

  const selectFilter = useCallback(
    (filter: MapThemeFilterType, onSelect?: () => void) => {
      onSelect?.()

      setSelectedWatchedPlacesFilter(filter)
    },
    [setSelectedWatchedPlacesFilter]
  )

  const unselectFilter = useCallback(
    (onUnselect?: () => void) => {
      onUnselect?.()

      resetFilter()
    },
    [resetFilter]
  )

  const handleToggleWatchedPlacesFilter = useCallback(
    (filter: MapThemeFilterType, onSelect?: () => void, onUnselect?: () => void) => {
      if (selectedWatchedPlacesFilter?.eventId === filter?.eventId) {
        unselectFilter(onUnselect)
      } else {
        selectFilter(filter, onSelect)
      }
    },
    [selectFilter, selectedWatchedPlacesFilter?.eventId, unselectFilter]
  )

  const handleClearWatchedPlacesFilter = () => {
    resetFilter()
  }

  return { selectedWatchedPlacesFilter, handleToggleWatchedPlacesFilter, handleClearWatchedPlacesFilter }
}

import { Modal, ModalProps } from '@daangn/karrot-clothes'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { ReactNode } from 'react'

import { Spacing } from '@src/styles/Spacing'

interface Props extends Omit<ModalProps, 'isCenterMode' | 'children'> {
  title?: string
  children?: ReactNode
  appendBottom?: ReactNode
}

function Drawer({ title, appendBottom, children, ...props }: Props) {
  return (
    <StyledModal isCenterMode={false} {...props}>
      {({ duration, outro }) => (
        <Container
          className={outro ? 'outro' : ''}
          style={{
            animationDuration: `${duration}ms`,
          }}>
          {title && <div className="title">{title}</div>}
          <div>{children}</div>
          <Spacing size={15} />
          <div className="bottom">{appendBottom}</div>
        </Container>
      )}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
`

const fromBottomAnimation = css`
  animation: fadeUp 300ms;
  &.outro {
    animation: fadeDown 300ms;
  }
  @keyframes fadeUp {
    from {
      transform: translate3d(0, 1.5rem, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeDown {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, 1.5rem, 0);
    }
  }
`

const Container = styled.div`
  position: absolute;
  bottom: -100vh;
  width: 100vw;
  margin: 0;
  padding: 1rem;
  background: ${vars.$semantic.color.paperSheet};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  ${fromBottomAnimation};

  .title {
    ${vars.$semantic.typography.title3Bold}
    margin: 1rem 0 0.75rem;
  }

  .bottom {
    padding: 0;
    padding: 0 0 constant(safe-area-inset-bottom);
    padding: 0 0 env(safe-area-inset-bottom);
  }
`

export default Drawer

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { TextareaHTMLAttributes, useEffect, useRef, useState } from 'react'

interface Props {
  id: string
  value?: string
  initialValue?: string
  placeholder?: string
  onChange?: TextareaHTMLAttributes<HTMLTextAreaElement>['onChange']
  onFocus?: TextareaHTMLAttributes<HTMLTextAreaElement>['onFocus']
  rowsLength?: number
  maxHeight?: number
  fixedHeight?: number
  maxTextLength?: number
}

const MultiLineTextInput = ({
  id,
  placeholder,
  value: propValue,
  initialValue,
  onChange,
  onFocus,
  rowsLength,
  maxTextLength,
  maxHeight,
  fixedHeight,
}: Props) => {
  const [value, setValue] = useState(initialValue)

  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    const textarea = textareaRef.current
    if (fixedHeight || !textarea) return

    const handleOnChange = () => {
      textarea.style.height = 'auto'
      textarea.style.height = `${Math.max(textarea.scrollHeight, 48)}px`
    }

    handleOnChange()
    textarea.addEventListener('input', handleOnChange)
    return () => {
      textarea.removeEventListener('input', handleOnChange)
    }
  }, [fixedHeight])

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  return (
    <Container maxHeight={maxHeight} fixedHeight={fixedHeight}>
      <textarea
        id={id}
        value={value}
        ref={textareaRef}
        onChange={(e) => {
          const value = e.target.value
          if (maxTextLength && value.length > maxTextLength) {
            return
          }

          onChange?.(e)
          setValue(e.target.value)
        }}
        onFocus={onFocus}
        placeholder={placeholder}
        rows={rowsLength}
      />
      {maxTextLength && (
        <WordCount>
          {value?.length ?? 0}/{maxTextLength}
        </WordCount>
      )}
    </Container>
  )
}

const Container = styled.div<{ maxHeight?: number; fixedHeight?: number }>`
  display: flex;
  flex-direction: column;
  background: ${vars.$scale.color.gray00};
  border: 1px solid ${vars.$scale.color.gray400};
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0.75rem 1rem;
  height: ${({ fixedHeight }) => fixedHeight}px;
  max-height: ${({ maxHeight }) => maxHeight}px;

  textarea {
    ${vars.$semantic.typography.bodyM1Regular}
    align-self: stretch;
    background-color: transparent;
    padding: 0;
    margin: 0;
    -webkit-tap-highlight-color: transparent;

    &::placeholder {
      color: ${vars.$scale.color.gray600};
    }
  }
`

const WordCount = styled.div`
  ${vars.$semantic.typography.caption1Regular}
  margin: 0.5rem 0 0;
  text-align: right;
  color: ${vars.$scale.color.gray600};
`

export default MultiLineTextInput

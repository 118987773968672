import { validateAddress } from '../Address'
import { validateOperatingInformation } from '../OperatingInformation'
import type { Field, FieldType, FormValues } from '../types'
import { validateWinterSnackMenuErrors } from '../WinterSnackMenu'

export const validate = async (fields: Field<FieldType>[], values: FormValues) => {
  const addressErrors = validateAddress(
    values.address,
    fields.find((field) => field.id === 'address') as Field<'address'>
  )

  const winterSnackMenuErrors = validateWinterSnackMenuErrors(
    values.winterSnackMenu,
    fields.find((field) => field.id === 'winterSnackMenu') as Field<'winterSnackMenu'>
  )

  const operatingInformationErrors = validateOperatingInformation(
    values.operatingInformation,
    fields.find((field) => field.id === 'operatingInformation') as Field<'operatingInformation'>
  )

  return {
    ...addressErrors,
    ...operatingInformationErrors,
    ...winterSnackMenuErrors,
  }
}

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useCallback, useRef, useState } from 'react'

import { PostPreview } from '@src/components/Preview'
import useSpotlightListItem from '@src/hooks/useSpotlightListItem'
import { useStepNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { ActivityParams } from '../PoiDetailPage'
import UpvoteButton from '../UpvoteButton'

interface Props {
  data: React.ComponentProps<typeof PostPreview>['data']
  onClick3Dot: () => void
}
const ReviewCard: React.FC<Props> = ({ data, onClick3Dot }) => {
  const logEvent = useAnalytics()
  const cardItemRef = useRef<HTMLDivElement>(null)

  const { spotlightPostId, ...params } = useActivityParams<ActivityParams>()
  const { stepReplace } = useStepNavigator('poi_detail')
  const [isHighlighted, setIsHighlighted] = useState(false)

  const handleBeforeSpotlight = useCallback(() => {
    logEvent('show_highlight_review', {
      review_id: data.id,
    })

    stepReplace({
      ...params,
      spotlightPostId: undefined,
    })

    setIsHighlighted(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAfterSpotlight = useCallback(() => {
    setIsHighlighted(false)
  }, [])

  useSpotlightListItem({
    itemElement: cardItemRef,
    shouldSpotlight: data.id === spotlightPostId,
    onBeforeSpotlight: handleBeforeSpotlight,
    onAfterSpotlight: handleAfterSpotlight,
  })

  return (
    <ReviewPreview ref={cardItemRef} isHighlighted={isHighlighted}>
      <PostPreview data={data} on3DotClick={onClick3Dot} />
      <UpvoteButton resourceType={'POI_REVIEW'} resourceId={String(data.id)} type={'UPVOTE'} />
    </ReviewPreview>
  )
}

const ReviewPreview = styled.div<{ isHighlighted: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  background: ${({ isHighlighted }) =>
    isHighlighted ? vars.$semantic.color.paperAccent : vars.$semantic.color.paperDefault};
  transition: 500ms background;

  &:not(:last-child) {
    border-bottom: 1px solid ${vars.$semantic.color.divider1};
  }
`

export default ReviewCard

import styled from '@emotion/styled'
import IconCameraFill from '@karrotmarket/karrot-ui-icon/lib/react/IconCameraFill'
import { vars } from '@seed-design/design-token'
import React, { forwardRef, useEffect, useImperativeHandle } from 'react'

import { ImageScrollView } from './ImageScrollView'
import { Image, ImageUploadPicture, useImageUpload } from './useImageUpload'

export interface Props {
  maxCount: number
  images?: Image[]
  usageType?: string
  onUpdateImage: (pictures: ImageUploadPicture[]) => void
}

export interface Ref {
  handleAddPictures?: (images: Image[]) => void
  handleDeletePicture?: (imageId: string) => void
  handleResetPictures?: () => void
}

export const ImageUploader = forwardRef<Ref, Props>(({ maxCount, images, usageType, onUpdateImage }, ref) => {
  const { pictures, imageUploading, onChangeFileInput, handleDeletePicture, handleAddPictures, reset } = useImageUpload(
    maxCount,
    images,
    undefined,
    { formOptions: { type: usageType } }
  )

  useImperativeHandle(ref, () => ({
    handleAddPictures,
    handleDeletePicture,
    handleResetPictures: reset,
  }))

  useEffect(() => {
    if (!imageUploading) {
      onUpdateImage(pictures)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUploading, pictures])
  return (
    <ImageSelectionScroller>
      <ImageScrollView
        pictures={pictures}
        onFileChange={onChangeFileInput}
        disabled={imageUploading}
        maxCount={maxCount}
        iconComponent={({ isMax }) => (
          <IconCameraFill
            width="24px"
            height="24px"
            color={isMax ? vars.$scale.color.gray500 : vars.$scale.color.gray600}
          />
        )}
        onClickDeletePictureItem={handleDeletePicture}
      />
    </ImageSelectionScroller>
  )
})

const ImageSelectionScroller = styled.div`
  margin: -6px -16px 0;
`

import { atomFamily, selectorFamily } from 'recoil'

import { bridge } from '@src/apis'
import { mapThemeToEventIdMap } from '@src/constants/id'
import { Coordinates } from '@src/types/global'

import { generateWatchedPlacesPageRecoilKey } from './keys'
import { watchedByUserSelectorFamily } from './selectedWatchedPoi'

export type MapThemeType = keyof typeof mapThemeToEventIdMap

type ServiceName = 'daangn-map' | 'parenting-map'

export type ScreenInstance = string
export type MapThemeFilterType = {
  mapTheme: MapThemeType
  eventId: number | ServiceName
  serviceName?: ServiceName
} | null

export const selectedWatchedPlacesFilterAtomFamily = atomFamily<MapThemeFilterType, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey('selectedWatchedPlacesFilter'),
  default: null,
})

export const currentPositionClickedAtomFamily = atomFamily<boolean, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey('currentPositionClicked'),
  default: false,
})

export type ViewType = 'LIST' | 'MAP'
export const selectedViewTypeAtomFamily = atomFamily<ViewType, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey('selectedViewTypeAtom'),
  default: 'LIST',
})

export const enableFocusingWatchedPlaceAtomFamily = atomFamily<boolean, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey('enableFocusingWatchedPlace'),
  default: false,
})

export const centerPositionSelectorFamily = selectorFamily<Coordinates | null, ScreenInstance>({
  key: generateWatchedPlacesPageRecoilKey('centerPositionAtom'),
  get:
    (screenInstance) =>
    async ({ get }) => {
      const firstPoiCoordinates = get(watchedByUserSelectorFamily(screenInstance))[0].target?.coords
      const userInfo = await bridge.getUserInfo()
      const {
        region: { center_coordinates, centerCoordinates },
      } = userInfo

      const coordinates = firstPoiCoordinates ?? center_coordinates ?? centerCoordinates

      return coordinates ?? null
    },
})

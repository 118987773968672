import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { MouseEventHandler } from 'react'

import { TopContent } from '@src/apis/generated/poi/models'
import defaultProfileImage from '@src/assets/images/profile_default.png'
import { EllipsisText } from '@src/components/Map/EllipsisText'
import { useSubprofileHalfview } from '@src/widgets/SubprofileHalfview/hooks'

interface Props {
  topContent: TopContent
  onClickUserProfile?: MouseEventHandler
}

const UserProfile: React.FC<Props> = ({ topContent, onClickUserProfile }) => {
  const {
    user: { id, profileImageUrl, nickname, displayRegionName, displayRegionCheckinsCount },
    userReviewCount,
  } = topContent!

  const { open } = useSubprofileHalfview()

  const handleClickUserProfile: MouseEventHandler = (e) => {
    e.stopPropagation()
    onClickUserProfile?.(e)

    open(id.toString())
  }

  return (
    <User onClick={handleClickUserProfile}>
      <img
        className="profileImage"
        src={profileImageUrl && profileImageUrl.length > 0 ? profileImageUrl : defaultProfileImage}
        alt="프로필"
      />
      <div className="user">
        <EllipsisText className="nickname">{nickname}</EllipsisText>
        <div className="user-information">
          {userReviewCount && (
            <>
              <div className="content-count">후기 {userReviewCount ?? '-'}</div>
              <span className="bull">&bull;</span>
            </>
          )}
          <EllipsisText>
            {displayRegionName}
            {displayRegionCheckinsCount ? ` 인증 ${displayRegionCheckinsCount}회` : ''}
          </EllipsisText>
        </div>
      </div>
    </User>
  )
}

const User = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.1875rem;
  .nickname {
    ${vars.$semantic.typography.caption1Bold}
    margin: 0 0 2px;
    height: 20px;
  }

  .profileImage {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid ${vars.$scale.color.grayAlpha50};
    margin: 0 12px 0 0;
  }

  .user-information {
    ${vars.$semantic.typography.caption1Regular}
    display: flex;
    height: 20px;
    color: ${vars.$scale.color.gray600};

    .content-count {
      ${vars.$semantic.typography.caption1Bold}
      color: ${vars.$semantic.color.primary};
      white-space: nowrap;
    }
  }

  .bull {
    margin: 0 2px 2px;
    color: ${vars.$scale.color.gray600};
    font-weight: 100;
  }
`

export default UserProfile

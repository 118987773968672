import { historySyncPlugin } from '@stackflow/plugin-history-sync'
import { StackflowReactPlugin } from '@stackflow/react'

declare type HistorySyncPluginOptions<K extends string> = {
  routes: {
    [key: string]: string | string[]
  }
  fallbackActivity: (args: { initialContext: any }) => K
  useHash?: boolean
}

export function historySyncWithModalPlugin<
  T extends {
    [activityName: string]: unknown
  }
>(options: HistorySyncPluginOptions<Extract<keyof T, string>>): StackflowReactPlugin<T> {
  const modalRoutes = Object.keys(options.routes).reduce(
    (modalRoutes, route) => ({
      ...modalRoutes,
      [`${route}_modal`]: `${options.routes[route]}/modal/:modalId`,
    }),
    {}
  ) as {
    [key in Extract<keyof T, string>]: string | string[]
  }

  const optionsWithRoutesOverride = {
    ...options,
    routes: {
      ...options.routes,
      ...modalRoutes,
    },
  }

  return historySyncPlugin(optionsWithRoutesOverride)
}

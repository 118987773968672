import styled from '@emotion/styled'
import IconRemoveCircleFill from '@karrotmarket/karrot-ui-icon/lib/react/IconRemoveCircleFill'
import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import { vars } from '@seed-design/design-token'
import React, { ChangeEvent, FormEvent, memo, useEffect, useRef, useState } from 'react'

import { bridge, karrotBridge } from '@src/bridge'

interface Props {
  defaultValue?: string
  onSearch: (input: string) => void
  onChange?: (input: string) => void
  onClear?: () => void
  onFocus?: () => void
}
export const SearchField = memo(({ defaultValue, onSearch, onFocus, onChange, onClear }: Props) => {
  const [inputValue, setInputValue] = useState<string>('')
  const inputElementRef = useRef<HTMLInputElement>(null)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSearch(inputValue)
    setTimeout(() => {
      inputElementRef.current?.blur()
    }, 0)
  }

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    onChange?.(e.target.value)
  }

  const handleClearInput = () => {
    setInputValue('')
    onClear?.()
    inputElementRef.current?.focus()
  }

  const handleOnFocus = () => {
    bridge.router.style({
      navbar: false,
      scrollable: false,
    })
    karrotBridge.styleCurrentRouter({
      router: {
        navbar: false,
        scrollable: false,
      },
    })
    onFocus?.()
  }

  const handleOnBlur = () => {
    bridge.router.style({
      navbar: false,
      scrollable: true,
    })
    karrotBridge.styleCurrentRouter({
      router: {
        navbar: false,
        scrollable: true,
      },
    })
  }

  useEffect(() => {
    if (typeof defaultValue === 'string' && defaultValue.length > 0) {
      setInputValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    setTimeout(() => {
      inputElementRef.current?.focus()
    }, 300)
  }, [])

  return (
    <Container>
      <IconSearchRegular width="1rem" height="1rem" color={vars.$scale.color.gray500} />
      <Form onSubmit={handleSubmit} action="." noValidate>
        <Input
          type="search"
          placeholder="장소 이름이나 주소를 입력해주세요."
          ref={inputElementRef}
          value={inputValue}
          onChange={handleChangeInput}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
        />
      </Form>
      {inputValue.length > 0 && (
        <ClearButton type="button" onClick={handleClearInput}>
          <IconRemoveCircleFill
            width="1.125rem"
            height="1.125rem"
            color={vars.$scale.color.gray600}
            onClick={handleClearInput}
          />
        </ClearButton>
      )}
    </Container>
  )
})

const Container = styled.div`
  margin: 0 1rem 0.75rem;
  padding: 0.75rem 1rem;
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
  border-radius: 0.375rem;
  background-color: ${vars.$scale.color.gray100};
`
const Form = styled.form`
  flex: 1;
`
const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 0.75rem;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.label2Regular};

  &::placeholder {
    color: ${vars.$scale.color.gray500};
  }

  /** Disable default appearance of input elements in WebKit and iOS */
  &[type='search'] {
    -webkit-appearance: textfield;
  }
  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }
`
const ClearButton = styled.button`
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

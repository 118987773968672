import {
  CommunityArticlePost,
  CommunityCommentPost,
  PoiReviewPost,
  BusinessProfileReviewPost,
  UserInfoHeader,
} from '@daangn/local-business-kit'
import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { vars } from '@seed-design/design-token'
import { BottomSheet } from '@stackflow/plugin-basic-ui'
import { ActivityComponentType } from '@stackflow/react'
import React, { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { PoiV2Dto, PostV2Dto } from '@src/apis/generated/poi/models'
import { bridge } from '@src/bridge'
import { Button } from '@src/components/Button'
import { useToast } from '@src/components/Toast'
import { APP_SCHEME, WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useActivity, useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { safeAreaPadding } from '@src/styles/safeArea'
import { createQueryString } from '@src/utils/url'

import { useAnalytics, useSubprofileHalfview, useSyncUserPosts, useUserPosts } from './hooks'
import { subprofileQueryByUserId, subprofileUserIdAtomFamily } from './state'

type ActivityParams = {
  userId: string
  id?: string
  entry?: string
  referrer?: string
}
const SubprofileHalfview: ActivityComponentType<ActivityParams> = ({
  params: { userId, id: poiId, entry, referrer },
}) => {
  const { push } = useNavigator()
  const subprofile = useRecoilValue(subprofileQueryByUserId(userId))
  const { posts, meta } = useUserPosts()
  const logEvent = useAnalytics(entry)
  const { close } = useSubprofileHalfview()
  const { showToast } = useToast()
  const activity = useActivity()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const isMySubprofile = userInfo?.id === subprofile?.user.id

  const setSubprofileUserIdAtom = useSetRecoilState(subprofileUserIdAtomFamily(activity.id))
  useEffect(() => {
    setSubprofileUserIdAtom(userId)
  }, [setSubprofileUserIdAtom, userId])

  const handleClickCard = (post: PostV2Dto, poi: PoiV2Dto) => {
    logEvent('click_poi_card', {
      poi_id: poi.bizAccountId ?? poi.id,
      content_type: post.type?.toLocaleLowerCase(),
      type: poi.bizAccountId ? 'biz_account' : 'poi',
    })

    if (poi.destroyed) {
      showToast({
        text: '장소가 삭제되어 더 이상 확인할 수 없어요.',
        duration: 'long',
      })
      return
    }

    if (poiId === poi.id) {
      close()
      return
    }

    const bizAccountId = poi.bizAccountId
    if (bizAccountId) {
      const isStory = post.type === 'STORY_ARTICLE' || post.type === 'STORY_COMMENT'
      const isBizReview = post.type === 'BIZ_REVIEW'
      const queryString = createQueryString({
        referrer: referrer ?? `place.${activity.name}`,
        entry: `place.${activity.name}`,
        tab: isStory ? 'STORY' : 'REVIEW',
        highlightReviewId: isBizReview ? post.sourceId : null,
        highlightStoryId: isStory ? post.sourceId : null,
      })

      bridge.router.push({
        app: `${WEBVIEW_BASE_URL}business-platform/home`,
        path: `/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
        navbar: false,
        scrollable: false,
      })
    } else {
      const tapParam = post.type === 'POI_REVIEW' ? 'review' : 'story'

      push('poi_detail', { poiId: poi.id, tab: tapParam })
    }
  }

  const handleClickMorePosts = async () => {
    if (!subprofile) return
    close()

    logEvent('click_more_poi_posts', {
      viewer_id: userInfo?.id,
      subprofile_user_id: subprofile.user.id,
    })

    setTimeout(() => {
      push('subprofile_reviews', { userId: subprofile.user.id.toString() })
    }, 40)
  }

  const handleClickUserProfile = () => {
    if (!subprofile) return

    logEvent('click_user_profile', {
      viewer_id: userInfo?.id,
      subprofile_user_id: subprofile?.user.id,
    })

    if (isMySubprofile) {
      window.location.href = `${APP_SCHEME}my_profile`
    } else {
      window.location.href = `${APP_SCHEME}users/${subprofile.user.id}`
    }
  }

  useEffect(() => {
    logEvent('show_halfview', {
      viewer_id: userInfo?.id,
      subprofile_user_id: subprofile?.user.id,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSyncUserPosts()

  return subprofile && meta ? (
    <BottomSheet>
      <Handlebar />
      <Container>
        <div onClick={handleClickUserProfile}>
          <UserInfoHeader
            name={subprofile.user.nickname}
            info={[
              `${subprofile.user.region!.name}${
                subprofile.user.displayRegionCheckinsCount > 0
                  ? ` 인증 ${subprofile.user.displayRegionCheckinsCount}회`
                  : ''
              }`,
            ]}
            temperature={Math.round(subprofile.user.temperature! * 10) / 10}
            profileImage={subprofile.user.profileImageUrl}
          />
        </div>
        <Summary>
          <div className="total">
            남긴 후기<span className="count">{meta.total}</span>
          </div>
          <div className="detail">
            가게 후기 {subprofile.poiReviewTotalCount + subprofile.bizReviewTotalCount} &middot; 동네생활{' '}
            {subprofile.storyArticleTotalCount + subprofile.storyCommentTotalCount}
          </div>
        </Summary>
        {posts?.slice(0, 3).map(({ pois, post }) => {
          const renderPost = () => {
            const poi = pois[0]
            const defaultProps = {
              poiName: poi.name || '',
              content: post.content,
              createdAt: new Date(post.createdAt),
              poiCategoryName: poi.category?.name,
              poiRegionName: poi.region?.name,
              poiDestroyedAt: poi.destroyed ? 'now' : undefined,
              images: post.images?.map((image) => image.url) || [],
            }

            switch (post.type) {
              case 'STORY_ARTICLE':
                return <CommunityArticlePost {...defaultProps} />
              case 'STORY_COMMENT':
                return <CommunityCommentPost {...defaultProps} articleContent={post.article?.content || ''} />
              case 'BIZ_REVIEW':
                return <BusinessProfileReviewPost {...defaultProps} tags={post.tags} />
              case 'POI_REVIEW':
              default:
                return <PoiReviewPost {...defaultProps} />
            }
          }

          return (
            <Card key={post.id} onClick={handleClickCard.bind(null, post, pois[0])}>
              {renderPost()}
            </Card>
          )
        })}
        {meta.total && meta.total > 3 && (
          <StyledButton styleTheme="secondary-outlined" textSize="14" fullwidth onClick={handleClickMorePosts}>
            후기 더보기 <IconChevronRightRegular width={12} height={12} />
          </StyledButton>
        )}
      </Container>
    </BottomSheet>
  ) : null
}

const Handlebar = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0 0;
  width: 100vw;
  height: 32px;
  ::after {
    content: '';
    display: block;
    width: 36px;
    height: 4px;
    border-radius: 2px;
    background: ${vars.$scale.color.gray300};
    transform: translateX(-18px);
  }
`
const Container = styled.div`
  padding: 0 1rem;
  ${safeAreaPadding('bottom')};
`
const Summary = styled.div`
  padding: 16px 0;
  .total {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.375rem;
    color: ${vars.$scale.color.gray900};
    margin: 0 0 0.125rem;
    .count {
      margin: 0 0 0 0.25rem;
      color: ${vars.$semantic.color.primary};
    }
  }
  .detail {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    color: ${vars.$scale.color.gray600};
  }
`

const Card = styled.div`
  padding: 16px 0;
  border-top: 1px solid ${vars.$semantic.color.divider1};
`

const StyledButton = styled(Button)`
  margin: 0 0 16px;

  svg {
    margin: 0 0 0 4px;
  }
`

export default SubprofileHalfview

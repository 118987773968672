import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useMemo } from 'react'

import { IS_PROD } from '@src/constants/environmentConstants'

import { BizAccountType, CouponEventType, CouponStateReturnValue, CouponType } from '../../types'
import { isCouponManagerViewUiDisabled, isCouponUserViewUiDisabled } from '../../utils/coupon-util'
import { UiDisableProps } from '../CouponCardBase'
import CouponCardType from '../CouponCardType'
import CouponStatus from '../CouponStatus'
import DateAndCountSection from '../DateAndCountSection'

export interface OnlineCouponCardInfoProps {
  isManager: boolean
  couponStatus: CouponStateReturnValue
  name: string
  target: CouponEventType
  issuedCount: number | null
  claimedCount: number
  couponType: CouponType
  minConditionPrice?: number
  expiresAt?: string
  couponBoxId: number
  bizAccountType?: BizAccountType
}

// eslint-disable-next-line no-warning-comments
// TODO 기획전 종료시 제거
const NH_COUPON = 299364

const OnlineCouponCardInfo: React.FC<OnlineCouponCardInfoProps> = ({
  issuedCount,
  claimedCount,
  couponStatus,
  isManager,
  name,
  expiresAt,
  minConditionPrice,
  target,
  couponType,
  couponBoxId,
  bizAccountType,
}) => {
  const uiDisabled = useMemo(
    () => (isManager ? isCouponManagerViewUiDisabled(couponStatus) : isCouponUserViewUiDisabled(couponStatus)),
    [couponStatus, isManager]
  )

  return (
    <S_Base uiDisabled={uiDisabled}>
      <S_CouponWrapper>
        <S_CouponContentWrapper>
          <CouponStatus
            isManager={isManager}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            couponStatus={couponStatus}
            uiDisabled={uiDisabled}
          />
          <S_CouponName uiDisabled={uiDisabled}>
            <span>{name}</span>
          </S_CouponName>

          <CouponCardType
            isManager={isManager}
            target={target}
            uiDisabled={uiDisabled}
            couponType={couponType}
            couponBoxId={couponBoxId}
            bizAccountType={bizAccountType}
          />

          {!!minConditionPrice && (
            <S_CouponRequirement uiDisabled={uiDisabled}>
              {minConditionPrice.toLocaleString('ko-KR')}원 이상 구매 시 사용가능
            </S_CouponRequirement>
          )}

          {IS_PROD && couponBoxId === NH_COUPON && (
            <S_TempolaryCouponWarning uiDisabled={uiDisabled}>상품 품절시 쿠폰 사용 불가</S_TempolaryCouponWarning>
          )}

          <DateAndCountSection
            isManager={isManager}
            issuedCount={issuedCount}
            claimedCount={claimedCount}
            expiresAt={expiresAt}
            uiDisabled={uiDisabled}
            couponStatus={couponStatus}
          />
        </S_CouponContentWrapper>
      </S_CouponWrapper>
    </S_Base>
  )
}

const S_Base = styled.div<UiDisableProps>``
const S_CouponWrapper = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
`
const S_CouponContentWrapper = styled.div`
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
`
const S_CouponName = styled.h6<UiDisableProps>`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 135%;
  letter-spacing: -0.03em;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray900)};
  margin: 0 0 0.25rem;
  text-align: start;
`
const S_CouponRequirement = styled.span<UiDisableProps>`
  display: block;
  font-size: 0.75rem;
  line-height: 133%;
  letter-spacing: -0.02em;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};
  margin-bottom: 0.125rem;
`

const S_TempolaryCouponWarning = styled.div<UiDisableProps>`
  display: flex;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 133%;
  letter-spacing: -0.02rem;
  margin-bottom: 0.125rem;
  color: ${({ uiDisabled }) => (uiDisabled ? vars.$scale.color.gray500 : vars.$scale.color.gray600)};
`

export default OnlineCouponCardInfo

import styled from '@emotion/styled'
import IconWarningThin from '@karrotmarket/karrot-ui-icon/lib/react/IconWarningThin'
import { vars } from '@seed-design/design-token'
import React, { ReactNode } from 'react'

export function FormItemWrapper({
  id,
  label,
  isOptional = false,
  subLabel,
  className,
  errorMessage,
  children,
}: {
  id: string
  label: string
  isOptional?: boolean
  subLabel?: string
  className?: string
  children: ReactNode
  errorMessage?: string
}) {
  return (
    <StyledInputGroup className={className}>
      <label className="input-group-title" htmlFor={id}>
        {label}
        {isOptional && <IsOptional>(선택)</IsOptional>}
      </label>
      {subLabel && <label className="sublabel">{subLabel}</label>}
      {children}
      {errorMessage && (
        <ErrorMessage>
          <IconWarningThin className="icon" />
          {errorMessage}
        </ErrorMessage>
      )}
    </StyledInputGroup>
  )
}

const StyledInputGroup = styled.div`
  align-self: stretch;
  margin: 1rem 0 2rem;
  label {
    display: block;
  }
  .input-group-title {
    ${vars.$semantic.typography.label3Bold}
    margin: 0 0 0.75rem;
  }
  .sublabel {
    ${vars.$semantic.typography.label3Regular}
    margin: -0.375rem 0 0.75rem;
  }
`

const IsOptional = styled.span`
  margin: 0 0 0 0.25rem;
  ${vars.$semantic.typography.label3Regular}
`

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 0 0;
  ${vars.$semantic.typography.label3Regular}
  color: ${vars.$semantic.color.danger};
  .icon {
    width: 1rem;
    height: 1rem;
    margin: 0 0.25rem 0 0;
  }
`

import styled from '@emotion/styled'
import IconGpsRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconGpsRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { FloatingButton } from '@src/components/Button'
import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useToast } from '@src/components/Toast'
import { useCurrentPosition } from '@src/hooks'
import { checkIsWithBatchim } from '@src/utils/checkIsWithBatchim'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { TEMP_SPACING_FOR_CENTER } from '../constants/style'
import { useAnalytics, useIsCardShown } from '../hooks'
import { currentPositionClickedAtomFamily, isMapBoundChangedAtomFamily, mapThemeAtomFamily } from '../state'

const CurrentPositionButton: React.FC = () => {
  const { showToast } = useToast()
  const { access, position, update: updateCurrentUserPosition } = useCurrentPosition()
  const logEvent = useAnalytics()
  const { panTo } = useKakaoMap()
  const screenInstance = useScreenInstance()

  const SPACING_FOR_CENTER = useRecoilValue(TEMP_SPACING_FOR_CENTER)
  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const isCardShown = useIsCardShown()
  const [currentPositionClicked, setCurrentPositionClicked] = useRecoilState(
    currentPositionClickedAtomFamily(screenInstance)
  )
  const setMapBoundChanged = useSetRecoilState(isMapBoundChangedAtomFamily(screenInstance))

  const handleClickCurrentPosition = async () => {
    updateCurrentUserPosition()

    try {
      if (access === 'denied') {
        throw '위치정보를 얻지 못했습니다.'
      }

      if (access === 'loading') return

      panTo({ coordinates: position, yOffset: isCardShown ? SPACING_FOR_CENTER : 0 })

      setCurrentPositionClicked(true)
      setMapBoundChanged(true)
    } catch {
      const curationCategory = mapTheme.name ?? '장소'
      const 을or를 = checkIsWithBatchim(curationCategory) ? '을' : '를'

      showToast({
        text: `위치 서비스를 켜고 내 근처 ${curationCategory}${을or를} 찾아보세요.`,
        duration: 'long',
      })
    } finally {
      logEvent('click_current_position_button')
    }
  }

  return <Button isClicked={currentPositionClicked} onClick={handleClickCurrentPosition} icon={<IconGpsRegular />} />
}

const Button = styled(FloatingButton)<{ isClicked: boolean }>`
  .icon {
    color: ${({ isClicked }) => (isClicked ? vars.$semantic.color.primary : vars.$scale.color.gray900)};
  }
`

export default CurrentPositionButton

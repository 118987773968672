import React, { MouseEvent, useCallback } from 'react'

import { ActionSheet } from '@src/components/ActionSheet'
import { useNavigator } from '@src/stackflow'

export interface Props {
  onClose?: () => void
  poiId: string
  curationCategoryId: string
}

export const OptionsBottomSheet = ({ onClose, poiId, curationCategoryId }: Props) => {
  const { replace, pop } = useNavigator()

  const handleReport = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      onClose?.()
      if (!poiId) return
      replace('daangn_map_report', { poiId, curationCategoryId })
    },
    [poiId, curationCategoryId, onClose, replace]
  )

  const handleClose = useCallback(() => {
    onClose?.()
    pop()
  }, [onClose, pop])

  return (
    <ActionSheet
      onOutsideClick={handleClose}
      dismissButton={{
        label: '닫기',
        onClick: handleClose,
      }}
      actionButtons={[
        {
          type: 'destructive',
          label: '신고하기',
          onClick: (e) => handleReport(e),
        },
      ]}
    />
  )
}

import { useActivityParams } from '@stackflow/react'
import { makeJsonDecoder } from '@urlpack/json'
import { isEmpty, isNumber } from 'lodash'
import { useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { GPS } from '@src/types/global'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { curationCategoriesSelector, mapThemeAtomFamily, selectedPoiIdAtomFamily } from '../state'

type ParamsType = {
  // Bounds
  b?: [number, number, number, number, number]
  // ThemeId
  t: string
  // PoiId
  i?: string
}

export function useParamsForShare() {
  const { p: mapLandingInfo, theme, poi_id } = useActivityParams<{ p: string; theme: string; poi_id: string }>()
  const screenInstance = useScreenInstance()

  const selectedPoiId = useRecoilValue(selectedPoiIdAtomFamily(screenInstance))
  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const curationCategories = useRecoilValue(curationCategoriesSelector)

  const decoder = makeJsonDecoder()

  const decodedParams = useMemo(() => {
    if (mapLandingInfo) {
      return decoder.decode(mapLandingInfo) as ParamsType
    } else {
      return null
    }
  }, [mapLandingInfo, decoder])

  const bounds = useMemo(() => {
    if (!decodedParams?.b) return null

    const {
      b: [swLat, swLng, neLat, neLng, zoomLevel],
    } = decodedParams

    const isRightParams =
      isNumberNotNaN(swLat) &&
      isNumberNotNaN(swLng) &&
      isNumberNotNaN(neLat) &&
      isNumberNotNaN(neLng) &&
      isNumberNotNaN(zoomLevel)

    if (isRightParams) {
      return {
        bound: { sw: [swLat, swLng] as GPS, ne: [neLat, neLng] as GPS },
        zoomLevel,
      }
    }

    return null
  }, [decodedParams])

  const curationCategoryId = useMemo(() => {
    const selectedMapThemeId = mapTheme.id !== -1 ? mapTheme.id : null
    const curationCategoryId = selectedMapThemeId ?? theme ?? decodedParams?.t
    const isValidId = !!curationCategoryId && curationCategories.find(({ id }) => id === Number(curationCategoryId))

    return isValidId ? Number(curationCategoryId) : null
  }, [curationCategories, decodedParams?.t, mapTheme.id, theme])

  const poiId = useMemo(() => {
    const id = selectedPoiId ?? poi_id ?? decodedParams?.i
    const isValidId = !isEmpty(id)

    return isValidId ? id : null
  }, [decodedParams?.i, poi_id, selectedPoiId])

  return { bounds, poiId, mapThemeId: curationCategoryId }
}

function isNumberNotNaN(num?: number) {
  return num && isNumber(num) && !isNaN(num)
}

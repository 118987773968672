import React, { SVGProps } from 'react'

export const IconEmojiHeartColored = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="15" cy="15" r="15" fill="#FF656C" />
      <circle cx="15" cy="15" r="14.4" stroke="#FF3E46" strokeOpacity="0.5" strokeWidth="1.2" />
      <path
        d="M22.6594 9.52654C21.7237 8.54519 20.4881 8 19.1686 8C17.8491 8 16.6015 8.54519 15.6778 9.52654L14.994 10.2535L14.3103 9.52654C13.3746 8.54519 12.139 8 10.8195 8C9.49993 8 8.25236 8.54519 7.32868 9.52654C5.57729 11.3802 5.55329 14.3727 7.2807 16.7837C10.2677 20.9877 14.5142 23.5562 14.6941 23.6652C14.7781 23.7258 14.8861 23.75 14.994 23.75C15.102 23.75 15.21 23.7258 15.3059 23.6652C15.4739 23.5562 19.5525 20.9877 22.6954 16.8079C24.4468 14.4696 24.4348 11.4165 22.6594 9.52654Z"
        fill="white"
      />
    </svg>
  )
}

export const IconEmojiAngryColored = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="#FFEAB3"
      />
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="url(#paint0_linear_1442_16)"
      />
      <path
        d="M29.4 15C29.4 22.9529 22.9529 29.4 15 29.4C7.0471 29.4 0.6 22.9529 0.6 15C0.6 7.0471 7.0471 0.6 15 0.6C22.9529 0.6 29.4 7.0471 29.4 15Z"
        stroke="#FF5F3C"
        strokeOpacity="0.1"
        strokeWidth="1.2"
      />
      <path
        d="M8.35452 14.5821C8.03305 14.5018 7.83759 14.176 7.91796 13.8545C7.99833 13.533 8.32409 13.3376 8.64557 13.418L12.6456 14.418C12.967 14.4983 13.1625 14.8241 13.0821 15.1456C13.0018 15.467 12.676 15.6625 12.3545 15.5821L12.2455 15.5549C12.1877 16.9089 11.6488 18 10.9773 18C10.2969 17.9569 9.70712 16.8793 9.75248 15.5C9.75878 15.3087 9.77467 15.1224 9.79919 14.9433L8.35452 14.5821Z"
        fill="#4D5159"
      />
      <path
        d="M21.6456 14.5821C21.967 14.5018 22.1625 14.176 22.0821 13.8545C22.0018 13.533 21.676 13.3376 21.3545 13.418L17.3545 14.418C17.033 14.4983 16.8376 14.8241 16.918 15.1456C16.9983 15.467 17.3241 15.6625 17.6456 15.5821L17.7546 15.5549C17.8124 16.9089 18.3513 18 19.0227 18C19.7032 17.9569 20.293 16.8793 20.2476 15.5C20.2413 15.3087 20.2254 15.1224 20.2009 14.9433L21.6456 14.5821Z"
        fill="#4D5159"
      />
      <path
        d="M10.7979 22.6446C11.0154 22.8936 11.3931 22.9198 11.6428 22.7035L11.6493 22.698C11.6562 22.6923 11.6684 22.6823 11.6857 22.6688C11.7204 22.6417 11.7754 22.6002 11.8498 22.5491C11.9987 22.4467 12.2237 22.3065 12.5163 22.1654C13.1018 21.8829 13.9501 21.6 14.9999 21.6C16.0497 21.6 16.898 21.8829 17.4835 22.1654C17.7761 22.3065 18.0011 22.4467 18.1501 22.5491C18.2244 22.6002 18.2794 22.6417 18.3141 22.6688C18.3314 22.6823 18.3436 22.6923 18.3505 22.698L18.3564 22.7029C18.6061 22.9198 18.9843 22.8938 19.2019 22.6446C19.4198 22.395 19.3941 22.0159 19.1445 21.798L19.0536 21.7237C19.0009 21.6825 18.926 21.6263 18.8299 21.5602C18.6379 21.4282 18.3601 21.2559 18.005 21.0846C17.2951 20.7421 16.2684 20.4 14.9999 20.4C13.7314 20.4 12.7047 20.7421 11.9948 21.0846C11.6397 21.2559 11.3619 21.4282 11.1699 21.5602C11.0738 21.6263 10.9989 21.6825 10.9462 21.7237L10.8561 21.7973L10.8553 21.798C10.6057 22.0159 10.58 22.395 10.7979 22.6446Z"
        fill="#4D5159"
      />
      <defs>
        <linearGradient id="paint0_linear_1442_16" x1="15" y1="0" x2="15" y2="30" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF5F3C" />
          <stop offset="1" stopColor="#FFE191" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const IconEmojiSadColored = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="#FFEAB3"
      />
      <path
        d="M29.4 15C29.4 22.9529 22.9529 29.4 15 29.4C7.0471 29.4 0.6 22.9529 0.6 15C0.6 7.0471 7.0471 0.6 15 0.6C22.9529 0.6 29.4 7.0471 29.4 15Z"
        stroke="#FDBA56"
        strokeOpacity="0.5"
        strokeWidth="1.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 21.6C13.2676 21.6 11.956 22.3801 11.5427 23.256C11.4013 23.5557 11.0437 23.6841 10.744 23.5427C10.4443 23.4013 10.316 23.0437 10.4574 22.744C11.1395 21.2983 12.9955 20.4 15 20.4C17.0046 20.4 18.8606 21.2983 19.5427 22.744C19.6841 23.0437 19.5557 23.4013 19.2561 23.5427C18.9564 23.6841 18.5988 23.5557 18.4574 23.256C18.0441 22.3801 16.7325 21.6 15 21.6Z"
        fill="#4D5159"
      />
      <path
        d="M9.97732 16.75C10.6578 16.75 11.2022 15.8534 11.2475 14.75C11.2929 13.6466 10.7032 12.75 10.0227 12.75C9.34221 12.75 8.79782 13.6466 8.75246 14.75C8.70709 15.8534 9.29684 16.7155 9.97732 16.75Z"
        fill="#4D5159"
      />
      <path
        d="M19.9773 16.75C20.6578 16.75 21.2022 15.8534 21.2475 14.75C21.2929 13.6466 20.7032 12.75 20.0227 12.75C19.3422 12.75 18.7978 13.6466 18.7525 14.75C18.7071 15.8534 19.2968 16.7155 19.9773 16.75Z"
        fill="#4D5159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0252 8.54815C12.2268 8.81116 12.177 9.18778 11.914 9.38936C11.223 9.91901 10.5329 10.3129 9.78048 10.5212C9.02178 10.7313 8.23589 10.7428 7.36838 10.5595C7.04416 10.491 6.83687 10.1726 6.90537 9.84843C6.97387 9.52422 7.29222 9.31692 7.61644 9.38542C8.32135 9.53436 8.91042 9.51696 9.46024 9.36472C10.0164 9.21073 10.5684 8.90881 11.184 8.43694C11.447 8.23535 11.8236 8.28515 12.0252 8.54815Z"
        fill="#4D5159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9748 8.54815C17.7732 8.81116 17.823 9.18778 18.086 9.38936C18.777 9.91901 19.4671 10.3129 20.2195 10.5212C20.9782 10.7313 21.7641 10.7428 22.6316 10.5595C22.9558 10.491 23.1631 10.1726 23.0946 9.84843C23.0261 9.52422 22.7078 9.31692 22.3836 9.38542C21.6786 9.53436 21.0896 9.51696 20.5398 9.36472C19.9836 9.21073 19.4316 8.90881 18.816 8.43694C18.553 8.23535 18.1764 8.28515 17.9748 8.54815Z"
        fill="#4D5159"
      />
      <path
        d="M6.57905 20.1535C6.49765 20.6151 6.36359 22.0194 7.63834 22.2442C8.9131 22.469 9.26742 21.1035 9.34882 20.6419C9.67441 18.7954 8.84593 16.7454 8.61511 16.7047C8.3843 16.664 6.90464 18.307 6.57905 20.1535Z"
        fill="#49BED7"
      />
      <path
        d="M23.421 20.1535C23.5024 20.6151 23.6364 22.0194 22.3617 22.2442C21.0869 22.469 20.7326 21.1035 20.6512 20.6419C20.3256 18.7954 21.1541 16.7454 21.3849 16.7047C21.6157 16.664 23.0954 18.307 23.421 20.1535Z"
        fill="#49BED7"
      />
    </svg>
  )
}

export const IconEmojiRecommendColored = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="15" cy="15" r="15" fill="#FF9E66" />
      <circle cx="15" cy="15" r="14.4" stroke="#FF8A3D" strokeOpacity="0.5" strokeWidth="1.2" />
      <path
        d="M22.0108 11.3435C22.9336 11.3435 24.4124 12.3491 23.8919 14.2538C23.1939 16.821 22.4486 19.2935 21.5494 20.6304C20.8633 21.636 20.8151 21.8016 19.9042 21.8016C17.6919 21.8016 11.7038 21.7779 11.7038 21.7779V12.3136H13.3147C13.3147 12.3136 15.0892 10.5509 17.2897 5.83052C18.2716 4.24524 20.614 6.54035 19.8805 8.42138C19.147 10.3024 18.4609 11.3435 18.4609 11.3435C18.4609 11.3435 21.5613 11.3435 22.0108 11.3435Z"
        fill="white"
      />
      <path
        d="M9.79485 11.2249H6.54998C6.24199 11.2249 6 11.5158 6 11.8573V21.9765C6 22.3307 6.24199 22.609 6.54998 22.609H9.79485C10.1028 22.609 10.3448 22.3307 10.3448 21.9765V11.8573C10.3448 11.5158 10.0918 11.2249 9.79485 11.2249Z"
        fill="white"
      />
    </svg>
  )
}

export const IconEmojiSmileColored = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="#FFEAB3"
      />
      <path
        d="M29.4 15C29.4 22.9529 22.9529 29.4 15 29.4C7.0471 29.4 0.6 22.9529 0.6 15C0.6 7.0471 7.0471 0.6 15 0.6C22.9529 0.6 29.4 7.0471 29.4 15Z"
        stroke="#FDBA56"
        strokeOpacity="0.5"
        strokeWidth="1.2"
      />
      <ellipse cx="5.5" cy="16" rx="1.5" ry="1" fill="#FD9095" />
      <ellipse cx="24.5" cy="16" rx="1.5" ry="1" fill="#FD9095" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3999 18C8.3999 17.6687 8.66853 17.4 8.9999 17.4H20.9999C21.3313 17.4 21.5999 17.6687 21.5999 18C21.5999 21.6451 18.645 24.6 14.9999 24.6C11.3548 24.6 8.3999 21.6451 8.3999 18Z"
        fill="#4D5159"
      />
      <path
        d="M20.5031 21.645C19.3212 23.4259 17.2977 24.6 15.0001 24.6C12.7025 24.6 10.6791 23.426 9.49707 21.6451C10.931 20.6255 12.8682 20 15.0002 20C17.1321 20 19.0692 20.6254 20.5031 21.645Z"
        fill="#FD9095"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1333 8.81146C22.3396 9.05619 22.2661 9.39239 21.969 9.56237L18.8702 11.3355L21.9521 12.9845C22.2555 13.1468 22.3417 13.481 22.1447 13.7309C21.9476 13.9808 21.542 14.0518 21.2387 13.8895L17.3392 11.803C17.1562 11.7051 17.0445 11.5386 17.0411 11.3589C17.0377 11.1793 17.1431 11.01 17.3223 10.9074L21.2218 8.67613C21.5189 8.50614 21.927 8.56673 22.1333 8.81146Z"
        fill="#4D5159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.86669 8.81146C7.66035 9.05619 7.73391 9.39239 8.03098 9.56237L11.1298 11.3355L8.04786 12.9845C7.74453 13.1468 7.65834 13.481 7.85535 13.7309C8.05236 13.9808 8.45797 14.0518 8.76131 13.8895L12.6608 11.803C12.8438 11.7051 12.9555 11.5386 12.9589 11.3589C12.9623 11.1793 12.8569 11.01 12.6777 10.9074L8.77819 8.67613C8.48113 8.50614 8.07303 8.56673 7.86669 8.81146Z"
        fill="#4D5159"
      />
    </svg>
  )
}

export const IconEmojiWowColored = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z"
        fill="#FFEAB3"
      />
      <path
        d="M29.4 15C29.4 22.9529 22.9529 29.4 15 29.4C7.0471 29.4 0.6 22.9529 0.6 15C0.6 7.0471 7.0471 0.6 15 0.6C22.9529 0.6 29.4 7.0471 29.4 15Z"
        stroke="#FDBA56"
        strokeOpacity="0.5"
        strokeWidth="1.2"
      />
      <path
        d="M9.97732 13.984C10.6578 13.984 11.2022 13.0875 11.2475 11.984C11.2929 10.8806 10.7032 9.98401 10.0227 9.98401C9.34221 9.98401 8.79782 10.8806 8.75246 11.984C8.70709 13.0875 9.29684 13.9495 9.97732 13.984Z"
        fill="#4D5159"
      />
      <path
        d="M19.9773 13.984C20.6578 13.984 21.2022 13.0875 21.2475 11.984C21.2929 10.8806 20.7032 9.98401 20.0227 9.98401C19.3422 9.98401 18.7978 10.8806 18.7525 11.984C18.7071 13.0875 19.2968 13.9495 19.9773 13.984Z"
        fill="#4D5159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1749 4.27635C12.2563 3.95514 12.0619 3.62874 11.7407 3.54733C10.8967 3.33341 10.1072 3.2439 9.33365 3.34943C8.55363 3.45584 7.827 3.75544 7.10225 4.26624C6.83139 4.45714 6.76657 4.83147 6.95747 5.10233C7.14837 5.37318 7.5227 5.438 7.79356 5.2471C8.38247 4.83204 8.93058 4.61553 9.49586 4.53842C10.0676 4.46042 10.694 4.51996 11.4459 4.71054C11.7671 4.79196 12.0935 4.59756 12.1749 4.27635Z"
        fill="#4D5159"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8232 4.27635C17.7417 3.95514 17.9361 3.62874 18.2574 3.54733C19.1013 3.33341 19.8908 3.2439 20.6644 3.34943C21.4444 3.45584 22.171 3.75544 22.8958 4.26624C23.1667 4.45714 23.2315 4.83147 23.0406 5.10233C22.8497 5.37318 22.4753 5.438 22.2045 5.2471C21.6156 4.83204 21.0675 4.61553 20.5022 4.53842C19.9305 4.46042 19.3041 4.51997 18.5522 4.71054C18.231 4.79196 17.9046 4.59756 17.8232 4.27635Z"
        fill="#4D5159"
      />
      <path
        d="M12.387 17.9112C13.0074 17.025 13.9219 16.4 14.9999 16.4C16.0779 16.4 16.9924 17.025 17.6128 17.9112C18.2344 18.7993 18.5999 19.9943 18.5999 21.2857C18.5999 22.5772 18.2344 23.7722 17.6128 24.6603C16.9924 25.5465 16.0779 26.1715 14.9999 26.1715C13.9219 26.1715 13.0074 25.5465 12.387 24.6603C11.7654 23.7722 11.3999 22.5772 11.3999 21.2857C11.3999 19.9943 11.7654 18.7993 12.387 17.9112Z"
        fill="#4D5159"
      />
      <path
        d="M18.0365 23.9246C17.9132 24.1874 17.7713 24.4338 17.6128 24.6603C16.9924 25.5465 16.0779 26.1715 14.9999 26.1715C13.922 26.1715 13.0074 25.5465 12.3871 24.6603C12.2286 24.4339 12.0868 24.1875 11.9634 23.9247C12.8052 23.3446 13.8579 23 15 23C16.1421 23 17.1947 23.3446 18.0365 23.9246Z"
        fill="#FD9095"
      />
    </svg>
  )
}

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { HTMLAttributes } from 'react'

import { useNavigator } from '@src/stackflow'

import { useAnalytics } from './hooks'

interface Props extends HTMLAttributes<HTMLDivElement> {
  poiId: string
}

export default function RemoveSuggestion({ poiId, ...props }: Props) {
  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const replaceWithDeleteSuggestionPage = () => {
    logEvent('click_delete_suggestion_button')

    push('delete_poi_suggestion', { poiId })
  }
  return (
    <Container {...props}>
      <StyledFont textColor={vars.$scale.color.gray600}>존재하지 않는 장소인가요?</StyledFont>
      <TextButton onClick={replaceWithDeleteSuggestionPage}>
        <StyledFont textColor={vars.$scale.color.blue800}>장소 삭제</StyledFont>
      </TextButton>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
`

const TextButton = styled.button`
  background: none;
  border: none;
  padding: 0 0.375rem;
`

const StyledFont = styled.div<{ textColor: string }>`
  ${vars.$semantic.typography.caption1Regular}
  color: ${({ textColor }) => textColor};
`

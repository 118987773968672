import { useScrollToTopEffect } from '@daangn/stackflow-plugin-scroll-to-top'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useRef } from 'react'
import { useRecoilValue } from 'recoil'

import { Divider } from '@src/components/Divider'
import ListViewComponent from '@src/components/Map/ListView'
import { safeAreaPadding } from '@src/styles/safeArea'
import { useScreenInstance } from '@src/widgets/screenInstance'

import ListViewWatchCard from '../PoiCard/listView/WatchCard'
import { filteredWatchedPlacesByMapThemeSelectorFamily } from '../state/selectedWatchedPoi'

export function ListView() {
  const screenInstance = useScreenInstance()
  const filteredWatchedPlaces = useRecoilValue(filteredWatchedPlacesByMapThemeSelectorFamily(screenInstance))

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useScrollToTopEffect(() => {
    scrollContainerRef?.current?.scroll({ top: 0, behavior: 'smooth' })
  }, [scrollContainerRef])

  return (
    <Container ref={scrollContainerRef}>
      {filteredWatchedPlaces.map((watch) => (
        <React.Fragment key={watch.targetId}>
          <ListViewWatchCard {...watch} />
          <Divider css={{ ':last-of-type': { display: 'none' } }} />
        </React.Fragment>
      ))}
    </Container>
  )
}

const Container = styled(ListViewComponent)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${vars.$semantic.color.paperDefault};

  ${safeAreaPadding('bottom', '4rem')};
`

export function checkIsWithBatchim(word: string) {
  if (word === '') return false
  const lastLetter = word[word.length - 1]
  const uni = lastLetter.charCodeAt(0)

  if (uni < 44032 || uni > 55203) return true

  return (uni - 44032) % 28 !== 0
}

// by copilot
export const add을를 = (text: string) => {
  return checkIsWithBatchim(text) ? `${text}을` : `${text}를`
}

export const add은는 = (text: string) => {
  return checkIsWithBatchim(text) ? `${text}은` : `${text}는`
}

export const add이가 = (text: string) => {
  return checkIsWithBatchim(text) ? `${text}이` : `${text}가`
}

export const add과와 = (text: string) => {
  return checkIsWithBatchim(text) ? `${text}과` : `${text}와`
}

import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { useLogEvent } from '@src/components/Analytics'

const useAnalytics = () => {
  const logEvent = useLogEvent()
  const { poi_id } = useActivityParams<{ poi_id?: string }>()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = { poi_id: poi_id ?? null, ...params }

      logEvent({ name, params: _params })
    },
    [logEvent, poi_id]
  )
}

export default useAnalytics

import { useEffect } from 'react'

import { useNudge } from '@src/components/Nudge/useNudge'
import { useActivity } from '@src/stackflow'

export const useClearNudge = () => {
  const { clearNudge } = useNudge()
  const { isTop } = useActivity()

  useEffect(() => {
    if (!isTop) {
      clearNudge()
    }
  }, [isTop, clearNudge])
}

import { BookmarkDto } from '@daangn/local-business-network/lib/poi'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'

import { bridge } from '@src/bridge'
import { GeneralWatchOverview } from '@src/components/GeneralWatchOverview'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import WatchButton from '@src/shared/watch/WatchButton'
import { useNavigator } from '@src/stackflow'
import { safeAreaPadding } from '@src/styles/safeArea'

import { useAnalytics, useResetWatchedByUser } from '../../hooks'

function WatchedPlaceCard(props: BookmarkDto) {
  const { targetId, targetType, target } = props

  const { push } = useNavigator()
  const logEvent = useAnalytics()
  const { referrer } = useActivityParams<{ referrer: string }>()

  const resetWatchedByUser = useResetWatchedByUser()

  const isBiz = targetType === 'BA'

  const handleClickCard = async () => {
    logEvent('click_view_poi_details_page_card', {
      poi_type: isBiz ? 'biz_account' : 'poi',
      poi_id: targetId,
    })
    if (isBiz) {
      bridge.router.push({
        app: `${WEBVIEW_BASE_URL}business-platform/home`,
        path: `/biz_accounts/${targetId}/viewer/home?entry=native.mydaangn.place_watched&referrer=${referrer}`,
        navbar: false,
        scrollable: false,
      })
    } else {
      push('poi_detail', { poiId: targetId })
      resetWatchedByUser()
    }
  }

  const handleLogClickWatch = (setToWatch: boolean) => () => {
    logEvent(setToWatch ? 'click_add_watch_button' : 'click_remove_watch_button', {
      poi_type: isBiz ? 'biz_account' : 'poi',
      poi_id: targetId,
      area: 'map_poi_card',
    })
  }

  return (
    <Wrapper>
      <GeneralWatchOverview
        thumbnail={target?.thumbnail?.url ?? ''}
        title={target?.name ?? ''}
        tags={[target?.category?.name ?? '', target?.region?.name ?? ''].filter(Boolean)}
        description={target?.address ?? ''}
        onClickBody={handleClickCard}
        favoriteButton={
          <WatchButton
            placeWatchId={{ targetId, targetType }}
            onAddWatch={handleLogClickWatch(true)}
            onRemoveWatch={handleLogClickWatch(false)}
            customDefaultIconCSS={css`
              path:last-of-type {
                fill: ${vars.$scale.color.gray600};
              }
            `}
          />
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: ${vars.$semantic.color.paperDefault};
  ${safeAreaPadding('bottom')};
`
export default WatchedPlaceCard

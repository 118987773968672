import { SizeIconProps } from '@daangn/karrot-clothes/lib/types/svg'
import { vars } from '@seed-design/design-token'
import React from 'react'

const RadioUnSelected: React.FC<SizeIconProps> = ({ size = 24, color = vars.$scale.color.gray300 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36081 16.6392 3.6 12 3.6C7.36081 3.6 3.6 7.36081 3.6 12C3.6 16.6392 7.36081 20.4 12 20.4ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill={color}
      />
    </svg>
  )
}

export default React.memo(RadioUnSelected)

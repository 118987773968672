import { getCurationPoisV2 } from '@daangn/local-business-network/lib/poi'
import { atomFamily, DefaultValue, selectorFamily } from 'recoil'

import { CurationMapItem } from '@src/apis/generated/poi/models'
import { karrotBridgeCache } from '@src/bridge'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { convertCoordinatesArrayToObject, isPointInBounds } from '@src/utils/coordinate'

import { analyticsSessionIds } from './analytics'
import {
  boundsAtomFamily,
  centerPositionAtomFamily,
  defaultFilterId,
  isCurrentAreaSearchAtomFamily,
  mapThemeAtomFamily,
  selectedFilterIdAtomFamily,
} from './index'
import { generateMapPageRecoilKey } from './keys'

/**
 * Global scope
 * Global scoped store shares cache globally.
 * Given the same key or variable or dependent state, the same value will be returned.
 */
export const getPoisQuery = selectorFamily({
  key: generateMapPageRecoilKey('poisResponse'),
  get:
    (screenInstance: string) =>
    async ({ get }) => {
      const mapTheme = get(mapThemeAtomFamily(screenInstance))
      const centerPosition = get(centerPositionAtomFamily(screenInstance))
      const userInfo = get(bridgeInfoUserAtom)
      const bounds = get(boundsAtomFamily(screenInstance))
      const isCurrentAreaSearch = get(isCurrentAreaSearchAtomFamily(screenInstance))
      const visitSessionId = analyticsSessionIds.getVisitSessionId()
      const querySessionId = analyticsSessionIds.getQuerySessionId()

      if (mapTheme.id === -1 || !(centerPosition || bounds) || !userInfo) return null

      const userLocation = karrotBridgeCache.getCurrentPosition()?.geolocation.currentPosition?.position ?? undefined
      const coordinates = centerPosition ? convertCoordinatesArrayToObject(centerPosition) : null

      /*
        3가지 경우의 수가 존재
        1. coordinates와 [boundSw, boundNe]가 둘다 존재하지 않는경우
        2. coordinates가 존재하는 경우
        3. [boundSw, boundNe]가 존재하는 경우
      */
      const { data } = await getCurationPoisV2({
        params: {
          xAuthToken: userInfo.authToken,
          regionId: userInfo.region.id,
          curationCategoryId: mapTheme.id,
          coordinates: coordinates ? `${coordinates.latitude},${coordinates.longitude}` : undefined,
          userLocation:
            userLocation?.latitude && userLocation.longitude
              ? `${userLocation.latitude},${userLocation.longitude}`
              : undefined,
          boundSw: bounds ? `${bounds.bottomLeft.latitude},${bounds.bottomLeft.longitude}` : undefined,
          boundNe: bounds ? `${bounds.topRight.latitude},${bounds.topRight.longitude}` : undefined,
          visitSessionId,
          querySessionId,
          isCurrentAreaSearch,
        },
      })

      return data.data.items
    },
})

type PoiId = string

export const poiAtomFamily = atomFamily<CurationMapItem | null, PoiId>({
  key: generateMapPageRecoilKey('poiAtomFamily'),
  default: null,
})

export const poiSelectorFamily = selectorFamily<CurationMapItem | null, PoiId>({
  key: generateMapPageRecoilKey('poiSelectorFamily'),
  get:
    (poiId) =>
    ({ get }) => {
      const poi = get(poiAtomFamily(poiId))

      return poi
    },
  set:
    (poiId) =>
    ({ set, reset }, poi) => {
      if (poi instanceof DefaultValue || !poi) {
        reset(poiAtomFamily(poiId))

        return
      }

      set(poiAtomFamily(poiId), poi)
    },
})

/**
 * Page scope
 *
 */
export const poiIdsAtomFamily = atomFamily<PoiId[], string>({
  key: generateMapPageRecoilKey('poiIdsAtom'),
  default: [],
})

export const poisSelectorFamily = selectorFamily({
  key: generateMapPageRecoilKey('poisSelector'),
  get:
    (screenInstance: string) =>
    ({ get }) => {
      const poiIds = get(poiIdsAtomFamily(screenInstance))

      const allPois = poiIds.map((id) => get(poiAtomFamily(id))).filter((poi) => !!poi) as CurationMapItem[]

      return allPois
    },
})

export const filteredPoisSelectorFamily = selectorFamily({
  key: generateMapPageRecoilKey('filteredPoisSelector'),
  get:
    (screenInstance: string) =>
    ({ get }) => {
      const pois = get(poisSelectorFamily(screenInstance))
      const filterId = get(selectedFilterIdAtomFamily(screenInstance))
      const mapTheme = get(mapThemeAtomFamily(screenInstance))
      const bounds = get(boundsAtomFamily(screenInstance))

      const filteredPois = pois.filter((poiMapType) => {
        if (!poiMapType) return

        const {
          poi: { attributes, events, coordinates },
        } = poiMapType

        const isIncludedInFilter =
          filterId === defaultFilterId || attributes?.some((attribute) => attribute.id === filterId)
        const isChosenEvent = events?.some((event) => mapTheme.id === event.id)
        const isWithInBound = !bounds || isPointInBounds(bounds, coordinates!)

        return isIncludedInFilter && isChosenEvent && isWithInBound
      })

      return filteredPois
    },
})

export type PoisStatus = 'initialize' | 'loading' | 'error' | 'exists' | 'no-pois' | 'no-filtered-pois'

export const poisStatusSelectorFamily = selectorFamily<PoisStatus, string>({
  key: generateMapPageRecoilKey('poisStatusSelector'),
  get:
    (screenInstance: string) =>
    ({ get }) => {
      const pois = get(poisSelectorFamily(screenInstance))
      const filteredPois = get(filteredPoisSelectorFamily(screenInstance))

      if (pois.length === 0) return 'no-pois'
      if (filteredPois.length === 0) return 'no-filtered-pois'

      return 'exists'
    },
})

export const selectedPoiIdAtomFamily = atomFamily<string | null, string>({
  key: generateMapPageRecoilKey('selectedPoiId'),
  default: null,
})

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { CreateReviewBody } from '../models';
// @ts-ignore
import { CreateReviewResponse } from '../models';
// @ts-ignore
import { GetReviewsByIdsV2200Response } from '../models';
// @ts-ignore
import { ReviewDtoV2 } from '../models';
// @ts-ignore
import { UpdateReviewBody } from '../models';
/**
 * ReviewsApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @summary 유저가 새 POI 리뷰 생성
   * @param {string} xAuthToken 
   * @param {CreateReviewBody} createReviewBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const createReviewV2AxiosParamCreator = async (xAuthToken: string, createReviewBody: CreateReviewBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = createReviewBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 좋아요 리스트 조회
   * @param {string} reviewIds 콤마로 이어붙인 리뷰 아이디들
   * @param {string} xAuthToken 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getReviewLikesV2AxiosParamCreator = async (reviewIds: string, xAuthToken: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/likes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (reviewIds !== undefined) {
          localVarQueryParameter['reviewIds'] = reviewIds;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 응답 바디에 포함된 리뷰의 순서는 요청 쿼리 파라미터로 전달한 id의 순서와 관계가 없어요
   * @summary 1개 이상의 아이디로 리뷰 조회
   * @param {Array<number>} ids 콤마로 이어붙인 리뷰 아이디들
   * @param {string} xAuthToken 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getReviewsByIdsV2AxiosParamCreator = async (ids: Array<number>, xAuthToken: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (ids) {
          localVarQueryParameter['ids'] = ids;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary POI의 리뷰 조회
   * @param {string} poiId POI 아이디
   * @param {number} [userId] 유저 아이디
   * @param {'highestScore' | 'latestCreation' | 'oldestCreation'} [sort] 정렬 방식. smb에 대해서는 score 정렬이 적용되지 않음.
   * @param {number} [page] 조회할 페이지
   * @param {number} [perPage] 조회할 아이템 갯수
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getReviewsByPoiIdV2AxiosParamCreator = async (poiId: string, userId?: number, sort?: 'highestScore' | 'latestCreation' | 'oldestCreation', page?: number, perPage?: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/by-poi-id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (poiId !== undefined) {
          localVarQueryParameter['poiId'] = poiId;
      }

      if (userId !== undefined) {
          localVarQueryParameter['userId'] = userId;
      }

      if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
      }

      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary POI의 리뷰 조회
   * @param {number} [userId] 유저 아이디
   * @param {'highestScore' | 'latestCreation' | 'oldestCreation'} [sort] 정렬 방식
   * @param {number} [page] 조회할 페이지
   * @param {number} [perPage] 조회할 아이템 갯수
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getReviewsByUserIdV2AxiosParamCreator = async (userId?: number, sort?: 'highestScore' | 'latestCreation' | 'oldestCreation', page?: number, perPage?: number, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/by-user-id`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (userId !== undefined) {
          localVarQueryParameter['userId'] = userId;
      }

      if (sort !== undefined) {
          localVarQueryParameter['sort'] = sort;
      }

      if (page !== undefined) {
          localVarQueryParameter['page'] = page;
      }

      if (perPage !== undefined) {
          localVarQueryParameter['perPage'] = perPage;
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 좋아요
   * @param {string} id 리뷰 아이디
   * @param {string} xAuthToken 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const likeReviewV2AxiosParamCreator = async (id: string, xAuthToken: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/{id}/like`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 유저가 본인이 작성한 POI 리뷰 삭제
   * @param {string} id 리뷰 아이디
   * @param {string} xAuthToken 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const removeReviewV2AxiosParamCreator = async (id: string, xAuthToken: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/{id}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 좋아요 취소
   * @param {string} id 리뷰 아이디
   * @param {string} xAuthToken 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const unlikeReviewV2AxiosParamCreator = async (id: string, xAuthToken: string, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/{id}/like`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @summary 유저가 본인이 작성한 POI 리뷰 수정
   * @param {string} id 리뷰 아이디
   * @param {string} xAuthToken 
   * @param {UpdateReviewBody} updateReviewBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const updateReviewV2AxiosParamCreator = async (id: string, xAuthToken: string, updateReviewBody: UpdateReviewBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/reviews/{id}`
          .replace(`{${"id"}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = updateReviewBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * ReviewsApi - functional programming interface
 * @export
 */
    /**
     * 
     * @summary 유저가 새 POI 리뷰 생성
     * @param {string} xAuthToken 
     * @param {CreateReviewBody} createReviewBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const createReviewV2Fp = async (xAuthToken: string, createReviewBody: CreateReviewBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateReviewResponse>> => {
        const localVarAxiosArgs = await createReviewV2AxiosParamCreator(xAuthToken, createReviewBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 좋아요 리스트 조회
     * @param {string} reviewIds 콤마로 이어붙인 리뷰 아이디들
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getReviewLikesV2Fp = async (reviewIds: string, xAuthToken: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await getReviewLikesV2AxiosParamCreator(reviewIds, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 응답 바디에 포함된 리뷰의 순서는 요청 쿼리 파라미터로 전달한 id의 순서와 관계가 없어요
     * @summary 1개 이상의 아이디로 리뷰 조회
     * @param {Array<number>} ids 콤마로 이어붙인 리뷰 아이디들
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getReviewsByIdsV2Fp = async (ids: Array<number>, xAuthToken: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReviewsByIdsV2200Response>> => {
        const localVarAxiosArgs = await getReviewsByIdsV2AxiosParamCreator(ids, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary POI의 리뷰 조회
     * @param {string} poiId POI 아이디
     * @param {number} [userId] 유저 아이디
     * @param {'highestScore' | 'latestCreation' | 'oldestCreation'} [sort] 정렬 방식. smb에 대해서는 score 정렬이 적용되지 않음.
     * @param {number} [page] 조회할 페이지
     * @param {number} [perPage] 조회할 아이템 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getReviewsByPoiIdV2Fp = async (poiId: string, userId?: number, sort?: 'highestScore' | 'latestCreation' | 'oldestCreation', page?: number, perPage?: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReviewsByIdsV2200Response>> => {
        const localVarAxiosArgs = await getReviewsByPoiIdV2AxiosParamCreator(poiId, userId, sort, page, perPage, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary POI의 리뷰 조회
     * @param {number} [userId] 유저 아이디
     * @param {'highestScore' | 'latestCreation' | 'oldestCreation'} [sort] 정렬 방식
     * @param {number} [page] 조회할 페이지
     * @param {number} [perPage] 조회할 아이템 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getReviewsByUserIdV2Fp = async (userId?: number, sort?: 'highestScore' | 'latestCreation' | 'oldestCreation', page?: number, perPage?: number, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReviewsByIdsV2200Response>> => {
        const localVarAxiosArgs = await getReviewsByUserIdV2AxiosParamCreator(userId, sort, page, perPage, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 좋아요
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const likeReviewV2Fp = async (id: string, xAuthToken: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await likeReviewV2AxiosParamCreator(id, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 유저가 본인이 작성한 POI 리뷰 삭제
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const removeReviewV2Fp = async (id: string, xAuthToken: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await removeReviewV2AxiosParamCreator(id, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 좋아요 취소
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const unlikeReviewV2Fp = async (id: string, xAuthToken: string, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> => {
        const localVarAxiosArgs = await unlikeReviewV2AxiosParamCreator(id, xAuthToken, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @summary 유저가 본인이 작성한 POI 리뷰 수정
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {UpdateReviewBody} updateReviewBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const updateReviewV2Fp = async (id: string, xAuthToken: string, updateReviewBody: UpdateReviewBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewDtoV2>> => {
        const localVarAxiosArgs = await updateReviewV2AxiosParamCreator(id, xAuthToken, updateReviewBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * ReviewsApi - factory interface
 * @export
 */
    /**
     * 
     * @summary 유저가 새 POI 리뷰 생성
     * @param {string} xAuthToken 
     * @param {CreateReviewBody} createReviewBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const createReviewV2 = (xAuthToken: string, createReviewBody: CreateReviewBody, options?: any): AxiosPromise<CreateReviewResponse> => {
        return createReviewV2Fp(xAuthToken, createReviewBody, options).then((request) => request());
    }
    /**
     * 
     * @summary 좋아요 리스트 조회
     * @param {string} reviewIds 콤마로 이어붙인 리뷰 아이디들
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getReviewLikesV2 = (reviewIds: string, xAuthToken: string, options?: any): AxiosPromise<void> => {
        return getReviewLikesV2Fp(reviewIds, xAuthToken, options).then((request) => request());
    }
    /**
     * 응답 바디에 포함된 리뷰의 순서는 요청 쿼리 파라미터로 전달한 id의 순서와 관계가 없어요
     * @summary 1개 이상의 아이디로 리뷰 조회
     * @param {Array<number>} ids 콤마로 이어붙인 리뷰 아이디들
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getReviewsByIdsV2 = (ids: Array<number>, xAuthToken: string, options?: any): AxiosPromise<GetReviewsByIdsV2200Response> => {
        return getReviewsByIdsV2Fp(ids, xAuthToken, options).then((request) => request());
    }
    /**
     * 
     * @summary POI의 리뷰 조회
     * @param {string} poiId POI 아이디
     * @param {number} [userId] 유저 아이디
     * @param {'highestScore' | 'latestCreation' | 'oldestCreation'} [sort] 정렬 방식. smb에 대해서는 score 정렬이 적용되지 않음.
     * @param {number} [page] 조회할 페이지
     * @param {number} [perPage] 조회할 아이템 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getReviewsByPoiIdV2 = (poiId: string, userId?: number, sort?: 'highestScore' | 'latestCreation' | 'oldestCreation', page?: number, perPage?: number, options?: any): AxiosPromise<GetReviewsByIdsV2200Response> => {
        return getReviewsByPoiIdV2Fp(poiId, userId, sort, page, perPage, options).then((request) => request());
    }
    /**
     * 
     * @summary POI의 리뷰 조회
     * @param {number} [userId] 유저 아이디
     * @param {'highestScore' | 'latestCreation' | 'oldestCreation'} [sort] 정렬 방식
     * @param {number} [page] 조회할 페이지
     * @param {number} [perPage] 조회할 아이템 갯수
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getReviewsByUserIdV2 = (userId?: number, sort?: 'highestScore' | 'latestCreation' | 'oldestCreation', page?: number, perPage?: number, options?: any): AxiosPromise<GetReviewsByIdsV2200Response> => {
        return getReviewsByUserIdV2Fp(userId, sort, page, perPage, options).then((request) => request());
    }
    /**
     * 
     * @summary 좋아요
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const likeReviewV2 = (id: string, xAuthToken: string, options?: any): AxiosPromise<void> => {
        return likeReviewV2Fp(id, xAuthToken, options).then((request) => request());
    }
    /**
     * 
     * @summary 유저가 본인이 작성한 POI 리뷰 삭제
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const removeReviewV2 = (id: string, xAuthToken: string, options?: any): AxiosPromise<void> => {
        return removeReviewV2Fp(id, xAuthToken, options).then((request) => request());
    }
    /**
     * 
     * @summary 좋아요 취소
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const unlikeReviewV2 = (id: string, xAuthToken: string, options?: any): AxiosPromise<void> => {
        return unlikeReviewV2Fp(id, xAuthToken, options).then((request) => request());
    }
    /**
     * 
     * @summary 유저가 본인이 작성한 POI 리뷰 수정
     * @param {string} id 리뷰 아이디
     * @param {string} xAuthToken 
     * @param {UpdateReviewBody} updateReviewBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const updateReviewV2 = (id: string, xAuthToken: string, updateReviewBody: UpdateReviewBody, options?: any): AxiosPromise<ReviewDtoV2> => {
        return updateReviewV2Fp(id, xAuthToken, updateReviewBody, options).then((request) => request());
    }



import { getUserCouponBoxV1 } from '@daangn/local-business-network/lib/coupon'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'
import { getDistanceBetweenCoordinates } from '@src/utils/coordinate'

import type { BizAccountExtraInfo } from '../types'

const generateKey = recoilKeyGenerator('couponBoxAPI')

export const couponBoxSelectorFamily = selectorFamily({
  key: generateKey('couponBoxSelectorFamily'),
  get:
    ({ couponBoxId }: { couponBoxId: number }) =>
    async () => {
      try {
        if (!couponBoxId) return undefined

        const { data } = await getUserCouponBoxV1({ params: { couponBoxId } })

        return data
      } catch (error) {
        captureException(error)

        throw error
      }
    },
})

export type CouponBoxBizCoordinatesState =
  | {
      key: 'NOT_SETTLED'
    }
  | {
      key: 'VISIBLE' | 'HIDDEN'
      data: {
        meterDistance: number
        label: string
        coordinates: BizAccountExtraInfo['coordinates']
      }
    }

const TEN_KILOMETER = 10000
export const couponBoxBizCoordinatesSelector = selectorFamily<CouponBoxBizCoordinatesState, number>({
  key: 'CouponBoxBizCoordinatesSelector',
  get:
    (couponBoxId) =>
    ({ get }) => {
      const coordinates = get(couponBoxSelectorFamily({ couponBoxId }))?.data?.coupon_box.business_account?.coordinates
      const appCoordinates =
        get(bridgeInfoUserAtom)?.region?.center_coordinates ?? get(bridgeInfoUserAtom)?.region?.centerCoordinates

      if (!coordinates?.lat || !coordinates?.lng || !appCoordinates?.latitude || !appCoordinates.longitude) {
        return {
          key: 'NOT_SETTLED',
        }
      }

      try {
        const { meterDistance, text } = getDistanceBetweenCoordinates(
          appCoordinates.latitude,
          appCoordinates.longitude,
          coordinates.lat,
          coordinates.lng
        )
        const visible = meterDistance < 701

        return {
          key: visible ? 'VISIBLE' : 'HIDDEN',
          data: {
            coordinates,
            label: meterDistance > TEN_KILOMETER ? '10km+' : text ?? '',
            meterDistance,
          },
        } as CouponBoxBizCoordinatesState
      } catch (e) {
        return {
          key: 'NOT_SETTLED',
        }
      }
    },
})

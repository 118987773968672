import styled from '@emotion/styled'
import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { Button } from '@src/components/Button'
import { Section as SectionComponent } from '@src/components/Layout'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../../hooks'

const EmptyReviewSection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const handleClickWriteReview = async () => {
    if (!poi) return

    /** @deprecated */
    logEvent('click_write_review_button', {
      area: 'home_tab',
    })
    logEvent('click_write_profile_review_button', {
      area: 'home_tab',
    })

    push('poi_review', { action: 'create', poiId })
  }

  return (
    <Section>
      <Text>
        {poi?.name} 가보셨나요?
        <span>이웃을 위해 가장 먼저 후기를 남겨보세요.</span>
      </Text>
      <Button textSize="14" styleTheme="primary-outlined" fullwidth onClick={handleClickWriteReview}>
        <IconWriteRegular width="16" height="16" />
        후기 작성
      </Button>
    </Section>
  )
}

const Section = styled(SectionComponent)`
  padding: 1.5rem 1rem 1.25rem;
  display: flex;
  flex-direction: column;
`

const Text = styled.p`
  margin: 0 0 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 133%;
  color: ${vars.$scale.color.gray900};
  letter-spacing: -0.04em;

  span {
    margin: 0.25rem 0 0;
    font-weight: 400;
    font-size: 0.875rem;
    color: ${vars.$scale.color.gray600};
    line-height: 143%;
    letter-spacing: -0.02em;
  }
`

export default EmptyReviewSection

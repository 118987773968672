import { Poi } from '@daangn/local-business-network/lib/poi'
import { isDefaultLoadable } from '@daangn/recoil-loadable'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useSetRecoilState } from 'recoil'

import { AlertDialog } from '@src/components/AlertDialog'
import { useNavigator, useStepNavigator } from '@src/stackflow'

import CreateSuggestion from './CreateSuggestion'
import { DialogType } from './index'
import { usePoisStore } from '../WinterSnack/hooks'
import { latestSearchResultAtom } from '../WinterSnack/state'

const CreateSuggestionForWinterSnack = () => {
  const params = useActivityParams<{ alert?: DialogType }>()

  const { pop } = useNavigator()
  const { stepPush } = useStepNavigator('create_poi_suggestion')
  const { stepReplace } = useStepNavigator('winter_snack')

  const setLatestSearchResult = useSetRecoilState(latestSearchResultAtom)
  const [pois, setPois] = usePoisStore()

  const [submittedPoi, setSubmittedPoi] = React.useState<Poi>()

  const handleSubmitComplete = (poi: Poi | null) => {
    stepPush({ alert: 'winterSnackCompleteSubmit' })

    if (isDefaultLoadable(pois) || !pois || !poi) return

    setSubmittedPoi(poi)
    setPois([
      ...pois,
      {
        poi,
        distance: 0,
      },
    ])
    setLatestSearchResult([
      {
        poi,
        distance: 0,
      },
    ])
  }

  const handleClickConfirm = () => {
    pop()

    if (submittedPoi) {
      stepReplace({
        selectedPoiId: submittedPoi.id,
        filterId: 'all',
        mode: 'view',
      })
    }
  }

  // eslint-disable-next-line no-warning-comments
  // @todo change Poi type generated from local-business-network
  return (
    <>
      <CreateSuggestion onSubmit={(poi) => handleSubmitComplete(poi as Poi)} />
      {params.alert === 'winterSnackCompleteSubmit' && (
        <AlertDialog
          contents={'소중한 제보 감사해요.\n겨울간식 가게로 확인되면 이웃에게 보여져요.'}
          CTAButton={{
            label: '확인',
            onClick: handleClickConfirm,
          }}
        />
      )}
    </>
  )
}

export default CreateSuggestionForWinterSnack

import React from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import { SectionHeader } from '@src/components'
import { Section } from '@src/components/Layout'
import { brandProfileSelectorFamily } from '@src/shared/bizProfile/store'
import CouponCardContainer from '@src/shared/coupon/CouponCard/CouponCardContainer'

interface Props {
  brandAccountId: string
}
export const CouponSection = withErrorBoundary(
  ({ brandAccountId }: Props) => {
    const brandProfile = useRecoilValue(brandProfileSelectorFamily({ brandAccountId }))
    const couponId = brandProfile?.brandCouponId

    if (!couponId) return null

    return (
      <Section>
        <SectionHeader text="쿠폰" type="basic" css={{ marginBottom: '1rem' }} />
        <CouponCardContainer couponId={couponId} isManager={false} />
      </Section>
    )
  },
  {
    fallbackRender: () => null,
  }
)

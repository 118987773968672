import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect } from 'react'

import { TextInput } from '@src/components/Input'
import { useNavigator } from '@src/stackflow'
import { FormItemWrapper } from '@src/widgets/form/FormItemWrapper'

import { EMPTY_SELECTED_POI, usePoiInputSelectionState } from './state'

interface Props {
  onClick?: () => void
}

const PoiInput = ({ onClick }: Props) => {
  const { push } = useNavigator()
  const { poiId, poiName } = useActivityParams<{ poiId: string; poiName: string }>()

  const [{ name }, setSelectedPoiInfo] = usePoiInputSelectionState()

  const poiInputId = 'poi'

  const handleClickPoiInput = () => {
    onClick?.()

    push('search_poi', {})
  }

  const handleClickClearPoiInput = () => {
    setSelectedPoiInfo(EMPTY_SELECTED_POI)
  }

  useEffect(() => {
    if (poiId && poiName) {
      setSelectedPoiInfo({
        id: poiId,
        name: poiName,
      })
    }

    return () => {
      setSelectedPoiInfo(EMPTY_SELECTED_POI)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormItemWrapper id={poiInputId} label="장소 검색">
      <TextInput
        id={poiInputId}
        placeholder="추천할 장소를 검색해주세요."
        value={name}
        readOnly
        onClick={handleClickPoiInput}
        onClearInputValue={handleClickClearPoiInput}
        appendRight={<IconSearchRegular width={22} height={22} />}
        type="search"
      />
    </FormItemWrapper>
  )
}

export default PoiInput

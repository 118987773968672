import { captureException, ErrorBoundary } from '@sentry/react'
import { StackflowReactPlugin } from '@stackflow/react'
import React from 'react'

import ErrorPage from '@src/components/Error/Base'

export const errorBoundaryPlugin = (): StackflowReactPlugin => {
  return () => ({
    key: 'error-boundary-plugin',
    wrapActivity: ({ activity }) => {
      return (
        <ErrorBoundary fallback={<ErrorPage />} onError={(error) => captureException(error)}>
          {activity.render()}
        </ErrorBoundary>
      )
    },
  })
}

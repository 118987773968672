import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { ViewTypeToggleButton as ViewTypeToggleButtonComponent } from '@src/components/Map'

import { useAnalytics } from '../hooks'
import { focusedPoiIdAtom } from '../state/map'
import { ViewType, viewTypeAtom } from '../state/view'

interface Props {
  onClick?: ({ prevViewType }: { prevViewType: ViewType }) => void
}
export const ViewTypeToggleButton = ({ onClick }: Props) => {
  const logEvent = useAnalytics()

  const [viewType, setViewType] = useRecoilState(viewTypeAtom)
  const focusedPoiId = useRecoilValue(focusedPoiIdAtom)
  const bottomSpacing = focusedPoiId ? 86 : 0

  const handleViewTypeToggle = () => {
    if (viewType === 'list') {
      logEvent('click_view_map_button')
      setViewType('map')
      onClick?.({ prevViewType: 'map' })
    } else {
      setViewType('list')
      logEvent('click_view_list_button')
      onClick?.({ prevViewType: 'list' })
    }
  }

  return (
    <ViewTypeToggleButtonComponent
      viewType={viewType === 'map' ? 'MAP' : 'LIST'}
      bottomSpacing={viewType === 'map' ? bottomSpacing : 0}
      onClick={handleViewTypeToggle}
    />
  )
}

import { BookmarkPoiIdDto } from '@daangn/local-business-network/lib/poi'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'

import { POIBookmarksApi } from '@src/apis/generated/poi/api'
import { errorMessage } from '@src/components/Error/constants'
import { useToast } from '@src/components/Toast'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { watchedByUserResponseSelector } from '../store'

export type WatchStatus = 'loading' | 'error' | 'exists' | 'no-watches'

export function useWatch() {
  const { showToast } = useToast()
  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const resetWatchedByUser = useRecoilRefresher_UNSTABLE(watchedByUserResponseSelector)

  const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'

  async function addWatch(watchPlaceId: BookmarkPoiIdDto) {
    try {
      if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

      await POIBookmarksApi.createBookmarkV2(userInfo.authToken, watchPlaceId)

      resetWatchedByUser()
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === AUTH_TOKEN_ERROR) {
          return showToast({
            text: errorMessage.AUTHENTICATION_MESSAGE,
            duration: 'long',
          })
        }
      }

      showToast({
        text: errorMessage.TEMPORARY_NOT_WORKING_MESSAGE,
        duration: 'long',
      })
    }
  }

  async function removeWatch(watchPlaceId: BookmarkPoiIdDto) {
    try {
      if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

      await POIBookmarksApi.deleteBookmarkV2(watchPlaceId.targetId, watchPlaceId.targetType, userInfo.authToken)

      resetWatchedByUser()
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === AUTH_TOKEN_ERROR) {
          return showToast({
            text: errorMessage.AUTHENTICATION_MESSAGE,
            duration: 'long',
          })
        }
      }

      showToast({
        text: errorMessage.TEMPORARY_NOT_WORKING_MESSAGE,
        duration: 'long',
      })
    }
  }

  return {
    addWatch,
    removeWatch,
    resetWatchedByUser,
  }
}

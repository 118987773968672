import styled from '@emotion/styled'
import IconWriteRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconWriteRegular'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { Button } from '@src/components/Button'
import { poiByIdSelectorFamily } from '@src/shared/poi/store'
import { useNavigator } from '@src/stackflow'

import { useAnalytics } from '../hooks'

const EmptyReviewTab = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const { push } = useNavigator()
  const logEvent = useAnalytics()

  const poi = useRecoilValue(poiByIdSelectorFamily({ poiId }))

  const handleClickWriteReview = async () => {
    if (!poi) return

    /** @deprecated */
    logEvent('click_write_review_button', {
      area: 'empty_review_tab',
    })
    logEvent('click_write_profile_review_button', {
      area: 'empty_review_tab',
    })

    push('poi_review', { action: 'create', poiId })
  }

  return (
    <Container>
      <Text>
        {poi?.name ?? ''} 가보셨나요? <br />
        이웃을 위해 가장 먼저 후기를 남겨보세요.
      </Text>
      <div className="buttons">
        <ActionButton styleTheme="secondary-outlined" textSize="14" onClick={handleClickWriteReview}>
          <IconWriteRegular width="16" height="16" />
          후기 작성
        </ActionButton>
      </div>
    </Container>
  )
}

const Container = styled.div`
  padding: 6.25rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${vars.$semantic.color.paperDefault};
`

const Text = styled.p`
  margin: 0 0 0.75rem;
  text-align: center;
  font-weight: 400;
  font-size: 1rem;
  line-height: 138%;
  letter-spacing: -0.03em;
  color: ${vars.$scale.color.gray600};
`

const ActionButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

export default EmptyReviewTab

import { atom } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

export const generateKey = recoilKeyGenerator(['searchPoi', 'query'])

export const queryAtom = atom({
  key: generateKey('query'),
  default: '',
})

import { selectorFamily } from 'recoil'

import { POICurationCategoriesApi } from '@src/apis/generated/poi/api'
import type { ServiceName } from '@src/apis/types/poi'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateKey = recoilKeyGenerator('curationCategory')

const curationCategorySelectorFamily = selectorFamily({
  key: generateKey('curation-category-selector-family'),
  get: (serviceName: ServiceName) => async () => {
    const resp = await POICurationCategoriesApi.getCurationCategoriesV2(serviceName)

    const categoryArray = resp.data.data

    return categoryArray
  },
})

export default curationCategorySelectorFamily

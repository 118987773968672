import { useActivityParams } from '@stackflow/react'
import { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { useKakaoMap } from '@src/components/Map/KakaoMap'
import { useStepNavigator } from '@src/stackflow'

import { useFilteredPois } from './useFilteredPois'
import { TEMP_SPACING_FOR_CENTER } from '../constants/style'
import { WinterSnackParams } from '../WinterSnackPage'

export const useSelectedPoi = () => {
  const { selectedPoiId } = useActivityParams<WinterSnackParams>()
  const { stepReplace } = useStepNavigator('winter_snack')
  const filteredPois = useFilteredPois()
  const { kakao, panTo } = useKakaoMap()

  const SPACING_FOR_CENTER = useRecoilValue(TEMP_SPACING_FOR_CENTER)

  const getPoiById = useCallback(
    (id: string) => {
      return filteredPois.find(({ poi }) => poi.id === id)
    },
    [filteredPois]
  )
  const selectedPoi = useMemo(() => (!selectedPoiId ? null : getPoiById(selectedPoiId)), [getPoiById, selectedPoiId])
  const setSelectedPoiId = useCallback(
    (id: string) => {
      stepReplace({
        selectedPoiId: id,
      })
    },
    [stepReplace]
  )
  const spotlightPoiOnMap = useCallback(
    (id: string, shouldPanTo = true) => {
      const selectedPoi = getPoiById(id)
      if (!selectedPoi) return
      setSelectedPoiId(id)

      const coordinates = selectedPoi.poi.coordinates
      if (coordinates && shouldPanTo && kakao) {
        panTo({
          coordinates,
          yOffset: SPACING_FOR_CENTER,
        })
      }
    },
    [SPACING_FOR_CENTER, getPoiById, kakao, panTo, setSelectedPoiId]
  )

  return {
    selectedPoi,
    selectedPoiId,
    setSelectedPoiId,
    spotlightPoiOnMap,
  }
}

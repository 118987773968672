import { ActivityComponentType } from '@stackflow/react'
import React, { useEffect } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { watchedByUserMapperSelector } from '@src/shared/watch/store'
import { Screen } from '@src/stackflow'

import RightBottomButtons from './FloatingLayer/RightBottomButtons'
import ViewTypeToggleButton from './FloatingLayer/ViewToggleButton'
import { useClearNudge, useOnFilteredPoisChangeEffect, useReviewFeedback, useSyncPoisEffect } from './hooks'
import useFetchInitialPois from './hooks/useFetchInitialPois'
import IntroductionDrawer from './IntroductionDrawer'
import { ListView } from './ListView/ListView'
import { ButtonWrapper, Contents, DaangnMapParams, FlexContainer } from './MapPage'
import ButtonShare from './Navbar/ButtonShare'
import ButtonWatchList from './Navbar/ButtonWatchList'
import { submittedReviewAtom } from './state'
import NudgeItems from '../../components/Nudge/NudgeItems'

const MapWithoutMap: ActivityComponentType<DaangnMapParams> = () => {
  const { handleReviewFeedback } = useReviewFeedback()

  const submittedReview = useRecoilValue(submittedReviewAtom)

  useEffect(() => {
    if (!submittedReview) return

    const { poiId, bizAccountId, reviewId } = submittedReview

    handleReviewFeedback({ poiId, bizAccountId, reviewId })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedReview])

  useRecoilValueLoadable(watchedByUserMapperSelector)
  useClearNudge()
  useSyncPoisEffect()
  useFetchInitialPois()
  useOnFilteredPoisChangeEffect()
  // useSubprofileNudge()

  return (
    <>
      <Screen
        appBar={{
          title: '당근지도',
          border: false,
          appendRight: () => {
            return (
              <ButtonWrapper>
                <ButtonWatchList />
                <ButtonShare />
              </ButtonWrapper>
            )
          },
        }}>
        <FlexContainer>
          <Contents>
            <ListView viewType="LIST" />
          </Contents>
        </FlexContainer>

        <RightBottomButtons viewType="LIST" />
        <ViewTypeToggleButton viewType="LIST" />
      </Screen>

      <IntroductionDrawer />
      <NudgeItems />
    </>
  )
}

export default MapWithoutMap

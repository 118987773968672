import styled from '@emotion/styled'
import IconChevronRightRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconChevronRightRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { withErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'

import { karrotBridge } from '@src/bridge'
import { BIZ_PROFILE_APP_BASE_URL } from '@src/constants/apiUrls'
import { brandProfileSelectorFamily } from '@src/shared/bizProfile/store'

import { OfficialBadge } from './OfficialBadge'

interface Props {
  brandAccountId: string
}
export const BrandInfo = withErrorBoundary(
  ({ brandAccountId }: Props) => {
    const brandProfile = useRecoilValue(brandProfileSelectorFamily({ brandAccountId }))

    if (!brandProfile) return null

    const { brandName, brandImageUrl } = brandProfile

    const handleClick = () => {
      karrotBridge.pushRouter({
        router: {
          remote: `${BIZ_PROFILE_APP_BASE_URL}/biz_accounts/${brandAccountId}/viewer/home?referrer=place.poi.detail`,
          navbar: false,
          scrollable: false,
        },
      })
    }

    return (
      <Wrapper onClick={handleClick}>
        <Brand>
          <Logo src={brandImageUrl} />
          <Name>{brandName}</Name>
          <OfficialBadge
            width="1.125rem"
            height="1.125rem"
            css={{ minWidth: '1.125rem', minHeight: '1.125rem' }}
            fill={vars.$scale.color.green500}
          />
        </Brand>
        <Action>
          브랜드 보기
          <IconChevronRightRegular width="1rem" height="1rem" />
        </Action>
      </Wrapper>
    )
  },
  {
    fallbackRender: () => null,
  }
)

const Wrapper = styled.a`
  padding: 1rem;
  width: 100%;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${vars.$semantic.color.paperDefault};
`
const Brand = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  min-width: 0; // for ellipsis
`
const Logo = styled.img`
  margin: 0 0.5rem 0 0;
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
`
const Name = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0.125rem 0 0;
  color: ${vars.$scale.color.gray900};
  ${vars.$semantic.typography.bodyM2Regular};
`
const Action = styled.div`
  margin: 0 0 0 4px;
  flex: 0 0 fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${vars.$scale.color.gray600};
  ${vars.$semantic.typography.bodyM2Regular};
`

import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { POIReviewsApi } from '@src/apis/generated/poi/api'
import { useToast } from '@src/components/Toast'
import { Screen, useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'

import { ActivityParams } from '.'
import { SubmitButton, SubmitParams } from './Submit'
import WritePoiReview from './WritePoiReview'

const UpdatePoiReviewPage = () => {
  const { showToast } = useToast()
  const { pop } = useNavigator()
  const { reviewId } = useActivityParams<ActivityParams>()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const handleSubmit = async (params: SubmitParams) => {
    if (!reviewId) return

    try {
      await POIReviewsApi.updateReviewV2(
        reviewId,
        userInfo!.authToken,
        {
          ...params,
          tagIds: [],
        },
        userInfo?.authToken
      )

      showToast({
        text: '후기를 수정했어요.',
        duration: 'short',
      })

      pop()
    } catch (error) {
      showToast({
        text: '후기 수정에 실패했어요. 다시 한번 시도해주세요.',
        duration: 'long',
      })

      throw error
    }
  }

  return (
    <Screen
      appBar={{
        title: '후기 수정',
        appendRight: () => {
          return <SubmitButton onSubmit={handleSubmit}>완료</SubmitButton>
        },
      }}>
      <WritePoiReview />
    </Screen>
  )
}

export default UpdatePoiReviewPage

import React from 'react'

import { bridge } from '@src/bridge'

import CreateSuggestion from './CreateSuggestion'

const CreateSuggestionForSearch = () => {
  const handleSubmitComplete = () => {
    bridge.router.close()
  }

  return <CreateSuggestion onSubmit={handleSubmitComplete} />
}

export default CreateSuggestionForSearch

import { getPostV1 } from '@daangn/local-business-network/lib/post'
import { captureException } from '@sentry/react'
import { selectorFamily } from 'recoil'

import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const generateKey = recoilKeyGenerator('bizPost')

type BizPostRequestParamters = Parameters<typeof getPostV1>['0']['params']

export const bizPostSelectorFamily = selectorFamily({
  key: generateKey('couponBoxSelectorFamily'),
  get: (params: BizPostRequestParamters) => async () => {
    const { postId } = params

    try {
      if (!postId) return undefined

      const { data } = await getPostV1({ params })

      return data
    } catch (error) {
      captureException(error)

      throw error
    }
  },
})

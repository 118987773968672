import { BookmarkDto } from '@daangn/local-business-network/lib/poi'
import { css } from '@emotion/css'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useSetRecoilState } from 'recoil'

import { bridge } from '@src/bridge'
import { GeneralWatchOverview } from '@src/components/GeneralWatchOverview'
import { Loading } from '@src/components/Loading'
import { withSuspense } from '@src/components/Suspense'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { currentPositionClickedAtomFamily } from '@src/pages/Map/state'
import WatchButton from '@src/shared/watch/WatchButton'
import { useStepNavigator, useNavigator } from '@src/stackflow'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics, useSelectedWatchedPlaceId, useResetWatchedByUser } from '../../hooks'
import { enableFocusingWatchedPlaceAtomFamily } from '../../state'

interface Props extends BookmarkDto {
  className?: string
}

function ListViewWatchedPlaceCard(props: Props) {
  const { targetId, targetType, target } = props

  const logEvent = useAnalytics()
  const { push } = useNavigator()
  const { stepPush } = useStepNavigator('place_watch')
  const { setSelectedWatchedPlaceId } = useSelectedWatchedPlaceId()
  const resetWatchedByUser = useResetWatchedByUser()
  const screenInstance = useScreenInstance()
  const { referrer } = useActivityParams<{ referrer: string }>()

  const setEnableFocusingWatch = useSetRecoilState(enableFocusingWatchedPlaceAtomFamily(screenInstance))
  const setCurrentPositionClicked = useSetRecoilState(currentPositionClickedAtomFamily(screenInstance))

  const isBiz = targetType === 'BA'

  const handleClickPoiCard = async () => {
    logEvent('click_view_poi_on_map_card', {
      poi_type: isBiz ? 'biz_account' : 'poi',
      poi_id: targetId,
    })

    if (!window.kakao) {
      if (isBiz) {
        bridge.router.push({
          app: `${WEBVIEW_BASE_URL}business-platform/home`,
          path: `/biz_accounts/${targetId}/viewer/home?entry=native.mydaangn.place_watch&referrer=${referrer}`,
          navbar: false,
          scrollable: false,
        })
      } else {
        push('poi_detail', { poiId: targetId })

        resetWatchedByUser()
      }

      return
    }

    stepPush({ view_type: 'MAP' })
    setEnableFocusingWatch(true)
    setSelectedWatchedPlaceId(targetId)
    setCurrentPositionClicked(false)
  }

  const handleLogClickWatch = (setToWatch: boolean) => () => {
    logEvent(setToWatch ? 'click_add_watch_button' : 'click_remove_watch_button', {
      poi_type: isBiz ? 'biz_account' : 'poi',
      poi_id: targetId,
      area: 'list_poi_card',
    })
  }

  return (
    <GeneralWatchOverview
      thumbnail={target?.thumbnail?.url ?? ''}
      title={target?.name ?? ''}
      tags={[target?.category?.name ?? '', target?.region?.name ?? ''].filter(Boolean)}
      description={target?.address ?? ''}
      onClickBody={handleClickPoiCard}
      favoriteButton={
        <WatchButton
          placeWatchId={{ targetId, targetType }}
          onAddWatch={handleLogClickWatch(true)}
          onRemoveWatch={handleLogClickWatch(false)}
          customDefaultIconCSS={css`
            path:last-of-type {
              fill: ${vars.$scale.color.gray600};
            }
          `}
        />
      }
    />
  )
}

export default withSuspense(ListViewWatchedPlaceCard, Loading)

import React from 'react'
import { useRecoilValue } from 'recoil'

import { AlertDialog } from '@src/components/AlertDialog'
import { useNavigator } from '@src/stackflow'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { localStorage } from '@src/utils/localStorage'

import { usePushToDetailPage } from '../hooks'
import { submittedReviewAtom } from '../state/review'

export const ReviewDialog = () => {
  const { handlePush } = usePushToDetailPage()
  const { pop } = useNavigator()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)
  const review = useRecoilValue(submittedReviewAtom)

  const userNickname = userInfo?.nickname ? `${userInfo.nickname}님! ` : ''

  const handleViewReview = () => {
    localStorage.setItem('IS_FIRST_DAANGN_MAP_REVIEW', 'false')

    handlePush({
      poiId: review!.poiId,
      bizAccountId: review?.bizAccountId,
      reviewId: review!.reviewId,
      options: {
        area: 'dialog',
        operation: 'replace',
      },
    })
  }

  const handleConfirm = () => {
    localStorage.setItem('IS_FIRST_DAANGN_MAP_REVIEW', 'false')

    pop()
  }

  return (
    <AlertDialog
      header="후기를 살펴보고 있어요"
      contents={`${userNickname}소중한 후기 감사해요.\n써주신 후기가 당근지도에 등록되면 알려드릴게요.`}
      CTAButton={{
        label: '확인',
        onClick: handleConfirm,
      }}
      subButton={{
        label: '후기 보기',
        onClick: handleViewReview,
      }}
      buttonAlign="row"
      onOutsideClick={() => pop()}
    />
  )
}

/* tslint:disable */
/* eslint-disable */
/**
 * V2
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * Contact: 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, mockServer } from '../base';

// @ts-ignore
import { CreateReactionBody } from '../models';
// @ts-ignore
import { CreateReactionDto } from '../models';
// @ts-ignore
import { DeleteReactionBody } from '../models';
// @ts-ignore
import { DeleteReactionDto } from '../models';
// @ts-ignore
import { GetReactionsDto } from '../models';
/**
 * ReactionsApi - axios parameter creator
 * @export
 */
  /**
   * 
   * @param {string} xAuthToken 
   * @param {CreateReactionBody} createReactionBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const createReactionV2AxiosParamCreator = async (xAuthToken: string, createReactionBody: CreateReactionBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/me/reactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = createReactionBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {string} xAuthToken 
   * @param {DeleteReactionBody} deleteReactionBody 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const deleteReactionV2AxiosParamCreator = async (xAuthToken: string, deleteReactionBody: DeleteReactionBody, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/me/reactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
      localVarRequestOptions.data = deleteReactionBody || '';

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };
  /**
   * 
   * @param {string} xAuthToken 
   * @param {'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW'} resourceType 
   * @param {Array<string>} resourceIds 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
  const getReactionsV2AxiosParamCreator = async (xAuthToken: string, resourceType: 'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW', resourceIds: Array<string>, options: AxiosRequestConfig = {}, configuration?: Configuration): Promise<RequestArgs> => {
      const localVarPath = `/api/v2/me/reactions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
          baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (resourceType !== undefined) {
          localVarQueryParameter['resourceType'] = resourceType;
      }

      if (resourceIds) {
          localVarQueryParameter['resourceIds'] = resourceIds;
      }

      if (xAuthToken !== undefined && xAuthToken !== null) {
          localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
      }



      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

      return {
          url: toPathString(localVarUrlObj),
          options: localVarRequestOptions,
      };
  };

/**
 * ReactionsApi - functional programming interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {CreateReactionBody} createReactionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const createReactionV2Fp = async (xAuthToken: string, createReactionBody: CreateReactionBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateReactionDto>> => {
        const localVarAxiosArgs = await createReactionV2AxiosParamCreator(xAuthToken, createReactionBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {string} xAuthToken 
     * @param {DeleteReactionBody} deleteReactionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const deleteReactionV2Fp = async (xAuthToken: string, deleteReactionBody: DeleteReactionBody, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteReactionDto>> => {
        const localVarAxiosArgs = await deleteReactionV2AxiosParamCreator(xAuthToken, deleteReactionBody, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }
    /**
     * 
     * @param {string} xAuthToken 
     * @param {'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW'} resourceType 
     * @param {Array<string>} resourceIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    const getReactionsV2Fp = async (xAuthToken: string, resourceType: 'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW', resourceIds: Array<string>, options?: AxiosRequestConfig, configuration?: Configuration): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetReactionsDto>> => {
        const localVarAxiosArgs = await getReactionsV2AxiosParamCreator(xAuthToken, resourceType, resourceIds, options, configuration);
        return createRequestFunction(localVarAxiosArgs, globalAxios, configuration);
    }

/**
 * ReactionsApi - factory interface
 * @export
 */
    /**
     * 
     * @param {string} xAuthToken 
     * @param {CreateReactionBody} createReactionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const createReactionV2 = (xAuthToken: string, createReactionBody: CreateReactionBody, options?: any): AxiosPromise<CreateReactionDto> => {
        return createReactionV2Fp(xAuthToken, createReactionBody, options).then((request) => request());
    }
    /**
     * 
     * @param {string} xAuthToken 
     * @param {DeleteReactionBody} deleteReactionBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const deleteReactionV2 = (xAuthToken: string, deleteReactionBody: DeleteReactionBody, options?: any): AxiosPromise<DeleteReactionDto> => {
        return deleteReactionV2Fp(xAuthToken, deleteReactionBody, options).then((request) => request());
    }
    /**
     * 
     * @param {string} xAuthToken 
     * @param {'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW'} resourceType 
     * @param {Array<string>} resourceIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    export const getReactionsV2 = (xAuthToken: string, resourceType: 'STORY_ARTICLE' | 'STORY_COMMENT' | 'POI_REVIEW', resourceIds: Array<string>, options?: any): AxiosPromise<GetReactionsDto> => {
        return getReactionsV2Fp(xAuthToken, resourceType, resourceIds, options).then((request) => request());
    }



import { useRecoilValue } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { subprofileQueryByUserId, subprofileUserIdAtomFamily } from '../state'

export const useSubprofile = () => {
  const screenInstance = useScreenInstance()
  const userId = useRecoilValue(subprofileUserIdAtomFamily(screenInstance))
  const subprofile = useRecoilValue(subprofileQueryByUserId(userId))

  return subprofile
}

import styled from '@emotion/styled'
import IconGpsRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconGpsRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilState } from 'recoil'

import { FloatingButton } from '@src/components/Button'
import { useCurrentPosition } from '@src/hooks'
import { useActivity } from '@src/stackflow'

import { useAnalytics } from '../hooks'
import { currentPositionClickedAtomFamily } from '../state'

export const GPSButton = () => {
  const logEvent = useAnalytics()
  const { update: updateCurrentUserPosition } = useCurrentPosition()
  const activity = useActivity()
  const [currentPositionClicked, setCurrentPositionClicked] = useRecoilState(
    currentPositionClickedAtomFamily(activity.id)
  )

  return (
    <Button
      isClicked={currentPositionClicked}
      onClick={() => {
        logEvent('click_current_position_button')
        setCurrentPositionClicked(true)
        updateCurrentUserPosition()
      }}>
      <IconGpsRegular className="icon" />
    </Button>
  )
}

const Button = styled(FloatingButton)<{ isClicked: boolean }>`
  .icon {
    color: ${({ isClicked }) => (isClicked ? vars.$semantic.color.primary : vars.$scale.color.gray900)};
  }
`

import { isNull } from 'lodash'
import { useMemo } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { useScreenInstance } from '@src/widgets/screenInstance'

import { getPoisQuery, PoisStatus, poisStatusSelectorFamily } from '../state'

export const usePoisStatus: () => PoisStatus = () => {
  const screenInstance = useScreenInstance()
  const poisStatus = useRecoilValue(poisStatusSelectorFamily(screenInstance))
  const poisLoadable = useRecoilValueLoadable(getPoisQuery(screenInstance))

  return useMemo(
    () => (isNull(poisLoadable.contents) ? 'initialize' : poisLoadable.state === 'loading' ? 'loading' : poisStatus),
    [poisLoadable, poisStatus]
  )
}

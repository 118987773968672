import styled from '@emotion/styled'
import { ActivityComponentType } from '@stackflow/react'
import React from 'react'

import { OptionsBottomSheet } from './Modal/OptionsBottomSheet'
import { ReviewDialog } from './Modal/ReviewDialog'

type ModalType = 'firstMapReviewDialog' | 'poiOptionsBottomSheet'
interface ModalActivityParams {
  modalId: ModalType
  poiId?: string
  curationCategoryId?: string
}
export const MapModal: ActivityComponentType<ModalActivityParams> = ({
  params: { modalId, poiId, curationCategoryId },
}) => {
  return (
    <>
      {(() => {
        switch (modalId) {
          default:
            return null
          case 'firstMapReviewDialog':
            return <ReviewDialog />
          case 'poiOptionsBottomSheet': {
            if (!poiId) return null

            return <OptionsBottomSheet poiId={poiId} curationCategoryId={curationCategoryId ?? ''} />
          }
        }
      })()}
    </>
  )
}

export const FlexContainer = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`
export const Contents = styled.div`
  flex: 1;
  position: relative;
`
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

type ColorType = 'default'
type SizeType = 'size48'

interface Props {
  color?: ColorType
  size?: SizeType
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon?: React.ReactNode
  children?: React.ReactNode
  className?: string
}

const FloatingButton: React.FC<Props> = ({
  color = 'default',
  size = 'size48',
  onClick,
  icon,
  children,
  className,
}) => {
  return (
    <Button className={className} color={color} size={size} onClick={onClick}>
      {icon && <span className="icon">{icon}</span>}
      {children}
    </Button>
  )
}

const Button = styled.button<{
  color: ColorType
  size: SizeType
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.05));

  ${({ color }) => styleColor(color)}
  ${({ size }) => styleSize(size)}
`

const styleColor = (color: ColorType) => {
  switch (color) {
    case 'default':
      return colorDefault
    default:
      return colorDefault
  }
}

const colorDefault = css`
  background-color: ${vars.$semantic.color.paperSheet};

  .icon {
    color: ${vars.$scale.color.gray900};
  }
`
const styleSize = (size: SizeType) => {
  switch (size) {
    case 'size48':
      return size48
    default:
      return size48
  }
}

const size48 = css`
  width: 3rem;
  height: 3rem;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
`
export default FloatingButton

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'
import { useRecoilState } from 'recoil'

import { Category } from '@src/apis/generated/poi/models'
import RadioSelected from '@src/assets/icons/RadioSelected'
import RadioUnSelected from '@src/assets/icons/RadioUnSelected'
import { selectedCategoryAtom } from '@src/widgets/CategoryInput/state'

export type CategoryListItemProps = Category & {
  onClick: (category: Category) => void
}

const CategoryListItem: React.FC<CategoryListItemProps> = ({ id, levels, levelIds, name, onClick }) => {
  const [selectedCategory, setSelectedCategory] = useRecoilState(selectedCategoryAtom)

  const handleClick = () => {
    const category = {
      id,
      levels,
      levelIds,
      name,
    }
    onClick(category)
    setSelectedCategory(category)
  }

  return (
    <Card onClick={handleClick}>
      {selectedCategory?.id === id ? <RadioSelected /> : <RadioUnSelected />}
      <div className="content">
        <div className="content__name">{name}</div>
        <div className="content__levels">
          {levels?.map((level, index) => `${level}${index !== levels.length - 1 ? ' > ' : ''}`)}
        </div>
      </div>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
  padding: 12px 0;
  border-bottom: solid 1px ${vars.$semantic.color.divider1};

  svg {
    margin: 0 12px 0 0;
  }
  .content {
    &__name {
      ${vars.$semantic.typography.label2Regular}
    }
    &__levels {
      ${vars.$semantic.typography.caption1Regular}
      color: ${vars.$scale.color.gray600};
    }
  }
`

export default CategoryListItem

import { SubprofilePostCard } from '@daangn/local-business-kit'
import { XPoiPostType } from '@daangn/local-business-kit/lib/types/Poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect, useRef } from 'react'

import { PoiV2Dto, PostV2Dto } from '@src/apis/generated/poi/models'
import defaultPoiBizProfileImage from '@src/assets/images/default_poi_biz_profile.png'
import { bridge } from '@src/bridge'
import { ImageDetailSlider } from '@src/components/Image'
import { useToast } from '@src/components/Toast'
import { WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useImageSliderState } from '@src/hooks/useImageSliderState'
import { useOnScreen } from '@src/hooks/useOnScreen'
import { useActivity, useNavigator } from '@src/stackflow'
import { getDateDiffFromNow } from '@src/utils/date'
import { createQueryString } from '@src/utils/url'

import { useAnalytics } from './hooks'
import { ActivityParams } from './Page'
import UpvoteButton from './UpvoteButton'

interface Props {
  poi: PoiV2Dto
  post: PostV2Dto
  index: number
}

const SubprofileCard = ({ poi, post, index }: Props) => {
  const { handleCloseDetailImage, handleSelectDetailImage, selectedImageIdx } = useImageSliderState()
  const { push } = useNavigator()
  const logEvent = useAnalytics()
  const ref = useRef<HTMLDivElement>(document.createElement('div'))
  const onScreen = useOnScreen<HTMLDivElement>(ref, 1, true)
  const { showToast } = useToast()
  const { referrer } = useActivityParams<ActivityParams>()
  const { name: activityName } = useActivity()

  const handleClickMorePhotos = (index: number) => {
    handleSelectDetailImage(index)
  }

  const handleClickCard = (post: PostV2Dto, poi: PoiV2Dto, tab: 'post' | 'information') => {
    logEvent(tab === 'post' ? 'click_poi_content' : 'click_poi_title', {
      poi_id: poi.bizAccountId ?? poi.id,
      content_type: post.type?.toLocaleLowerCase(),
      type: poi.bizAccountId ? 'biz_account' : 'poi',
    })

    if (poi.destroyed) {
      showToast({
        text: '장소가 삭제되어 더 이상 확인할 수 없어요.',
        duration: 'long',
      })
      return
    }

    const bizAccountId = poi.bizAccountId
    if (bizAccountId) {
      const isStory = post.type === 'STORY_ARTICLE' || post.type === 'STORY_COMMENT'
      const isBizReview = post.type === 'BIZ_REVIEW'
      const queryString = createQueryString({
        referrer: referrer ?? `place.${activityName}`,
        entry: `place.${activityName}`,
        tab: isStory ? 'STORY' : 'REVIEW',
        highlightReviewId: isBizReview ? post.sourceId : null,
        highlightStoryId: isStory ? post.sourceId : null,
      })

      bridge.router.push({
        app: `${WEBVIEW_BASE_URL}business-platform/home`,
        path: `/biz_accounts/${bizAccountId}/viewer/home${queryString}`,
        navbar: false,
        scrollable: false,
      })
    } else {
      const tapParam = tab === 'information' ? 'home' : post.type === 'POI_REVIEW' ? 'review' : 'story'

      push('poi_detail', { poiId: poi.id, tab: tapParam })
    }
  }

  const renderTypeToText = (type: XPoiPostType) => {
    switch (type) {
      case 'STORY_ARTICLE':
      case 'STORY_COMMENT':
        return '동네생활'
      case 'POI_REVIEW':
      case 'BIZ_REVIEW':
        return '가게 후기'
      default:
        return '후기'
    }
  }

  useEffect(() => {
    if (onScreen) {
      logEvent('show_poi_card', {
        poi_type: poi.bizAccountId ? 'biz_account' : 'poi',
        poi_id: poi.bizAccountId ?? poi.id,
        position: index + 1,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  return (
    <Card key={post.id} ref={ref}>
      <SubprofilePostCard
        data={{
          type: post.type,
          id: post.id.toString(),
          bizProfileAndPoi: {
            name: poi.name ?? '',
            regionName: poi.region?.name,
            categoryName: poi.category?.name,
            destroyedAt: poi.destroyed ? 'now' : undefined,
            profileImage: poi.destroyed || !poi.thumbnail ? defaultPoiBizProfileImage : poi.thumbnail.url,
          },
          content: post.content,
          createdAt: new Date(post.createdAt),
          images: post.images,
          tags: post.tags,
          articleContent: post.article?.content,
        }}
        onClickImage={handleClickMorePhotos}
        onClickContent={() => handleClickCard(post, poi, 'post')}
        onClickProfile={() => handleClickCard(post, poi, 'information')}
      />
      <Bottom>
        <UpvoteButton resourceType={post.type} resourceId={post.sourceId} type={'UPVOTE'} bizId={poi.bizAccountId} />
        <div className="info">
          {getDateDiffFromNow(new Date(post.createdAt)).text} 전 &middot; {renderTypeToText(post.type)}
        </div>
      </Bottom>
      <ImageDetailSlider
        onClose={handleCloseDetailImage}
        initialIndex={selectedImageIdx}
        images={post.images.map(({ url }: any) => url)}
      />
    </Card>
  )
}

const Card = styled.div`
  margin: 0 16px;
  padding: 16px 0;
  border-top: 1px solid ${vars.$semantic.color.divider1};
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .info {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 400;
    color: ${vars.$scale.color.gray600};
  }
`

export default SubprofileCard

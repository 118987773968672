import { Poi, suggestCreateV2, SuggestCreationBody } from '@daangn/local-business-network/lib/poi'
import { captureException } from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import React, { useCallback, useMemo } from 'react'
import { useRecoilValue } from 'recoil'

import { bridge } from '@src/bridge'
import { useToast } from '@src/components/Toast'
import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { FormPage } from '@src/widgets/form/FormPage'
import type { CreateSuggestionRequestBody, Field } from '@src/widgets/form/types'

import { useAnalytics } from './hooks'
import { errorMessage } from '../../components/Error/constants'

interface Props {
  onSubmit: (poi: Poi | null) => void
}

const CreateSuggestion = ({ onSubmit }: Props) => {
  const logEvent = useAnalytics()
  const { showToast } = useToast()
  const { variation, name } = useActivityParams<{ variation: string; name?: string }>()

  const userInfo = useRecoilValue(bridgeInfoUserAtom)

  const handleSubmit = useCallback(
    async (requestBody: Partial<CreateSuggestionRequestBody>) => {
      const AUTH_TOKEN_ERROR = 'AUTH_TOKEN_ERROR'
      try {
        if (!userInfo?.authToken) throw new Error(AUTH_TOKEN_ERROR)

        const resp = await suggestCreateV2({
          params: {
            xAuthToken: userInfo.authToken,
            suggestCreationBody: {
              ...requestBody,
              name: requestBody.name ?? '',
              openingHours: requestBody.openingHours as SuggestCreationBody['openingHours'],
            },
          },
        })

        bridge.analytics.log({ name: 'click_poi_create_suggest_complete' })
        bridge.analytics.log({
          name: 'click_tagging_poi_register',
        })
        logEvent('click_submit_form_button')

        if (!resp.data.poi) {
          captureException(new Error('생성 요청에서 poi가 없었습니다.'))
        }
        onSubmit(resp.data.poi)
      } catch (err) {
        captureException(err)

        if (err instanceof Error) {
          if (err.message === AUTH_TOKEN_ERROR) {
            return showToast({
              text: errorMessage.AUTHENTICATION_MESSAGE,
              duration: 'long',
            })
          }
        }

        bridge.toast.open({ body: errorMessage.PLEASE_RETRY_MESSAGE })
      }
    },
    [logEvent, onSubmit, showToast, userInfo?.authToken]
  )

  const fields = useMemo(() => {
    const defaultFields: Field<'name' | 'address' | 'categoryId'>[] = [
      {
        id: 'name',
        isRequired: true,
      },
      {
        id: 'address',
        isRequired: true,
      },
      {
        id: 'categoryId',
      },
    ]

    if (variation === 'winterSnack') {
      const winterSnackFields: Field<'name' | 'winterSnackMenu' | 'address' | 'photos'>[] = [
        {
          id: 'name',
          isRequired: true,
        },
        {
          id: 'winterSnackMenu',
          isRequired: true,
        },
        {
          id: 'address',
          isRequired: true,
        },
        {
          id: 'photos',
        },
      ]

      return winterSnackFields
    }

    return defaultFields
  }, [variation])

  return (
    <FormPage
      title="장소 등록"
      fields={fields}
      initialValues={{ name }}
      onClickAddressSearch={() => {
        bridge.analytics.log({
          name: 'click_tagging_address_register',
          params: { from: 'search' },
        })
        logEvent('click_search_address_input')
      }}
      onClickPinAddress={
        window.kakao
          ? (e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              bridge.analytics.log({
                name: 'click_tagging_address_register',
                params: { from: 'map' },
              })
              logEvent('click_pin_address_button')
            }
          : undefined
      }
      onSubmit={handleSubmit}
    />
  )
}

export default CreateSuggestion

import { useLoadableFamilyPaginate } from '@daangn/recoil-loadable'
import { useActivityParams } from '@stackflow/react'
import React, { useEffect, useMemo, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { reviewsByPoiIdAndUserIdLoadableFamily } from '@src/shared/review'
import { useScreenInstance } from '@src/widgets/screenInstance'

import HomeTab from './HomeTab/HomeTab'
import { useTab } from './hooks'
import PostsTab from './PostsTab/PostsTab'
import {
  communityPostsStaticMetaAtomFamily,
  communityPostsSortingOptionAtomFamily,
  postsTabAtomFamily,
  PostsTabKey,
  Tab,
  tabAtomFamily,
  TabKey,
} from './state'

interface Props {
  tabs: Tab[]
  initialTabKey?: TabKey | PostsTabKey
}
const TabPanel = ({ tabs, initialTabKey = 'home' }: Props) => {
  const { poiId } = useActivityParams<{ poiId: string }>()
  const { setTab } = useTab({ poiId })
  const screenInstance = useScreenInstance()

  const currentTab = useRecoilValue(tabAtomFamily(screenInstance))
  const setPostTab = useSetRecoilState(postsTabAtomFamily(screenInstance))
  const communityPostsSortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ poiId, screenInstance }))
  const communityPostsStaticMeta = useRecoilValue(
    communityPostsStaticMetaAtomFamily({ poiId, screenInstance, sortingOption: communityPostsSortingOption })
  )

  const initialTabChangeCheckRef = useRef(false)

  const { value: reviewsStore } = useLoadableFamilyPaginate({
    loadable: reviewsByPoiIdAndUserIdLoadableFamily,
    variables: { poiId, sort: 'latestCreation', userId: undefined },
    fetchPolicy: 'store-or-network',
  })

  useEffect(() => {
    const isTabKey =
      tabs.some((tab) => tab.key === initialTabKey) ||
      initialTabKey === ('story' as PostsTabKey) ||
      initialTabKey === ('review' as PostsTabKey)

    if (isTabKey && initialTabKey !== 'home') {
      const initialTabMapper = {
        ['posts']: 'posts',
        ['review']: 'posts',
        ['story']: 'posts',
        ['home']: 'home',
      } as const

      setTab(
        initialTabMapper[initialTabKey as keyof typeof initialTabMapper],
        initialTabKey === 'story' ? 'story' : 'review'
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialTabKey, tabs])

  const initialPostTabKey = useMemo(
    () => (initialTabKey !== 'home' && initialTabKey !== 'posts' ? initialTabKey : undefined),
    [initialTabKey]
  )

  useEffect(() => {
    if (initialTabChangeCheckRef.current) return

    const reviewsInitialized = reviewsStore?.total !== -1
    const communityPostsInitialized = communityPostsStaticMeta.total !== -1
    if (reviewsInitialized && communityPostsInitialized) {
      initialTabChangeCheckRef.current = true
    }
    if (initialPostTabKey || ((reviewsStore?.total ?? 0) <= 0 && communityPostsStaticMeta.total > 0)) {
      setPostTab(initialPostTabKey ?? 'story')
    }
  }, [initialPostTabKey, reviewsStore?.total, communityPostsStaticMeta.total, setPostTab])

  switch (currentTab) {
    case 'home':
    default:
      return <HomeTab />
    case 'posts':
      return <PostsTab />
  }
}

export default TabPanel

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import { useActivityParams } from '@stackflow/react'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { SectionHeader } from '@src/components'
import { Button } from '@src/components/Button'
import { Section } from '@src/components/Layout'
import { PostPreviewSimplified } from '@src/components/Preview'
import type { ReactionType } from '@src/components/Reaction/ReactionDisplay'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics, useTab } from '../../hooks'
import {
  communityPostsAtomFamily,
  communityPostsStaticMetaAtomFamily,
  communityPostsSortingOptionAtomFamily,
} from '../../state/story'

const StorySection = () => {
  const { poiId } = useActivityParams<{ poiId: string }>()

  const logEvent = useAnalytics()
  const { setTab } = useTab({ poiId })
  const screenInstance = useScreenInstance()

  const sortingOption = useRecoilValue(communityPostsSortingOptionAtomFamily({ screenInstance, poiId }))
  const communityPosts = useRecoilValue(communityPostsAtomFamily({ screenInstance, poiId, sortingOption }))
  const communityPostsStaticMeta = useRecoilValue(
    communityPostsStaticMetaAtomFamily({ screenInstance, poiId, sortingOption })
  )
  const communityPostsInitialized = communityPostsStaticMeta.total !== -1

  const goToStoryArticleTab = () => {
    logEvent('click_view_more_community_button')
    setTab('posts', 'story')
  }

  if (!communityPostsInitialized || communityPostsStaticMeta.total === 0) {
    return null
  }

  return (
    <Section>
      <SectionHeader type="basic" text="동네생활" />
      <div>
        {communityPosts
          .filter((communityPost) => communityPost.content)
          .slice(0, 3)
          .map(({ type, ...post }, idx) => {
            return (
              <React.Fragment key={idx}>
                <Card
                  data={{
                    type: type === 'STORY_COMMENT' ? 'comment' : 'article',
                    id: post.sourceId,
                    author: {
                      id: post.user?.id ?? -1,
                      name: post.user?.nickname ?? '',
                      profileImage: post.user?.profileImageUrl,
                      regionName: post.user?.displayRegionName?.toString() ?? '',
                      regionCheckInCount: post.user?.displayRegionCheckinsCount,
                    },
                    content: post.content ?? '',
                    images: {
                      url: post.images?.map((image) => image.url) ?? [],
                    },
                    createdAt: post.createdAt ?? '',
                    reaction: {
                      count: post.emotionsCount,
                      types: post.topEmotions?.map((emotion) => emotion as ReactionType),
                    },
                    ...(type === 'STORY_COMMENT' &&
                      'article' in post && {
                        articleContent: post.article?.content,
                        articleId: post.article?.id,
                      }),
                  }}
                />
                <Divider />
              </React.Fragment>
            )
          })}
      </div>
      {communityPostsStaticMeta.total > 3 && (
        <Button styleTheme="secondary-outlined" textSize="14" fullwidth onClick={goToStoryArticleTab}>
          동네생활 글 더보기
        </Button>
      )}
    </Section>
  )
}

const Card = styled(PostPreviewSimplified)`
  padding: 1rem 0;
`
const Divider = styled.hr`
  height: 0.063rem;
  inline-size: 100%;
  margin: 0 auto;
  border: none;
  background-color: ${vars.$semantic.color.divider1};
  &:last-of-type {
    display: none;
  }
`
export default StorySection

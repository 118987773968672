import { BookmarkPoiIdDto } from '@daangn/local-business-network/lib/poi'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { CurationMapItem } from '@src/apis/generated/poi/models'
import { KakaoMarker, useKakaoMarkerImage } from '@src/components/Map/KakaoMap'
import { watchedByUserMapperSelector } from '@src/shared/watch/store'
import { placeWatchIdToString } from '@src/shared/watch/utils'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics, useSelectedPoi } from '../hooks'
import { selectedPoiIdAtomFamily } from '../state'

interface Props {
  poi: CurationMapItem
}
const Marker: React.FC<Props> = ({ poi }) => {
  const screenInstance = useScreenInstance()
  const { contents: watchContentsGlobal, state: watchStateGlobal } = useRecoilValueLoadable(watchedByUserMapperSelector)
  const selectedPoiId = useRecoilValue(selectedPoiIdAtomFamily(screenInstance))

  const logEvent = useAnalytics()
  const { markerImage } = useKakaoMarkerImage({ color: 'carrot' })
  const { spotlightPoiOnMap } = useSelectedPoi()

  const coordinates = poi?.poi?.coordinates

  const placeWatchId = {
    targetType: poi.poi.bizAccountId ? 'BA' : 'POI',
    targetId: poi.poi.bizAccountId ?? poi.poi.id,
  } as BookmarkPoiIdDto
  const isWatchedGlobal = !!watchContentsGlobal && watchContentsGlobal[placeWatchIdToString(placeWatchId)]
  const [isWatched, setIsWatched] = useState(isWatchedGlobal)

  interface Marker {
    image: any
    zIndex: number
  }
  const marker = useMemo<Marker>(() => {
    const isSelected = selectedPoiId === poi.poi.id

    if (isWatched)
      return isSelected ? { image: markerImage.selectedWatched, zIndex: 3 } : { image: markerImage.watched, zIndex: 2 }

    return isSelected ? { image: markerImage.selected, zIndex: 3 } : { image: markerImage.default, zIndex: 1 }
  }, [
    selectedPoiId,
    poi.poi.id,
    isWatched,
    markerImage.selectedWatched,
    markerImage.watched,
    markerImage.selected,
    markerImage.default,
  ])

  const handleClickMarker = useCallback(() => {
    spotlightPoiOnMap(poi.poi.id, true)

    const bizAccountId = poi?.poi.bizAccountId

    logEvent('click_map_pin', {
      poi_type: bizAccountId ? 'biz_account' : 'poi',
      poi_id: bizAccountId ?? poi.poi.id,
    })
  }, [logEvent, poi.poi.bizAccountId, poi.poi.id, spotlightPoiOnMap])

  useEffect(() => {
    if (watchStateGlobal !== 'hasValue' || isWatchedGlobal == isWatched) return
    setIsWatched(isWatchedGlobal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchedGlobal, watchStateGlobal])

  if (!poi || !coordinates) return null

  return (
    <KakaoMarker
      position={{
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      }}
      image={marker.image}
      onClick={handleClickMarker}
      zIndex={marker.zIndex}
    />
  )
}

export default React.memo(Marker)

import styled from '@emotion/styled'
import React, { useContext } from 'react'

import ToastContext from './context'
import Toast from './Toast'

function ToastList() {
  const { toasts } = useContext(ToastContext)

  return (
    <Container>
      {Object.keys(toasts)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .slice(0, 1)
        .map((id) => {
          const toast = toasts[id]

          return <Toast key={id} id={id} {...toast} />
        })}
    </Container>
  )
}

const Container = styled.div`
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: fixed;
  right: 8px;
  left: 8px;
  bottom: 8px;
  bottom: calc(8px + constant(safe-area-inset-bottom));
  bottom: calc(8px + env(safe-area-inset-bottom));
`

export default ToastList

export const DROPDOWN_FILTER_NAME_TO_TYPE_FILTER = {
  category: 'category',
  sort: 'list',
} as { [dropdownFilterName: string]: string }

export const SORT_OPTION_ID_TO_DETAIL_TYPE = {
  BA_POI_SCORE_DESC: 'recommend',
  BA_POI_CREATED_AT_DESC: 'most_recent',
  BA_POI_DISTANCE_ASC: 'distance',
} as { [sortOptionId: string]: string }

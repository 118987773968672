import { BookmarkDto } from '@daangn/local-business-network/lib/poi'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilValue, useRecoilValueLoadable } from 'recoil'

import { KakaoMarker, useKakaoMarkerImage } from '@src/components/Map/KakaoMap'
import { watchedByUserMapperSelector } from '@src/shared/watch/store'
import { placeWatchIdToString } from '@src/shared/watch/utils'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics } from '../hooks'
import { selectedWatchedPlaceSelectorFamily } from '../state/selectedWatchedPoi'

interface Props {
  place: BookmarkDto
  onClick: () => void
}
const Marker: React.FC<Props> = ({ place, onClick }) => {
  const { contents: watchContentsGlobal, state: watchStateGlobal } = useRecoilValueLoadable(watchedByUserMapperSelector)
  const screenInstance = useScreenInstance()
  const selectedPlace = useRecoilValue(selectedWatchedPlaceSelectorFamily(screenInstance))

  const logEvent = useAnalytics()
  const { markerImage } = useKakaoMarkerImage({ color: 'carrot' })

  const isWatchedGlobal = !!watchContentsGlobal && watchContentsGlobal[placeWatchIdToString(place)]
  const [isWatched, setIsWatched] = useState(isWatchedGlobal)

  interface Marker {
    image: any
    zIndex: number
  }
  const marker = useMemo<Marker>(() => {
    const isSelected = selectedPlace?.targetId === place.targetId

    if (isWatched)
      return isSelected ? { image: markerImage.selectedWatched, zIndex: 3 } : { image: markerImage.watched, zIndex: 2 }

    return isSelected ? { image: markerImage.selected, zIndex: 3 } : { image: markerImage.default, zIndex: 1 }
  }, [
    selectedPlace,
    place.targetId,
    isWatched,
    markerImage.selectedWatched,
    markerImage.watched,
    markerImage.selected,
    markerImage.default,
  ])

  const handleClickMarker = useCallback(() => {
    const isBiz = place.targetType === 'BA'

    logEvent('click_map_pin', {
      poi_type: isBiz ? 'biz_account' : 'poi',
      poi_id: place.targetId,
    })
    onClick()
  }, [logEvent, onClick, place])

  useEffect(() => {
    if (watchStateGlobal !== 'hasValue' || isWatchedGlobal == isWatched) return
    setIsWatched(isWatchedGlobal)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWatchedGlobal, watchStateGlobal])

  if (!place.target?.coords) return null

  const coordinates = place.target?.coords

  return (
    <KakaoMarker
      position={{
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
      }}
      image={marker.image}
      onClick={handleClickMarker}
      zIndex={marker.zIndex}
    />
  )
}

export default React.memo(Marker)

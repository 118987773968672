import React, { SVGProps } from 'react'

export const OfficialBadge = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.665 3.94876C9.32128 2.78585 10.5681 2 12 2C13.4318 2 14.6787 2.78582 15.335 3.94858C16.6213 3.59047 18.0586 3.91658 19.0709 4.92888C20.0834 5.94138 20.4094 7.37866 20.0512 8.665C21.2141 9.32128 22 10.5681 22 12C22 13.4318 21.2142 14.6787 20.0514 15.335C20.4095 16.6213 20.0834 18.0586 19.0711 19.0709C18.0586 20.0834 16.6213 20.4094 15.335 20.0512C14.6787 21.2141 13.4319 22 12 22C10.5682 22 9.32134 21.2142 8.66505 20.0514C7.37873 20.4095 5.94136 20.0834 4.92907 19.0711C3.91657 18.0586 3.59062 16.6213 3.94876 15.335C2.78586 14.6787 2 13.4319 2 12C2 10.5682 2.78582 9.32134 3.94858 8.66504C3.59047 7.37873 3.91658 5.94136 4.92888 4.92907C5.94138 3.91657 7.37865 3.59061 8.665 3.94876ZM15.6455 8.66136C15.2629 8.38552 14.7292 8.472 14.4534 8.85453L11.2182 13.341L9.48237 11.4542C9.16306 11.1072 8.62285 11.0847 8.27578 11.404C7.9287 11.7233 7.90619 12.2635 8.2255 12.6106L10.6701 15.2677C10.8438 15.4565 11.0932 15.5572 11.3493 15.542C11.6054 15.5267 11.8411 15.3971 11.9911 15.189L15.8386 9.85345C16.1145 9.47092 16.028 8.93721 15.6455 8.66136Z"
      />
    </svg>
  )
}

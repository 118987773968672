import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React from 'react'

type Size = 'small' | 'medium' | 'large'
type Type = 'basic' | 'primary' | 'success' | 'error'

interface Props {
  className?: string
  children: string
  size: Size
  type: Type
  outlined?: boolean
  bold?: boolean
}

const SquareBadge: React.FC<Props> = ({ className, size, type, bold = false, outlined = false, children }) => {
  return (
    <Container className={className} size={size} type={type} bold={bold} outlined={outlined}>
      {children}
    </Container>
  )
}

const Container = styled.div<{
  size: Size
  type: Type
  bold: boolean
  outlined: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  height: fit-content;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  ${({ size }) => styleSize(size)};
  ${({ type, outlined, bold }) => styleType(type, outlined, bold)};
`

const styleSize = (size: Size) => {
  switch (size) {
    case 'small':
      return sizeSmall
    default:
      return sizeSmall
  }
}

const sizeSmall = css`
  padding: 0.094rem 0.25rem;
  font-size: 0.625rem;
  line-height: 140%;
  border-radius: 2px;
`

const styleType = (type: Type, outlined: boolean, bold: boolean) => {
  switch (type) {
    case 'basic':
      return typeBasic(outlined, bold)
    case 'success':
      return typeSuccess(outlined, bold)
  }
}

const typeBasic = (outlined: boolean, bold: boolean) => {
  if (outlined) {
    return css`
      color: ${vars.$scale.color.gray900};
      border: 1px solid ${vars.$scale.color.gray900};
      background-color: transparent;
    `
  }
  if (bold) {
    return css`
      color: ${vars.$scale.color.gray00};
      background-color: ${vars.$scale.color.gray700};
    `
  }
  return css`
    color: ${vars.$scale.color.gray500};
    background-color: ${vars.$scale.color.gray100};
  `
}
const typeSuccess = (outlined: boolean, bold: boolean) => {
  if (outlined) {
    return css`
      color: ${vars.$semantic.color.success};
      border: 1px solid ${vars.$semantic.color.success};
      background-color: transparent;
    `
  }
  if (bold) {
    return css`
      color: ${vars.$static.color.staticWhite};
      background-color: ${vars.$semantic.color.success};
    `
  }
  return css`
    color: ${vars.$scale.color.green700};
    background-color: ${vars.$semantic.color.successLow};
  `
}
export default SquareBadge

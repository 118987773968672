import { isUndefined } from 'lodash'
import React, { useImperativeHandle, useLayoutEffect, useMemo, useRef } from 'react'
import ReactDOM from 'react-dom'

import { Coordinates } from './types'
import useEventListenerEffect from './useEventListenerEffect'
import { useMap } from './useMap'

interface Props {
  position: Coordinates
  clickable?: boolean
  zIndex?: number
  onClick?: () => void
}

const CustomOverlay = React.forwardRef<any, React.PropsWithChildren<Props>>(
  ({ children, position, clickable, zIndex, onClick }, ref) => {
    const { kakao, map } = useMap()

    const container = useRef(document.createElement('div'))

    const customOverlay = useMemo(() => {
      const kakaoCustomOverlay = new kakao.maps.CustomOverlay({
        content: container.current,
        clickable,
      })

      container.current.style.display = 'none'

      return kakaoCustomOverlay
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const customOverlayPosition = useMemo(() => {
      return new kakao.maps.LatLng(position.latitude, position.longitude)
    }, [kakao.maps.LatLng, position.latitude, position.longitude])

    useLayoutEffect(() => {
      if (!map) return

      customOverlay.setMap(map)

      return () => {
        customOverlay.setMap(null)
      }
    }, [customOverlay, map])

    useLayoutEffect(() => {
      if (!customOverlay || !map || !customOverlayPosition) return

      customOverlay.setPosition(customOverlayPosition)
    }, [customOverlay, map, customOverlayPosition])

    useLayoutEffect(() => {
      if (!customOverlay || !map || isUndefined(zIndex)) return

      customOverlay.setZIndex(zIndex)
    }, [customOverlay, map, zIndex])

    useEventListenerEffect(customOverlay, 'click', onClick)

    useImperativeHandle(ref, () => customOverlay, [customOverlay])

    return container.current.parentElement && ReactDOM.createPortal(children, container.current.parentElement)
  }
)

export default CustomOverlay

import styled from '@emotion/styled'
import React from 'react'

import { Poi } from '@src/apis/generated/poi/models'
import { karrotBridge } from '@src/bridge'
import { PERMALINK_BASE_URL } from '@src/constants/url'

import IconButton from './IconButton'
import NavbarMoreButton from './NavbarMoreButton'
import { useAnalytics } from '../hooks'

interface Props {
  poiData: Poi
  iconColor?: string
}

const NavbarButtons: React.FC<Props> = ({ poiData, iconColor }) => {
  const { publicId, name } = poiData

  const logEvent = useAnalytics()

  const handleClickShare = () => {
    /** @deprecated */
    logEvent('click_share_button', {
      area: 'navbar',
    })
    logEvent('click_share_profile_button', {
      area: 'navbar',
    })

    karrotBridge.share({
      share: {
        url: `${PERMALINK_BASE_URL}/kr/pois/${publicId}`,
        text: name,
      },
    })
  }

  return (
    <Container>
      {[
        {
          id: 'share',
          node: <IconButton type="share" activatedStatusColor={iconColor} onClick={handleClickShare} />,
        },
        {
          id: 'moreOptions',
          node: <NavbarMoreButton iconColor={iconColor} />,
        },
      ].map(({ id, node }) => (
        <React.Fragment key={id}>{node}</React.Fragment>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > :not(:first-of-type) {
    margin: 0 0 0 1rem;
  }
`

export default NavbarButtons

import { v4 } from 'uuid'

import { AnalyticsSessionIds } from '@src/components/Analytics'

const initialVisitSessionId = v4()
const initialQuerySessionId = v4()

export const analyticsSessionIds = new AnalyticsSessionIds({
  initialVisitSessionId,
  initialQuerySessionId,
})

import styled from '@emotion/styled'
import React, { Suspense, useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { Skeleton } from '@src/components/Loading'
import { FilterButton } from '@src/components/Map/FilterButton'
import useScrollElementIntoView from '@src/hooks/useScrollElementIntoView'
import { Stack } from '@src/styles/Stack'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics, useFilter } from '../hooks'
import {
  mapThemeByCurationCategoryIdSelector,
  defaultFilterId,
  mapThemeAtomFamily,
  selectedFilterIdAtomFamily,
  selectedPoiIdAtomFamily,
} from '../state'

const FilterButtons = () => {
  const logEvent = useAnalytics()
  const { unselectFilter, toggleFilter } = useFilter()
  const screenInstance = useScreenInstance()

  const selectedFilterId = useRecoilValue(selectedFilterIdAtomFamily(screenInstance))
  const setSelectedPoiId = useSetRecoilState(selectedPoiIdAtomFamily(screenInstance))
  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const filterList = useRecoilValue(mapThemeByCurationCategoryIdSelector(mapTheme.id))?.attributes

  const scrollRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<{ [id: string]: HTMLElement }>({})

  const scrollIntoView = useScrollElementIntoView()

  const handleClickFilter = (filterId: number) => {
    // '전체' 필터 클릭
    if (filterId === defaultFilterId) {
      logEvent('click_select_filter_button', {
        filter_id: filterId,
      })

      unselectFilter()

      return
    }

    const handleSelectFilter = () => {
      logEvent('click_select_filter_button', {
        filter_id: filterId,
      })
    }

    const handleUnselectFilter = () => {
      logEvent('click_unselect_filter_button', {
        filter_id: filterId,
      })
    }

    setSelectedPoiId(null)

    toggleFilter({
      filterId: filterId,
      onSelect: handleSelectFilter,
      onUnselect: handleUnselectFilter,
    })
  }

  useEffect(() => {
    if (scrollRef.current && targetRef.current[selectedFilterId ?? defaultFilterId]) {
      scrollIntoView(scrollRef.current, targetRef.current[selectedFilterId ?? defaultFilterId], 'horizontal', {
        paddingMarginStart: 40,
        paddingMarginEnd: 40,
      })
    }
  }, [scrollIntoView, selectedFilterId, mapTheme.id])

  return (
    <StyledStack gutter={6} alignItems="center" ref={scrollRef}>
      <FilterButton
        ref={(el) => !!el && !!targetRef.current && (targetRef.current[defaultFilterId] = el)}
        key={defaultFilterId}
        isSelected={selectedFilterId === defaultFilterId}
        onClick={() => handleClickFilter(defaultFilterId)}>
        전체
      </FilterButton>
      {filterList?.map(({ id, name }) => {
        return (
          <FilterButton
            ref={(el) => !!el && !!targetRef.current && (targetRef.current[id] = el)}
            key={id}
            isSelected={id === selectedFilterId}
            onClick={() => handleClickFilter(id)}>
            {name}
          </FilterButton>
        )
      })}
      <div className="endPadding" />
    </StyledStack>
  )
}

function Loading() {
  return (
    <StyledStack gutter={6} alignItems="center">
      <SkeletonButton width={70} height={30} />
      <SkeletonButton width={70} height={30} />
      <SkeletonButton width={70} height={30} />
    </StyledStack>
  )
}

export default function FilterButtonsWithAsyncBoundary() {
  return (
    <Suspense fallback={<Loading />}>
      <FilterButtons />
    </Suspense>
  )
}

const StyledStack = styled(Stack)`
  flex: none;
  position: relative;
  display: flex;
  padding: 12px 24px 12px 12px;
  align-items: center;
  overflow-x: scroll;
  width: 100%;

  .endPadding {
    height: 100%;
    min-width: 12px;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const SkeletonButton = styled(Skeleton)`
  border-radius: 18px;
`

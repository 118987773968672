import styled from '@emotion/styled'
import React from 'react'

import TimeInput from '../TimeInput'

interface Props {
  businessHours: [string | null, string | null]
  onChangeFrom: (value: string) => void
  onChangeTo: (value: string) => void
}

const BusinessHoursInput: React.FC<Props> = ({ businessHours: [from, to], onChangeFrom, onChangeTo }) => {
  return (
    <StyledBusinessHoursInput>
      <div className="from">
        <TimeInput id="businessHoursFrom" placeholder="시작 시간" value={from ?? ''} onChange={onChangeFrom} />
      </div>
      <div className="from-to">~</div>
      <div className="from">
        <TimeInput id="businessHoursTo" placeholder="종료 시간" value={to ?? ''} onChange={onChangeTo} />
      </div>
    </StyledBusinessHoursInput>
  )
}

const StyledBusinessHoursInput = styled.div`
  display: flex;
  align-items: center;
  .from,
  .to {
    width: calc(50% - 17px);
  }
  .from-to {
    text-align: center;
    width: 34px;
  }
`

export default BusinessHoursInput

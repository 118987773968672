import styled from '@emotion/styled'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import poiPinSelected from '@src/assets/images/ic_pin_selected.svg'
import { bridge } from '@src/bridge'
import { Button } from '@src/components/Button'
import { Skeleton } from '@src/components/Loading'
import Map from '@src/components/Map/Map'
import type { GPS } from '@src/types/global'

import { useAnalytics } from '../../hooks'

interface Props {
  address: string
  coordinates: GPS
}

// Component
export const Direction = ({ address, coordinates }: Props) => {
  const logEvent = useAnalytics()

  const handleMapClick = () => {
    /** @deprecated */
    logEvent('click_map')
    logEvent('click_profile_map')

    if (!coordinates) return
    const params = encodeURIComponent(
      `${address.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '')},${coordinates[0]},${coordinates[1]}`
    )
    const url = `https://map.daum.net/link/map/${params}`
    bridge.router.push({ remote: url, navbar: true })
  }

  const handleMapLoaded = (kakao: any, map: any) => {
    // 카카오 api 버그 대응
    if (!kakao.maps.LatLng) return

    const targetPoint = new kakao.maps.LatLng(...coordinates)

    const imageSize = new kakao.maps.Size(23.8, 34.3)
    const imageOption = { offset: new kakao.maps.Point(10, 28) }
    const markerImage = new kakao.maps.MarkerImage(poiPinSelected, imageSize, imageOption)

    const marker = new kakao.maps.Marker({
      position: targetPoint,
      image: markerImage,
    })

    marker.setMap(map)

    map.setZoomable(false)
    map.setDraggable(false)

    setTimeout(() => {
      map.relayout()
      const kakaoLatLng = new kakao.maps.LatLng(...coordinates)
      map.setCenter(kakaoLatLng)
    }, 500)
  }

  return window.kakao ? (
    <ErrorBoundary fallback={<FallbackButton onClick={handleMapClick} />}>
      <StyledDirections>
        <div className="map" onClick={handleMapClick}>
          {coordinates && <Map onMapLoad={handleMapLoaded} center={coordinates} />}
          <div className="overlay" />
        </div>
      </StyledDirections>
    </ErrorBoundary>
  ) : null
}

export const DirectionLoading = () => {
  return <Skeleton height={120} />
}

const FallbackButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button
      onClick={onClick}
      styleTheme={'secondary-outlined'}
      css={{
        width: '100%',
        marginBottom: '0.75rem',
      }}>
      지도에서 위치보기
    </Button>
  )
}

const StyledDirections = styled.div`
  box-sizing: border-box;
  margin: 0 0 16px 0;

  .map {
    position: relative;
    height: 120px;
    border-radius: 6px;
    overflow: hidden;
    isolation: isolate;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
      /** @todo: seed-design */
      border: 1px solid rgba(0, 0, 0, 0.07);
      border-radius: 6px;
    }
  }
`

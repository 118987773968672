import IconSearchRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconSearchRegular'
import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { TextInput } from '@src/components/Input'
import { useNavigator } from '@src/stackflow'
import { CategoryInputState, selectedCategoryAtom } from '@src/widgets/CategoryInput/state'

interface Props {
  onChangeCategory: (category: CategoryInputState | null) => void
}

const CategoryInput: React.FC<Props> = ({ onChangeCategory }) => {
  const { push } = useNavigator()

  const [selectedCategory, setSelectedCategory] = useRecoilState(selectedCategoryAtom)

  const handleClearInputValue = () => {
    setSelectedCategory(null)
    onChangeCategory(null)
  }

  const handleClickInput = () => {
    push('search_category', {})
  }

  useEffect(() => {
    if (!selectedCategory) return

    onChangeCategory({ ...selectedCategory })
  }, [selectedCategory, onChangeCategory])

  useEffect(() => {
    return () => {
      setSelectedCategory(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextInput
      type="text"
      id="categoryName"
      name="categoryName"
      width="100%"
      value={selectedCategory?.name ?? ''}
      placeholder="카테고리를 검색해주세요."
      readOnly
      onClick={handleClickInput}
      onClearInputValue={handleClearInputValue}
      appendRight={<IconSearchRegular width={22} height={22} />}
    />
  )
}

export default CategoryInput

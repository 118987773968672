import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { useLogEvent } from '@src/components/Analytics'

const useAnalytics = () => {
  const logEvent = useLogEvent()
  const { poiId, story_article_id, story_comment_id } = useActivityParams<{
    poiId: string
    story_article_id?: string
    story_comment_id?: string
  }>()

  return useCallback(
    (name: string, params?: { [key: string]: any }) => {
      const _params = {
        poi_id: poiId,
        story_article_id: story_article_id ?? null,
        story_comment_id: story_comment_id ?? null,
        ...params,
      }

      logEvent({ name, params: _params })
    },
    [logEvent, poiId, story_article_id, story_comment_id]
  )
}

export default useAnalytics

import { atomFamily, selectorFamily } from 'recoil'

import { POIPoisApi } from '@src/apis/generated/poi/api'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

import { detailPagePrefix } from '../constants/recoilKey'

const prefix = `${detailPagePrefix}/image`
const generateKey = recoilKeyGenerator(prefix)

/**
 * Global scope
 * Global scoped store shares cache globally.
 * Given the same key or variable or dependent state, the same value will be returned.
 */
export const imagesByPoiIdPaginationFamily = atomFamily({
  key: generateKey(['pagination', 'family']),
  default: {
    page: 1,
    perPage: 20,
  },
})

export const imagesByPoiIdHasNextPageFamily = atomFamily({
  key: generateKey(['hasNextPage', 'family']),
  default: true,
})

export const imagesByPoiIdSelectorFamily = selectorFamily({
  key: generateKey('images'),
  get:
    (id: string) =>
    async ({ get }) => {
      if (id === '') {
        return { items: [], hasNextPage: false, total: 0 }
      }

      const { page, perPage } = get(imagesByPoiIdPaginationFamily(id))

      // CHECK WITH SERVER: check if page used, if not add pagination, response as void asked qoo for help
      const response = await POIPoisApi.getPoiImagesV2(id, page, perPage)

      return response.data
    },
})

import { decode } from '@msgpack/msgpack'
import { captureException } from '@sentry/react'
import { useActivityParams } from '@stackflow/react'
import { useCallback } from 'react'

import { ensureArray } from '@src/utils/array'

const PREDICTION_PROBABILITY_THRESHOLD = 0.6

type TagInfo = {
  is_tag_suggested: boolean
  poi_name: string
  probability: number
}

export const useKeywords = () => {
  const { keywords, tagInfo, predictedKeyword } = useActivityParams<{
    keywords?: string
    tagInfo?: string
    predictedKeyword?: string
  }>()

  const getKeywordFromTagInfo = () => {
    try {
      if (!tagInfo) return

      const tagInfoMsgpack = atob(decodeURIComponent(tagInfo).replace(/_/g, '/').replace(/-/g, '+'))

      const buf = Uint8Array.from(Array.from(tagInfoMsgpack).map((c) => c.charCodeAt(0)))
      const tagInfoObj = decode(buf) as TagInfo | null | undefined

      // 태깅 확률이 threshold 이상인 경우에만 추천 키워드로 보여준다.
      if (!!tagInfoObj && tagInfoObj.probability >= PREDICTION_PROBABILITY_THRESHOLD) {
        return tagInfoObj.poi_name
      }
    } catch (error) {
      captureException(error)
    }
  }

  const _keyword = predictedKeyword || getKeywordFromTagInfo()
  const _keywords = (decodeURIComponent(keywords ?? '').split(',') ?? []).concat(_keyword ?? '').filter(Boolean)

  const hasKeyword = useCallback(
    (text: string) => {
      return _keywords.includes(text)
    },
    [_keywords]
  )

  return {
    keywords: ensureArray(_keywords),
    hasKeyword,
  }
}

import styled from '@emotion/styled'
import React, { useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { Loading } from '@src/components/Loading'
import { withSuspense } from '@src/components/Suspense'

import {
  categoriesBySearchTextResponseSelector,
  categoriesBySearchTextSelector,
  paginationSelector,
  useSyncCategoriesBySearchText,
} from './state'

const LoadMoreCategories = () => {
  useSyncCategoriesBySearchText()

  useRecoilValue(categoriesBySearchTextResponseSelector)
  const [pagination, setPagination] = useRecoilState(paginationSelector)
  const { hasNextPage } = useRecoilValue(categoriesBySearchTextSelector)

  const endOfPageRef = useRef<HTMLDivElement>(null)

  const handleLoadMoreCategories = () => {
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    })
  }

  useEffect(() => {
    // Fetch more reviews on scroll to bottom
    let observer: IntersectionObserver

    if (endOfPageRef.current) {
      observer = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          isIntersecting && handleLoadMoreCategories()
        },
        { threshold: 0.3 }
      )
      observer.observe(endOfPageRef.current!)

      if (!hasNextPage) {
        observer && observer.disconnect()
      }
    }

    return () => observer && observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, pagination])

  return <ObservingDiv ref={endOfPageRef} />
}

const ObservingDiv = styled.div`
  position: relative;
  height: 30px;
  width: 100%;
`

export default withSuspense(LoadMoreCategories, Loading)

import { atomFamily, selectorFamily } from 'recoil'

import { POIProfilesApi } from '@src/apis/generated/poi/api'
import recoilKeyGenerator from '@src/store/recoilKeyGenerator'

const subprofileKey = recoilKeyGenerator('subprofile')

export const subprofileQueryByUserId = selectorFamily({
  key: subprofileKey('queryByUserId'),
  get: (userId: string) => async () => {
    if (!userId) return null

    const {
      data: { data: profile },
    } = await POIProfilesApi.getProfileV2(userId)

    return profile
  },
})

export const subprofileUserIdAtomFamily = atomFamily({
  key: subprofileKey('userId'),
  default: '',
})

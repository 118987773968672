import { useActivityParams } from '@stackflow/react'
import React from 'react'

import { AlertDialog } from '@src/components/AlertDialog'
import { APP_SCHEME, WEBVIEW_BASE_URL } from '@src/constants/environmentConstants'
import { useNavigator } from '@src/stackflow'
import { checkIsWithBatchim } from '@src/utils/checkIsWithBatchim'

import { useAnalytics } from '../hooks'
import { ModalActivityParams } from '../PoiDetailModal'

interface Props {
  poiName: string
  onConfirm?: () => void
  onClose?: () => void
}
const ClaimOwnershipDialog: React.FC<Props> = ({ poiName, onClose, onConfirm }) => {
  const logEvent = useAnalytics()
  const { pop } = useNavigator()
  const { poiId } = useActivityParams<ModalActivityParams>()

  const handleClose = () => {
    logEvent('click_cancel_claim_ownership_button')
    onClose?.()
    pop()
  }

  const handleConfirm = () => {
    logEvent('click_confirm_claim_ownership_button')
    onConfirm?.()
    pop()
    window.location.href = `${APP_SCHEME}minikarrot/router?app=${WEBVIEW_BASE_URL}business-platform/home&path=/onboarding?poiId=${poiId}`
  }

  return (
    <AlertDialog
      header={`'${poiName}' 사장님이신가요?`}
      contents={`'${poiName}'${
        checkIsWithBatchim(poiName) ? '을' : '를'
      } 비즈프로필로 전환할 수 있어요. 필요한정보를 입력하고 고객 관리를 시작해보세요!`}
      CTAButton={{
        label: '비즈프로필로 전환하기',
        onClick: handleConfirm,
      }}
      subButton={{
        label: '취소',
        onClick: handleClose,
      }}
      buttonAlign="column"
      onOutsideClick={handleClose}
    />
  )
}

export default ClaimOwnershipDialog

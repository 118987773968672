import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { ReactNode, useCallback } from 'react'

import WholeBodyDaangn from '@src/assets/images/img_detail_view_empty.png'
import { bridge } from '@src/bridge'

export interface ErrorPageProps {
  /**
   * messages 보다 우선순위 높음.
   */
  children?: ReactNode
  /**
   * 메시지 리스트(줄바꿈 됌)
   */
  messages?: string[]
  /**
   * emotion css override
   */
  buttonMessage?: ReactNode
  /**
   * 액션을 취하기 위한 버튼을 보여주는 여부
   */
  showButton?: boolean
  onClickBackButton?: () => void
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  messages,
  children,
  onClickBackButton,
  buttonMessage,
  showButton = true,
}) => {
  const defaultHandleOnclickBackButton = useCallback(() => {
    bridge.router.close()
  }, [])

  return (
    <Base>
      <Contents>
        <IconWrapper>
          <ErrorImage src={WholeBodyDaangn} />
        </IconWrapper>
        <Title>
          {children ||
            messages?.map((message, idx) => (
              <React.Fragment key={message}>
                {message}
                {messages.length - 1 > idx && <br />}
              </React.Fragment>
            ))}
        </Title>
        {showButton && (
          <BackButtonWrapper>
            <BackBtn onClick={onClickBackButton || defaultHandleOnclickBackButton}>{buttonMessage || '닫기'}</BackBtn>
          </BackButtonWrapper>
        )}
      </Contents>
    </Base>
  )
}

const Base = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: ${vars.$semantic.color.paperDefault};
  padding: 0 0 1.1875rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
`

const Contents = styled.div`
  flex: 1;
  width: 100%;
`

const Title = styled.p`
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.02em;
  color: ${vars.$scale.color.gray600};
  text-align: center;
  margin: 1.5rem 0 1rem;
`
const BackBtn = styled.div`
  width: 10rem;
  min-height: 2.5rem;
  background-color: ${vars.$semantic.color.paperDefault};
  border: 1px solid ${vars.$scale.color.gray300};
  border-radius: 0.3125rem;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: -0.02em;
  color: ${vars.$scale.color.gray900};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`
const BackButtonWrapper = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0 1rem 0;
`
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const ErrorImage = styled.img`
  height: 12.5625rem;
`

export default ErrorPage

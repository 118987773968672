import { IS_PROD } from '@src/constants/environmentConstants'

export const CONTENT_CURATOR_API_KEY_TO_THEME_ID_MAPPER = IS_PROD
  ? {
      '638d44cbb06ed63a8692dc76': '106',
      '638d45bfb06ed63a8692dc78': '118',
      '6389b4005bcd9c97afdd040a': '123',
      '638d697cb06ed63a8692dc7a': '130',
      '6389c4c95bcd9c97afdd040c': '140',
      '638d3fc8b06ed63a8692dc72': '156',
      '638d4185b06ed63a8692dc74': '175',
      '638d76e4b06ed63a8692dc80': '179',
      '638d77aab06ed63a8692dc82': '189',
      '638d78f3b06ed63a8692dc84': '192',
      '638d70f7b06ed63a8692dc7e': '166',
      '638d6f8db06ed63a8692dc7c': '159',
    }
  : ({
      '638d44cbb06ed63a8692dc76': '107',
      '638d45bfb06ed63a8692dc78': '108',
      '6389b4005bcd9c97afdd040a': '109',
      '6389c4c95bcd9c97afdd040c': '111',
      '638d697cb06ed63a8692dc7a': '132',
      '638d3fc8b06ed63a8692dc72': '150',
      '638d6f8db06ed63a8692dc7c': '153',
      '638d70f7b06ed63a8692dc7e': '160',
      '638d4185b06ed63a8692dc74': '168',
      '638d76e4b06ed63a8692dc80': '172',
      '638d77aab06ed63a8692dc82': '185',
      '638d78f3b06ed63a8692dc84': '186',
    } as { [key: string]: string })

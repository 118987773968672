import styled from '@emotion/styled'
import IconListThin from '@karrotmarket/karrot-ui-icon/lib/react/IconListThin'
import IconLocationRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconLocationRegular'
import { vars } from '@seed-design/design-token'
import React from 'react'

import type { ViewType } from '@src/pages/Map/types'

const viewModeElement = {
  MAP: (
    <>
      <IconListThin className="icon" />
      목록보기
    </>
  ),
  LIST: (
    <>
      <IconLocationRegular className="icon" />
      지도보기
    </>
  ),
}

interface Props {
  viewType: ViewType
  bottomSpacing: number
  cardMargin?: number
  onClick: () => void
}

function ViewTypeToggleButton({ bottomSpacing, viewType, cardMargin = 16, onClick }: Props) {
  return (
    <Container bottomSpacing={bottomSpacing} cardMargin={cardMargin}>
      <StyledButton viewType={viewType} onClick={onClick}>
        {!window.kakao ? viewModeElement['LIST'] : viewType && viewModeElement[viewType]}
      </StyledButton>
    </Container>
  )
}

export const VIEW_BUTTON_HEIGHT = 32

const Container = styled.div<Pick<Props, 'bottomSpacing' | 'cardMargin'>>`
  z-index: 99;
  position: fixed;
  left: 50%;
  transform: translate3d(-50%, -${({ bottomSpacing }) => bottomSpacing}px, 0);
  bottom: ${({ cardMargin }) => cardMargin}px;
  bottom: calc(${({ cardMargin }) => cardMargin}px + env(safe-area-inset-bottom));
  bottom: calc(${({ cardMargin }) => cardMargin}px + constant(safe-area-inset-bottom));

  transition: transform 200ms;

  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button<Pick<Props, 'viewType'>>`
  ${vars.$semantic.typography.caption1Regular};

  width: 105px;
  height: ${VIEW_BUTTON_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;

  border: 1px solid
    ${({ viewType }) => (viewType === 'LIST' ? vars.$scale.color.gray700 : vars.$semantic.color.divider2)};
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: ${({ viewType }) =>
    viewType === 'LIST' ? vars.$scale.color.gray700 : vars.$semantic.color.paperDefault};
  color: ${({ viewType }) => (viewType === 'LIST' ? vars.$scale.color.gray00 : vars.$scale.color.gray900)};
  box-sizing: border-box;
  border-radius: ${VIEW_BUTTON_HEIGHT / 2}px;

  .icon {
    width: 18px;
    height: 18px;
    margin: 0 6px 0 0;
    color: ${({ viewType }) => (viewType === 'LIST' ? vars.$scale.color.gray00 : vars.$scale.color.gray900)};
  }
`

export default React.memo(ViewTypeToggleButton)

import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { useEffect, useRef } from 'react'
import { useRecoilCallback, useRecoilValue } from 'recoil'

import { PoiEventDto } from '@src/apis/generated/poi/models'
import { Loading } from '@src/components/Loading'
import { withSuspense } from '@src/components/Suspense'
import useScrollElementIntoView from '@src/hooks/useScrollElementIntoView'
import { useScreenInstance } from '@src/widgets/screenInstance'

import { useAnalytics, useFilter, useFetchPois } from '../hooks'
import {
  centerPositionAtomFamily,
  curationCategoriesSelector,
  isInitialFetchAtomFamily,
  isMapBoundChangedAtomFamily,
  mapThemeAtomFamily,
} from '../state'

export const THEME_BUTTONS_HEIGHT = 40

interface Props {
  className?: string
}

const CurationCategoryButtons: React.FC<Props> = (props) => {
  const screenInstance = useScreenInstance()
  const { unselectFilter } = useFilter()
  const logEvent = useAnalytics()
  const { fetchPois } = useFetchPois()

  const scrollIntoView = useScrollElementIntoView()

  const mapTheme = useRecoilValue(mapThemeAtomFamily(screenInstance))
  const curationCategories = useRecoilValue(curationCategoriesSelector)
  const centerPosition = useRecoilValue(centerPositionAtomFamily(screenInstance))

  const scrollRef = useRef<HTMLDivElement>(null)
  const targetRef = useRef<{ [id: string]: HTMLElement }>({})

  const createHandleChangeMapTheme = useRecoilCallback(
    ({ reset }) =>
      (clickedTheme: PoiEventDto) => {
        if (mapTheme.id !== clickedTheme.id) {
          logEvent('click_select_category_button', {
            category_id: clickedTheme.id,
          })

          fetchPois({
            bounds: null,
            centerPosition: centerPosition ?? 'reset',
            mapTheme: clickedTheme,
          })

          reset(isInitialFetchAtomFamily(screenInstance))
          reset(isMapBoundChangedAtomFamily(screenInstance))
          unselectFilter()
        }
      },
    [mapTheme.id, logEvent, screenInstance, unselectFilter, fetchPois, centerPosition]
  )

  useEffect(() => {
    if (mapTheme.id === -1) return

    if (scrollRef.current && targetRef.current[mapTheme.id]) {
      scrollIntoView(scrollRef.current, targetRef.current[mapTheme.id], 'horizontal', {
        paddingMarginStart: 40,
        paddingMarginEnd: 40,
      })
    }
  }, [mapTheme.id, scrollIntoView])

  return (
    <Container
      id="mapThemeBtn"
      ref={scrollRef}
      onClick={() => {
        unselectFilter()
      }}
      {...props}>
      <div className="categories">
        <div className="divider" />
        {curationCategories.map((curationCategory) => {
          const { id, icon, name } = curationCategory

          return (
            <ThemeButton
              key={id}
              isSelected={mapTheme.id === id}
              ref={(el) => !!el && !!targetRef.current && (targetRef.current[id] = el)}
              onClick={() => createHandleChangeMapTheme(curationCategory)}>
              <div className="align">
                <img src={icon?.thumbnail || ''} alt="name" />
                {name}
              </div>
            </ThemeButton>
          )
        })}
      </div>
    </Container>
  )
}

const Container = styled.div`
  flex: none;
  white-space: nowrap;
  overflow: scroll;
  width: 100%;
  .categories {
    position: relative;
    width: fit-content;
    padding: 0 12px;
  }

  .divider {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${vars.$semantic.color.divider1};
  }

  ::-webkit-scrollbar {
    display: none;
  }
`

const ThemeButton = styled.div<{ isSelected: boolean }>`
  display: inline-block;
  margin: 0 24px -1px 0;
  &:last-of-type {
    margin: 0;
  }
  .align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    border-top: solid 2px transparent;
    border-bottom: solid 2px ${({ isSelected }) => (isSelected ? `${vars.$scale.color.gray900}` : 'transparent')};
    color: ${({ isSelected }) => (isSelected ? vars.$scale.color.gray900 : vars.$scale.color.gray600)};
  }
  img {
    width: 18px;
    margin: 0 2px 0 0;
  }
`

export default withSuspense(CurationCategoryButtons, Loading)

import { Modal, ModalProps } from '@daangn/karrot-clothes'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import React, { MouseEvent, ReactNode } from 'react'

interface Sheet {
  name: ReactNode
  warning?: boolean
  onClick?: (e: MouseEvent<HTMLLIElement>) => void
}

export interface BottomSheetProps<T = Sheet> extends Omit<ModalProps, 'isCenterMode' | 'children'> {
  /**
   * sheet 리스트
   */
  sheets: T[]
}

function BottomSheet<SheetType extends Sheet>({ sheets, isOpen, onClose, ...modalProps }: BottomSheetProps<SheetType>) {
  return (
    <StyledModal
      {...modalProps}
      isOpen={isOpen}
      isCenterMode={false}
      zIndex={9999}
      onClose={onClose}
      bodyClassName={`modalBodyOverrideStyle ${modalProps.bodyClassName}`}>
      {({ duration, outro }) => (
        <List
          className={`bottomSheetSelectionList${outro ? ' outro' : ''}`}
          style={{
            animationDuration: `${duration}ms`,
          }}>
          {sheets.map(({ name, warning, onClick }, idx) => (
            <li key={idx} onClick={onClick} className={`bottomSheetSelectionItem ${warning ? 'warning' : ''}`}>
              {name}
            </li>
          ))}
        </List>
      )}
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
`

const List = styled.ul`
  * {
    box-sizing: border-box;
  }

  @keyframes fadeUp {
    from {
      transform: translate3d(0, 1.5rem, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeDown {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(0, 1.5rem, 0);
    }
  }
  position: absolute;
  bottom: -100vh;
  background: ${vars.$semantic.color.paperSheet};
  margin: 0;
  padding: 0;
  padding: 0 0 constant(safe-area-inset-bottom);
  padding: 0 0 env(safe-area-inset-bottom);
  width: 100vw;
  list-style: none;
  animation: fadeUp 300ms;
  &.outro {
    animation: fadeDown 300ms;
  }
  .bottomSheetSelectionItem {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 3.5rem;
    border-bottom: 1px solid ${vars.$semantic.color.divider1};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: -0.02em;
    cursor: pointer;
    transition: background-color 200ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: ${vars.$scale.color.gray900};
  }
  .bottomSheetSelectionItem:last-child {
    border: none;
  }
  .bottomSheetSelectionItem:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .bottomSheetSelectionItem.warning {
    color: ${vars.$semantic.color.danger};
  }
`

export default BottomSheet

import { WinterSnackMapItem } from '@daangn/local-business-network/lib/poi'
import styled from '@emotion/styled'
import { vars } from '@seed-design/design-token'
import debounce from 'lodash/debounce'
import React, { useEffect, useRef, useState } from 'react'

const MORE_TAGS_TEXT_WIDTH = 42
const SIDE_PADDING = 16
const SCREEN_CARD_SIDE_PADDING = 16

interface Props {
  attributes: WinterSnackMapItem['poi']['attributes']
}
export const Menu = ({ attributes }: Props) => {
  const INITIAL_TAGS_END_PIXEL = `calc(100vw - ${
    SIDE_PADDING * 2 + MORE_TAGS_TEXT_WIDTH + SCREEN_CARD_SIDE_PADDING * 2
  }px)`

  const [tagsInLine, setTagsInline] = useState(0)

  const tagsRef = useRef<HTMLDivElement>(null)
  const tagsForComputationRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const computeTagStyle = () => {
      if (!tagsForComputationRef.current) return
      const tagElements = tagsForComputationRef.current.children
      const tagsInLine =
        attributes.length === 0
          ? 0
          : attributes.reduce((tagsInLine, attribute, index) => {
              const currentElement = tagElements[index] as HTMLSpanElement
              if (
                !tagsForComputationRef.current ||
                Math.abs(currentElement.offsetTop - tagsForComputationRef.current.offsetTop) > 5
              ) {
                return tagsInLine
              }
              return index + 1
            }, 0)

      const endOfLineTag = tagElements[tagsInLine - 1] as HTMLSpanElement

      requestAnimationFrame(() => {
        if (!tagsRef.current) return

        const computedWidth = endOfLineTag
          ? `${endOfLineTag.offsetLeft + endOfLineTag.offsetWidth + 3}px`
          : INITIAL_TAGS_END_PIXEL

        tagsRef.current.style.width = computedWidth
      })

      setTagsInline(tagsInLine)
    }

    const currenTagsDiv = tagsRef.current
    if (!currenTagsDiv) return

    computeTagStyle()

    const debounced = debounce(computeTagStyle, 600)
    window.addEventListener('resize', debounced)
    return () => {
      window.removeEventListener('resize', debounced)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributes])
  return (
    <Text tagEndPixel={INITIAL_TAGS_END_PIXEL} hasTags={!!attributes}>
      <div className="tags-container" ref={tagsRef}>
        <div className="tags">
          {attributes.map(({ id, name }, index) => {
            const hasMore = !tagsInLine || index <= tagsInLine - 2

            return (
              <span key={id} className="tag">
                {name + (hasMore ? ', ' : '')}
              </span>
            )
          })}
        </div>
        <div className="tags-for-computation" ref={tagsForComputationRef}>
          {attributes.map(({ id, name }, index) => {
            const hasMore = !tagsInLine || index <= tagsInLine - 2

            return (
              <span key={id} className="tag">
                {name + (hasMore ? ', ' : '')}
              </span>
            )
          })}
        </div>
      </div>
      {attributes.length !== tagsInLine ? <div className="tags_plus">외 {attributes.length - tagsInLine}개</div> : ''}
    </Text>
  )
}

const Text = styled.div<{ hasTags: boolean; tagEndPixel: string }>`
  position: relative;
  margin: 0 0 1rem;
  display: flex;
  color: ${({ hasTags }) => (hasTags ? vars.$scale.color.gray700 : vars.$scale.color.gray500)};
  ${vars.$semantic.typography.bodyL2Regular};

  .tags-container {
    position: relative;
    width: ${({ tagEndPixel }) => tagEndPixel};
    height: 20px;
    max-height: 20px;
    overflow: hidden;
    white-space: normal;
  }

  .tags,
  .tags-for-computation {
    display: inline-block;
    height: 20px;
    overflow: hidden;
    white-space: normal;
  }

  .tags {
    flex: 1;
    width: 100%;
  }

  .tags-for-computation {
    width: ${({ tagEndPixel }) => tagEndPixel};
    visibility: hidden;
  }
  .tag {
    display: inline-block;
    margin: 0 0 0 2px;
    color: ${vars.$scale.color.gray700};
  }
  .tags_plus {
    display: inline-block;
    min-width: ${MORE_TAGS_TEXT_WIDTH}px;
    width: ${MORE_TAGS_TEXT_WIDTH}px;
    color: ${vars.$scale.color.gray700};
  }
`

import IconCloseRegular from '@karrotmarket/karrot-ui-icon/lib/react/IconCloseRegular'
import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { bridgeInfoUserAtom } from '@src/store/bridgeInfo'
import { localStorage } from '@src/utils/localStorage'

import { Container } from './Nudge'
import { NudgeType } from './state'

interface Props extends NudgeType {
  style?: React.CSSProperties
  uniqueKey: string
}

export const Tooltip = forwardRef<HTMLDivElement, Props>(
  (
    { uniqueKey, text, arrowDirection, isShowCloseButton = true, duration, onRemove, zIndex, className, ...props },
    ref
  ) => {
    const userInfo = useRecoilValue(bridgeInfoUserAtom)

    const [showTooltip, setShowTooltip] = useState(false)

    const removeTooltip = useCallback(() => {
      setShowTooltip(false)
      localStorage.setItem(uniqueKey, 'true')
      onRemove?.()
    }, [onRemove, uniqueKey])

    useEffect(() => {
      if (!userInfo?.id) return
      ;(async () => {
        const isSeen = await localStorage.getItem(uniqueKey)

        if (isSeen === 'true') return

        setShowTooltip(true)

        duration &&
          setTimeout(() => {
            removeTooltip()
          }, duration)
      })()
    }, [duration, onRemove, removeTooltip, uniqueKey, userInfo?.id])

    return showTooltip ? (
      <Container
        role="tooltip"
        ref={ref}
        className={className}
        style={props.style}
        arrowDirection={arrowDirection}
        duration={duration}
        zIndex={zIndex}>
        {text}
        {isShowCloseButton && <IconCloseRegular onClick={removeTooltip} className="icon" />}
      </Container>
    ) : null
  }
)

import { FallbackScreen } from '@src/components/Error/FallbackScreen'
import CreateSuggestionPage from '@src/pages/CreateSuggestion'
import { DeleteSuggestionPage } from '@src/pages/DeleteSuggestion'
import { MapPage } from '@src/pages/Map'
import { MapModal } from '@src/pages/Map/MapModal'
import { PinAddressPage } from '@src/pages/PinAddress'
import { PoiDetailPage, PoiDetailModal } from '@src/pages/PoiDetail'
import { PurposefulCategoryPage } from '@src/pages/PurposefulCategory'
import OptionsDrawer from '@src/pages/PurposefulCategory/OptionsDrawer'
import { MapReportPage } from '@src/pages/Report'
import SearchAddressPage from '@src/pages/SearchAddress'
import SearchCategoryPage from '@src/pages/SearchCategory'
import { SearchPoiPage } from '@src/pages/SearchPoi'
import { SearchPoiRedirection } from '@src/pages/SearchPoi/Redirection'
import { StoryArticlePoiCollectionPage } from '@src/pages/StoryArticlePoiCollection'
import { SubprofilePage } from '@src/pages/Subprofile'
import { UpdateSuggestionPage } from '@src/pages/UpdateSuggestion'
import { WatchedPlacesPage } from '@src/pages/WatchedPlaces'
import { WinterSnackPage, WinterSnackModal } from '@src/pages/WinterSnack'
import WritePoiReviewPage from '@src/pages/WritePoiReview'
import { SubprofileHalfview } from '@src/widgets/SubprofileHalfview'

export const activities = {
  poi_detail: PoiDetailPage,
  poi_detail_modal: PoiDetailModal,
  create_poi_suggestion: CreateSuggestionPage,
  update_poi_suggestion: UpdateSuggestionPage,
  delete_poi_suggestion: DeleteSuggestionPage,
  pin_address: PinAddressPage,
  search_address: SearchAddressPage,
  search_category: SearchCategoryPage,
  search_poi: SearchPoiPage,
  search_poi_communityTag: SearchPoiRedirection,
  poi_review: WritePoiReviewPage,
  daangn_map: MapPage,
  daangn_map_modal: MapModal,
  daangn_map_report: MapReportPage,
  place_watch: WatchedPlacesPage,
  subprofile: SubprofilePage,
  subprofile_reviews: SubprofilePage,
  subprofile_halfview: SubprofileHalfview,
  purposeful_category: PurposefulCategoryPage,
  purposeful_category_filter_picker: OptionsDrawer,
  winter_snack: WinterSnackPage,
  winter_snack_modal: WinterSnackModal,
  story_article_poi_collection: StoryArticlePoiCollectionPage,

  fallback: FallbackScreen,
}

export type ActivitiesType = typeof activities
